import { Fragment, useState } from 'react'
import Query from 'components/Query'
import Timeline from 'components/Timeline'
import { gql, ApolloQueryResult } from '@apollo/client'
import { Pagination } from 'antd'

import {
  MODULE_VEHICLEVariables,
  MODULE_VEHICLE,
} from './__generated__/MODULE_VEHICLE'

export const MODULE_VEHICLE_QUERY = gql`
  query MODULE_VEHICLE($module_id: ID!, $paginate: Paginate) {
    module(module_id: $module_id) {
      id
      bike {
        vehicle_id
        vehicle_state_history(paginate: $paginate) {
          nodes {
            id
            state
            time
            triggers
          }
          pagination {
            current: page
            pageSize: per_page
            total
          }
        }
      }
    }
  }
`

const VehicleStateEvents = ({ module_id }: MODULE_VEHICLEVariables) => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const onPaginationChange = (clickedPage: number, pageSize: number) => {
    setPage(clickedPage)
    setPerPage(pageSize)
  }

  return (
    <Query
      query={MODULE_VEHICLE_QUERY}
      variables={{ module_id, paginate: { page, per_page: perPage } }}
      waitFor="data.module.bike.vehicle_state_history"
      pollInterval={0}
    >
      {({ data }: ApolloQueryResult<MODULE_VEHICLE>) => {
        const result = data.module?.bike?.vehicle_state_history
        if (!result) {
          return null
        }
        const { nodes, pagination } = result

        const items = nodes?.map(node => ({
          time: node.time,
          message: `${node.triggers.join(':')} -> ${node.state}`,
        }))

        return (
          <Fragment>
            <Timeline items={items} />
            <Pagination
              hideOnSinglePage
              showSizeChanger
              pageSizeOptions={['20', '40', '60']}
              current={pagination?.current}
              total={pagination?.total}
              pageSize={perPage}
              onChange={onPaginationChange}
              onShowSizeChange={onPaginationChange}
              style={{ float: 'right' }}
            />
          </Fragment>
        )
      }}
    </Query>
  )
}

export default VehicleStateEvents
