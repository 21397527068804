import bothLocked from 'images/both-locked.png'
import frontLocked from 'images/front-locked.png'
import rearLocked from 'images/rear-locked.png'
import unlocked from 'images/unlocked.png'
import unknown from 'images/lockUnknown.png'

import { VehicleLockState } from '__generated__/globalTypes'
import { ucfirst } from 'utils'

type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
  variant: VehicleLockState
}

export const LockIcon = ({ variant, ...rest }: Props) => {
  const size = 25
  const alt = ucfirst(variant.toString().replace('_', ' '))
  const props: React.ImgHTMLAttributes<HTMLImageElement> = {
    style: { width: size, height: size, marginRight: 6 },
    ...rest,
  }
  switch (variant) {
    case VehicleLockState.locked:
      return <img src={bothLocked} alt={alt} {...props} />
    case VehicleLockState.unlocked:
      return <img src={unlocked} alt={alt} {...props} />
    case VehicleLockState.front_lock_only:
      return <img src={frontLocked} alt={alt} {...props} />
    case VehicleLockState.rear_lock_only:
      return <img src={rearLocked} alt={alt} {...props} />
    default:
      return <img src={unknown} alt={alt} {...props} />
  }
}
