import { Select, SelectProps } from 'antd'
import useSchemeJobAssets from 'hooks/useSchemeJobAssets'
import { useMemo } from 'react'
import { getAssetCategoryName } from '../utils'

type Option = { label: string; value: string }
type Options = { label: string; options: Option[] }

interface IAssetSelect extends SelectProps {
  schemeId: string
}

const AssetSelect = ({ schemeId, disabled, ...props }: IAssetSelect) => {
  const assets = useSchemeJobAssets(schemeId)

  const filterOption = (input: string, option: Option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
    (option?.value ?? '').toLowerCase().includes(input.toLowerCase())

  const options = useMemo<Options[]>(
    () =>
      Object.values(
        assets?.reduce((acc, { assetTag, assetName, assetCategory }) => {
          const assetCategoryName = getAssetCategoryName(assetCategory)
          acc[assetCategory] = acc?.[assetCategory] || {
            label: assetCategoryName,
            options: [],
          }
          acc[assetCategory].options.push({
            label: assetName,
            value: assetTag,
          })
          return acc
        }, {}) ?? {},
      ),
    [assets],
  )

  return (
    <Select
      showSearch
      options={options}
      filterOption={filterOption}
      disabled={disabled || !assets}
      loading={!assets}
      {...props}
    />
  )
}

export default AssetSelect
