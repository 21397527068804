import * as routes from 'constants/routes'
import { UserCredential } from 'firebase/auth'
import { useEffect, useState } from 'react'

import {
  GoogleOutlined,
  LinkOutlined,
  LockOutlined,
  RollbackOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Box, Divider } from '@weareberyl/design-system'
import { Button, Form, Input } from 'antd'
import { MessageApi } from 'antd/lib/message'
import { getDefaultRoute } from 'components/Navigation'
import { useDevices } from 'hooks'
import { authentication } from 'utils/firebase'
import { trackLogin } from 'utils/firebase/firestore'

enum Method {
  PASSWORD = 'password',
  EMAIL_LINK = 'emailLink',
}

interface SignInFormProps {
  auth: {
    sendSignInLinkToEmail: (email: string) => Promise<void>
    signInWithEmailAndPassword: (
      email: string,
      password: string,
    ) => Promise<UserCredential>
    signInWithGoogleUsingPopup: () => Promise<UserCredential>
  }
  history: any
  message: MessageApi
}

const SignInForm = ({ auth, history, message }: SignInFormProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [method, setMethod] = useState<Method | null>(null)
  const { fetchDevices } = useDevices()
  const [form] = Form.useForm()

  useEffect(() => {
    if (error) {
      // NOTE: we can't send up the actual firebase error here, as it
      // may reveal whether the user account exists or not
      message.error('Login failed: Please check your credentials')
      setError('')
    }
  }, [error])

  const postLoginItems = async () => {
    await trackLogin()
    fetchDevices()
    if (window.location.pathname.endsWith('/logout')) {
      // Redirect user away from /logout
      const user = await authentication.loadCurrentUser()
      history.push(getDefaultRoute(user, ''))
    }
  }

  const loginWithGoogle = async () => {
    setSubmitting(true)

    try {
      await auth.signInWithGoogleUsingPopup()
      await postLoginItems()
    } catch (err) {
      setError(`${err}`)
    } finally {
      setSubmitting(false)
    }
  }

  const onFinish = async ({
    email,
    password,
  }: {
    email: string
    password: string | null
  }): Promise<void> => {
    setSubmitting(true)

    try {
      switch (method) {
        case Method.PASSWORD:
          await auth.signInWithEmailAndPassword(email, password as string)
          await postLoginItems()
          break
        case Method.EMAIL_LINK:
          await auth.sendSignInLinkToEmail(email)
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          history.push(routes.MAGIC_LINK)
          break
        default:
          break // skipcq TCV-001 - this code will never be executed
      }
    } catch (err) {
      setError(`${err}`)
    } finally {
      setSubmitting(false)
    }
  }

  if (!method) {
    return (
      <Box style={{ gap: 10 }}>
        <Button
          size="large"
          type="primary"
          icon={<GoogleOutlined />}
          onClick={loginWithGoogle}
          style={{ height: 50 }}
        >
          Continue with Google
        </Button>
        <Divider />
        <Button
          size="large"
          type="default"
          icon={<LinkOutlined />}
          onClick={() => setMethod(Method.EMAIL_LINK)}
        >
          Continue with Magic Link
        </Button>
        <Button
          size="large"
          type="default"
          icon={<LockOutlined />}
          onClick={() => setMethod(Method.PASSWORD)}
        >
          Continue with Password
        </Button>
      </Box>
    )
  }

  return (
    <Form form={form} size="large" onFinish={onFinish}>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Please enter your email',
          },
          {
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: 'Please enter a valid email address',
          },
        ]}
      >
        <Input
          prefix={<UserOutlined />}
          type="email"
          placeholder="Email"
          data-testid="email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: method === Method.PASSWORD,
            message: 'Please enter your password',
          },
        ]}
        hidden={method !== Method.PASSWORD}
      >
        <Input
          prefix={<LockOutlined />}
          type="password"
          placeholder="Password"
          data-testid="password"
        />
      </Form.Item>
      <Form.Item>
        <Button block type="primary" htmlType="submit" loading={submitting}>
          Log in
        </Button>
        <Button
          icon={<RollbackOutlined />}
          type="link"
          htmlType="button"
          onClick={() => setMethod(null)}
        >
          Select a different login method
        </Button>
      </Form.Item>
    </Form>
  )
}

export default SignInForm
