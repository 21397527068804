import React from 'react'

import { Flex, Text, Box } from '@weareberyl/design-system'

export const InputWrapper: React.FC<{ label: string }> = ({
  children,
  label,
}) => (
  <Flex alignItems="center" mb={2} height={42}>
    <Text mr={2}>{label}</Text>
    <Box>{children}</Box>
  </Flex>
)
