import React, { ReactNode } from 'react'
import { groupBy } from 'lodash'
import { endOfDay, isToday, isYesterday, parseISO } from 'date-fns'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Timeline } from 'antd'
import { formatDatetime, capitalize, getIcon } from 'utils'

export type TimelineHistoryItem = {
  time: string
  message?: string
}

type Props = {
  items: TimelineHistoryItem[]
  secondaryTitle: (items?: TimelineHistoryItem[]) => string | ReactNode
  children: (
    item: TimelineHistoryItem,
    i: number,
    items: TimelineHistoryItem[],
  ) => ReactNode
}

const formatDate = (stamp: string) => {
  if (!stamp) {
    return ''
  }
  if (isToday(parseISO(stamp))) {
    return 'Today'
  }
  if (isYesterday(parseISO(stamp))) {
    return 'Yesterday'
  }
  return formatDatetime(stamp, 'date')
}

const defaultItemWrapper = (
  item: TimelineHistoryItem,
  i: number,
  _groupedItems: TimelineHistoryItem[],
) => {
  const { time, message } = item
  if (!message) {
    return null
  }

  const icon = getIcon(message)

  return (
    <div className="mb-1" key={i}>
      <LegacyIcon
        style={{ fontSize: '16px', color: icon.colour }}
        type={icon.icon}
      />{' '}
      <span style={{ fontWeight: 600 }}>{capitalize(message)}</span> -{' '}
      {formatDatetime(time, 'time')}
    </div>
  )
}

const TimelineView = (props: Props) => {
  const groupedItems = groupBy(props.items, i =>
    endOfDay(parseISO(i.time)).toISOString(),
  )
  if (!groupedItems) {
    return null
  }

  return (
    <Timeline>
      {Object.keys(groupedItems).map((stamp: string, i: number) => {
        const gItems = groupedItems[stamp]

        return (
          <Timeline.Item key={i}>
            <h2>
              {formatDate(stamp)} {props.secondaryTitle(gItems)}
            </h2>
            <div>
              {gItems.map((item, j) => props.children(item, j, gItems))}
            </div>
          </Timeline.Item>
        )
      })}
    </Timeline>
  )
}

TimelineView.defaultProps = {
  secondaryTitle: () => null,
  children: defaultItemWrapper,
}

export default TimelineView
