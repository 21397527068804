import { Fragment } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Flex, Box, Card, Heading, Text } from '@weareberyl/design-system'

import { getModuleTitle } from 'components/ModuleHeader'
import HardwareTypeIcon from '../../ModuleHeader/HardwareTypeIcon'
import ModuleLink from 'components/ModuleLink'
import ZoneLink from 'components/ZoneLink'
import LegendCard from './Card'
import { isDockedScheme } from 'utils'

import {
  ZONE_LEGEND,
  ZONE_LEGEND_zone_modules_nodes,
} from './__generated__/ZONE_LEGEND'
import { ZoneType } from '__generated__/globalTypes'
import ZoneTypeStatus from 'components/Zone/ZoneTypeStatus'
import { Divider, message } from 'antd'
import { getDerivedModule, getDerivedZone } from 'utils/derived'

const ZONE_MODULE_PAGINATION = 8

const ZONE_LEGEND_QUERY = gql`
  query ZONE_LEGEND($zone_id: ID!, $paginate: Paginate, $where: [ModuleWhere]) {
    zone(zone_id: $zone_id) {
      id
      name
      capacity
      geom
      all_commissioned_modules_count
      docked_vehicles_count
      zone_type
      bikes: all_commissioned_modules_count(hardware_type: beryl_bike)
      ebikes: all_commissioned_modules_count(hardware_type: bbe)
      scooters: all_commissioned_modules_count(hardware_type: scooter)
      modules(paginate: $paginate, where: $where) {
        nodes {
          id
          hardware_type
          bike {
            vehicle_id
            frame {
              id
            }
          }
          vehicle {
            frame_type
          }
        }
      }
      scheme {
        id
      }
    }
  }
`

const ZoneModuleLink = (m: ZONE_LEGEND_zone_modules_nodes) => (
  <Box key={m.id} mb={2}>
    <ModuleLink id={m.id}>
      <Card variant="gray" style={{ paddingTop: 12, paddingBottom: 12 }}>
        <Flex justifyContent="space-between">
          <Text variant="small">
            {getModuleTitle(m?.bike?.frame?.id ?? null, m?.hardware_type)}
          </Text>
          <HardwareTypeIcon hardwareType={m.hardware_type} mt={'2px'} />
        </Flex>
      </Card>
    </ModuleLink>
  </Box>
)

export interface ZoneLegendProps {
  zone_id: string
  onClose: () => void
  where?: object | null
}

export const ZoneLegend = ({ zone_id, onClose, where }: ZoneLegendProps) => {
  const { loading, error, data } = useQuery<ZONE_LEGEND>(ZONE_LEGEND_QUERY, {
    variables: {
      zone_id,
      paginate: { page: 1, per_page: ZONE_MODULE_PAGINATION },
      where: where,
    },
    pollInterval: 0,
  })

  if (loading) return null

  if (error) {
    message.error(error.message)
  }

  const zone = getDerivedZone(data?.zone, data?.zone?.scheme?.id)
  const showDockCount = zone && isDockedScheme(data?.zone?.scheme?.id)
  const modulesInZone = zone?.all_commissioned_modules_count ?? 0
  const zoneType = zone?.zone_type ?? ZoneType.normal

  const zoneInformationLink = (
    <Flex justifyContent="center" my={2}>
      <ZoneLink id={zone_id}>
        <Text variant="small" fontFamily="Hellix-SemiBold">
          Bay information
        </Text>
      </ZoneLink>
    </Flex>
  )

  const footer = { custom: zoneInformationLink }
  if (modulesInZone) {
    footer.custom = (
      <Fragment>
        <Heading variant="h4" mt={2} mb={3}>
          Vehicles in bay
        </Heading>
        {zone?.modules?.nodes?.map(getDerivedModule).map(ZoneModuleLink)}
        {zoneInformationLink}
      </Fragment>
    )
  }

  return (
    <LegendCard onClose={onClose} footer={footer}>
      <Box flex="1">
        <Flex flexDirection="column" alignItems="center">
          <ZoneTypeStatus zoneType={zoneType} />
          <Heading variant="h3">{zone?.name}</Heading>
          <Divider />
        </Flex>
        <Flex justifyContent="space-between" width="100%" mb={3}>
          <Flex flex="1" mr={3}>
            <Text variant="small">Total: </Text>
            <Text variant="small" fontFamily="Hellix-SemiBold">
              {zone?.all_commissioned_modules_count}
            </Text>
          </Flex>
          <Flex flex="1">
            <Text variant="small">Spaces: </Text>
            <Text variant="small" fontFamily="Hellix-SemiBold">
              {zone?.capacity}
            </Text>
          </Flex>
        </Flex>
        {showDockCount && (
          <Flex justifyContent="space-between" width="100%" mb={3}>
            <Flex flex="1" mr={3}>
              <Text variant="small">Docked: </Text>
              <Text variant="small" fontFamily="Hellix-SemiBold">
                {zone?.docked_vehicles_count}
              </Text>
            </Flex>
          </Flex>
        )}
        <Flex justifyContent="space-between" width="100%" mb={3}>
          <Flex flex="1" mr={3}>
            <Text variant="small">Bikes: </Text>
            <Text variant="small" fontFamily="Hellix-SemiBold">
              {zone?.bikes}
            </Text>
          </Flex>
          <Flex flex="1">
            <Text variant="small">E-Bikes: </Text>
            <Text variant="small" fontFamily="Hellix-SemiBold">
              {zone?.ebikes}
            </Text>
          </Flex>
        </Flex>
        <Flex width="100%" mb={2}>
          <Flex flex="1" mr={3}>
            <Text variant="small">E-Scooters: </Text>
            <Text variant="small" fontFamily="Hellix-SemiBold">
              {zone?.scooters}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </LegendCard>
  )
}

export default ZoneLegend
