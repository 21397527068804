import { Flex, Box, Icon, Text, ThemeColors } from '@weareberyl/design-system'

type CheckVariantProps = {
  box: { bg: keyof ThemeColors }
  icon: { type: 'tick' | 'cross'; color: keyof ThemeColors }
}

type CheckVariant = 'checked' | 'unchecked' | 'pending'
const variants: Record<CheckVariant, CheckVariantProps> = {
  checked: {
    box: { bg: 'mint' },
    icon: { type: 'tick', color: 'jade' },
  },
  unchecked: {
    box: { bg: 'prawn' },
    icon: { type: 'cross', color: 'brick' },
  },
  pending: {
    box: { bg: 'wheat' },
    icon: { type: 'tick', color: 'jaffaPressed' },
  },
}

const Check = ({
  checked,
  text,
  pending,
}: {
  checked: boolean
  text: string
  pending?: boolean
}): JSX.Element => {
  let variant: CheckVariant = checked ? 'checked' : 'unchecked'
  if (pending) {
    variant = 'pending'
  }
  const variantProps = variants[variant]
  return (
    <Flex mb={1}>
      <Box
        {...variantProps.box}
        width={16}
        height={16}
        borderRadius={10}
        alignSelf="center"
        justifyContent="center"
        alignItems="center"
        mr={2}
      >
        <Icon {...variantProps.icon} width={9} height={9} />
      </Box>
      <span>
        <Text variant="small" fontFamily="Hellix-SemiBold">
          {text}
        </Text>
      </span>
    </Flex>
  )
}

export default Check
