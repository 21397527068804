import { CURRENT_USER } from 'types'
import { isWithinInterval, addDays } from 'date-fns'
import { Badge, Box, PButton } from '@weareberyl/design-system'
import useCurfews from 'hooks/Curfew/useCurfews'
import { getCurfewName } from 'components/Curfew/utils'
import { useHistory } from 'react-router-dom'
import { hasPermission } from 'components/Session'
import { message } from 'antd'
import { AvailabilityRestrictionDataFragment } from 'gql/generated/graphql'
import { useCurrentScheme, useCurrentUser } from 'hooks'

const parseIntBase10 = (s: string) => parseInt(s, 10)

function restrictionApplicable(start_time: string, end_time: string) {
  const [start_h, start_m, start_s] = start_time.split(':').map(parseIntBase10)
  const [end_h, end_m, end_s] = end_time.split(':').map(parseIntBase10)

  const start = new Date()
  start.setHours(start_h, start_m, start_s)

  let end = new Date()
  end.setHours(end_h, end_m, end_s)
  if (end.getTime() < start.getTime()) {
    end = addDays(end, 1)
  }

  return isWithinInterval(new Date(), { start, end })
}

interface IRestrictionBanner {
  schemeId: string
  curfew: AvailabilityRestrictionDataFragment
  clickable?: boolean
}

const RestrictionBanner = ({
  schemeId,
  curfew,
  clickable = false,
}: IRestrictionBanner) => {
  const { start_time, end_time, vehicle_type, frame_type, propulsion_type } =
    curfew

  const history = useHistory()

  if (!restrictionApplicable(start_time, end_time)) {
    return null
  }

  const name = getCurfewName(vehicle_type, frame_type ?? null, propulsion_type)

  const message = `🛑 ${name}\n${start_time} - ${end_time}`

  const onPress = () =>
    clickable ? history.push(`/scheme/${schemeId}/curfew`) : null

  return (
    <PButton onPress={onPress}>
      <Badge color="brick" m={1} text={message} solid variant="brick" />
    </PButton>
  )
}

const Restrictions = () => {
  const { currentSchemeId } = useCurrentScheme()
  const [user] = useCurrentUser()
  const { data, error } = useCurfews(currentSchemeId)
  if (error) {
    message.error(`Error fetching curfews: ${error.message}`)
    return null
  }
  if (!data || data?.length === 0) return null

  const hasPermissionsToViewCufew = (
    user?: CURRENT_USER,
    schemeId?: string | null,
  ) => {
    if (!user || !schemeId) return false
    return hasPermission(user.roles, 'vehicle_curfew.view', parseInt(schemeId))
  }

  const clickable = hasPermissionsToViewCufew(user, currentSchemeId)

  return (
    <Box m={1}>
      {data.map(curfew => (
        <RestrictionBanner
          key={`curfewBanner:${curfew.id}`}
          schemeId={currentSchemeId ?? ''}
          curfew={curfew}
          clickable={clickable}
        />
      ))}
    </Box>
  )
}

export default Restrictions
