export const LANDING = '/'
export const SIGN_IN = '/signin'
export const MAGIC_LINK = '/signin/confirm'
export const ACCOUNT = '/account'
export const MAP = '/map'
export const MODULE = '/bike/:id'
export const MODULES = '/bike'
// this is a hack to show all modules (outside of scheme context)
export const ALL_MODULES = '/module'
export const ZONE = '/zone/:id'
export const ZONES = '/zone'
export const ZONES_SYNC = '/zone/sync'
export const ZONE_HISTORY = '/zone/:id/history'
export const SERVICE_AREA = '/service-area/:id([0-9]+)'
export const SERVICE_AREAS = '/service-area'
export const SERVICE_AREAS_SYNC = '/service-area/sync'
export const JOURNEY = '/journey/:id'
export const JOURNEYS = '/journey'
export const ALL_JOURNEYS = '/journeys'
export const PRODUCTS = '/products'
export const USERS = '/user'
export const ALL_USERS = '/users'
export const USER = '/user/:id'
export const SCHEMES = '/scheme'
export const SCHEME = '/scheme/:scheme_id([0-9]{1,2})'
export const WHITELISTS = '/whitelist'
export const INVOICES = '/invoice'
export const INVOICE = '/invoice/:id'
export const SOAK_TEST = '/soaktest'
export const DEPLOYMENTS = '/deployment'
export const DEPLOYMENT = '/deployment/:id'
export const CURFEW = '/curfew'
export const AUTOMATIONS = '/automations'
export const CAMPAIGNS = '/campaigns'
export const CAMPAIGN = '/campaigns/edit/:id'
export const NEW_CAMPAIGN = '/campaigns/new'
export const VALIDATION_RULES = '/validation-rules'

export const JOBS = '/job'
export const ALL_JOBS = '/jobs'
export const JOB = '/job/:id'

export const withScheme = (path: string) =>
  `/scheme/:scheme_id([0-9]{1,2})${path}`
export const prependScheme = (path: string, schemeId: string) =>
  `/scheme/${schemeId}${path}`

// Optionally remove /scheme/{number} prefix to get the first part of the url
export const stripScheme = (path: string) => {
  const matches = path.match(/(\/scheme\/\d+)?(\/((\w+)|-?)+)\/?/)
  return matches?.[2]
}
