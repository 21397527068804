import { gql, useMutation } from '@apollo/client'
import * as types from './__generated__/requestEventEnd'

const query = gql`
  mutation requestEventEnd($vehicleId: GUID!) {
    vehicle_event_end(vehicle_id: $vehicleId)
  }
`

export default function useEventEnd(
  variables?: types.requestEventEndVariables,
) {
  return useMutation<types.requestEventEnd, types.requestEventEndVariables>(
    query,
    {
      variables,
      context: {
        useApolloNetworkStatus: false,
      },
    },
  )
}
