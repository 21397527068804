import { useContext } from 'react'
import { ConfigContext } from 'context/configContext'

export default function useJobTypes() {
  const { jobTypes } = useContext(ConfigContext)
  return (
    jobTypes ?? {
      jobOptions: [],
      jobOptionsByRole: _ => [],
      jobCodeLookup: {},
      roleByJobLookup: {},
    }
  )
}
