import { Layout } from 'antd'
import { ReactNode } from 'react'

import Devices from 'components/Devices'
import Footer from 'components/Footer'
import Navigation from 'components/Navigation'
import Restrictions from 'components/RestrictionBanner'
import { SchemeNav } from 'components/Scheme'

const { Sider, Content, Header } = Layout

const Main = ({ children }: { children: ReactNode }) => (
  <>
    <Devices />
    <Layout style={{ minHeight: '100vh' }}>
      <Sider theme="light" className="sm-hide">
        <div className="sticky-menu">
          <SchemeNav />
          <Restrictions />
          <Navigation />
          <Footer />
        </div>
      </Sider>
      <Layout>
        <Header className="mobile-header sm-only">
          <SchemeNav mobile />
          <Navigation mobile />
        </Header>
        <Content className="app app-wrapper">{children}</Content>
      </Layout>
    </Layout>
  </>
)

export default Main
