import { Link, generatePath } from 'react-router-dom'
import { prependScheme, ZONE_HISTORY } from '../../../constants/routes'
import { TextLink } from '@weareberyl/design-system'

type ViewHistoryProps = {
  id: string
  schemeId: string
}

export const ViewHistory = ({ id, schemeId }: ViewHistoryProps) => {
  const historyPath = generatePath(ZONE_HISTORY, { id })
  const fullPath = prependScheme(historyPath, schemeId)

  return (
    <TextLink variant="grape" size="small">
      <Link to={fullPath} style={{ color: 'currentColor' }}>
        View History
      </Link>
    </TextLink>
  )
}
