import { CloseOutlined } from '@ant-design/icons'
import { Flex, Card, Heading } from '@weareberyl/design-system'

export default ({ onClose, children, footer }) => {
  return (
    <div className="grid-legend">
      <Card footer={footer}>
        <Flex justifyContent="flex-end">
          <Heading variant="h1">
            <CloseOutlined onClick={onClose} />
          </Heading>
        </Flex>
        <Flex>{children}</Flex>
      </Card>
    </div>
  )
}
