import { some, find } from 'lodash'

import Table from 'components/Table'

import { isNotNullOrUndefined } from 'utils/types'
import type { SchemeProductListItem } from './data'

/**
 * Render a table of configs, transposed into rows so each value can be listed for each hardware_type
 */
export const ConfigTable = ({
  schemeProduct: { configs_v3 },
}: {
  schemeProduct: SchemeProductListItem
}): JSX.Element => {
  const vehicle_categories = Object.keys(configs_v3)

  // Get all product config keys the first hardware_type config that is present
  // They should all be the same cos the query requests all fields
  const firstConfig = find<{ __typename: string } | null | undefined>(
    [
      configs_v3.bike,
      configs_v3.ebike,
      configs_v3.escooter,
      configs_v3.cargo_ebike,
    ],
    isNotNullOrUndefined,
  )
  const keys = Object.keys(firstConfig ?? {}).filter(k => k !== '__typename')

  // Loop through config keys and combine values for each from all hardware types into a row object.
  const nodes = keys.reduce((prev, field) => {
    const getFormatted = (category: string) => {
      const val = configs_v3[category]?.[field]
      switch (typeof val) {
        case 'object':
          return val?.formatted_amount ?? val
        case 'boolean':
          return val ? 'True' : 'False'
        default:
          return val
      }
    }
    // Get value for this key for each hardware type
    const cols = vehicle_categories.reduce((prev, hw_type) => {
      prev[hw_type] = getFormatted(hw_type)
      return { ...prev, [hw_type]: getFormatted(hw_type) }
    }, {})

    // Skip row if all cols have no value
    if (!some(cols, val => ![undefined, '', null].includes(val))) {
      return prev
    }
    const row = {
      field,
      ...cols,
    }

    return [...prev, row]
  }, [])

  return (
    <Table
      hideProgress
      columns={[
        {
          title: 'Field',
          dataIndex: 'field',
          align: 'right',
          render: (field: string) => <strong>{field}</strong>,
        },
        { title: 'e-Bike', dataIndex: 'ebike' },
        { title: 'Pedal', dataIndex: 'bike' },
        { title: 'Scooter', dataIndex: 'escooter' },
        { title: 'Cargo Bike', dataIndex: 'cargo_ebike' },
      ]}
      data={{ table: { nodes } }}
    />
  )
}
