import { gql, useMutation } from '@apollo/client'
import * as types from './__generated__/requestEventStart'

const query = gql`
  mutation requestEventStart($vehicleId: GUID!) {
    vehicle_event_start(vehicle_id: $vehicleId)
  }
`

export default function useEventStart(
  variables?: types.requestEventStartVariables,
) {
  return useMutation<types.requestEventStart, types.requestEventStartVariables>(
    query,
    {
      variables,
      context: {
        useApolloNetworkStatus: false,
      },
    },
  )
}
