import { Flex, Box, Heading, Text } from '@weareberyl/design-system'

import { SCHEME_EMOJIS } from 'constants/domain'
import { useCurrentScheme } from 'hooks'
import { LoadingComponent } from 'components/Query/utils'
import { useUserSchemesQuery } from 'gql/generated/graphql'

const UserSchemes = ({ userId }: { userId: string }) => {
  const { setSchemeIdAndUpdateLocation } = useCurrentScheme()
  const { data, loading } = useUserSchemesQuery({
    variables: { userId },
    pollInterval: 0,
  })

  return (
    <Box style={{ flex: '1', minWidth: '150px', alignItems: 'flex-start' }}>
      <Heading mb={1}>Active in schemes:</Heading>
      {!data && loading && <LoadingComponent />}
      {data?.user?.user_schemes.map(({ scheme, scheme_id }) => (
        <button
          style={{
            cursor: 'pointer',
            border: 0,
            padding: 0,
            background: 'none',
          }}
          key={scheme_id}
          title={`Switch to ${scheme?.name ?? scheme_id}`}
          onClick={() => setSchemeIdAndUpdateLocation(scheme_id)}
        >
          <Flex alignItems="center" style={{ flexWrap: 'nowrap' }}>
            <Text>{SCHEME_EMOJIS[scheme_id]}</Text>
            <Text variant="mini" ml={2}>
              {scheme?.name ?? scheme_id}
            </Text>
          </Flex>
        </button>
      ))}
    </Box>
  )
}

export default UserSchemes
