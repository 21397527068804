import { useState, memo, useEffect } from 'react'
import { Form, FormProps } from 'antd'
import { Modal, Input, Select, Cascader } from 'antd'
import { Text, colors } from '@weareberyl/design-system'

import SchemeSelect from 'components/Scheme/SchemeSelect'
import { convertStringToLatLng } from 'utils'
import {
  JobListQuery_table_JobConnection_nodes_Job as Job,
  JobStatusEnum,
} from 'gql/generated/graphql'
import { useJobTypes } from 'hooks'
import AssetSelect from './AssetSelect'

export const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
}

// selectFilterOption :: Option a => (String, a) -> Boolean
export function selectFilterOption(input, option) {
  const value = option.props.children || ''
  return value.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

export type JobInput = {
  details: {
    assetTag: undefined
    reportedLocation: undefined
    locationDescription: undefined
    status: undefined
    scheme: {
      id: undefined
    }
    role: undefined
    taskId: undefined
  }
}

export type NewJob = {
  details: {
    assetTag?: string
    scheme?: {
      id: string
      name: string
    }
    status: JobStatusEnum.new
    reportedLocation?: string
    locationDescription?: string
  }
}

interface JobModalProps extends FormProps {
  isVisible: boolean
  onOk: (job: Job) => void
  onCancel: () => void
  job: NewJob | Job
  title: string
  loading?: boolean
}

const JobModal = ({
  job,
  isVisible,
  onCancel,
  onOk,
  title,
  loading = false,
}: JobModalProps) => {
  const [form] = Form.useForm()

  const [isValidLatLng, setIsValidLatLng] = useState(false)
  const { jobOptions } = useJobTypes()

  const jobFill = {
    uuid: undefined,
    ...job,
    details: {
      reportedLocation: undefined,
      role: undefined,
      taskId: undefined,
      priority: undefined,
      notes: undefined,
      ...job?.details,
      scheme: { id: undefined, ...job?.details?.scheme },
    },
  }

  const {
    uuid,
    details: {
      assetTag,
      reportedLocation,
      locationDescription,
      status,
      scheme: { id: schemeId },
      role,
      taskId,
      priority,
      notes,
    },
  } = jobFill

  const inputSchemeId = Form.useWatch('scheme_id', form)
  const inputAssetTag = Form.useWatch('assetTag', form)
  const hideAsset = !inputSchemeId
  const hideBody = !inputAssetTag
  const isZone = inputAssetTag?.match(/^zone_/)
  const hideLocation = Boolean(!inputAssetTag || isZone)

  useEffect(() => {
    if (assetTag) return
    form.setFieldValue('assetTag', null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSchemeId])

  const validateLatLng = (value: string) => {
    const latLng = convertStringToLatLng(value)
    setIsValidLatLng(Boolean(latLng))
  }

  const onFinish = fields => {
    // this is provided as an array for UI
    // categorization. eg [ "Redistribution Driver", "RD02"]
    // but the backend only needs the last "taskId" value.
    const [, taskId] = fields.jobType
    delete fields.jobType

    onOk({
      ...(jobFill as Job),
      details: {
        ...fields,
        taskId,
      },
    })
  }
  const onFinishFailed = errorInfo => {
    console.log(errorInfo)
  }

  return (
    <Modal
      title={title}
      open={isVisible}
      okButtonProps={{ disabled: loading }}
      onOk={() => form.submit()}
      onCancel={onCancel}
    >
      <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item
          label="Scheme"
          name="scheme_id"
          initialValue={schemeId}
          rules={[
            {
              required: true,
              message: 'Please select a scheme',
            },
          ]}
          hasFeedback
          {...formItemLayout}
        >
          <SchemeSelect scheme_id={schemeId} disabled={Boolean(schemeId)} />
        </Form.Item>
        <Form.Item
          label="Asset"
          name="assetTag"
          hidden={hideAsset}
          initialValue={assetTag}
          rules={[
            {
              required: true,
              message: 'Please select an asset',
            },
          ]}
          hasFeedback
          {...formItemLayout}
        >
          <AssetSelect schemeId={inputSchemeId} disabled={Boolean(assetTag)} />
        </Form.Item>
        <Form.Item
          label="Job Type"
          name="jobType"
          hidden={hideBody}
          initialValue={role === undefined ? '' : [role, taskId]}
          rules={[
            {
              required: true,
              message: 'Please select a job type',
            },
          ]}
          hasFeedback
          {...formItemLayout}
        >
          <Cascader
            options={jobOptions ?? undefined}
            disabled={Boolean(uuid)}
            expandTrigger="hover"
          />
        </Form.Item>
        <Form.Item
          label="Status"
          name="status"
          hidden={hideBody}
          initialValue={status}
          rules={[
            {
              required: true,
              message: 'Please select a status',
            },
          ]}
          hasFeedback
          {...formItemLayout}
        >
          <Select
            style={{ width: 200 }}
            placeholder="Select a Status"
            optionFilterProp="children"
            filterOption={selectFilterOption}
            options={[
              { value: 'new', label: 'New' },
              { value: 'inProgress', label: 'In Progress' },
              { value: 'complete', label: 'Complete' },
              { value: 'blocked', label: 'Blocked' },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Priority"
          name="priority"
          hidden={hideBody}
          initialValue={priority}
          rules={[
            {
              required: true,
              message: 'Please select a priority',
            },
          ]}
          hasFeedback
          {...formItemLayout}
        >
          <Select
            style={{ width: 200 }}
            placeholder="Select a Priority"
            optionFilterProp="children"
            filterOption={selectFilterOption}
            options={[
              { value: 'true', label: 'High' },
              { value: 'false', label: 'Low' },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Location"
          name="reportedLocation"
          hidden={hideLocation}
          initialValue={reportedLocation}
          hasFeedback
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          <Input
            onBlur={() =>
              validateLatLng(form.getFieldValue('reportedLocation'))
            }
            placeholder="Enter a Location"
            style={{ marginBottom: 0 }}
          />
        </Form.Item>
        <div
          style={{
            marginBottom: '20px',
            marginLeft: '120px',
          }}
        >
          {isValidLatLng && (
            <Text color={colors.berylGreen} variant="small">
              Valid lat/lon coords - these will be used on the job map
            </Text>
          )}
        </div>
        <Form.Item
          label="Location desc"
          name="locationDescription"
          hidden={hideLocation}
          initialValue={locationDescription}
          {...formItemLayout}
        >
          <Input.TextArea
            placeholder="Location description"
            autoSize={{ minRows: 1, maxRows: 6 }}
            style={{ marginBottom: 0 }}
          />
        </Form.Item>
        <Form.Item
          label="Notes"
          name="notes"
          hidden={hideBody}
          initialValue={notes}
          {...formItemLayout}
          style={{ marginBottom: 0 }}
        >
          <Input.TextArea
            placeholder="Job notes"
            autoSize={{ minRows: 2, maxRows: 6 }}
            style={{ marginBottom: 0 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default memo(JobModal)
