import React from 'react'
import { authentication } from 'utils/firebase'
import { Center } from 'components/Layout'
import { message } from 'antd'

export default () => (
  <Center containerStyle={{ maxWidth: '800px', textAlign: 'center' }}>
    <h1>{"Please confirm it's you!"}</h1>
    <p>
      {"We've just sent you an email to"}{' '}
      <b>{authentication.getMagicLinkEmail()}</b> with a magic link.
    </p>
    <p>
      {"Didn't get an email?"}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}{' '}
      <a
        onClick={async () => {
          try {
            const email = authentication.getMagicLinkEmail()
            if (!email) {
              message.error("Coudln't find email")
              return
            }
            await authentication.sendSignInLinkToEmail(email)
            message.info("It's on its way!")
          } catch (err) {
            message.error(err.message)
          }
        }}
      >
        resend
      </a>
    </p>
  </Center>
)
