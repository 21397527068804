import { useJobQuery } from 'gql/generated/graphql'
import useWrappedQuery from './useWrappedQuery'

const useJob = (job_id: string) =>
  useWrappedQuery(
    useJobQuery({
      variables: { job_id },
      pollInterval: 0,
      errorPolicy: 'ignore',
    }),
    data => data?.job,
  )

export default useJob
