import React from 'react'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Box, Flex, Badge, BoxProps } from '@weareberyl/design-system'

interface Props extends BoxProps {
  text: string
  icon: string
  iconColor: string
  textColor: string
  badgeColor?: string
}

const AutomationIconRow = ({
  text,
  icon,
  iconColor,
  textColor,
  badgeColor = 'transparent',
  ...props
}: Props) => {
  const badge_mx = badgeColor === 'transparent' ? 0 : 3
  return (
    <Box {...props}>
      <Flex alignItems="center" justifyContent="space-evenly">
        <LegacyIcon type={icon} style={{ fontSize: 20, color: iconColor }} />
        <Badge
          mx={2}
          px={badge_mx}
          style={{
            backgroundColor: badgeColor,
            border: 'none',
          }}
          color={textColor}
          text={text}
        />
      </Flex>
    </Box>
  )
}

export default AutomationIconRow
