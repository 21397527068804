import { Box, Card, Text } from '@weareberyl/design-system'
import { Switch } from 'antd'
import { WebMercatorViewport } from 'deck.gl'
import { useState } from 'react'

import Map, { BasisMap } from 'components/Map'
import {
  JobIconLayer,
  ServiceAreaGeoJsonLayer,
  ZoneIconLayers,
} from 'components/Map/layers'
import Legend, { LegendProps } from 'components/Map/Legend'
import withSchemeServiceAreas, {
  SERVICE_AREAS_GEOJSON,
} from 'components/Scheme/withSchemeServiceAreas'
import {
  useZonesMapQuery,
  JobListQuery_table_JobConnection_nodes_Job as Job,
  ZonesMapQuery_all_zones_ZoneConnection_nodes_Zone as Zone,
} from 'gql/generated/graphql'
import { calculateSchemeCoordinates } from 'utils/calculateSchemeCoordinates'

type Pin<T> = Record<string, unknown> & Record<'object', T>

const defaultLegendProps: LegendProps = { type: null }

const LayerToggle = ({ showZones, setShowZones }) => {
  return (
    <Card className="map-filter-card" mt={2} p={0} style={{ width: 140 }}>
      <Box
        p={3}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text>Bays</Text>
        <Switch checked={showZones} onChange={() => setShowZones(!showZones)} />
      </Box>
    </Card>
  )
}

const viewport = new WebMercatorViewport(BasisMap.defaultProps)

const generateMapLayer = (jobs: Job[], options) =>
  JobIconLayer({
    layerId: 'jobIcons',
    jobs,
    onClick: options.onClick,
  })

const generateServiceAreasLayer = serviceAreas =>
  ServiceAreaGeoJsonLayer({
    serviceAreas,
    layerId: 'service-area-layer',
  })

const generateZoneLayer = (zones: Zone[], options) =>
  ZoneIconLayers({
    zones,
    layerId: 'zone-layer',
    iconLayerProps: {
      visible: options.visible,
      onClick: options.onClick,
    },
    textLayerProps: {
      visible: options.visible,
    },
  })

const JobsMap = ({
  nodes,
  schemeId,
  serviceAreas,
}: {
  nodes: Job[]
  schemeId: string
  serviceAreas: SERVICE_AREAS_GEOJSON
}) => {
  const [legendProps, setLegendProps] =
    useState<LegendProps>(defaultLegendProps)
  const [showZones, setShowZones] = useState<boolean>(false)

  const { data } = useZonesMapQuery({
    pollInterval: 0,
    variables: {
      scheme_id: schemeId,
      suspended: false,
    },
  })

  const zoneNodes = data?.all_zones.nodes ?? []

  const serviceAreaLayer = generateServiceAreasLayer(serviceAreas)

  const mapLayer = generateMapLayer(nodes, {
    onClick: (pin: Pin<Job>) =>
      setLegendProps({ type: 'Job', job: pin.object }),
  })

  const zoneLayer = generateZoneLayer(zoneNodes, {
    visible: showZones,
    onClick: (zone: Pin<Zone>) =>
      setLegendProps({ type: 'Zone', zone_id: zone.object.id }),
  })

  const schemeCoords = calculateSchemeCoordinates(serviceAreas, viewport)

  return (
    <>
      <Box height="calc(100vh - 300px)">
        <Map
          layers={[serviceAreaLayer, zoneLayer, mapLayer]}
          zoom={schemeCoords?.zoom}
          longitude={schemeCoords?.coords?.longitude}
          latitude={schemeCoords?.coords?.latitude}
        >
          {legendProps.type && (
            <Legend
              {...legendProps}
              onClose={() => setLegendProps({ type: null })}
            />
          )}
        </Map>
      </Box>
      <LayerToggle showZones={showZones} setShowZones={setShowZones} />
    </>
  )
}

export default withSchemeServiceAreas(JobsMap)
