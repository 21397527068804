import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { unassignJobsVariables } from './__generated__/unassignJobs'
import { JobDocument, JobListDocument } from 'gql/generated/graphql'

const unassignJobsMutation = gql`
  mutation unassignJobs($uuids: [ID!]!) {
    update_jobs(
      job_ids: $uuids
      job: { details: { assignee: null, status: new } }
    )
  }
`

export default function useUnassignJobs(variables: unassignJobsVariables) {
  return useMutation(unassignJobsMutation, {
    refetchQueries: [JobDocument, JobListDocument],
    variables,
    errorPolicy: 'ignore',
  })
}
