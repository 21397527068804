import {
  SchemeAvailabilityRestrictionsDocument,
  useUpdateSchemeAvailabilityRestrictionMutation,
} from 'gql/generated/graphql'

const useUpdateCurfew = () => {
  return useUpdateSchemeAvailabilityRestrictionMutation({
    refetchQueries: [SchemeAvailabilityRestrictionsDocument],
  })
}

export default useUpdateCurfew
