import { gql, ApolloQueryResult } from '@apollo/client'
import { capitalize, startCase } from 'lodash'
import { DownOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import { Flex, Box, Heading } from '@weareberyl/design-system'

import Query from 'components/Query'
import { formatDatetime } from 'utils'
import { Actions } from 'components/Module/actions'
import ModuleHeader from 'components/ModuleHeader'
import Tags from '../../Module/Tags'
import CardSection from 'components/shared/CardSection'
import LegendCard from './Card'

import { MODULE_LEGEND } from './__generated__/MODULE_LEGEND'
import { assistConfig } from 'components/Module/Summary'
import { ModuleHardware } from '__generated__/globalTypes'
import { REWARD_TYPES } from 'constants/domain'
import { getDerivedHardwareType } from 'utils/derived'

const MODULE_LEGEND_QUERY = gql`
  query MODULE_LEGEND($module_id: ID!) {
    module(module_id: $module_id) {
      id
      hardware_type
      bike {
        vehicle_id
        frame {
          id
        }
      }
      vehicle {
        id
        frame_type
        rewards {
          type
        }
        detail {
          lock_configuration
          scheme {
            config {
              is_vehicle_rewards_enabled
            }
          }
        }
      }
      location {
        id
        max_error
        time
      }
      telemetry {
        id
        battery_percent
        drive {
          battery_percent
        }
      }
      iot_state {
        id
        power_mode
      }
      lock_state {
        id
        overall_state
        vehicle_lock_state
      }
      connectivity {
        is_online
      }
      availability {
        id
        status
      }
      tags {
        id
        name
        created_at
      }
      module_state {
        id
        ... on ModuleState {
          assist_config
        }
      }
    }
  }
`

export interface ModuleLegendProps {
  module_id: string
  onClose: () => void
  where?: object | null
}

const ModuleLegend = ({ module_id, onClose }: ModuleLegendProps) => {
  return (
    <Query
      waitFor="data.module"
      variables={{
        module_id,
      }}
      query={MODULE_LEGEND_QUERY}
      opts={{ showRefetchLoader: true }}
    >
      {({ data: { module } }: ApolloQueryResult<MODULE_LEGEND>) => {
        const message =
          module?.lock_state?.vehicle_lock_state ??
          module?.lock_state?.overall_state
        const availability = module?.availability?.status
        const maxError = module?.location?.max_error
        const batteryPercent = module?.telemetry?.battery_percent
        const powerMode = module?.iot_state?.power_mode ?? 'No data'
        const time = module?.location?.time
        const frame_type = module?.vehicle?.frame_type
        const hardware_type = getDerivedHardwareType(module)
        const vehicleId = module?.bike?.vehicle_id

        const isOnline = module?.connectivity?.is_online ?? 'No data'
        const connected =
          isOnline !== 'No data' ? (isOnline ? 'Online' : 'Offline') : isOnline
        const scheme = module?.vehicle?.detail?.scheme
        const showRewards = scheme?.config.is_vehicle_rewards_enabled
        const rewards = module?.vehicle?.rewards ?? []
        const lock_configuration = module?.vehicle?.detail?.lock_configuration

        const legendItems = [
          {
            key: 'Module ID',
            value: module_id,
          },
          {
            key: 'Frame Type',
            value: startCase(frame_type),
          },
          {
            key: 'Lock state',
            value: message ? capitalize(message) : 'No data',
          },
          {
            key: 'Connected',
            value: connected,
          },
          {
            key: 'Availability',
            value: availability ? capitalize(availability) : 'No data',
          },
          {
            key: 'Max error',
            value: maxError ? `${maxError}m` : 'No data',
          },
          {
            key: 'Module charge',
            value: batteryPercent ? `${batteryPercent}%` : 'No data',
          },
          {
            key: 'Power mode',
            value: capitalize(powerMode),
          },
        ]

        if (module?.module_state && 'assist_config' in module.module_state) {
          if (hardware_type === ModuleHardware.bbe) {
            legendItems.push({
              key: 'Assist config',
              value:
                (module?.module_state?.assist_config &&
                  assistConfig[module?.module_state?.assist_config]) ||
                'No data',
            })
          }
        }

        legendItems.push({
          key: 'Data received',
          value: time ? formatDatetime(time) : 'No data',
        })

        if (showRewards) {
          const rewardsString =
            rewards.length > 0
              ? rewards
                  .map(reward => `⭐️ ${REWARD_TYPES[reward.type]}`)
                  .join(', ')
              : 'None'

          legendItems.push({
            key: 'Rewards available',
            value: rewardsString,
          })
        }

        if (!hardware_type || !vehicleId) {
          return null
        }
        return (
          <LegendCard
            onClose={onClose}
            footer={{
              custom: (
                <Dropdown
                  key="dropdown"
                  trigger={['click']}
                  overlay={
                    <Actions
                      module_id={module_id}
                      hardware_type={hardware_type}
                      vehicleId={vehicleId}
                      lock_configuration={lock_configuration}
                    />
                  }
                >
                  <Flex justifyContent="space-between">
                    <Heading variant="h4">Actions</Heading>
                    <DownOutlined style={{ fontSize: 18, paddingTop: 2 }} />
                  </Flex>
                </Dropdown>
              ),
            }}
          >
            <Box flex="1" mb={3}>
              <Box mt={2} mb={3}>
                <ModuleHeader
                  moduleId={module_id}
                  frameId={module?.bike?.frame.id}
                  hardwareType={hardware_type}
                  batteryPercent={
                    module?.telemetry?.drive?.battery_percent ?? null
                  }
                  size="medium"
                />
              </Box>
              <Tags vehicleId={vehicleId} tags={module?.tags ?? []} />
              <Flex mt={4}>
                <CardSection items={legendItems} />
              </Flex>
            </Box>
          </LegendCard>
        )
      }}
    </Query>
  )
}

export default ModuleLegend
