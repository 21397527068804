import { useState } from 'react'
import { ApolloError, gql, useQuery } from '@apollo/client'
import {
  Alert,
  Button,
  Heading,
  Text,
  Box,
  Flex,
} from '@weareberyl/design-system'
import {
  getServiceNowAuthStatus,
  getServiceNowAuthStatus_service_now_oauth_check,
} from './__generated__/getServiceNowAuthStatus'
import Loading from 'components/Loading'

export default function ServiceNowAuth() {
  const [serviceNowAuthDetails, setServiceNowAuthDetails] =
    useState<getServiceNowAuthStatus_service_now_oauth_check | null>(null)

  const [error, setError] = useState<string | null>(null)

  const SERVICE_NOW_AUTH_CHECK = gql`
    query getServiceNowAuthStatus {
      service_now_oauth_check {
        authorized
        login_url
      }
    }
  `

  const { loading: serviceNowAuthCheckLoading } =
    useQuery<getServiceNowAuthStatus>(SERVICE_NOW_AUTH_CHECK, {
      pollInterval: 0,
      onCompleted: data => {
        setServiceNowAuthDetails(data.service_now_oauth_check)
      },
      onError: (apolloError: ApolloError) => {
        const errorMessage = `Unable to check ServiceNow auth: ${apolloError.message}`
        setError(errorMessage)
      },
    })

  return (
    <Flex flexDirection="col" alignItems="flex-start" gridGap={'20px'}>
      <Heading variant="h1">ServiceNow Auth 🐕‍🦺</Heading>
      {!serviceNowAuthDetails && !error ? (
        <Loading />
      ) : (
        <>
          {error ? (
            <Box mt={3}>
              <Alert message={error} type="error" />
            </Box>
          ) : (
            <>
              {serviceNowAuthDetails?.authorized ? (
                <>
                  <Box mb={3}>
                    <Text>
                      You've already authenticated with ServiceNow, but if you
                      need to re-authenticate you can press the button below:
                    </Text>
                  </Box>
                  <Button
                    disabled={!serviceNowAuthDetails.login_url}
                    mb={5}
                    title="Re-authenticate with ServiceNow"
                    variant="primary"
                    loading={serviceNowAuthCheckLoading}
                    onClick={() => window.open(serviceNowAuthDetails.login_url)}
                  />
                </>
              ) : (
                <>
                  <Box mb={3}>
                    <Text>
                      You need to authenticate with ServiceNow - please press
                      the button below:
                    </Text>
                  </Box>
                  <Button
                    disabled={!serviceNowAuthDetails?.login_url}
                    mb={5}
                    title="Set up ServiceNow"
                    variant="danger"
                    loading={serviceNowAuthCheckLoading}
                    onClick={() =>
                      window.open(serviceNowAuthDetails?.login_url)
                    }
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </Flex>
  )
}
