import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import { Button, message } from 'antd'
import {
  UPDATE_MODULE,
  UPDATE_MODULEVariables,
} from './__generated__/UPDATE_MODULE'

export const UPDATE_MODULE_QUERY = gql`
  mutation UPDATE_MODULE($module_id: ID!, $unlock_id: ID!) {
    update_module(mod: { module_id: $module_id, unlock_id: $unlock_id }) {
      id
      unlock_id
    }
  }
`

type UpdateModuleProps = {
  module_id: string
  unlock_id: string
  valid: boolean
  onCompleted: () => void
}

export const UpdateModule = ({
  module_id,
  unlock_id,
  valid,
  onCompleted = () => message.info('success'),
}: UpdateModuleProps) => {
  return (
    <Mutation<UPDATE_MODULE, UPDATE_MODULEVariables>
      onError={err => message.error(err.message)}
      mutation={UPDATE_MODULE_QUERY}
      onCompleted={onCompleted}
    >
      {(updateModule, { loading }) => {
        return (
          <Button
            type="primary"
            loading={loading}
            disabled={!valid}
            onClick={() => {
              return updateModule({
                variables: {
                  module_id,
                  unlock_id,
                },
              })
            }}
          >
            Save
          </Button>
        )
      }}
    </Mutation>
  )
}
