import { InMemoryCache, TypePolicies } from '@apollo/client'
import fragmentTypes from '../client/fragmentTypes.json'

const { possibleTypes } = fragmentTypes

//https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects

const typePolicies: TypePolicies = {
  Drive: {
    merge: false,
  },
  FormattedAmount: {
    merge: false,
  },
  PeriodPassCapInfo: {
    merge: false,
  },
  Module: {
    fields: {
      tags: {
        merge: false,
      },
    },
  },
  VehicleReward: {
    merge: false,
  },
  Bike: {
    keyFields: ['vehicle_id'],
  },
  Job: {
    keyFields: ['uuid'],
    fields: {
      details: {
        merge: true,
      },
      timestamps: {
        merge: false,
      },
    },
  },
  Task: {
    keyFields: ['uuid'],
    fields: {
      details: {
        merge: false,
      },
    },
  },
}

const cache = new InMemoryCache({
  typePolicies,
  possibleTypes,
})

export default cache
