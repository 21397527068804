import React from 'react'
import { TouchableOpacity } from 'react-native'
import { Badge, Text, Icon, Flex, colors } from '@weareberyl/design-system'
import { TEXT_VARIANTS } from '@weareberyl/design-system/build/components/Typography/Text'

type TagProps = {
  onClose?: () => void
  closable: boolean
  label: string
  labelVariant: keyof typeof TEXT_VARIANTS
}

const Tag = ({
  onClose,
  closable,
  label,
  labelVariant = 'regular',
  ...props
}: TagProps) => {
  const innerTag = () => (
    <Badge
      m={1}
      px={2}
      style={{
        backgroundColor: 'rgba(205, 232, 254, 0.51)',
        border: 'none',
      }}
      {...props}
      text={
        <Flex alignItems="baseline" flexWrap="nowrap">
          <Text
            style={{ whiteSpace: 'nowrap' }}
            color={colors.corporate}
            variant={labelVariant}
          >
            {label}
          </Text>
          {closable && (
            <Icon
              type="cross"
              ml={2}
              height={10}
              width={10}
              color="corporate"
            />
          )}
        </Flex>
      }
    />
  )

  return (
    <>
      {closable ? (
        <TouchableOpacity onPress={onClose}>{innerTag()}</TouchableOpacity>
      ) : (
        innerTag()
      )}
    </>
  )
}

export default Tag
