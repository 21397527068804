import { ReactNode } from 'react'
import { formatDatetime } from 'utils'
import { Box, Card } from '@weareberyl/design-system'

import Query from 'components/Query'
import Table from 'components/Table'
import { useQueryParams } from 'utils/useQueryParams'
import { Heading, Text } from '@weareberyl/design-system'
import { DEPLOYMENTVariables } from './__generated__/DEPLOYMENT'
import { POLL_INTERVAL } from 'client'
import { DeploymentDocument } from 'gql/generated/graphql'
import HeadTitle from 'components/HeadTitle'

const Label = ({
  label,
  value,
}: {
  label: string
  value: string | ReactNode
}) => {
  if (!value) {
    return null
  }

  if (typeof value === 'string') {
    return (
      <div>
        <Text>{label}</Text>: <Text>{value}</Text>
      </div>
    )
  }

  return (
    <div>
      <Text>{label}</Text>: {value}
    </div>
  )
}

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Date/Time',
    dataIndex: 'created_at',
    render: t => formatDatetime(t),
  },
  {
    title: 'State',
    dataIndex: 'state',
  },
  {
    title: 'module',
    dataIndex: ['module', 'id'],
  },
]

const Events = ({ events, onChange, refetch }) => {
  return (
    <Table
      id="events-table"
      onChange={onChange}
      refetch={refetch}
      // @ts-ignore antd upgrade
      columns={columns}
      data={{
        table: events,
      }}
    />
  )
}

const Deployment = ({ id }: DEPLOYMENTVariables) => {
  const [{ current, pageSize }, setQueryParams] = useQueryParams(id)

  return (
    <>
      <HeadTitle pageTitle={`Deployment ${id}`} />

      <Query
        pollInterval={POLL_INTERVAL}
        waitFor="data.deployment"
        query={DeploymentDocument}
        variables={{
          id,
          paginate: {
            per_page: pageSize,
            page: current,
          },
        }}
      >
        {({ data, refetch }) => {
          const { deployment } = data
          return (
            <Card p={5}>
              <Heading variant="h1">Deployment: {deployment.id}</Heading>
              <Box py={4}>
                <Label label="Version" value={deployment?.version?.name} />
                <Label
                  label="Created at"
                  value={formatDatetime(deployment.created_at)}
                />
              </Box>
              <Heading mb={3} variant="h3">
                Module progress
              </Heading>
              <Events
                refetch={refetch}
                events={deployment?.modules}
                onChange={({ current, pageSize }) =>
                  setQueryParams({ current, pageSize })
                }
              />
            </Card>
          )
        }}
      </Query>
    </>
  )
}

export default Deployment
