import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Typography } from 'antd'

import {
  Flex,
  Box,
  Heading,
  TextLink,
  Icon,
  Text,
} from '@weareberyl/design-system'

import * as routes from 'constants/routes'
import config from 'constants/config'
import Roles from './Roles'
import { Actions } from './actions'
import DeletedUserIcon from './DeletedUserIcon'
import Dropdown from 'components/shared/Dropdown'
import UserLink from 'components/UserLink'
import { formatDatetime, getLogsLink } from 'utils'
import Copy from 'components/Copy'
import Check from 'components/Check'
import { useCurrentScheme } from 'hooks'
import { LoadingComponent } from 'components/Query/utils'
import UserSchemes from './Schemes'
import {
  UserSummaryDocument,
  useUserSummaryQuery,
  UserSummaryFieldsFragment as User,
  UserSummaryQuery_user_User,
} from 'gql/generated/graphql'

const ONFIDO_LINK = (applicantId: string) => {
  const env = config.REACT_APP_ENV
  return `https://dashboard.onfido.com/library?_sandbox_[0]=${
    env !== 'production'
  }&q=${applicantId}`
}

const DRIVING_LICENCE_LINK = (userId: string, applicantId?: string) => {
  if (applicantId) {
    if (applicantId.startsWith('act_')) {
      return `https://withpersona.com/dashboard/accounts/${applicantId}`
    } else {
      return ONFIDO_LINK(applicantId)
    }
  } else {
    return `https://withpersona.com/dashboard/inquiries?filter=${encodeURIComponent(
      JSON.stringify({ omni: userId }),
    )}`
  }
}

const logsLink = (userId: string) => {
  return getLogsLink(`(@user_id:${userId} OR @request_user_id:${userId})`)
}

const UserChecklist = ({ user }: { user: UserSummaryQuery_user_User }) => {
  // Checkboxes
  const hasValidLicenceText = user.has_valid_licence ? '🪪 Valid' : '🪪 No valid'
  const isOver18 = Boolean(user.is_over_18)
  const over18Text = isOver18 ? '👵 Over 18' : '👶 Not over 18'
  const birthdateText = user.has_birthdate
    ? `🎈 Verified: ${over18Text}`
    : '❓ Age not verified'
  const selfieText = user.identity_checked_at
    ? `🤳 Selfie verified at: ${formatDatetime(user.identity_checked_at)}`
    : '🤳 No Selfie'
  let personaStatusText = '🥷 No Persona account'
  if (user.persona_enrolled) {
    personaStatusText = '🥷 Persona account'
  }
  if (user.persona_enrolled_device) {
    personaStatusText = '📱 Persona account – fully enrolled'
  }
  const exemptionText =
    user.charge_exemptions.length > 0
      ? '😇 Charge exemptions'
      : '🤑 No exemptions'

  return (
    <>
      {user.phone_number && (
        <Flex mb={3}>
          <Icon type="user" width={24} height={24} color="afternoon" mr={2} />
          <span>
            <Text variant="small" fontFamily="Hellix-SemiBold">
              {user?.phone_number}
            </Text>
          </span>
        </Flex>
      )}
      <Check
        checked={Boolean(user.has_valid_licence)}
        text={`${hasValidLicenceText} driving license`}
      />
      <Check
        checked={user.persona_enrolled}
        pending={user.persona_enrolled && !user.persona_enrolled_device}
        text={personaStatusText}
      />
      <Check checked={Boolean(user.phone_number)} text={'🤙 Phone number'} />
      <Check checked={Boolean(user.is_over_18)} text={birthdateText} />
      <Check checked={Boolean(user.identity_checked_at)} text={selfieText} />
      <Check
        checked={Boolean(user.charge_exemptions.length > 0)}
        text={exemptionText}
      />
    </>
  )
}

const UserEmail = ({ user }: { user: User }) => {
  let userStatusIcon = '😇'
  let userStatusText = 'Active user'
  if (user.is_blocked) {
    userStatusIcon = '👿'
    userStatusText = 'This user is disabled on Firebase'
  } else if (user.deleted) {
    userStatusIcon = '💀'
    userStatusText = 'Deleted user'
  }
  return (
    <Heading variant="h1" mb={3} title={userStatusText}>
      <Typography.Text ellipsis style={{ width: '100%' }}>
        <span style={{ userSelect: 'none' }}>{userStatusIcon}&nbsp;</span>
        <span>{user.email}</span>
      </Typography.Text>
    </Heading>
  )
}

const UserLinks = ({ user }: { user: User }) => {
  return (
    <Flex justifyContent="flex-end" mb={3}>
      <TextLink
        variant="gray"
        onPress={() =>
          window.open(
            DRIVING_LICENCE_LINK(user.id, user.driving_licence_check?.id),
            '_blank',
          )
        }
      >
        Identity checks
      </TextLink>
      <Text mx={2}>·</Text>
      <TextLink
        variant="gray"
        onPress={() => window.open(logsLink(user.id), '_blank')}
      >
        Server logs
      </TextLink>
    </Flex>
  )
}

const UserSummaryHeader = ({ user }: { user: User }) => {
  const URL = `${routes.USERS}/${user.id}`
  const location = useLocation()

  return (
    <Box mb={3}>
      {location.pathname.includes(URL) ? (
        <Heading variant="callout">User</Heading>
      ) : (
        <UserLink user={user}>
          <Heading variant="h1" color="jade">
            User
          </Heading>
        </UserLink>
      )}
    </Box>
  )
}

export default ({
  id,
  setUserEmail,
}: {
  id: string
  setUserEmail?: (email: string) => void
}) => {
  const { currentSchemeId } = useCurrentScheme()
  const { data, loading } = useUserSummaryQuery({
    variables: { id, schemeId: currentSchemeId ?? '1' },
    pollInterval: 0,
  })
  const user = data?.user

  useEffect(() => {
    if (user?.email) {
      setUserEmail?.(user.email)
    }
  }, [user?.email, setUserEmail])

  if (!user) {
    if (loading) {
      return <LoadingComponent />
    }

    return null
  }

  return (
    <Box>
      <Flex justifyContent="space-between" mb={3}>
        <UserSummaryHeader user={user} />
        <Box>
          {!user.deleted && (
            <Dropdown
              overlay={
                <Actions
                  user_id={id}
                  refetchQueries={[
                    { query: UserSummaryDocument, variables: { id } },
                  ]}
                />
              }
            />
          )}
        </Box>
      </Flex>
      <UserLinks user={user} />
      <Flex justifyContent="space-between" style={{ gap: '20px' }}>
        <Box justifyContent="space-between" style={{ minWidth: '320px' }}>
          <Box>
            <UserEmail user={user} />
            <Flex mb={3} alignItems="center">
              <Copy id="user_id" text={user.id} />
              {user.deleted && <DeletedUserIcon />}
            </Flex>
            <UserChecklist user={user} />
          </Box>
          <Flex mt={3}>
            <Roles
              refetchQueries={[
                { query: UserSummaryDocument, variables: { id } },
              ]}
              roles={user.roles || []}
              labelVariant="micro"
              showScheme
            />
          </Flex>
        </Box>

        <UserSchemes userId={id} />
      </Flex>
    </Box>
  )
}
