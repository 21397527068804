import { message } from 'antd'
import { withUser } from 'components/Session'
import { withScheme } from 'components/Scheme'
import Tag from 'components/shared/Tag'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import { formatRoleName } from '../../utils/index'

const REMOVE_ROLE = gql`
  mutation REMOVE_ROLE($role_id: ID!) {
    remove_role(role_id: $role_id)
  }
`
const getRoleDescription = role => {
  let description = ''
  const roleName = formatRoleName(role.type.description)

  if (role.scheme) description = ' - ' + role.scheme.name || role.scheme.id
  if (role.org) description = ' - ' + role.org.name || role.org.id

  if (role.type.id === '0') {
    if (role.scheme || role.org) {
      return 'Scheme Admin' + description
    } else {
      return 'Beryl admin [deprecated]'
    }
  }

  if (role.type.id === '2' || role.type.id === '3' || role.type.id === '6') {
    return roleName
  }

  return roleName + description
}

const Roles = ({ roles, refetchQueries, labelVariant, closable }) => {
  // Sort the roles so they look pretty
  const sortedRoles = roles
    .slice(0)
    .sort((a, b) => getRoleDescription(a) > getRoleDescription(b))

  return sortedRoles.map(role => {
    const description = getRoleDescription(role)

    return (
      <Mutation
        key={role.id}
        onCompleted={() => message.info('Role removed')}
        refetchQueries={refetchQueries}
        onError={err => {
          message.error(`Something went wrong: ${err.message}`)
        }}
        mutation={REMOVE_ROLE}
      >
        {removeRole => (
          <Tag
            closable={closable}
            onClose={() => removeRole({ variables: { role_id: role.id } })}
            label={description}
            labelVariant={labelVariant}
          />
        )}
      </Mutation>
    )
  })
}

export default withUser(withScheme(Roles))
