import { Divider } from 'antd'

import { Flex, Box, Heading, Text } from '@weareberyl/design-system'
import LegendCard from './Card'
import AssetLink from 'components/Scheme/AssetLink'
import { JOBS as JOBS_ROUTE } from 'constants/routes'
import { JobListQuery_table_JobConnection_nodes_Job as Job } from 'gql/generated/graphql'

import { useJobTypes } from 'hooks'
import { formatDatetime } from 'utils'

export interface JobLegendProps {
  type: string | null
  job: Job
  onClose: () => void
  where?: object | null
}

export const JobLegend = ({ job, onClose }: JobLegendProps) => {
  const { jobCodeLookup } = useJobTypes()
  const jobName = jobCodeLookup?.[job?.details?.taskId]

  const jobInformationLink = (
    <Flex justifyContent="center" my={2}>
      <AssetLink basePath={JOBS_ROUTE} id={job.uuid}>
        <Text variant="small" fontFamily="Hellix-SemiBold">
          Job detail
        </Text>
      </AssetLink>
    </Flex>
  )
  const footer = { custom: jobInformationLink }

  const row = (label: string, value?: string | null) => {
    return (
      <Flex flex="1" mr={3}>
        <Text variant="small">{label}: </Text>
        <Text variant="small" fontFamily="Hellix-SemiBold">
          {value}
        </Text>
      </Flex>
    )
  }

  return (
    <LegendCard onClose={onClose} footer={footer}>
      <Box flex="1">
        <Flex flexDirection="column" alignItems="center">
          <Heading variant="h3">{jobName}</Heading>
          <Divider />
        </Flex>
        <Flex justifyContent="space-between" width="100%" mb={3}>
          {row('Vehicle', job.details?.assetName)}
        </Flex>
        <Flex justifyContent="space-between" width="100%" mb={3}>
          {row('Description', job.details?.description)}
        </Flex>
        <Flex justifyContent="space-between" width="100%" mb={3}>
          <Flex flex="1">{row('Status', job.details?.status)}</Flex>
        </Flex>
        <Flex justifyContent="space-between" width="100%" mb={3}>
          {row('Created At', formatDatetime(job.timestamps?.created_at))}
          {row('Updated At', formatDatetime(job.timestamps?.updated_at))}
        </Flex>
      </Box>
    </LegendCard>
  )
}

export default JobLegend
