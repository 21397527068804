import { useState } from 'react'
import { Modal } from 'antd'
import CreateMember from 'components/User/CreateMember'
import SetMemberRole from 'components/User/SetMemberRole'

type newMemberModelProps = {
  show: boolean
  close: () => void
}

const NewMemberModal = ({ show, close }: newMemberModelProps) => {
  const [newMember, setNewMember] = useState({
    isCreated: false,
    email: '',
    id: '',
  })

  const onClose = () => {
    close()
    setNewMember({
      isCreated: false,
      email: '',
      id: '',
    })
  }

  return (
    <>
      <Modal
        open={show}
        zIndex={1050}
        width={550}
        bodyStyle={{ padding: 0 }}
        onCancel={onClose}
        footer={null}
        destroyOnClose={true}
      >
        {newMember.isCreated ? (
          <SetMemberRole
            members={[
              {
                email: newMember.email,
                id: newMember.id,
                isNewMember: true,
              },
            ]}
            closeModal={onClose}
          />
        ) : (
          <CreateMember setNewMember={setNewMember} />
        )}
      </Modal>
    </>
  )
}

export default NewMemberModal
