import {
  SchemeProductListQueryResult,
  SchemeProductListQuery_Query,
  SchemeProductListQuery_table_SchemeProductConnection_nodes_SchemeProduct,
  useSchemeProductListQuery,
} from 'gql/generated/graphql'

// Define a skeleton of data so the table can be shown
// with the loading state if there isn't any data yet
export const defaultData: SchemeProductListQuery_Query = {
  __typename: 'Query',
  table: {
    __typename: 'SchemeProductConnection',
    nodes: [],
    pagination: {
      __typename: 'Pagination',
      total: 0,
      current: 0,
      pageSize: 0,
    },
  },
  role_types: [],
}

export type UseSchemeProducts = Pick<
  SchemeProductListQueryResult,
  'data' | 'loading'
>

// Use this type for listings, and the query might not include all fields that
// the SchemeProduct type has
export type SchemeProductListItem =
  SchemeProductListQuery_table_SchemeProductConnection_nodes_SchemeProduct

export const useSchemeProducts = ({
  scheme,
  current,
  pageSize,
}): UseSchemeProducts => {
  const variables = {
    scheme_id: scheme.id,
    paginate: { page: current, per_page: pageSize },
  }

  const { data, loading } = useSchemeProductListQuery({
    variables,
    pollInterval: 0,
  })

  return { data, loading }
}
