import { useState } from 'react'
import Query from 'components/Query'
import { ApolloQueryResult, gql } from '@apollo/client'
import { Select } from 'antd'
import { throttle } from 'lodash'
import { ASSIGNEE_LIST } from './__generated__/ASSIGNEE_LIST'

const Option = Select.Option

export const ASSIGNEE_QUERY = gql`
  query ASSIGNEE_LIST(
    $where: [UserWhere]
    $order_by: [UserOrderby]
    $paginate: Paginate
  ) {
    workshop_users(where: $where, order_by: $order_by, paginate: $paginate) {
      nodes {
        id
        email
      }
    }
  }
`

export default ({ ...props }) => {
  const [search, setSearchValue] = useState('')
  const throttledSearch = throttle(setSearchValue, 1000)

  return (
    <Query
      waitFor={'data.workshop_users'}
      showEmpty={false}
      query={ASSIGNEE_QUERY}
      pollInterval={0}
      defaultData={{ workshop_users: { nodes: [] } }}
      variables={{
        where: search ? [{ email: { like: `%${search}%` } }] : [],
        order_by: [{ email: 'asc' }],
        paginate: { page: 1, per_page: 100 },
      }}
    >
      {({ data }: ApolloQueryResult<ASSIGNEE_LIST>) => {
        return (
          <Select
            allowClear={true}
            showSearch
            onSearch={throttledSearch}
            filterOption={false}
            placeholder="Select an Assignee"
            notFoundContent={null}
            {...props}
          >
            {data?.workshop_users.nodes.map(({ id, email }) => {
              if (id === null || email === null) {
                return null
              }
              return (
                <Option key={id} value={id}>
                  {email}
                </Option>
              )
            })}
          </Select>
        )
      }}
    </Query>
  )
}
