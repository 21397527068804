import {
  Card,
  Flex,
  Heading,
  Icon,
  Text,
  TextLink,
} from '@weareberyl/design-system'
import { Col, Row } from 'antd'
import React from 'react'
import { generatePath, Link } from 'react-router-dom'

import HeadTitle from 'components/HeadTitle'
import { EmptyComponent, LoadingComponent } from 'components/Query/utils'
import Table from 'components/Table'
import {
  useZoneHistoryQuery,
  ZoneHistoryQuery_zone_history_ZoneHistoryConnection_nodes_ZoneHistory as ZoneHistoryQueryNode,
  ZoneType,
} from 'gql/generated/graphql'
import { useQueryParams } from 'utils/useQueryParams'

import { prependScheme, ZONE } from '../../../constants/routes'
import { ZoneHeading } from '../Summary'
import columns from './columns'
import type { WithIsChangedBool } from './markChangedValues'
import markChangedValues from './markChangedValues'

type ZoneProps = {
  id: string
  schemeId: string
}

const bayPath = ({ id, schemeId }) => {
  const historyPath = generatePath(ZONE, { id })
  const fullPath = prependScheme(historyPath, schemeId)
  return fullPath
}

const BackLink = ({ to }: { to: string }) => (
  <TextLink variant="grape" size="small" style={{ display: 'block' }}>
    <Link to={to} style={{ color: 'currentColor' }}>
      <Flex gridGap="2" alignItems="center">
        <Icon
          type={'arrowBack'}
          color="afternoon"
          mt="2px"
          width={16}
          height={16}
        />
        <Text variant="small">Back to Bay Details</Text>
      </Flex>
    </Link>
  </TextLink>
)

const Error = ({ error }) => (
  <Flex flexDirection="column" gridGap={1}>
    <Heading>Unable to load Bay History</Heading>
    {error?.message && <Text>Reason: {error.message}</Text>}
  </Flex>
)

const ZoneHistory = ({ id, schemeId }: ZoneProps) => {
  const [queryParams, setQueryParams] = useQueryParams('zone-history-table')

  const { data, loading, error } = useZoneHistoryQuery({
    variables: {
      id,
      page: queryParams.current,
      per_page: queryParams.pageSize,
    },
  })

  if (loading) {
    return <LoadingComponent />
  }

  if (!data) return <EmptyComponent />

  const table = {
    table: {
      pagination: data.zone_history.pagination,
      nodes: markChangedValues([...data.zone_history.nodes]),
    },
  }

  return (
    <Card id="zone-summary-card" mb={3} p={5}>
      <HeadTitle pageTitle={`Bay ${id} History`} />
      <Row>
        <Heading variant="callout">Bay History</Heading>
      </Row>
      <Row style={{ marginTop: 0 }}>
        <BackLink to={bayPath({ id, schemeId })} />
      </Row>

      {error ? (
        <Row>
          <Error error={error} />
        </Row>
      ) : (
        <React.Fragment>
          <Row>
            <ZoneHeading
              name={data.zone_history.nodes[0].name ?? 'Unknown'}
              zoneType={ZoneType.normal}
            />
          </Row>

          <Row gutter={16}>
            <Col xs={24}>
              <Table<WithIsChangedBool<ZoneHistoryQueryNode>>
                columns={columns}
                data={table}
                onChange={({ current, pageSize }) =>
                  setQueryParams({ current, pageSize })
                }
              />
            </Col>
          </Row>
        </React.Fragment>
      )}
    </Card>
  )
}

export default ZoneHistory
