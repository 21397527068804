import { useMemo } from 'react'
import { groupBy } from 'lodash'

import {
  useCreateEoJPromotionMutation,
  useCreateValidationRulesMutation,
  useListValidationRulesQuery,
  ListValidationRulesDocument,
  ListCampaignsDocument,
  useListMetadataSchemasQuery,
} from 'gql/generated/graphql'

export const useCreatePromotion = () => {
  const [createPromotion, { loading: saving, error }] =
    useCreateEoJPromotionMutation({
      refetchQueries: [ListCampaignsDocument],
    })
  return { createPromotion, saving, error }
}

export type ValidationRuleOption = {
  label: string
  value: string
}
export const useValidationRules = () => {
  const { data, loading } = useListValidationRulesQuery({ pollInterval: 0 })
  const rules = useMemo(
    () =>
      data?.table.nodes.map(item => ({
        label: item.name,
        value: item.id,
      })) ?? [],
    [data?.table],
  )

  const [createValidationRules, { loading: saving }] =
    useCreateValidationRulesMutation({
      refetchQueries: [ListValidationRulesDocument],
    })

  return { loading, rules, createValidationRules, saving }
}

export const useMetadataSchemaOptions = () => {
  const { data, loading } = useListMetadataSchemasQuery({
    pollInterval: 0,
    fetchPolicy: 'cache-first',
  })

  const metadataSchemas = groupBy(
    data?.metadata_schemas.nodes,
    item => item.related_object,
  )

  return {
    metadataSchemas,
    loading,
  }
}
