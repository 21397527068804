import logo from 'images/logo.png'
import config from 'constants/config'

const Footer = () => (
  <div className="footer sm-hide">
    <p className="footer--microcopy">Powered by</p>
    <img alt="logo" src={logo} />
    <p className="footer--version">
      Release: {config.REACT_APP_RELEASE ?? 'DEV'}
    </p>
  </div>
)

export default Footer
