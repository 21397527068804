import { Card, Modal, Layout } from 'antd'
import { useDevices } from 'hooks'
import { useEffect, useState } from 'react'
import { UAParser } from 'ua-parser-js'
import moment from 'moment'
import { Flex, Icon, Text, TextLink, colors } from '@weareberyl/design-system'

import { DEVICE_WARNING_COUNT } from 'constants/domain'

const { Header } = Layout

interface UserAgentProps {
  userAgent: string
  timestamp: string
  deviceID: string
  currentDeviceID: string | null
}

const UserAgent = ({
  userAgent,
  timestamp,
  deviceID,
  currentDeviceID,
}: UserAgentProps) => {
  const parsedUA = new UAParser(userAgent).getResult()
  const browser = parsedUA.browser
  const device = parsedUA.device
  const isCurrentDevice = currentDeviceID && deviceID === currentDeviceID
  let cardTitle = `Device: ${device.vendor}`
  if (isCurrentDevice) {
    cardTitle = `${cardTitle} (CURRENT)`
  }
  const timestampFormatted = moment(timestamp).format(
    'dddd, MMMM Do YYYY, h:mm:ss a',
  )
  return (
    <Card title={cardTitle} size="small">
      <p>
        <strong>Browser:</strong> {browser.name}
      </p>
      <p>
        <strong>Login time:</strong> {timestampFormatted}
      </p>
    </Card>
  )
}

const DeviceWarningHeader = ({ showModal }) => {
  return (
    <Header style={{ background: colors.jaffa, height: '45px' }}>
      <Flex height="100%" alignItems="center" justifyContent="center">
        <Text mr="5">
          Warning: Logged in on multiple devices. Please logout on other
          devices. <TextLink onClick={() => showModal()}>More info</TextLink>
        </Text>
      </Flex>
    </Header>
  )
}

const Devices = () => {
  const { devices, fetchDevices, loading, error, currentDeviceId } =
    useDevices()
  const [hideModal, setHideModal] = useState(true)

  const handleCancel = () => {
    setHideModal(true)
  }
  const showModal = () => {
    setHideModal(false)
  }

  // Get on initial load after app load,
  // i.e. user comes to app already logged in
  useEffect(() => {
    fetchDevices()
  }, [fetchDevices])

  const modalContent = (
    <>
      <Flex mb={20} alignItems="center" justifyContent="center" width="100%">
        <Icon type="warning" color="jaffa" height="50" width="50"></Icon>
      </Flex>
      <Text display="block" mb={20} variant="regular">
        <p style={{ textAlign: 'center', padding: '0 20px' }}>
          You are currently logged in on more than {DEVICE_WARNING_COUNT} device
          {DEVICE_WARNING_COUNT > 1 && 's'}. Please review the devices below and
          logout on any that you are no longer using.
        </p>
      </Text>
      {devices.map(device => (
        <UserAgent
          deviceID={device.deviceID}
          currentDeviceID={currentDeviceId}
          userAgent={device.userAgent}
          timestamp={device.timestamp}
          key={device.deviceID}
        ></UserAgent>
      ))}
      <Text>
        <p style={{ textAlign: 'center', padding: '0 20px' }}>
          <strong>Note:</strong> You may have closed the browser but still have
          an active session.
        </p>
      </Text>
    </>
  )

  return (
    <>
      {!loading && !error && devices.length > DEVICE_WARNING_COUNT && (
        <Layout>
          <DeviceWarningHeader showModal={showModal}></DeviceWarningHeader>
        </Layout>
      )}

      <Modal
        open={
          !loading &&
          !error &&
          devices.length > DEVICE_WARNING_COUNT &&
          !hideModal
        }
        onCancel={handleCancel}
        title={<strong>Warning: Logged in on multiple devices</strong>}
        footer={''}
      >
        {modalContent}
      </Modal>
    </>
  )
}

export default Devices
