import { Fragment, useEffect, useState } from 'react'
import Query from 'components/Query'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Pagination } from 'antd'
import { gql, ApolloQueryResult, useQuery } from '@apollo/client'
import Timeline from '../Timeline'

import {
  MODULE_HISTORY,
  MODULE_HISTORYVariables,
  MODULE_HISTORY_node_lock_state_history_nodes,
} from './__generated__/MODULE_HISTORY'
import { capitalize, formatDatetime, getIcon } from 'utils'
import { MODULE_LOCK_CONFIG } from './__generated__/MODULE_LOCK_CONFIG'
import { EmptyComponent, LoadingComponent } from 'components/Query/utils'
import { LockConfiguration } from '__generated__/globalTypes'
import { LockIcon } from './LockIcons'

export const MODULE_LOCK_CONFIG_QUERY = gql`
  query MODULE_LOCK_CONFIG($id: ID!) {
    module(module_id: $id) {
      id
      vehicle {
        id
        detail {
          id
          lock_configuration
        }
      }
    }
  }
`

const MODULE_HISTORY_QUERY = gql`
  query MODULE_HISTORY($id: ID!, $paginate: Paginate) {
    node: module(module_id: $id) {
      id
      lock_state_history(paginate: $paginate) {
        nodes {
          id
          time
          overall_state
          vehicle_lock_state
          front_lock_state
          rear_lock_state
        }
        pagination {
          current: page
          pageSize: per_page
          total
        }
      }
    }
  }
`
/**
 * Lock state history.
 * eg viewable on  /scheme/8/bike/{vehicleId}
 * not to be confused with journey history
 * @param id
 */
export default ({ id }: MODULE_HISTORYVariables) => {
  const { loading, data } = useQuery<MODULE_LOCK_CONFIG>(
    MODULE_LOCK_CONFIG_QUERY,
    {
      variables: { id },
      pollInterval: 0,
    },
  )
  const lockConfig = data?.module?.vehicle?.detail?.lock_configuration
  let hasDualLocks = false
  if (lockConfig !== undefined) {
    hasDualLocks = [
      LockConfiguration.front_and_rear_lock,
      LockConfiguration.front_and_electronic_rear_lock,
    ].includes(lockConfig)
  }
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(20)

  useEffect(() => {
    const numberOfItems = hasDualLocks ? 10 : 20
    setPerPage(numberOfItems)
  }, [hasDualLocks])

  const onPaginationChange = (clickedPage: number, pageSize: number) => {
    setPage(clickedPage)
    setPerPage(pageSize)
  }

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <Query
      waitFor={'data.node.lock_state_history'}
      query={MODULE_HISTORY_QUERY}
      variables={{
        id,
        paginate: { page, per_page: perPage },
      }}
    >
      {({ data }: ApolloQueryResult<MODULE_HISTORY>) => {
        const { nodes, pagination } = data?.node?.lock_state_history || {}

        if (!nodes || nodes.length === 0) {
          return <EmptyComponent />
        }

        const itemWrapper = (
          item: MODULE_HISTORY_node_lock_state_history_nodes,
          i,
        ) => {
          return (
            <div className="mb-1" key={i}>
              {item.vehicle_lock_state ? (
                <LockIcon variant={item.vehicle_lock_state} />
              ) : (
                <LegacyIcon
                  style={{ fontSize: '16px', paddingRight: 1 }}
                  type={
                    getIcon(item.overall_state?.toString() ?? 'unknown').icon
                  }
                />
              )}
              <span style={{ fontWeight: 600 }}>
                {capitalize(item.vehicle_lock_state ?? item.overall_state)}
              </span>{' '}
              - {formatDatetime(item.time, 'time')}
              <ul style={{ fontSize: '12px' }} key={`ul${i}`}>
                {hasDualLocks && (
                  <li key={`li1${i}`} style={{ fontWeight: 600 }}>
                    {`Front lock: ${capitalize(item.front_lock_state)}`}
                  </li>
                )}
                <li key={`li2${i}`} style={{ fontWeight: 600 }}>
                  {`Rear lock: ${capitalize(item.rear_lock_state)}`}
                </li>
              </ul>
            </div>
          )
        }

        return (
          <Fragment>
            <Timeline items={nodes}>{itemWrapper}</Timeline>
            <Pagination
              hideOnSinglePage
              showSizeChanger
              pageSizeOptions={
                hasDualLocks ? ['10', '30', '50'] : ['20', '40', '60']
              }
              current={pagination?.current}
              total={pagination?.total}
              pageSize={perPage}
              onChange={onPaginationChange}
              onShowSizeChange={onPaginationChange}
              style={{ float: 'right' }}
            />
          </Fragment>
        )
      }}
    </Query>
  )
}
