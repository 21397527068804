import { useLazyQuery } from '@apollo/client'
import gql from 'graphql-tag'
import {
  getSchemePermittedVehicles,
  getSchemePermittedVehiclesVariables,
  getSchemePermittedVehicles_permitted_vehicle_models,
} from './__generated__/getSchemePermittedVehicles'

export const getSchemePermittedVehiclesQuery = gql`
  query getSchemePermittedVehicles($schemeId: ID!) {
    permitted_vehicle_models(scheme_id: $schemeId) {
      frame_type
      hardware_type
    }
  }
`

export default (): [
  (schemeId: string) => void,
  getSchemePermittedVehicles_permitted_vehicle_models[] | undefined,
] => {
  const [getPermittedVehiclesQuery, { data }] = useLazyQuery<
    getSchemePermittedVehicles,
    getSchemePermittedVehiclesVariables
  >(getSchemePermittedVehiclesQuery, {
    partialRefetch: true,
  })
  // Return a function that takes schemeId at call time
  const getBySchemeId = (schemeId: string) => {
    return getPermittedVehiclesQuery({ variables: { schemeId } })
  }

  return [getBySchemeId, data?.permitted_vehicle_models]
}
