import { useMutation } from '@apollo/client'
import { JobDocument, JobListDocument } from 'gql/generated/graphql'
import gql from 'graphql-tag'

const assignJobsMutation = gql`
  mutation assignJobs($uuids: [ID!]!, $assignee: String!) {
    update_jobs(job_ids: $uuids, job: { details: { assignee: $assignee } })
  }
`

export default function useAssignJobs() {
  return useMutation(assignJobsMutation, {
    refetchQueries: [JobDocument, JobListDocument],
    errorPolicy: 'ignore',
  })
}
