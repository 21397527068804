import {
  createContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import {
  Device,
  getCurrentDevices,
  getDeviceId,
  subscribeToDeviceChanges,
} from '../utils/firebase/firestore'
import { Unsubscribe } from 'firebase/firestore'
import { noop } from 'lodash'

type DevicesCtx = {
  devices: Device[] | []
  fetchDevices: () => void
  loading: boolean
  error: boolean
  currentDeviceId: string | null
  unsubscribe: () => void
}
export const DevicesContext = createContext<DevicesCtx>({
  devices: [],
  fetchDevices: noop,
  loading: false,
  error: false,
  currentDeviceId: null,
  unsubscribe: noop,
})

type Props = {
  children: ReactNode
}

export default ({ children }: Props) => {
  const [devices, setDevices] = useState<Device[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [currentDeviceId, setCurrentDeviceId] = useState<string | null>(null)
  const [subscribed, setSubscribed] = useState(false)
  const unsubscribeRef = useRef<Unsubscribe | null>(null)

  const fetchDevices = useCallback(async () => {
    try {
      const devicesData = await getCurrentDevices()
      setDevices(devicesData)
      const deviceId = getDeviceId()
      setCurrentDeviceId(deviceId)
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }

    // Re-subscribe to changes after login
    setSubscribed(true)
  }, [])

  const updateDevices = (devices: Device[]) => {
    setDevices(devices)
  }

  useEffect(() => {
    const subscribeToChanges = async () => {
      return await subscribeToDeviceChanges(updateDevices)
    }

    if (subscribed) {
      const unsubPromise = subscribeToChanges()
      unsubPromise.then(ubsub => {
        unsubscribeRef.current = ubsub
      })
    }

    return () => {
      // Unsubscribe
      if (unsubscribeRef.current) {
        unsubscribeRef.current()
      }
      return
    }
  }, [subscribed])

  const unsubscribe = () => {
    if (unsubscribeRef.current) {
      unsubscribeRef.current()
      setSubscribed(false)
    }
  }

  return (
    <DevicesContext.Provider
      value={{
        devices,
        fetchDevices,
        loading,
        error,
        currentDeviceId,
        unsubscribe,
      }}
    >
      {children}
    </DevicesContext.Provider>
  )
}
