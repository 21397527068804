import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { message } from 'antd'

const INVALIDATE_USER_LICENCE = gql`
  mutation INVALIDATE_USER_LICENCE($user_id: ID!) {
    invalidate_user_licence(user_id: $user_id) {
      id
    }
  }
`

type InvalidateUserLicenceProps = {
  user_id: string
}

const InvalidateUserLicence = ({ user_id }: InvalidateUserLicenceProps) => {
  return (
    <Mutation
      mutation={INVALIDATE_USER_LICENCE}
      onCompleted={() => message.info("User's driving licence invalidated!")}
      refetchQueries={['USER_SUMMARY']}
      onError={err => {
        message.error(`Something went wrong: ${err.message}`)
      }}
    >
      {invalidateLicence => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a onClick={() => invalidateLicence({ variables: { user_id } })}>
          Invalidate driving licence
        </a>
      )}
    </Mutation>
  )
}

export default InvalidateUserLicence
