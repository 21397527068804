import { FrameType, LockConfiguration } from '__generated__/globalTypes'
import { COMMISSION_DETAILS_KEY } from 'constants/localStorage'
import { noop } from 'lodash'
import { ReactNode, createContext, useState } from 'react'

type CommissionDetails = {
  schemeId: string
  frameId: string
  lockConfiguration: LockConfiguration
  frameType?: FrameType
  vehicleUnlockId?: string
}

type CommissionDetailsCtx = {
  currentCommissionDetails: CommissionDetails | null
  setCommissionDetails: (commissionDetails: CommissionDetails | null) => void
}

export const CommissionDetailsContext = createContext<CommissionDetailsCtx>({
  currentCommissionDetails: null,
  setCommissionDetails: noop,
})

interface Props {
  children: ReactNode
}

export default ({ children }: Props) => {
  const localStorageCommissionDetails = window.localStorage.getItem(
    COMMISSION_DETAILS_KEY,
  )
  const [currentCommissionDetails, _setCommissionDetails] =
    useState<CommissionDetails | null>(
      localStorageCommissionDetails
        ? JSON.parse(localStorageCommissionDetails)
        : null,
    )

  const setCommissionDetails = (
    commissionDetails: CommissionDetails | null,
  ) => {
    _setCommissionDetails(commissionDetails)
    window.localStorage.setItem(
      COMMISSION_DETAILS_KEY,
      JSON.stringify(commissionDetails),
    )
  }

  return (
    <CommissionDetailsContext.Provider
      value={{
        currentCommissionDetails,
        setCommissionDetails,
      }}
    >
      {children}
    </CommissionDetailsContext.Provider>
  )
}
