import React from 'react'

type Props = {
  x: number
  y: number
  properties: any
}

export default ({ x, y, properties }: Props) => (
  <div className="map-tooltip" style={{ left: x, top: y }}>
    {Object.keys(properties).map((prop: string, i: number) => (
      <div key={prop + '-' + i}>
        {prop}: {properties[prop]}
      </div>
    ))}
  </div>
)
