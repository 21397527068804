import { Flex, Icon, colors } from '@weareberyl/design-system'
import { AssetCategory } from 'gql/generated/graphql'

const AssetCategoryIcons = {
  [AssetCategory.bay]: 'parking',
  [AssetCategory.dock]: 'bikeDocked',
  [AssetCategory.scooter]: 'scooter',
  [AssetCategory.ebike]: 'eBike',
  [AssetCategory.bike]: 'bike',
  [AssetCategory.unknown]: 'questionMark',
}

export const AssetCategoryNames = {
  [AssetCategory.bay]: 'Bay',
  [AssetCategory.dock]: 'Dock',
  [AssetCategory.scooter]: 'e-Scooter',
  [AssetCategory.ebike]: 'e-Bike',
  [AssetCategory.bike]: 'Bike',
  [AssetCategory.unknown]: 'Unknown Asset',
}

interface IAssetIcon {
  assetCategory?: AssetCategory | null
  color?: string | null
}

interface IAssetText extends IAssetIcon {
  assetName?: string | null
}

export const AssetIcon = ({ assetCategory, color }: IAssetIcon) => (
  <Icon
    type={AssetCategoryIcons[assetCategory ?? AssetCategory.unknown]}
    color={color ?? colors.jade}
  />
)

export const AssetText = ({ assetCategory, assetName, color }: IAssetText) => (
  <Flex flexDirection="row" alignItems="center" justifyContent="start">
    <AssetIcon assetCategory={assetCategory} color={color} />
    {` ${assetName}`}
  </Flex>
)

export const assetTagToZoneId = (assetTag: string) =>
  assetTag.match(/\d+$/)?.[0]
export const zoneIdToAssetTag = (zoneId: string | number) => `zone_${zoneId}`

export const isVehicle = (assetCategory?: AssetCategory | null) =>
  [AssetCategory.bike, AssetCategory.ebike, AssetCategory.scooter].includes(
    assetCategory ?? AssetCategory.unknown,
  )

export const getAssetCategoryName = (assetCategory: AssetCategory) =>
  AssetCategoryNames[assetCategory]
