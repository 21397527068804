import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
  colors,
  Flex,
  Heading,
  Text,
  getVehicleName,
  isElectricVehicle,
} from '@weareberyl/design-system'

import ModuleLink from 'components/ModuleLink'
import HardwareTypeIcon from './HardwareTypeIcon'
import * as routes from 'constants/routes'
import batteryIcon from 'images/battery.svg'

import { ModuleHardware } from '__generated__/globalTypes'
import { SCHEME } from 'types'

type ModuleHeaderProps = RouteComponentProps & {
  moduleId: string | null
  frameId?: string | null
  scheme?: SCHEME | null
  hardwareType: ModuleHardware | null
  batteryPercent: number | null
  size?: 'large' | 'medium'
  location: any
}

const ModuleHeader = withRouter(
  ({
    moduleId,
    frameId,
    scheme,
    hardwareType,
    batteryPercent,
    size = 'large',
    location,
  }: ModuleHeaderProps) => {
    const URL = `${routes.MODULES}/${moduleId}`
    const summaryTitle = getModuleTitle(frameId, hardwareType)

    return (
      <Flex justifyContent="space-between">
        <Flex>
          <HardwareTypeIcon
            hardwareType={hardwareType}
            mt={size === 'large' ? 2 : '5px'}
          />
          {location.pathname.includes(URL) || !moduleId ? (
            <Heading variant={size === 'large' ? 'h1' : 'h3'}>
              {summaryTitle}
            </Heading>
          ) : (
            <ModuleLink id={moduleId} bikeScheme={scheme ?? undefined}>
              <Heading
                variant={size === 'large' ? 'h1' : 'h3'}
                color={colors.jade}
              >
                {summaryTitle}
              </Heading>
            </ModuleLink>
          )}
        </Flex>
        {hardwareType && isElectricVehicle(hardwareType) && (
          <Flex mt={size === 'large' ? 1 : 0}>
            <img
              alt="electric"
              style={{ height: 18, marginTop: 2 }}
              src={batteryIcon}
            />
            <Text ml={1}>{batteryPercent || '- '}%</Text>
          </Flex>
        )}
      </Flex>
    )
  },
)

export default ModuleHeader

export const getModuleTitle = (
  frameId?: string | null,
  hardwareType?: ModuleHardware | null,
) => {
  if (frameId) {
    return `${
      hardwareType ? getVehicleName(hardwareType) : 'Vehicle'
    } ${frameId}`
  }

  return 'Unknown Frame ID'
}
