import { Flex, Box, Icon, BoxProps } from '@weareberyl/design-system'
import { InvoiceStatus } from '../../__generated__/globalTypes'
import {
  IconColor,
  IconType,
} from '@weareberyl/design-system/build/components/Icon'
import { Typography } from 'antd'

const statusIconMap: {
  [key in InvoiceStatus]: {
    type: IconType
    color: IconColor
    bg: BoxProps['bg']
  }
} = {
  pending: {
    type: 'warning',
    color: 'deepPurple',
    bg: 'oyster',
  },
  outstanding: {
    type: 'warning',
    color: 'jaffa',
    bg: 'wheat',
  },
  settled: {
    type: 'tick',
    color: 'pickle',
    bg: 'mint',
  },
  fully_refunded: {
    type: 'repeat',
    color: 'lake',
    bg: 'shell',
  },
  partially_refunded: {
    type: 'repeat',
    color: 'lake',
    bg: 'shell',
  },
  void: {
    type: 'cross',
    color: 'brick',
    bg: 'prawn',
  },
}

type InvoiceStatusProps = {
  status: InvoiceStatus
}

const InvoiceStatusLabel = ({ status }: InvoiceStatusProps) => {
  return (
    <Flex flexWrap="nowrap" alignItems="center">
      <Box
        borderRadius="100%"
        alignItems="center"
        bg={statusIconMap[status].bg}
        mt={1}
        mr={2}
        p={1}
        height={20}
        width={20}
      >
        <Icon
          color={statusIconMap[status].color}
          mt="1px"
          type={statusIconMap[status].type}
          width={9}
          height={9}
        />
      </Box>
      <Typography.Text strong ellipsis>
        {status}
      </Typography.Text>
    </Flex>
  )
}

export default InvoiceStatusLabel
