import type { ComponentProps, ComponentType } from 'react'
import useSchemes, { SchemePickerScheme } from './useSchemes'

export type WithScheme = {
  scheme: SchemePickerScheme
}

/**
 * @deprecated try to directly use useSchemes() hook instead of this
 */
function withScheme(Component: ComponentType<any>) {
  return function Wrapped(props: ComponentProps<typeof Component>) {
    const { currentScheme } = useSchemes()
    return <Component scheme={currentScheme || {}} {...props} />
  }
}

export default withScheme
