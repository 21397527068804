import { createContext, useEffect, useState } from 'react'
import { message } from 'antd'

import useJobTypesQuery, {
  JobTypesTransformations,
} from 'context/ConfigHooks/useJobTypesQuery'
import { useCurrentUser } from 'hooks'
import { CURRENT_USER } from 'types'
import { hasAnyRelevantPermission } from 'utils/firebase/authentication'

type ConfigCtx = {
  jobTypes?: JobTypesTransformations
}

export const ConfigContext = createContext<ConfigCtx>({
  jobTypes: undefined,
})

export default ({ children }) => {
  const [jobTypes, setJobTypes] = useState<JobTypesTransformations | undefined>(
    undefined,
  )
  const [queriesRun, setQueriesRun] = useState(false)
  const [user] = useCurrentUser()

  const [getJobTypes, { error: jobTypesError, ...jobsTransformations }] =
    useJobTypesQuery()

  useEffect(() => {
    if (!queriesRun) {
      // we can't run any queries until the user is logged in
      const current_user = user as CURRENT_USER
      if (current_user?.id && hasAnyRelevantPermission(current_user?.roles)) {
        getJobTypes()
        setQueriesRun(true)
      }
    }
  }, [user, queriesRun, setQueriesRun, getJobTypes])

  useEffect(() => {
    if (jobTypesError) {
      message.error(jobTypesError.message)
    }
  }, [jobTypesError])

  useEffect(() => {
    if (!jobTypes && jobsTransformations?.jobOptions) {
      setJobTypes(jobsTransformations)
    }
  }, [jobsTransformations, jobTypes])

  return (
    <ConfigContext.Provider value={{ jobTypes }}>
      {children}
    </ConfigContext.Provider>
  )
}
