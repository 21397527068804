import { Box, Flex, Text } from '@weareberyl/design-system'
import { ColumnsType } from 'antd/lib/table'

import BooleanIcon from 'components/shared/BooleanIcon'
import {
  VehicleType,
  ZoneHistoryQuery_zone_history_ZoneHistoryConnection_nodes_ZoneHistory as ZoneHistoryQueryNode,
} from 'gql/generated/graphql'
import { formatDatetime, VehicleIcon } from 'utils'

import ZoneTypeStatus from '../ZoneTypeStatus'
import { WithIsChangedBool } from './markChangedValues'

const defaultRender = value => value

const alignLookup = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

/**
 * Thanks to the markChangedValues helper that's been run over the data, we know
 * that this render function will be given the tuple
 * [originalValue, isModified].
 *
 * Use isModified to wrap the original render function with styling that shows
 * changed values
 */
const highlightChangedValuesRender = (
  record: [unknown, boolean],
  render,
  align = 'left',
) => {
  const [value, isModified] = record
  const justifyContent = alignLookup[align] ?? 'flex-start'

  return (
    <Box
      opacity={`${isModified ? '1' : '0.5'}`} // fade out unchanged values
      style={{ flexDirection: 'row', justifyContent }}
    >
      {
        render(value) // call the original render function with the original value
      }
    </Box>
  )
}

const columns: ColumnsType<WithIsChangedBool<ZoneHistoryQueryNode>> = [
  {
    title: 'Updated At',
    dataIndex: ['time'],
    key: 'time',
    align: 'center',
    render: value => <Text>{formatDatetime(value)}</Text>,
  },
  {
    title: 'Suspended',
    dataIndex: ['is_suspended'],
    key: 'is_suspended',
    align: 'center',
    render: value => <BooleanIcon value={value} />,
  },
  {
    title: 'Promotional',
    dataIndex: ['is_promotional'],
    key: 'is_promotional',
    align: 'center',
    render: value => <BooleanIcon value={value} />,
  },
  {
    title: 'Notes',
    dataIndex: ['notes'],
    key: 'notes',
    render: value => (
      <Box style={{ maxWidth: '200px' }}>
        <Text>{value}</Text>
      </Box>
    ),
  },
  {
    title: 'Name',
    dataIndex: ['name'],
    key: 'name',
    render: value => <Text>{value}</Text>,
  },
  {
    title: 'Capacity',
    dataIndex: ['capacity'],
    key: 'capacity',
    align: 'center',
  },
  {
    title: 'Permitted Vehicles',
    dataIndex: ['permitted_vehicles'],
    key: 'permitted_vehicles',
    align: 'center',
    render: (value: VehicleType[]) => {
      return (
        <Flex>
          {value.map(vehicle => (
            <VehicleIcon key={vehicle} vehicle={vehicle} size={21} />
          ))}
        </Flex>
      )
    },
  },
  {
    title: 'Zone Type',
    dataIndex: ['zone_type'],
    key: 'zone_type',
    align: 'center',
    render: zoneType => <ZoneTypeStatus zoneType={zoneType} />,
  },
]

// Wrap the original render function with the one that highlights changed values
columns.forEach(col => {
  const render = col.render || defaultRender

  col.render = record => highlightChangedValuesRender(record, render, col.align)
  return col
})

export default columns
