import { useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  colors,
} from '@weareberyl/design-system'
import { useMutation } from '@apollo/client'

import { UPDATE_JOB_MUTATION } from '../Actions'
import { message, Input, Tooltip } from 'antd'
import Justification from '../Justification'
import { convertStringToLatLng } from '../../../utils'
import { isNotNullOrUndefined } from 'utils/types'
import { isVehicle } from '../utils'
import { JobDocument, JobQuery_job_Job } from 'gql/generated/graphql'

const CompleteJob = ({ job }: { job: JobQuery_job_Job }) => {
  const [updateJob] = useMutation(UPDATE_JOB_MUTATION, {
    refetchQueries: [{ query: JobDocument, variables: { job_id: job.uuid } }],
  })
  const [notesInput, setNotesInput] = useState(job?.details?.notes)
  const [reportedLocationInput, setReportedLocationInput] = useState(
    job?.details?.reportedLocation ?? undefined,
  )
  const [locationDescriptionInput, setLocationDescriptionInput] = useState(
    job?.details?.locationDescription,
  )

  const [isValidLatLng, setIsValidLatLng] = useState(
    Boolean(
      job?.details?.reportedLocation &&
        convertStringToLatLng(job.details.reportedLocation),
    ),
  )

  const assetCategory = job?.details?.assetCategory
  const status = job?.details?.status
  const attempts = job?.attempts
  const hasAttempts = (attempts?.length ?? -1) > 0

  return (
    <Box>
      <Justification job={job} />
      {hasAttempts && (
        <Box mt={2} mb={2}>
          <Heading mt={1} mb={1}>
            Attempts
          </Heading>
          {attempts?.filter(isNotNullOrUndefined).map(attempt => {
            return (
              <Flex key={attempt.created_at} mb={1} justifyContent="flex-start">
                <Text variant="small">
                  {`${new Date(attempt.created_at).toLocaleString()} by `}
                </Text>
                <Text variant="small" fontFamily="Hellix-SemiBold">
                  {attempt.assignee.email}
                </Text>
              </Flex>
            )
          })}
        </Box>
      )}
      {isVehicle(assetCategory) && (
        <>
          <Heading>Reported location</Heading>
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Input.TextArea
              disabled={status === 'complete'}
              placeholder="Reported location"
              defaultValue={reportedLocationInput}
              onBlur={e => {
                const value = e.currentTarget.value.trim()
                const latLng = convertStringToLatLng(value)
                setIsValidLatLng(Boolean(latLng))
              }}
              onChange={e => {
                const value = e.currentTarget.value.trim()
                setReportedLocationInput(value)
              }}
            />
            {isValidLatLng && (
              <Text color={colors.berylGreen} variant="small">
                Valid lat/lon coords - these will be used on the job map
              </Text>
            )}
          </div>

          <Heading>Location description</Heading>
          <Input.TextArea
            disabled={status === 'complete'}
            placeholder="Location description"
            defaultValue={locationDescriptionInput ?? undefined}
            style={{ marginTop: 10, marginBottom: 10 }}
            onChange={e => {
              setLocationDescriptionInput(e.currentTarget.value.trim())
            }}
          />
        </>
      )}
      <Heading>Notes</Heading>
      <Input.TextArea
        disabled={status === 'complete'}
        placeholder="Job notes"
        defaultValue={job.details?.notes ?? ''}
        style={{ marginTop: 10, marginBottom: 20 }}
        onChange={e => {
          setNotesInput(e.currentTarget.value.trim())
        }}
      />
      <Flex justifyContent="end">
        <Button
          mr={10}
          disabled={status === 'complete'}
          title="Update Job"
          onPress={async () => {
            try {
              await updateJob({
                variables: {
                  job_id: job.uuid,
                  job: {
                    details: {
                      notes: notesInput,
                      locationDescription: locationDescriptionInput,
                      reportedLocation: reportedLocationInput,
                    },
                  },
                },
              })
              message.success('Notes saved')
            } catch (err) {
              message.error(err.message)
            }
          }}
        />
        <Tooltip
          title="Use the engineering to app to complete this job"
          placement="bottom"
        >
          <Button mr={10} title="Complete Job" disabled />
        </Tooltip>
        <Tooltip
          title="Use the engineering to app to mark this job as cannot complete"
          placement="bottom"
        >
          <Button title="Cannot complete Job" disabled />
        </Tooltip>
      </Flex>
    </Box>
  )
}

export default CompleteJob
