import { Helmet } from 'react-helmet'

import { getSiteEnv } from 'utils'
import { useCurrentScheme } from 'hooks'
import { SCHEME_EMOJIS } from 'constants/domain'
import { isNotNullOrUndefined } from 'utils/types'

type Props = {
  pageTitle?: string
}

const HeadTitle = ({ pageTitle }: Props) => {
  const siteEnv = getSiteEnv()
  const { currentSchemeId } = useCurrentScheme()
  const schemeIcon = currentSchemeId
    ? SCHEME_EMOJIS[currentSchemeId]
    : undefined

  const title = [
    siteEnv,
    schemeIcon,
    pageTitle,
    'Bike share dashboard by Beryl',
  ].filter(isNotNullOrUndefined)
  return (
    <Helmet>
      <title>{title.join(' · ')}</title>
    </Helmet>
  )
}

export default HeadTitle
