import {
  SchemeAvailabilityRestrictionsDocument,
  useRemoveSchemeAvailabilityRestrictionMutation,
} from 'gql/generated/graphql'

const useRemoveCurfew = () => {
  return useRemoveSchemeAvailabilityRestrictionMutation({
    refetchQueries: [SchemeAvailabilityRestrictionsDocument],
  })
}

export default useRemoveCurfew
