export type NullOrUndefined = null | undefined
export type NotNullOrUndefined<T> = Exclude<T, NullOrUndefined>

/**
 * Helper to construct type predicates with narrowing
 * Construct with the type T and a predicate which returns true if a
 * type is of type T
 */
export const isType =
  <T>(pred: (arg: unknown) => boolean) =>
  (arg: unknown): arg is T =>
    pred(arg)

/**
 * Returns true if arg is null or undefined, and narrows the type accordingly
 * @param arg arg to check for null or undefined
 */
export const isNullOrUndefined = isType<NullOrUndefined>(
  arg => arg === null || arg === undefined,
)

export const isNotNullOrUndefined = <T>(arg: T): arg is NotNullOrUndefined<T> =>
  !isNullOrUndefined(arg)
