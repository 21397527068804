import {
  Flex,
  Card as BerylCard,
  Heading,
  LoadingDots,
} from '@weareberyl/design-system'

import HeadTitle from 'components/HeadTitle'
import Copy from 'components/Copy'
import ExternalLink from 'components/Navigation/ExternalLink'

import { CampaignDetail, useCampaign } from '../data'
import EoJPromotionSummary from './EoJPromotionSummary'
import PromotionEdit from 'components/AutoPromos/Edit'
import { useCurrentScheme } from 'hooks'

const ExternalLinks = ({ id }: { id: string }) => {
  const campaignURL = `https://app.voucherify.io/#/app/core/redeemables/campaigns/${id}/dashboard`
  return (
    <Flex justifyContent="flex-end" mb={3}>
      <ExternalLink onPress={() => window.open(campaignURL, '_blank')}>
        View on Voucherify
      </ExternalLink>
    </Flex>
  )
}

const CampaignHeader = ({ campaign }: { campaign: CampaignDetail }) => {
  return (
    <BerylCard mb={4} p={5} pb={3} flex={1}>
      <Heading variant="callout">{campaign.name}</Heading>
      <Flex justifyContent="space-between" mt={2}>
        <Copy id="campaign-id" text={campaign.id} />
        <ExternalLinks id={campaign.id} />
      </Flex>
    </BerylCard>
  )
}

const CampaignSummary = ({ campaign }: { campaign: CampaignDetail }) => {
  switch (campaign.__typename) {
    case 'EoJPromotion':
      return <EoJPromotionSummary campaign={campaign} />
    case 'CampaignSummary':
      return <p>TODO</p>
    default:
      return null
  }
}

const CampaignForm = ({ campaign }: { campaign: CampaignDetail }) => {
  switch (campaign.__typename) {
    case 'EoJPromotion':
      return <PromotionEdit promotion={campaign} />
    case 'CampaignSummary':
      return null
    default:
      return null
  }
}

const CampaignEdit = ({ id }: { id: string }) => {
  const { currentSchemeId } = useCurrentScheme()
  const { data, loading } = useCampaign(id, currentSchemeId)

  if (!data) {
    return null
  }

  if (loading) {
    return <LoadingDots />
  }

  return (
    <>
      <HeadTitle pageTitle="Campaigns" />
      <CampaignHeader campaign={data.campaign} />
      <CampaignSummary campaign={data.campaign} />
      <CampaignForm campaign={data.campaign} />
    </>
  )
}

export default CampaignEdit
