import { ApolloQueryResult, gql } from '@apollo/client'
import { noop, groupBy } from 'lodash'
import { parseISO, isPast } from 'date-fns'
import type { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import { withRouter } from 'react-router'

import { Box, Text } from '@weareberyl/design-system'

import { formatDatetime } from 'utils'
import Query from 'components/Query'
import { withScheme } from 'components/Scheme'
import Table from 'components/Table'
import * as routes from 'constants/routes'

import type {
  USER_PRODUCT_LIST,
  USER_PRODUCT_LIST_user_table_nodes as UserProduct,
} from './__generated__/USER_PRODUCT_LIST'
import { ProductType } from '__generated__/globalTypes'
import { SCHEME } from 'types'

const isInactive = (userProduct: UserProduct): boolean => {
  const dt = parseISO(userProduct.valid_until)
  if (isPast(dt)) {
    return true
  }
  const hasBalance = !!userProduct.balances?.current_balance?.amount
  const isPeriodPass =
    userProduct.scheme_product.product_type === ProductType.period_pass
  return !hasBalance && isPeriodPass
}

const Usage = ({
  used,
  limit,
  period,
  label,
}: {
  used: number
  limit: number
  period: string
  label: string
}) => {
  const usage = [...Array(limit).keys()]
    .reverse()
    .map(index => (index < used ? '⚪️' : '🟢'))
    .join(' ')

  const formattedPeriod = moment.duration(period).humanize().replace('a ', '') // e.g. "a day" -> "day"
  const text = `${used} used, out of ${limit} for last ${formattedPeriod}`

  return (
    <Box key={limit} title={text}>
      {label}: {usage}
      <Text variant="micro">{text}</Text>
    </Box>
  )
}

const columns: ColumnProps<UserProduct>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Date/Time',
    dataIndex: 'created_at',
    render: text => formatDatetime(text),
  },
  {
    title: 'Name',
    dataIndex: ['scheme_product', 'name'],
  },
  {
    title: 'Valid Until',
    dataIndex: 'valid_until',
    render: text => formatDatetime(text),
  },
  {
    title: 'Pass Minutes',
    dataIndex: ['balances', 'current_balance', 'formatted_amount'],
  },
  {
    title: 'Total Pass Minutes',
    dataIndex: ['balances', 'total_balance', 'formatted_amount'],
  },
  {
    title: 'Caps',
    render: (data: UserProduct) => {
      if (data.period_pass_journey_cap) {
        const { used, limit, period } = data.period_pass_journey_cap
        return (
          <Usage
            used={used}
            limit={limit}
            period={period}
            label="Billable journeys"
          />
        )
      }
      if (data.period_pass_unlocks) {
        // Group by limit just in case that is configured differently for a particular hardware_type
        return Object.entries(
          groupBy(data.period_pass_unlocks, value => value.limit),
        ).map(
          ([hardware_type, value]: [
            string,
            UserProduct['period_pass_unlocks'],
          ]) => {
            // Get the first one, as the group members should have the same limit for all hardware types
            if (!value?.[0]) {
              return null
            }
            const { used, limit, period } = value[0]
            return (
              <Usage
                key={hardware_type}
                used={used}
                limit={limit}
                period={period}
                label="Included unlocks"
              />
            )
          },
        )
      }
      return null
    },
  },
  {
    title: 'Bonus?',
    dataIndex: 'is_bonus',
    align: 'center',
    render: is_bonus => (is_bonus ? '🌟' : ''),
  },
]

export const USER_PRODUCT_LIST_QUERY = gql`
  query USER_PRODUCT_LIST($scheme_id: ID, $user_id: ID!, $paginate: Paginate) {
    user(user_id: $user_id) {
      id
      table: user_products(scheme_id: $scheme_id, paginate: $paginate) {
        nodes {
          id
          valid_until
          created_at
          is_bonus
          balances {
            total_balance {
              formatted_amount
              amount
              unit
            }
            current_balance {
              formatted_amount
              amount
              unit
            }
          }
          period_pass_unlocks {
            hardware_type
            used
            limit
            period
          }
          scheme_product {
            id
            name
            product_type
            scheme_id
          }
          period_pass_journey_cap {
            limit
            used
            period
            is_billable
          }
        }
        pagination {
          current: page
          pageSize: per_page
          total
        }
      }
    }
  }
`

const UserProducts = ({
  scheme,
  user_id,
  history,
}: {
  scheme: SCHEME
  user_id: string
  history: { push: (route: string) => void }
}) => (
  <Query
    waitFor="data.user.table"
    pollInterval={0}
    query={USER_PRODUCT_LIST_QUERY}
    variables={{ scheme_id: scheme.id, user_id, order_by: [{ id: 'desc' }] }}
  >
    {({
      data,
      refetch,
    }: ApolloQueryResult<USER_PRODUCT_LIST> & {
      refetch: (variables: unknown) => Promise<void>
    }) => (
      <Table<UserProduct>
        columns={columns}
        data={data.user}
        refetch={refetch}
        onChange={noop}
        rowClassName={(record: UserProduct) =>
          isInactive(record) ? 'inactive-row' : 'generic'
        }
        onRow={(record: UserProduct) => {
          return {
            onClick: () => {
              history.push(
                routes.prependScheme(
                  `${routes.PRODUCTS}?expandedProducts=${record.scheme_product.id}&id=products-table`,
                  record.scheme_product.scheme_id,
                ),
              )
            },
          }
        }}
      />
    )}
  </Query>
)

export default withRouter(withScheme(UserProducts))
