import { ReactChildren, ReactElement } from 'react'
import { gql, ApolloQueryResult } from '@apollo/client'
import { Link } from 'react-router-dom'
import Query from 'components/Query'
import * as routes from 'constants/routes'

import { ZONE_NAME_FROM_ID } from './__generated__/ZONE_NAME_FROM_ID'

const ZONE_NAME_QUERY = gql`
  query ZONE_NAME_FROM_ID($id: ID!) {
    zone(zone_id: $id) {
      id
      name
      scheme {
        id
      }
    }
  }
`

type ZoneLinkProps = {
  id: string
  children?: ReactChildren | ReactElement
}

export default ({ id, children }: ZoneLinkProps) => {
  const zonePath = `${routes.ZONES}/${id}`

  return (
    <Query
      waitFor="data.zone"
      query={ZONE_NAME_QUERY}
      variables={{ id }}
      fetchPolicy="cache-first"
      pollInterval={0}
    >
      {({ data }: ApolloQueryResult<ZONE_NAME_FROM_ID>) => {
        const zoneName = data.zone?.name
        if (!data.zone?.scheme.id) {
          return id
        }
        const schemeZonePath = routes.prependScheme(
          zonePath,
          data.zone.scheme.id,
        )

        return zoneName ? (
          <Link to={schemeZonePath}>{children || zoneName}</Link>
        ) : (
          id
        )
      }}
    </Query>
  )
}
