import { gql, useMutation } from '@apollo/client'
import * as types from './__generated__/requestVehicleProviderDropOff'

const query = gql`
  mutation requestVehicleProviderDropOff($vehicleId: GUID!) {
    vehicle_provider_drop_off(vehicle_id: $vehicleId)
  }
`

export default function useProviderDropOff(
  variables?: types.requestVehicleProviderDropOffVariables,
) {
  return useMutation<
    types.requestVehicleProviderDropOff,
    types.requestVehicleProviderDropOffVariables
  >(query, {
    variables,
    context: {
      useApolloNetworkStatus: false,
    },
  })
}
