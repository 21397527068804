import {
  AvailabilityRestrictionDataFragment,
  VehicleType,
  VehiclePropulsionType,
  FrameType,
} from 'gql/generated/graphql'

type CurfewTemplate = {
  name: string
  vehicle_type: VehicleType
  frame_type: FrameType
  propulsion_type: VehiclePropulsionType
}

// this defines the allowed vehicle curfew types
export const curfewTemplates: CurfewTemplate[] = [
  {
    name: 'Scooter',
    vehicle_type: VehicleType.scooter,
    frame_type: FrameType.okai_scooter,
    propulsion_type: VehiclePropulsionType.electric,
  },
  {
    name: 'e-Cargo Bike',
    vehicle_type: VehicleType.cargo_bike,
    frame_type: FrameType.raleigh_cargo_e_bike,
    propulsion_type: VehiclePropulsionType.human,
  },
]

const matchingCurfew = (
  curfew: CurfewTemplate | AvailabilityRestrictionDataFragment,
  vehicle_type: VehicleType,
  frame_type: FrameType | null,
  propulsion_type: VehiclePropulsionType,
) =>
  curfew.vehicle_type === vehicle_type &&
  curfew.propulsion_type === propulsion_type &&
  (curfew.frame_type ?? FrameType.okai_scooter) ===
    (frame_type ?? FrameType.okai_scooter)

export const getCurfewName = (
  vehicle_type: VehicleType,
  frame_type: FrameType | null,
  propulsion_type: VehiclePropulsionType,
) =>
  curfewTemplates.find(template =>
    matchingCurfew(template, vehicle_type, frame_type, propulsion_type),
  )?.name

export const mapCurfews = (
  restrictions?: AvailabilityRestrictionDataFragment[],
) =>
  curfewTemplates.map(({ name, vehicle_type, frame_type, propulsion_type }) => {
    const matchingConfig = restrictions?.find(curfew =>
      matchingCurfew(curfew, vehicle_type, frame_type, propulsion_type),
    )

    return {
      id: matchingConfig?.id ?? undefined,
      name,
      vehicle_type,
      frame_type,
      propulsion_type,
      start_time: matchingConfig?.start_time ?? undefined,
      end_time: matchingConfig?.end_time ?? undefined,
      last_status_change_time:
        matchingConfig?.last_status_change_time ?? undefined,
    }
  })
