import { BoxProps, Button, Heading } from '@weareberyl/design-system'
import useArchiveJobList from 'hooks/useArchiveJobList'
import { useState } from 'react'
import ArchiveJobsTable from './ArchiveJobsTable'

interface IArchiveJobList {
  assetTag: string
}

const ArchiveJobList = ({ assetTag }: IArchiveJobList) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const { data: table, refetch } = useArchiveJobList({
    variables: {
      asset_tag: assetTag,
      paginate: {
        page: currentPage,
        per_page: pageSize,
      },
    },
    pollInterval: 0,
  })

  return (
    <>
      <Heading variant="h1">📁 Archive</Heading>
      <ArchiveJobsTable
        table={table}
        refetch={refetch}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
      />
    </>
  )
}

interface IArchiveJobListContainer extends BoxProps {
  assetTag?: string | null
}

const ArchiveJobListContainer = ({
  assetTag,
  ...props
}: IArchiveJobListContainer) => {
  const [isOpen, setIsOpen] = useState(false)
  if (!assetTag) return null

  if (!isOpen)
    return (
      <Button
        title="Show Archive"
        onPress={() => setIsOpen(true)}
        variant="outline"
        {...props}
      />
    )

  return <ArchiveJobList assetTag={assetTag} />
}

export default ArchiveJobListContainer
