import { Box, Flex, Icon } from '@weareberyl/design-system'
import { Typography } from 'antd'

const icons = {
  succeeded: {
    type: 'tick',
    color: 'pickle',
    bg: 'mint',
  },
  failed: {
    type: 'cross',
    color: 'brick',
    bg: 'prawn',
  },
  refunded: {
    type: 'repeat',
    color: 'lake',
    bg: 'white',
  },
  pending: {
    type: 'clock',
    color: 'brickLight',
    bg: 'brick',
  },
}

type TransactionStatusLabelProps = {
  text: string
  icon: keyof typeof icons
}

const TransactionStatusLabel = ({
  text,
  icon,
}: TransactionStatusLabelProps) => (
  <Flex flexWrap="nowrap" alignItems="center">
    <Box
      borderRadius="100%"
      alignItems="center"
      bg={icons[icon].bg}
      mt={1}
      mr={2}
      p={1}
      height={18}
      width={18}
    >
      <Icon
        color={icons[icon].color}
        mt="1px"
        type={icons[icon].type}
        width={9}
        height={9}
      />
    </Box>
    <Typography.Text ellipsis>{text}</Typography.Text>
  </Flex>
)

export default TransactionStatusLabel
