import React from 'react'
import deletedUserIcon from 'images/deleted-user.svg'

export default () => (
  <img
    title="Deleted user"
    alt="Deleted user icon"
    height={16}
    width={16}
    style={{
      marginLeft: 5,
    }}
    src={deletedUserIcon}
  />
)
