import { useEffect } from 'react'
import { Modal, message } from 'antd'
import { TextLink } from '@weareberyl/design-system'

import {
  useZoneEnableMutation,
  useZoneSuspendMutation,
} from 'gql/generated/graphql'
import type { SetAnyLoading } from '../useIsAnyLoading'

const confirm = Modal.confirm

const SUSPEND_SYMBOL = Symbol('suspendZone')
const ENABLE_SYMBOL = Symbol('enableZone')

const onError = () =>
  message.error(
    'Something went wrong when trying to change the suspended status',
  )

type SuspendZoneProps = {
  id: string
  isSuspended: boolean
  setAnyLoading: SetAnyLoading
}

export const SuspendZone = ({
  id,
  isSuspended,
  setAnyLoading,
}: SuspendZoneProps) => {
  const [suspendZone, { loading: suspendLoading }] = useZoneSuspendMutation({
    variables: { id },
    onCompleted: () => message.success('Bay suspended'),
    onError,
    refetchQueries: ['ZoneSummary'],
  })

  useEffect(
    () => setAnyLoading(SUSPEND_SYMBOL, suspendLoading),
    [suspendLoading, setAnyLoading],
  )

  const [enableZone, { loading: enableLoading }] = useZoneEnableMutation({
    variables: { id },
    onCompleted: () => message.success('Bay enabled'),
    onError,
    refetchQueries: ['ZoneSummary'],
  })

  useEffect(
    () => setAnyLoading(ENABLE_SYMBOL, enableLoading),
    [enableLoading, setAnyLoading],
  )

  return (
    <TextLink
      variant="grape"
      size="small"
      onPress={() => {
        confirm({
          zIndex: 1050,
          title: `${isSuspended ? 'Enable' : 'Suspend'} this bay?`,
          onOk: () => {
            if (isSuspended) {
              enableZone()
            } else {
              suspendZone()
            }
          },
          onCancel: undefined,
        })
      }}
      testID="bay-suspend-btn"
    >
      {isSuspended ? 'Enable Bay' : 'Suspend Bay'}
    </TextLink>
  )
}
