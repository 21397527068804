import React, { useState } from 'react'
import { Flex, Box, Card, Heading, Icon } from '@weareberyl/design-system'
import Table from 'components/Table'
import Tabs from 'components/shared/Tabs'
import { EmptyComponent } from 'components/Query/utils'
import { capitalize } from 'utils'

import { ZonesSyncMutation_sync_zones_ZoneSyncResult } from 'gql/generated/graphql'
import { SERVICE_AREAS_SYNC_sync_service_areas } from 'components/ServiceArea/Sync/__generated__/SERVICE_AREAS_SYNC'

const { Tab } = Tabs

export enum SyncTab {
  added = 'added',
  updated = 'updated',
  deleted = 'deleted',
}

export const TabTitle = ({ tab }: { tab: SyncTab }) => {
  let iconType, iconColor, iconBackground
  switch (tab) {
    case SyncTab.updated:
      iconType = 'repeat'
      iconColor = 'jaffa'
      iconBackground = 'wheat'
      break

    case SyncTab.deleted:
      iconType = 'outOfOrder'
      iconColor = 'brick'
      iconBackground = 'prawn'
      break

    case SyncTab.added:
    default:
      iconType = 'plus'
      iconColor = 'pickle'
      iconBackground = 'mint'
      break
  }

  return (
    <Flex>
      <Box
        borderRadius="100%"
        alignItems="center"
        bg={iconBackground}
        mt={1}
        p={1}
        height={24}
        width={24}
      >
        <Icon
          color={iconColor}
          mt="1px"
          type={iconType}
          width={14}
          height={14}
        />
      </Box>
      <Heading variant="h2" mb={2} ml={2} style={{ opacity: 0.75 }}>
        {capitalize(tab)}
      </Heading>
    </Flex>
  )
}

type SyncTabsProps = {
  id: string
  data:
    | ZonesSyncMutation_sync_zones_ZoneSyncResult
    | SERVICE_AREAS_SYNC_sync_service_areas
    | null
  columns: (tab: SyncTab) => object[]
}

const SyncTabs = ({ id, data, columns }: SyncTabsProps) => {
  const [selectedTab, setSelectedTab] = useState<SyncTab>(SyncTab.added)

  const tableBorderProps: {
    borderTopLeftRadius?: number
    borderTopRightRadius?: number
  } = {}
  if (selectedTab === SyncTab.added) {
    tableBorderProps.borderTopLeftRadius = 0
  } else if (selectedTab === SyncTab.deleted) {
    tableBorderProps.borderTopRightRadius = 0
  }

  return (
    <Card variant="gray" p={3}>
      <Tabs>
        <Tab
          onPress={() => setSelectedTab(SyncTab.added)}
          isSelected={selectedTab === SyncTab.added}
        >
          <TabTitle tab={SyncTab.added} />
          <Heading variant="callout">{data?.added?.length || 0}</Heading>
        </Tab>
        <Tab
          onPress={() => setSelectedTab(SyncTab.updated)}
          isSelected={selectedTab === SyncTab.updated}
        >
          <TabTitle tab={SyncTab.updated} />
          <Heading variant="callout">{data?.updated?.length || 0}</Heading>
        </Tab>
        <Tab
          onPress={() => setSelectedTab(SyncTab.deleted)}
          isSelected={selectedTab === SyncTab.deleted}
        >
          <TabTitle tab={SyncTab.deleted} />
          <Heading variant="callout">{data?.deleted?.length || 0}</Heading>
        </Tab>
      </Tabs>
      <Card variant="borderless" p={4} {...tableBorderProps}>
        {!data?.[selectedTab] || data?.[selectedTab]?.length === 0 ? (
          <EmptyComponent text={`No changes found for ${selectedTab}.`} />
        ) : (
          <Table
            id={`${id}-table-${selectedTab}`}
            hideProgress
            columns={columns(selectedTab)}
            data={{
              table: {
                pagination: false,
                // @ts-ignore
                nodes: data?.[selectedTab],
              },
            }}
          />
        )}
      </Card>
    </Card>
  )
}

export default SyncTabs
