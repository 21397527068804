import React, { useState } from 'react'
import {
  borderRadii,
  Flex,
  colors,
  Box,
  Heading,
  Icon,
  Touchable,
} from '@weareberyl/design-system'
import PTextInput from '@weareberyl/design-system/build//primitives/TextInput'

type PrimitiveDropdownProps = {
  items: Array<{
    name: string
    key: string
  }>
  value: string
  onChange: (arg0: { name: string; key: string }) => void
  placeholder: string
  disabled?: boolean
  zIndex?: number
}

const PrimitiveDropdown = ({
  items,
  value,
  onChange,
  placeholder,
  disabled,
  zIndex,
}: PrimitiveDropdownProps) => {
  const [showDropdown, setShowDropdown] = useState(false)

  const toggleDropDown = () => {
    if (!disabled) setShowDropdown(!showDropdown)
  }

  const handleChange = (item: { key: string; name: string }) => {
    if (disabled) return

    onChange(item)
    toggleDropDown()
  }

  return (
    <Box zIndex={zIndex} mb={3}>
      <Flex
        bg="oyster"
        borderWidth={2}
        borderColor="oyster"
        borderRadius={borderRadii.default}
        mt={2}
        justifyContent="space-between"
        alignItems="center"
        onClick={toggleDropDown}
        flexWrap="nowrap"
        style={{ cursor: 'pointer' }}
      >
        <PTextInput
          fontSize={22}
          lineHeight={32}
          fontFamily="Hellix-SemiBold"
          color={colors.deepPurple}
          placeholderTextColor={colors.afternoon}
          selectionColor={colors.jade}
          px={3}
          py={2}
          height={48}
          editable={false}
          placeholder={placeholder}
          value={value}
          width="80%"
          style={{ cursor: 'pointer' }}
        />
        <Icon type="chevronDown" width={24} height={24} mr={2} />
      </Flex>
      {showDropdown && (
        <Flex
          flexDirection="column"
          bg="oyster"
          position="absolute"
          borderBottomLeftRadius={borderRadii.default}
          borderBottomRightRadius={borderRadii.default}
          maxHeight="210px"
          top="55px"
          width="100%"
          flexWrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
          px={3}
          style={{ cursor: 'pointer', overflowY: 'scroll' }}
        >
          {items.map(item => {
            return (
              <Touchable
                onPress={() => handleChange(item)}
                style={{ width: '100%' }}
                key={item.key}
              >
                <Heading variant="h1" py={1}>
                  {item.name}
                </Heading>
              </Touchable>
            )
          })}
        </Flex>
      )}
    </Box>
  )
}

export default PrimitiveDropdown
