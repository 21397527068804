import React, { Component } from 'react'
import {
  InboxOutlined,
  LoadingOutlined,
  WarningOutlined,
} from '@ant-design/icons'

import Sentry from 'utils/sentry'
import { Center } from 'components/Layout'
import { ApolloError } from '@apollo/client'
import ServiceNowAuthError from 'components/ServiceNow/ServiceNowAuthError'

export const ErrorComponent = ({ error }) => (
  <Center className="text-center">
    <WarningOutlined />
    <p>Something went wrong</p>
    <p>{error && error.message}</p>
  </Center>
)

export const LoadingComponent = () => (
  <Center className="text-center">
    <LoadingOutlined />
  </Center>
)

export const EmptyComponent = ({ text }: { text?: string }) => (
  <Center className="text-center">
    <InboxOutlined style={{ fontSize: '50px' }} />
    <p>{text || "We couldn't find what you were looking for..."}</p>
  </Center>
)

type ErrorBoundaryState = {
  hasError: boolean
  error: ApolloError | null
}

type ErrorBoundaryProps = {
  rootError?: ApolloError | null
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
    }
  }

  componentDidMount() {
    // This is an override to make sure we render the ServiceNow auth error even if the child
    // component doesn't throw.
    if (
      this.props.rootError &&
      ErrorBoundary.isServiceNowAuthError(this.props.rootError)
    ) {
      this.setState({ hasError: true, error: this.props.rootError })
    }
  }

  componentDidCatch(error, info) {
    if (error?.networkError) {
      // Log network errors but don't raise them.
      Sentry.captureMessage(error)
    } else {
      // You can also log the error to an error reporting service
      Sentry.captureException(error, { extra: info })
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  static isServiceNowAuthError(error) {
    return (
      error?.graphQLErrors?.[0]?.extensions?.code === 'SN001' &&
      error?.graphQLErrors?.[0]?.extensions?.login_url
    )
  }

  render() {
    if (this.state.hasError) {
      if (
        this.state.error &&
        ErrorBoundary.isServiceNowAuthError(this.state.error)
      ) {
        return (
          <ServiceNowAuthError
            loginUrl={
              this.state.error?.graphQLErrors?.[0]?.extensions
                .login_url as string
            }
          />
        )
      }
      return <ErrorComponent error={this.props.rootError || this.state.error} />
    }

    return this.props.children
  }
}
