import { Link } from 'react-router-dom'
import { Card, Heading } from '@weareberyl/design-system'

import { formatDatetime } from 'utils'
import Query from 'components/Query'
import Table from 'components/Table'
import { useQueryParams } from 'utils/useQueryParams'
import * as routes from 'constants/routes'
import { DeploymentListDocument } from 'gql/generated/graphql'
import HeadTitle from 'components/HeadTitle'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: id => <Link to={`${routes.DEPLOYMENTS}/${id}`}>{id}</Link>,
  },
  {
    title: 'Date/Time',
    dataIndex: 'created_at',
    render: t => formatDatetime(t),
  },
  {
    title: 'Version',
    dataIndex: ['version', 'name'],
  },
  {
    title: 'Version Size',
    dataIndex: ['version', 'formatted_size'],
  },
]

const id = 'deployments-table'

const DeploymentList = () => {
  const [{ current, pageSize }, setQueryParams] = useQueryParams(id)

  return (
    <>
      <HeadTitle pageTitle="Deployments" />
      <Query
        waitFor="data.table.nodes"
        pollInterval={0}
        variables={{
          paginate: {
            per_page: pageSize,
            page: current,
          },
        }}
        query={DeploymentListDocument}
      >
        {props => (
          <Card p={5}>
            <Heading variant="callout" mb={5}>
              Deployments
            </Heading>
            <Table
              id={id}
              columns={columns}
              onChange={({ current, pageSize }) =>
                setQueryParams({ current, pageSize })
              }
              {...props}
            />
          </Card>
        )}
      </Query>
    </>
  )
}

export default DeploymentList
