import { useContext } from 'react'
import { useLocation, useHistory } from 'react-router'
import { matchPath } from 'react-router-dom'

import * as routes from 'constants/routes'
import { CurrentSchemeContext } from 'context/currentScheme'

export default function useCurrentScheme() {
  const contextFunctions = useContext(CurrentSchemeContext)
  const { setCurrentSchemeId } = contextFunctions

  const location = useLocation()
  const history = useHistory()
  const setSchemeIdAndUpdateLocation = (newSchemeId: string) => {
    // Check current page is a scheme page
    const match = matchPath(location.pathname, {
      path: routes.withScheme(''),
      exact: false,
    })
    if (match) {
      // Redirect current path to use the new scheme in the URL
      // SchemePersistRoute will update the saved scheme
      const currentPath = location.pathname.slice()
      const url = routes.prependScheme(
        currentPath.replace(match.url, ''),
        newSchemeId,
      )
      history.push(url)
    } else {
      // Not on a scheme page, so just quietly update the stored scheme
      // in the background, so that all the links will update to the new
      // scheme for next time they're navigated to.
      setCurrentSchemeId(newSchemeId)
    }
  }

  return {
    ...contextFunctions,
    setSchemeIdAndUpdateLocation,
  }
}
