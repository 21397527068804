import { useEffect } from 'react'
import { Modal, message } from 'antd'
import { TextLink } from '@weareberyl/design-system'

import { useZoneSetPromotionalMutation } from 'gql/generated/graphql'
import type { SetAnyLoading } from '../useIsAnyLoading'

const confirm = Modal.confirm

const ENABLE_SYMBOL = Symbol('togglePromo')

const onError = () =>
  message.error(
    'Something went wrong when trying to change the promotional status',
  )

type SetPromotionalProps = {
  id: string
  isPromotional: boolean
  setAnyLoading: SetAnyLoading
}

export const SetPromotional = ({
  id,
  isPromotional,
  setAnyLoading,
}: SetPromotionalProps) => {
  const [setZonePromotional, { loading }] = useZoneSetPromotionalMutation({
    variables: { id, is_promotional: true },
    onCompleted: () =>
      message.success(`Bay set to ${isPromotional ? 'not' : ''} promotional`),
    onError,
    refetchQueries: ['ZoneSummary'],
  })

  const onPress = () => {
    confirm({
      zIndex: 1050,
      title: `${isPromotional ? 'Unset' : 'Set'} this bay as promotional?`,
      onOk: () => {
        setZonePromotional({
          variables: { id, is_promotional: Boolean(!isPromotional) },
        })
      },
    })
  }

  useEffect(
    () => setAnyLoading(ENABLE_SYMBOL, loading),
    [loading, setAnyLoading],
  )

  return (
    <TextLink
      variant="grape"
      size="small"
      onPress={onPress}
      testID="bay-promotional-btn"
    >
      {isPromotional ? 'Unset Promotional' : 'Set Promotional'}
    </TextLink>
  )
}
