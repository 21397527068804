import type { PropsWithChildren } from 'react'
import { Modal } from 'antd'

export type ActionModalProps = {
  visible: boolean
  close: () => void
}
const ActionModal = ({
  visible,
  close,
  children,
}: PropsWithChildren<ActionModalProps>) => {
  return (
    <Modal
      open={visible}
      zIndex={1050}
      width={550}
      bodyStyle={{ padding: 0 }}
      onCancel={close}
      footer={null}
      destroyOnClose
    >
      {children}
    </Modal>
  )
}

export default ActionModal
