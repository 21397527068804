import { FirebaseApp, getApps, initializeApp } from 'firebase/app'
import {
  browserLocalPersistence,
  GoogleAuthProvider,
  inMemoryPersistence,
  initializeAuth,
  browserPopupRedirectResolver,
  Dependencies,
} from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

import config from 'constants/config'

let _app: FirebaseApp | undefined
if (getApps().length < 1) {
  _app = initializeApp({
    apiKey: config.FIREBASE_API_KEY,
    appId: config.FIREBASE_APP_ID,
    projectId: config.GOOGLE_PROJECT_ID,
    authDomain: config.FIREBASE_AUTH_DOMAIN,
    messagingSenderId: config.REACT_APP_FCM_SENDER_ID,
  })
}

if (_app === undefined) {
  throw Error('Firebase App did not initialize')
}
const app = _app

let authDependencies: Dependencies = {
  persistence: inMemoryPersistence,
  popupRedirectResolver: undefined,
}

if (process.env.NODE_ENV !== 'test') {
  // These only work in browsers so don't use when running tests in node
  authDependencies = {
    persistence: browserLocalPersistence,
    popupRedirectResolver: browserPopupRedirectResolver,
  }
}

const auth = initializeAuth(app, authDependencies)

const googleAuthProvider = new GoogleAuthProvider()

const db = getFirestore(app as FirebaseApp, config.FIRESTORE_LOGINS_DATABASE)

export { app, auth, googleAuthProvider, db }
