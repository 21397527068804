import { Flex, Box, Icon } from '@weareberyl/design-system'
import { ZoneType } from '__generated__/globalTypes'

type ZoneTypeStatusProps = {
  zoneType: ZoneType
}

const ZoneTypeStatus = ({ zoneType }: ZoneTypeStatusProps) => {
  const isWorkshop = zoneType === ZoneType.workshop

  return (
    <Flex flexWrap="nowrap" alignItems="center" justifyContent="center">
      <Box
        borderRadius="100%"
        alignItems="center"
        bg={isWorkshop ? 'tumeric' : 'berylGreen'}
        mt="3px"
        p="2px"
        height={24}
        width={24}
      >
        <Icon
          color="grape"
          mt="2px"
          type={isWorkshop ? 'spanner' : 'parking'}
          width={16}
          height={16}
        />
      </Box>
    </Flex>
  )
}

export default ZoneTypeStatus
