import { useState } from 'react'
import { gql, ApolloQueryResult } from '@apollo/client'
import Query from 'components/Query'
import AutoComplete from '../../shared/AutoComplete'
import { ModuleHardware } from '__generated__/globalTypes'
import {
  TASK_CODE_QUERY as TASK_CODE_QUERY_RESULT,
  TASK_CODE_QUERY_task_codes_list,
} from './__generated__/TASK_CODE_QUERY'

const TASK_CODE_QUERY = gql`
  query TASK_CODE_QUERY {
    task_codes_list {
      code
      description
      vehicle_type
      __typename
    }
  }
`

interface Props {
  onSelect: (key: string) => void
  hardwareType: ModuleHardware | null
}

const TaskCodeSearch = (props: Props): JSX.Element => {
  return (
    <Query
      waitFor="data.task_codes_list"
      query={TASK_CODE_QUERY}
      pollInterval={0}
      fetchPolicy="cache-first"
    >
      {({
        data: { task_codes_list },
      }: ApolloQueryResult<TASK_CODE_QUERY_RESULT>) => {
        return (
          <TaskCodeSearchField {...props} task_codes_list={task_codes_list} />
        )
      }}
    </Query>
  )
}

interface TaskCodeSearchFieldProps extends Props {
  task_codes_list: TASK_CODE_QUERY_task_codes_list[]
}
const TaskCodeSearchField = ({
  hardwareType,
  onSelect,
  task_codes_list,
}: TaskCodeSearchFieldProps): JSX.Element => {
  const [searchResult, setSearchResult] = useState<
    TASK_CODE_QUERY_task_codes_list[]
  >([])

  const searchOptions = task_codes_list.filter(
    ({ vehicle_type }) => !hardwareType || vehicle_type.includes(hardwareType),
  )

  function handleSearch(inputValue: string) {
    if (inputValue.length === 0) {
      return []
    }
    const result = searchOptions.filter(option =>
      option.description.toLowerCase().includes(inputValue.toLowerCase()),
    )
    setSearchResult(result)
  }

  return (
    <AutoComplete
      placeholder="Search task code"
      onSearch={handleSearch}
      zIndex={3}
      items={searchResult.map(item => ({
        name: `${item.code} – ${item.description}`,
        key: item.code,
      }))}
      onSelect={({ key }) => onSelect(key)}
    />
  )
}

export default TaskCodeSearch
