import type { ComponentProps, ComponentType } from 'react'
import useCurrentUser from 'hooks/useCurrentUser'

/**
 * @deprecated try to directly use useCurrentUser() hook instead of this
 */
function withUser(Component: ComponentType<any>) {
  return function Wrapped(props: ComponentProps<typeof Component>) {
    const [user] = useCurrentUser()

    return <Component user={user ?? {}} {...props} />
  }
}

export default withUser
