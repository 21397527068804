import React from 'react'
import * as routes from 'constants/routes'
import AssetLink from 'components/Scheme/AssetLink'
import { SCHEME } from 'types'

type ModuleLinkProps = {
  id: string
  bikeScheme?: Pick<SCHEME, 'id'>
  children?: React.ReactNode
}

const ModuleLink = ({ id, bikeScheme, children }: ModuleLinkProps) => (
  <AssetLink basePath={routes.MODULES} id={id} schemeId={bikeScheme?.id}>
    {children || id}
  </AssetLink>
)

export default ModuleLink
