import { useState } from 'react'
import { gql, ApolloQueryResult } from '@apollo/client'
import { Card, Row, Col } from 'antd'
import { POLL_INTERVAL } from 'client'
import { PermissionOnly } from 'components/Session'
import History from './History'
import Summary from './Summary'
import Invoice from 'components/Invoice'
import Map from './Map'
import UserSummary from 'components/User/Summary'
import ModuleSummary from 'components/Module/Summary'
import Query from 'components/Query'

import {
  JOURNEY_INDEX,
  JOURNEY_INDEXVariables,
} from './__generated__/JOURNEY_INDEX'
import HeadTitle from 'components/HeadTitle'

export type JourneyIndexResult = {
  pollInterval?: number
} & ApolloQueryResult<JOURNEY_INDEX>

export const Journey = ({
  data: { journey },
  pollInterval,
}: JourneyIndexResult) => {
  if (!journey) {
    return null
  }
  const moduleId = journey.module?.id
  const userId = journey.user?.id
  const invoiceId = journey.invoice?.id

  return (
    <>
      <HeadTitle pageTitle={`Journey ${journey.id}`} />
      <Row gutter={[16, 16]} className="fill-height">
        <Col md={12} xs={24} className="flex-col">
          <Summary id={journey.id} pollInterval={pollInterval} />
          <Card id="journey-history-card" title="History">
            <History id={journey.id} pollInterval={pollInterval} />
          </Card>
        </Col>
        <Col lg={12} xs={24}>
          <Card id="journey-map-card" bodyStyle={{ padding: 0 }} title="Map">
            <div className="minimap-wrapper">
              <Map id={journey.id} />
            </div>
          </Card>
        </Col>
        <Col lg={12} xs={24}>
          {moduleId && <ModuleSummary id={moduleId} />}
        </Col>
        <PermissionOnly permission="user.view">
          {userId && (
            <Col lg={12} xs={24}>
              <Card id="journey-user-card">
                <UserSummary id={userId} />
              </Card>
            </Col>
          )}
        </PermissionOnly>
        {invoiceId && (
          <Col sm={24} xs={24}>
            <Invoice id={invoiceId} />
          </Col>
        )}
      </Row>
    </>
  )
}

const JOURNEY_INDEX_QUERY = gql`
  query JOURNEY_INDEX($id: ID!) {
    journey(journey_id: $id) {
      id
      is_complete
      module {
        id
      }
      user {
        id
      }
      invoice {
        id
      }
    }
  }
`

type Props = JOURNEY_INDEXVariables

export default ({ id }: Props) => {
  const [pollInterval, setPollInterval] = useState<number>(POLL_INTERVAL)

  return (
    <Query
      waitFor={'data.journey'}
      query={JOURNEY_INDEX_QUERY}
      variables={{ id }}
      pollInterval={pollInterval}
    >
      {(props: JourneyIndexResult) => {
        const isComplete = props?.data?.journey?.is_complete ?? false

        if (isComplete && pollInterval !== 0) {
          setPollInterval(0)
        }

        return <Journey {...props} pollInterval={pollInterval} />
      }}
    </Query>
  )
}
