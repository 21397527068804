import React, { Component } from 'react'
import Copy from '../Copy'
import { Heading, Box } from '@weareberyl/design-system'
import { auth } from 'utils/firebase'

type State = {
  token: string | null
}
type Props = Record<string, never>

class Token extends Component<Props, State> {
  state = {
    token: null,
  }

  componentDidMount = async () => {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken(true)
      this.setState({ token })
    }
  }

  render() {
    return (
      <>
        <Box mb={3}>
          <Heading variant="h1">Authentication Token</Heading>
        </Box>
        <Copy id="token" text={this.state.token ?? ''} />
      </>
    )
  }
}

export default Token
