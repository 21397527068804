import React, { useEffect } from 'react'
import { isEqual } from 'lodash'
import { Card, Flex, colors, Box, Button } from '@weareberyl/design-system'
import AutomationModal from '../Modal/AutomationModal'
import AutomationIconRow from './AutomationIconRow'
import AutomationJobFrames from './AutomationJobFrames'
import { useJobTypes } from 'hooks'
import { JobAutomationSideEffect } from '__generated__/globalTypes'
import { DASHBOARD_JUSTIFICATION_PREFIX } from '..'
import { limitChars } from 'utils'
import {
  JobAutomationsQuery_automations_Automation_jobs_AutomationJob,
  TriggerJobAutomationMutationVariables,
} from 'gql/generated/graphql'

interface Props {
  justification_id: string
  description: string
  taskId: string
  side_effects: JobAutomationSideEffect[]
  jobs: JobAutomationsQuery_automations_Automation_jobs_AutomationJob[]
  shouldPoll: boolean
  setShouldPoll: React.Dispatch<React.SetStateAction<boolean>>
  runFlow: (
    variables: TriggerJobAutomationMutationVariables,
    text: string,
  ) => void
  expectedJobs: string[]
  setExpectedJobs: (justification_id: string, expectedJobs: string[]) => void
}

const Automation = ({
  justification_id,
  description,
  taskId,
  side_effects,
  jobs,
  shouldPoll,
  setShouldPoll,
  runFlow,
  expectedJobs,
  setExpectedJobs,
}: Props) => {
  const { jobCodeLookup } = useJobTypes()

  useEffect(() => {
    // If the expected jobs have been raised, stop polling
    if (
      expectedJobs?.length > 0 &&
      isEqual(
        expectedJobs?.sort(),
        jobs?.map(({ frame_id }) => frame_id).sort(),
      )
    ) {
      setExpectedJobs(justification_id, [])
      setShouldPoll(false)
    }
  }, [expectedJobs, jobs, justification_id, setExpectedJobs, setShouldPoll])

  const upserting = shouldPoll && expectedJobs?.length > 0
  const deleting = shouldPoll && expectedJobs?.length === 0
  const editable = justification_id.startsWith(DASHBOARD_JUSTIFICATION_PREFIX)

  const onDelete = () =>
    // we run the flow on delete, but with no assets - this removes all the
    // justifications with a given justification_id
    runFlow(
      {
        justification_id,
        description,
        taskId,
        side_effects,
        assets: [],
        dry_run: false,
      },
      'Please confirm that you want to delete this automation.',
    )

  return (
    <Card m={1}>
      <Flex
        minHeight={150}
        width="100%"
        alignItems="top"
        justifyContent="space-evenly"
      >
        <Box m={2} height="90%" minHeight={135} minWidth={200} flex={1}>
          <Flex alignItems="start" flexDirection="column">
            <AutomationIconRow
              text={limitChars(description)}
              icon="robot"
              iconColor={colors.pickle}
              badgeColor={colors.mint}
              textColor={colors.pickle}
            />
            <AutomationIconRow
              text={limitChars(justification_id)}
              icon="idcard"
              iconColor={colors.lake}
              textColor={colors.lake}
            />
            <AutomationIconRow
              text={jobCodeLookup?.[taskId]}
              icon="tool"
              iconColor={colors.swift}
              textColor={colors.swift}
            />
            <AutomationIconRow
              mb={2}
              text={side_effects.length > 0 ? side_effects.join(', ') : 'none'}
              icon="thunderbolt"
              iconColor={colors.tumeric}
              textColor={colors.tumeric}
            />
            {editable && (
              <Flex alignItems="center" justifyContent="start" width="100%">
                <Box flex={1} ml={2} mr={1}>
                  <AutomationModal
                    automation={{
                      justification_id,
                      description,
                      side_effects,
                      taskId,
                      jobs,
                    }}
                    disabled={shouldPoll}
                    loading={upserting}
                    runFlow={runFlow}
                    variant="outline"
                  />
                </Box>
                <Box flex={1} ml={1} mr={2}>
                  <Button
                    title="Delete"
                    variant="outline"
                    disabled={shouldPoll}
                    onPress={onDelete}
                    loading={deleting}
                  />
                </Box>
              </Flex>
            )}
          </Flex>
        </Box>
        <Box m={2} height="90%" minHeight={108} flex={4}>
          <Flex alignItems="start" flexDirect="column">
            <AutomationJobFrames jobs={jobs} />
          </Flex>
        </Box>
      </Flex>
    </Card>
  )
}

export default React.memo(Automation)
