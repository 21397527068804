import { Tag, Checkbox, Input, Select } from 'antd'
import { InputProps } from 'antd/lib/input'

interface EditableCellInputProps extends InputProps {
  isEditing?: boolean
}

export const EditableCellInput = ({
  value,
  onChange,
  isEditing,
  ...rest
}: EditableCellInputProps) => {
  if (isEditing) {
    return <Input value={value} onChange={onChange} {...rest} />
  }

  return <span>{value}</span>
}

export const EditableCellSelect = ({
  value,
  onChange,
  isEditing,
  options = [],
  ...rest
}) => {
  if (isEditing) {
    return (
      <Select defaultValue={value} onChange={onChange} {...rest}>
        {options.map(v => (
          <Select.Option key={v} value={v}>
            {v}
          </Select.Option>
        ))}
      </Select>
    )
  }

  return (
    <span>{value.map ? value.map(v => <Tag key={v}>{v}</Tag>) : value}</span>
  )
}

export const EditableCellCheckBox = ({
  value,
  onChange,
  isEditing,
  ...rest
}) => {
  return (
    <Checkbox
      style={{ paddingLeft: 8 }}
      disabled={!isEditing}
      defaultChecked={value}
      onChange={onChange}
      {...rest}
    />
  )
}
