import { QueryResult } from '@apollo/client'
import { message } from 'antd'

const useWrappedQuery = <T, K>(
  { data, error, loading, refetch }: QueryResult<T>,
  getOutput: (data?: T) => K | undefined,
) => {
  if (error) {
    message.error(error.message)
  }

  if (loading || !getOutput(data)) {
    return { refetch, error, loading }
  }

  return { data: getOutput(data), refetch, error, loading }
}

export default useWrappedQuery
