import { useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  CloseCircleOutlined,
  EditOutlined,
  IdcardFilled,
} from '@ant-design/icons'
import { message, Select, Button } from 'antd'
import { SelectValue } from 'antd/lib/select'
import {
  colors,
  Flex,
  Box,
  Text,
  TextLink,
  BoxProps,
} from '@weareberyl/design-system'
import { ASSIGNEE_QUERY } from '../AssigneeSelect'
import { isSuperAdmin, hasRoleForScheme } from 'utils/firebase/authentication'
import useUnassignJobs from 'hooks/useUnassignJobs'
import useAssignJobs from 'hooks/useAssignJobs'
import { CURRENT_USER } from 'types'
import {
  JobListQuery_table_JobConnection_nodes_Job,
  JobQuery_job_Job,
} from 'gql/generated/graphql'
import { useCurrentScheme, useCurrentUser } from 'hooks'

type Job = JobListQuery_table_JobConnection_nodes_Job | JobQuery_job_Job

const jobsHaveAssignableStatus = (jobs: Job[]) =>
  jobs.every(job =>
    ['new', 'inProgress'].includes(job?.details?.status as string),
  )

export const hasPermissionsToAssign = (
  user?: CURRENT_USER,
  scheme_id?: number,
) => {
  if (!user || !scheme_id) return false
  const roles = user.roles
  return (
    isSuperAdmin(roles) ||
    hasRoleForScheme(roles, 'admin', scheme_id) ||
    hasRoleForScheme(roles, 'operations_lead', scheme_id)
  )
}

export const isDisabled = (
  user: CURRENT_USER,
  scheme_id: number,
  jobs: Job[],
) => {
  return !(
    hasPermissionsToAssign(user, scheme_id) && jobsHaveAssignableStatus(jobs)
  )
}

type Assignee = {
  value: string
  label: string
}

interface IAssigneeInput {
  assigneeIds: string[]
  jobIds: string[]
  allAssignees: Assignee[]
  setEditOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const AssigneeInput = ({
  assigneeIds,
  jobIds,
  allAssignees,
  setEditOpen,
}: IAssigneeInput) => {
  const [assigneeValue, setAssigneeValue] = useState<SelectValue | null>(null)
  const [assignJobs] = useAssignJobs()

  const jobsToAssign =
    assigneeIds.some(assignee => assignee !== assigneeValue) ||
    jobIds.length !== assigneeIds.length
  const canAssign = assigneeValue && jobsToAssign

  const handleUpdate = async () => {
    if (canAssign) {
      try {
        await assignJobs({
          variables: {
            uuids: jobIds,
            assignee: assigneeValue,
          },
        })
      } catch (err) {
        message.error(`Could not assign job: ${err.message}`)
      }
    }
    setEditOpen(false)
  }

  return (
    <Flex alignItems="center" justifyContent="center">
      <Select
        options={allAssignees}
        showSearch
        style={{ width: '300px' }}
        autoFocus
        optionFilterProp="label"
        onSelect={setAssigneeValue}
      />
      <Button
        disabled={!canAssign}
        onClick={handleUpdate}
        style={{ marginLeft: 5 }}
      >
        Assign
      </Button>
      <Button
        onClick={() => setEditOpen(false)}
        danger
        style={{ marginLeft: 5 }}
      >
        Close
      </Button>
    </Flex>
  )
}

interface IAssigner extends BoxProps {
  jobs: Job[]
  allAssignees: Assignee[]
  disabled: boolean
}

const Assigner = ({ jobs, allAssignees, disabled, ...props }: IAssigner) => {
  const jobIds = jobs.map(job => job.uuid)
  const [editOpen, setEditOpen] = useState(false)
  const [unassignMutation] = useUnassignJobs({
    uuids: jobIds,
  })

  const emailCounts = Object.entries(
    jobs.reduce((result: Record<string, number>, job: Job) => {
      const email = job?.details?.assignee?.email ?? 'unassigned'
      result[email] = (result[email] ?? 0) + 1
      return result
    }, {}),
  )
  const assigneeString = emailCounts
    .map(([email, count]) => {
      const includeCount = count > 1 || emailCounts.length > 1
      return email + (includeCount ? ` [${count}]` : '')
    })
    .join('\n')

  const uniqueAssigneeIds = Array.from(
    new Set(jobs.map(job => job.details?.assignee?.id)),
  ).filter((id): id is string => Boolean(id))
  const assignersExist = uniqueAssigneeIds.length !== 0
  const allJobsHaveAssignableStatus = jobsHaveAssignableStatus(jobs)

  return (
    <Box justifyContent="start" {...props}>
      <Flex alignItems="center" minHeight="45px">
        <Flex alignItems="center" minHeight="30px" mr={2}>
          <IdcardFilled
            style={{
              fontSize: '25px',
              color: colors.afternoon,
            }}
          />
        </Flex>
        {!editOpen && (
          <Flex alignItems="center" minHeight="30px">
            <TextLink
              disabled={disabled}
              onPress={() => {
                setEditOpen(true)
              }}
              variant="tiny"
              style={{ color: disabled ? colors.grape : colors.berylGreen }}
              fontFamily="Hellix-SemiBold"
            >
              {assigneeString}
            </TextLink>
            {!disabled && (
              <>
                <Box mr={2} ml={1}>
                  <EditOutlined
                    style={{ height: '12px', color: colors.berylGreen }}
                  />
                </Box>
                {assignersExist && (
                  <Box mr={2}>
                    <CloseCircleOutlined
                      style={{ height: '12px', color: colors.alert }}
                      onClick={() => unassignMutation()}
                    />
                  </Box>
                )}
              </>
            )}
            {!allJobsHaveAssignableStatus && jobs.length > 1 && (
              <Box>
                <Text color={colors.alert} variant="small" pl={3}>
                  *Selection includes complete/blocked jobs which cannot be
                  reassigned
                </Text>
              </Box>
            )}
          </Flex>
        )}
        {editOpen && (
          <AssigneeInput
            assigneeIds={uniqueAssigneeIds}
            jobIds={jobIds}
            allAssignees={allAssignees}
            setEditOpen={setEditOpen}
          />
        )}
      </Flex>
    </Box>
  )
}

interface IJobsAssigner extends BoxProps {
  jobs: Job[]
}

const JobsAssigner = ({ jobs, ...props }: IJobsAssigner) => {
  const [user] = useCurrentUser()
  const { currentSchemeId } = useCurrentScheme()

  const { data, error } = useQuery(ASSIGNEE_QUERY, {
    variables: {
      order_by: [{ email: 'asc' }],
      paginate: { page: 1, per_page: 1000 },
    },
    pollInterval: 0,
  })

  if (error) {
    message.error(`Could not load candidate assignees: ${error.message}`)
  }

  const allAssignees =
    data?.workshop_users?.nodes
      .filter(user => Boolean(user.email))
      .map(workshopUser => ({
        value: workshopUser.id,
        label: workshopUser.email,
      })) ?? []

  if (!jobs || jobs.length === 0) {
    return null
  }

  const disabled =
    !user ||
    !currentSchemeId ||
    isDisabled(user, parseInt(currentSchemeId), jobs)

  return (
    <Assigner
      jobs={jobs}
      allAssignees={allAssignees}
      disabled={disabled}
      {...props}
    />
  )
}

export default JobsAssigner
