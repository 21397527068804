import { IRole, isSuperAdmin, isSuperWorkshop } from './firebase/authentication'
import { isStaffRole } from 'constants/roles'
import { LEGACY_SCHEMES } from 'constants/domain'

const legacySchemefilter = scheme => !LEGACY_SCHEMES.includes(scheme.id)

const filterSelectableSchemes = (
  schemes: { id: string; name: string }[],
  roles: IRole[],
) => {
  const visibleSchemes = schemes.filter(legacySchemefilter)

  if (isSuperWorkshop(roles) || isSuperAdmin(roles)) {
    return visibleSchemes
  }

  const uniqueSchemeRoles = roles
    .filter(role => isStaffRole(role.role))
    .filter(role => role.scheme_id)

  if (uniqueSchemeRoles.length > 0) {
    return visibleSchemes.filter(scheme =>
      uniqueSchemeRoles.some(
        role => String(role.scheme_id) === String(scheme.id),
      ),
    )
  }

  return []
}

export default filterSelectableSchemes
