import React, { ChangeEvent, useState } from 'react'
import {
  borderRadii,
  Flex,
  colors,
  Box,
  Heading,
  Touchable,
} from '@weareberyl/design-system'
import { SearchOutlined } from '@ant-design/icons'
import PTextInput from '@weareberyl/design-system/build//primitives/TextInput'

type AutoCompleteProps = {
  items: Array<{
    name: string
    key: string
  }>
  onSearch: (arg0: any) => void
  onSelect: (arg0: { name: string; key: string }) => void
  placeholder: string
  disabled?: boolean
  zIndex?: number
}

const AutoComplete = ({
  items,
  onSearch,
  onSelect,
  placeholder,
  disabled,
  zIndex,
}: AutoCompleteProps) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [searchInput, setSearchInput] = useState('')

  function toggleDropDown() {
    if (!disabled) setShowDropdown(!showDropdown)
  }

  function handleChange(item: { key: string; name: string }) {
    if (disabled) return
    onSelect(item)
    setSearchInput('')
    toggleDropDown()
  }

  function handleTextChange(e: ChangeEvent<HTMLInputElement>) {
    setSearchInput(e.currentTarget.value)
    onSearch(e.currentTarget.value)
  }

  return (
    <Box zIndex={zIndex} mb={3}>
      <Flex
        bg="oyster"
        borderWidth={2}
        borderColor="oyster"
        borderRadius={borderRadii.default}
        mt={2}
        justifyContent="space-between"
        alignItems="center"
        onClick={toggleDropDown}
        flexWrap="nowrap"
        style={{ cursor: 'pointer' }}
      >
        <SearchOutlined style={{ marginLeft: 20, fontSize: 17 }} />
        <PTextInput
          fontSize={22}
          lineHeight={32}
          fontFamily="Hellix-SemiBold"
          color={colors.deepPurple}
          placeholderTextColor={colors.afternoon}
          selectionColor={colors.jade}
          px={3}
          py={2}
          height={48}
          onChange={handleTextChange}
          placeholder={placeholder}
          value={searchInput}
          style={{ cursor: 'pointer' }}
        />
      </Flex>
      {showDropdown && (
        <Flex
          flexDirection="column"
          bg="oyster"
          position="absolute"
          borderBottomLeftRadius={borderRadii.default}
          borderBottomRightRadius={borderRadii.default}
          maxHeight="200px"
          top="55px"
          width="100%"
          flexWrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
          px={3}
          style={{ cursor: 'pointer', overflowY: 'scroll', zIndex: zIndex }}
        >
          {items.map(item => {
            return (
              <Touchable
                onPress={() => handleChange(item)}
                style={{ width: '100%' }}
                key={item.key}
              >
                <Heading variant="h1" py={1}>
                  {item.name}
                </Heading>
              </Touchable>
            )
          })}
        </Flex>
      )}
    </Box>
  )
}

export default AutoComplete
