import { useMemo } from 'react'
import {
  ApolloError,
  OperationVariables,
  QueryLazyOptions,
  gql,
  useLazyQuery,
} from '@apollo/client'

const QUERY = gql`
  query job_types {
    job_types {
      redistributionDriver {
        ...AssigmentGroup
      }
      onStreetMechanic {
        ...AssigmentGroup
      }
      workshopMechanic {
        ...AssigmentGroup
      }
      securityTeam {
        ...AssigmentGroup
      }
    }
  }
  fragment AssigmentGroup on JobTypesAssignmentGroup {
    label
    children {
      label
      code
      name
      disabled
    }
  }
`

const jobTypesTransformations = jobTypes => {
  if (!jobTypes) {
    return {
      jobOptions: null,
      jobOptionsByRole: null,
      jobCodeLookup: null,
      roleByJobLookup: null,
    }
  }
  const allJobOptions = Object.keys(jobTypes)
    .filter(key => !key.startsWith('__'))
    .map(key => {
      const children = jobTypes[key].children.map(child => ({
        ...child,
        value: child.code,
      }))
      return { ...jobTypes[key], name: key, value: key, children }
    })
  const jobOptions = Object.keys(allJobOptions).map(key => ({
    ...allJobOptions[key],
    children: allJobOptions[key].children.filter(({ disabled }) => !disabled),
  }))
  const jobOptionsByRole = role => {
    let filtered = allJobOptions.filter(
      jobCategory => jobCategory.label === role,
    )
    filtered = filtered.length > 0 ? filtered : allJobOptions
    return filtered.flatMap(jobCategory =>
      jobCategory.children.map(job => ({
        label: job.label,
        value: job.code,
      })),
    )
  }
  const jobCodeLookup = Object.fromEntries(
    allJobOptions.flatMap(group =>
      group.children.map(child => [child.code, child.label]),
    ),
  )
  const roleByJobLookup = Object.fromEntries(
    allJobOptions.flatMap(group =>
      group.children.map(child => [child.code, group.value]),
    ),
  )
  return { jobOptions, jobOptionsByRole, jobCodeLookup, roleByJobLookup }
}

export type JobTypesTransformations = ReturnType<typeof jobTypesTransformations>

type useJobTypesQueryReturn = [
  (options?: QueryLazyOptions<OperationVariables>) => void,
  {
    error: ApolloError
    loading: boolean
  } & JobTypesTransformations,
]

export default () => {
  const [getJobTypes, { error, loading, data }] = useLazyQuery(QUERY, {
    pollInterval: 0,
  })
  const transformations = useMemo(
    () => jobTypesTransformations(data?.job_types),
    [data?.job_types],
  )
  return [
    getJobTypes,
    { error, loading, ...transformations },
  ] as useJobTypesQueryReturn
}
