import { ModuleHardware } from '__generated__/globalTypes'

export const BIKE_ICON = {
  width: 100,
  height: 105,
  anchorY: 105,
}

export const EBIKE_ICON = {
  width: 100,
  height: 116,
  anchorY: 116,
}

// Coords on the sprite sheet
// X is the colour
const JADE = { x: 0 }
const JAFFA = { x: 100 }
const PURPLE = { x: 200 }
const JADE_PROMO = { x: 300 }
const JAFFA_PROMO = { x: 400 }

// Y is the vehicle type
const BIKE = { y: 0 }
const EBIKE = { y: 115 }
const SCOOTER = { y: 240 }

export const MODULE_ICON_MAPPING = {
  [`${ModuleHardware.beryl_bike}-jade`]: {
    ...JADE,
    ...BIKE,
    ...BIKE_ICON,
  },
  [`${ModuleHardware.beryl_bike}-jaffa`]: {
    ...JAFFA,
    ...BIKE,
    ...BIKE_ICON,
  },
  [`${ModuleHardware.beryl_bike}-jade-promotional`]: {
    ...JADE_PROMO,
    ...EBIKE,
    ...EBIKE_ICON,
  },
  [`${ModuleHardware.beryl_bike}-jaffa-promotional`]: {
    ...JAFFA_PROMO,
    ...EBIKE,
    ...EBIKE_ICON,
  },
  [`${ModuleHardware.beryl_bike}-deepPurple`]: {
    ...PURPLE,
    ...BIKE,
    ...BIKE_ICON,
  },
  [`${ModuleHardware.bbe}-jade`]: {
    ...JADE,
    ...EBIKE,
    ...EBIKE_ICON,
  },
  [`${ModuleHardware.bbe}-jaffa`]: {
    ...JAFFA,
    ...EBIKE,
    ...EBIKE_ICON,
  },
  [`${ModuleHardware.bbe}-jade-promotional`]: {
    ...JADE_PROMO,
    ...EBIKE,
    ...EBIKE_ICON,
  },
  [`${ModuleHardware.bbe}-jaffa-promotional`]: {
    ...JAFFA_PROMO,
    ...EBIKE,
    ...EBIKE_ICON,
  },
  [`${ModuleHardware.bbe}-deepPurple`]: {
    ...PURPLE,
    ...EBIKE,
    ...EBIKE_ICON,
  },
  [`${ModuleHardware.scooter}-jade`]: {
    ...JADE,
    ...SCOOTER,
    ...EBIKE_ICON,
  },
  [`${ModuleHardware.scooter}-jaffa`]: {
    ...JAFFA,
    ...SCOOTER,
    ...EBIKE_ICON,
  },
  [`${ModuleHardware.scooter}-jade-promotional`]: {
    ...JADE_PROMO,
    ...SCOOTER,
    ...EBIKE_ICON,
  },
  [`${ModuleHardware.scooter}-jaffa-promotional`]: {
    ...JAFFA_PROMO,
    ...SCOOTER,
    ...EBIKE_ICON,
  },
  [`${ModuleHardware.scooter}-deepPurple`]: {
    ...PURPLE,
    ...SCOOTER,
    ...EBIKE_ICON,
  },
}

export const ZONE_ICON_MAPPING = {
  empty: {
    x: 13,
    y: 15,
    width: 162,
    height: 109,
  },
  emptysuspended: {
    x: 13,
    y: 515,
    width: 163,
    height: 109,
  },
  [`${ModuleHardware.beryl_bike}`]: {
    x: 13,
    y: 140,
    width: 162,
    height: 109,
  },
  [`${ModuleHardware.beryl_bike}suspended`]: {
    x: 13,
    y: 640,
    width: 163,
    height: 109,
  },
  [`${ModuleHardware.bbe}`]: {
    x: 13,
    y: 265,
    width: 162,
    height: 109,
  },
  [`${ModuleHardware.bbe}suspended`]: {
    x: 13,
    y: 765,
    width: 162,
    height: 109,
  },
  [`${ModuleHardware.scooter}`]: {
    x: 13,
    y: 390,
    width: 162,
    height: 109,
  },
  [`${ModuleHardware.scooter}suspended`]: {
    x: 13,
    y: 890,
    width: 162,
    height: 109,
  },
  [`${ModuleHardware.beryl_bike}${ModuleHardware.bbe}`]: {
    x: 196,
    y: 15,
    width: 218,
    height: 109,
  },
  [`${ModuleHardware.beryl_bike}${ModuleHardware.bbe}suspended`]: {
    x: 196,
    y: 515,
    width: 218,
    height: 109,
  },
  [`${ModuleHardware.bbe}${ModuleHardware.scooter}`]: {
    x: 196,
    y: 140,
    width: 218,
    height: 109,
  },
  [`${ModuleHardware.bbe}${ModuleHardware.scooter}suspended`]: {
    x: 196,
    y: 640,
    width: 218,
    height: 109,
  },
  [`${ModuleHardware.beryl_bike}${ModuleHardware.scooter}`]: {
    x: 196,
    y: 265,
    width: 218,
    height: 109,
  },
  [`${ModuleHardware.beryl_bike}${ModuleHardware.scooter}suspended`]: {
    x: 196,
    y: 640,
    width: 218,
    height: 109,
  },
  [`${ModuleHardware.beryl_bike}${ModuleHardware.bbe}${ModuleHardware.scooter}`]:
    {
      x: 434,
      y: 15,
      width: 350,
      height: 109,
    },
  [`${ModuleHardware.beryl_bike}${ModuleHardware.bbe}${ModuleHardware.scooter}suspended`]:
    {
      x: 434,
      y: 515,
      width: 350,
      height: 109,
    },
}

export const LAKE_PIN_COLOUR = [26, 152, 255]
export const JAFFA_PIN_COLOUR = [255, 185, 0]
export const BRICK_PIN_COLOUR = [251, 64, 111]
export const BERYL_GREEN_PIN_COLOUR = [0, 227, 194]
