import { gql, ApolloQueryResult } from '@apollo/client'
import Query from 'components/Query'
import Timeline from '../Timeline'

import {
  JOURNEY_HISTORY,
  JOURNEY_HISTORYVariables,
} from './__generated__/JOURNEY_HISTORY'

const QUERY = gql`
  query JOURNEY_HISTORY($id: ID!) {
    node: journey(journey_id: $id) {
      id
      state_history {
        nodes {
          id
          time
          message
        }
      }
    }
  }
`

type Props = {
  pollInterval?: number
} & JOURNEY_HISTORYVariables

export default ({ id, pollInterval }: Props) => (
  <Query
    waitFor={'data.node.state_history'}
    query={QUERY}
    variables={{ id }}
    pollInterval={pollInterval}
  >
    {History}
  </Query>
)

const History = ({ data }: ApolloQueryResult<JOURNEY_HISTORY>) => {
  return <Timeline items={data.node?.state_history.nodes ?? []} />
}
