import { Card, Row, Col } from 'antd'
import Map from './Map'
import Summary from './Summary'
import ModulesInZone from './ModulesInZone'
import { zoneIdToAssetTag } from 'components/Job/utils'
import HeadTitle from 'components/HeadTitle'
import AssetJobs from 'components/Job/List/AssetJobs'

type ZoneProps = {
  id: string
  schemeId: string
}

const Zone = ({ id, schemeId }: ZoneProps) => (
  <>
    <HeadTitle pageTitle={`Bay ${id}`} />
    <Row gutter={16} className="fill-height">
      <Col xs={24} lg={12}>
        <Summary id={id} />
      </Col>
      <Col xs={24} lg={12}>
        <Card id="zone-map-card" bodyStyle={{ padding: 0 }}>
          <div className="minimap-wrapper">
            <Map id={id} />
          </div>
        </Card>
      </Col>
    </Row>
    <Row gutter={16} className="fill-height">
      <Col xs={24}>
        <Card id="modules-in-zone-card" title="Vehicles in bay">
          <ModulesInZone id={id} />
        </Card>
      </Col>
    </Row>
    <AssetJobs assetTag={zoneIdToAssetTag(id)} scheme={{ id: schemeId }} />
  </>
)

export default Zone
