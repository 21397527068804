import { gql, ApolloQueryResult } from '@apollo/client'
import Query from 'components/Query'
import Edit from './Edit'

import {
  MODULE_UNLOCK_IDVariables,
  MODULE_UNLOCK_ID,
} from './__generated__/MODULE_UNLOCK_ID'

const QUERY = gql`
  query MODULE_UNLOCK_ID($module_id: ID!) {
    module(module_id: $module_id) {
      id
      unlock_id
    }
  }
`

export default ({ module_id }: MODULE_UNLOCK_IDVariables) => (
  <Query
    query={QUERY}
    variables={{ module_id }}
    waitFor="data.module"
    pollInterval={0}
  >
    {({ data }: ApolloQueryResult<MODULE_UNLOCK_ID>) => (
      <Edit module_id={module_id} unlock_id={data?.module?.unlock_id} />
    )}
  </Query>
)
