import { DocumentNode, useApolloClient } from '@apollo/client'

export const useScheduleRefresh = (queries: DocumentNode[], timeout = 2000) => {
  const client = useApolloClient()

  const scheduleRefresh = () => {
    const timeoutId = setTimeout(() => {
      client.refetchQueries({ include: queries })
    }, timeout)

    return () => clearTimeout(timeoutId)
  }

  return scheduleRefresh
}
