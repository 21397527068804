import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'

export default ({ onSubmit }) => {
  return (
    <Input
      id="zones-search-input"
      placeholder="Search for bays by name"
      onPressEnter={e => onSubmit(e.currentTarget.value.trim())}
      suffix={<SearchOutlined />}
    />
  )
}
