import React from 'react'
import { Flex, Box, Heading, Text, Icon } from '@weareberyl/design-system'

type SuspendedStatusProps = {
  isSuspended: boolean
  asHeader?: boolean
}

const SuspendedStatus = ({ isSuspended, asHeader }: SuspendedStatusProps) => {
  const suspendedText = isSuspended ? 'Suspended' : 'Enabled'

  return (
    <Flex flexWrap="nowrap">
      <Box
        borderRadius="100%"
        alignItems="center"
        bg={isSuspended ? 'prawn' : 'mint'}
        mt={asHeader ? 1 : '3px'}
        p={asHeader ? 1 : '2px'}
        height={asHeader ? 24 : 16}
        width={asHeader ? 24 : 16}
      >
        <Icon
          color={isSuspended ? 'brick' : 'pickle'}
          mt="2px"
          type={isSuspended ? 'cross' : 'tick'}
          width={asHeader ? 12 : 8}
          height={asHeader ? 12 : 8}
        />
      </Box>
      {asHeader ? (
        <Heading variant="h2" mb={2} ml={2} style={{ opacity: 0.75 }}>
          {suspendedText}
        </Heading>
      ) : (
        <Text variant="small" color="grape" ml={2}>
          {suspendedText}
        </Text>
      )}
    </Flex>
  )
}

export default SuspendedStatus
