import React from 'react'
import { Route, Switch } from 'react-router'
import SignInPage from 'components/SignIn'
import MagicLink from 'components/SignIn/MagicLink'
import * as routes from 'constants/routes'

const SignedOut = () => {
  return (
    <Switch>
      <Route exact path={routes.MAGIC_LINK} component={MagicLink} />
      <Route component={SignInPage} />
    </Switch>
  )
}

export default SignedOut
