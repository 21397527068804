import { useEffect } from 'react'
import { startCase } from 'lodash'
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  FormListFieldData,
  InputNumber,
  DatePicker,
  Switch,
  SelectProps,
  InputProps,
} from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Heading, Box } from '@weareberyl/design-system'
import type { Moment } from 'moment'

import { SchemeSelect } from 'components/Scheme/SchemeSelect'
import {
  MetadataSchemaProperties,
  MetadataSchemaPropertyType,
} from 'gql/generated/graphql'
import { ZoneSelect, HardwareTypeSelect } from './select-fields'
import { useMetadataSchemaOptions } from '../data'
import { isNotNullOrUndefined } from 'utils/types'

interface RuleValueFieldLookupProps
  extends Pick<SelectProps, 'id' | 'value' | 'mode'> {
  type: MetadataSchemaPropertyType
  property: string
}

const RuleValueOptionsField = ({
  property,
  type,
  mode,
  ...restProps
}: RuleValueFieldLookupProps) => {
  // See if this property has a lookup select
  switch (property) {
    case 'end_zone_id':
    case 'end_zone_ids':
    case 'start_zone_id':
    case 'start_zone_ids':
      return <ZoneSelect mode={mode} {...restProps} />
    case 'hardware_type':
    case 'hardware_types':
      return <HardwareTypeSelect mode={mode} {...restProps} />
    case 'scheme_id':
      return <SchemeSelect mode={mode} {...restProps} />
    default:
      // No lookup implemented, get field for type
      return <RuleValueField type={type} {...restProps} />
  }
}

const RuleValueField = ({
  type,
  ...inputProps
}: Pick<InputProps, 'id'> & { type: string }) => {
  // Pass input props down to whichever input, to make sure it works within <Input.Item />
  switch (type) {
    case MetadataSchemaPropertyType.number:
      return <InputNumber {...inputProps} />
    case MetadataSchemaPropertyType.string:
      return <Input {...inputProps} />
    case MetadataSchemaPropertyType.date:
      return <DatePicker {...inputProps} />
    case MetadataSchemaPropertyType.datetime:
      return <DatePicker showTime {...inputProps} />
    case MetadataSchemaPropertyType.boolean:
      return <Switch {...inputProps} />
    default:
      return <Input {...inputProps} />
  }
}

type ValidationCriterionField =
  | { property: string; operator: 'starts_with'; value: string }
  | { property: string; operator: 'ends_with'; value: string }
  | { property: string; operator: 'is'; value: string | Moment }
  | { property: string; operator: 'in'; value: string[] | number[] }
  | { property: string; operator: 'contains'; value: string }
  | {
      property: string
      operator: 'more_than'
      value: number | string | Moment
    }
  | {
      property: string
      operator: 'less_than'
      value: number | string | Moment
    }

export type FormValues = {
  name: string
  rules_list_customer: ValidationCriterionField[]
  rules_list_redemption: ValidationCriterionField[]
  logic: string
}

type Props = {
  form: FormInstance
  // TODO: hyrdate with existing data for edit usecase
  values?: FormValues
}

const operatorOptions = [
  {
    value: 'is',
    label: 'is',
    array: false,
    types: [
      MetadataSchemaPropertyType.string,
      MetadataSchemaPropertyType.number,
    ],
  },
  {
    value: 'in',
    label: 'in',
    array: true,
    types: [
      MetadataSchemaPropertyType.string,
      MetadataSchemaPropertyType.number,
    ],
  },
  {
    value: 'starts_with',
    label: 'starts with',
    array: false,
    types: [MetadataSchemaPropertyType.string],
  },
  {
    value: 'ends_with',
    label: 'ends with',
    array: false,
    types: [MetadataSchemaPropertyType.string],
  },
  {
    value: 'more_than',
    label: 'more than',
    array: false,
    types: [
      MetadataSchemaPropertyType.number,
      MetadataSchemaPropertyType.date,
      MetadataSchemaPropertyType.datetime,
    ],
  },
  {
    value: 'less_than',
    label: 'less than',
    array: false,
    types: [
      MetadataSchemaPropertyType.number,
      MetadataSchemaPropertyType.date,
      MetadataSchemaPropertyType.datetime,
    ],
  },
  {
    value: 'contains',
    label: 'contains',
    array: false,
    types: [MetadataSchemaPropertyType.string],
  },
]

const ConditionInputs = ({
  fieldData,
  remove,
  rulesListField,
  index,
  properties,
}: {
  fieldData: FormListFieldData
  remove: (index: number | number[]) => void
  rulesListField: ValidationCriterionField[]
  index: number
  properties: MetadataSchemaProperties[]
}) => {
  const { key, name, ...restField } = fieldData

  const thisField = rulesListField[index]
  const selectedProperty = properties.find(p => p.name == thisField?.property)

  // What properties are already assigned in previous rows?
  const previousProperties = rulesListField
    .map(r => r?.property)
    .filter(isNotNullOrUndefined)
    .reverse()
    .slice(rulesListField.length - index)

  // Prepare property options for the dropdown
  const propertyOptions = properties
    .map(p => ({
      value: p.name,
      label: startCase(p.name.replace(/_/, ' ')),
    }))
    // Filter out property options that are in a previous row
    .map(option =>
      previousProperties.includes(option.value)
        ? { ...option, disabled: true }
        : option,
    )

  // Filter operator options by the type described for the field on metadata schema
  const filteredOperatorOptions = operatorOptions.filter(option => {
    if (option.array != selectedProperty?.array) {
      return false
    }

    if (!option.types?.includes(selectedProperty.type)) {
      return false
    }
    return true
  })

  return (
    <Row key={key} gutter={[10, 10]}>
      <Col span={6}>
        <Form.Item
          {...restField}
          name={[name, 'property']}
          rules={[{ required: true }]}
        >
          <Select options={propertyOptions} />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item {...restField} name={[name, 'operator']} initialValue="is">
          <Select
            options={filteredOperatorOptions}
            disabled={!selectedProperty}
          />
        </Form.Item>
      </Col>

      <Col span={13}>
        <Form.Item
          {...restField}
          name={[name, 'value']}
          rules={[{ required: true, message: 'Missing value' }]}
        >
          {selectedProperty ? (
            <RuleValueOptionsField
              property={thisField.property}
              type={selectedProperty?.type}
              mode={thisField.operator === 'in' ? 'multiple' : undefined}
            />
          ) : (
            <Input disabled />
          )}
        </Form.Item>
      </Col>
      <Col span={1}>
        <MinusCircleOutlined onClick={() => remove(name)} />
      </Col>
    </Row>
  )
}

const ValidationRulesForm = ({ form }: Props) => {
  const redemptionRulesListField: ValidationCriterionField[] =
    Form.useWatch('rules_list_redemption', form) ?? []
  const customerRulesListField: ValidationCriterionField[] =
    Form.useWatch('rules_list_customer', form) ?? []

  // Get metadata schema as defined in voucherify, this tells us what field names and types
  // can be used to compose validation rules
  const { metadataSchemas } = useMetadataSchemaOptions()

  const defaultLogic = [...redemptionRulesListField, ...customerRulesListField]
    ?.map((_item, index) => index + 1)
    .join(' AND ')

  useEffect(() => {
    // Set logic to a chain of ANDs, it can be overriden if needed
    if (!form.isFieldTouched('logic')) {
      form.setFieldValue('logic', defaultLogic)
    }
  }, [defaultLogic, form])

  return (
    <Form form={form} layout="vertical" name="validationRulesForm">
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input placeholder="e.g. Is west midlands" />
      </Form.Item>
      <Heading pb={3}>Redemption metadata</Heading>
      <Box mt={2}>
        <Form.List name="rules_list_redemption">
          {(fields, { add, remove }) => (
            <>
              {fields.map((fieldData, index) => {
                const { key } = fieldData
                return (
                  <ConditionInputs
                    fieldData={fieldData}
                    remove={remove}
                    key={key}
                    index={index}
                    rulesListField={redemptionRulesListField}
                    properties={metadataSchemas.redemption[0].properties}
                  />
                )
              })}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add a rule
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Box>

      <Heading>Customer metadata</Heading>
      <Box mt={2}>
        <Form.List name="rules_list_customer">
          {(fields, { add, remove }) => (
            <>
              {fields.map((fieldData, index) => {
                const { key } = fieldData
                return (
                  <ConditionInputs
                    fieldData={fieldData}
                    remove={remove}
                    key={key}
                    index={index}
                    rulesListField={customerRulesListField}
                    properties={metadataSchemas.customer[0].properties}
                  />
                )
              })}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add a rule
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Box>

      <Form.Item
        name="logic"
        label="Logic"
        tooltip="AND | OR logic to combine the critera of this rule. e.g. (1 AND 2) OR 3"
        initialValue={defaultLogic}
        rules={[
          {
            required: true,
          },
          {
            pattern: /^(\(?(\d+|AND|OR| )?\)?)+$/,
            message: 'Malformed logic combination',
          },
        ]}
      >
        <Input placeholder={`e.g. ${defaultLogic ?? '1'}`} />
      </Form.Item>
    </Form>
  )
}

export default ValidationRulesForm
