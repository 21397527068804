import { useEffect, useState } from 'react'

import { Heading, Flex, Box, colors } from '@weareberyl/design-system'

import JobsAssigner, {
  hasPermissionsToAssign,
} from '../JobsAssigner/JobsAssigner'
import columns from 'components/Job/List/columns'
import Table from 'components/Table'
import { currentLocation } from 'utils'
import { useQueryParams } from 'utils/useQueryParams'
import { parseJobFilters } from './JobFilters'
import { CURRENT_USER, Scheme } from 'types'
import { VehicleType } from '__generated__/globalTypes'
import {
  JobListQuery,
  JobListQueryVariables,
  JobListQuery_table_JobConnection,
} from 'gql/generated/graphql'
import { RefetchFunction } from '@apollo/client/react/hooks/useSuspenseQuery'

interface JobTableProps {
  table?: JobListQuery_table_JobConnection
  scheme?: Scheme
  user?: CURRENT_USER
  refetch?: RefetchFunction<JobListQuery, JobListQueryVariables>
}

const JobsTable = ({ table, scheme, user, refetch }: JobTableProps) => {
  // VehicleType filter
  const [{ vehicleType }, setQueryParams] = useQueryParams('jobs-table')

  // Logic for re-assigning selected cols
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const onSelectedRowKeysChange = (keys: string[]) => {
    setSelectedRowKeys(keys)
  }

  const rowSelectionProp =
    scheme?.id && hasPermissionsToAssign(user, parseInt(scheme.id))
      ? {
          selectedRowKeys,
          onChange: onSelectedRowKeysChange,
        }
      : undefined
  const selectedJobs =
    table?.nodes.filter(job => selectedRowKeys.some(key => key === job.uuid)) ??
    []

  // Location for location column directions link
  const [clientLocation, setClientLocation] = useState({
    longitude: 33.631839,
    latitude: 27.380583,
  })

  useEffect(() => {
    currentLocation().then(setClientLocation, setClientLocation)
  }, [])

  return (
    <>
      {selectedRowKeys.length > 0 && (
        <Box mt={3} ml={2}>
          <Flex flexDirection="row" alignItems="center">
            <Heading variant="h4" color={colors.alert} mr={3}>
              {`Reassigning ${selectedRowKeys.length} job${
                selectedRowKeys.length === 1 ? '' : 's'
              }:`}
            </Heading>
            <Box mt={1}>
              <JobsAssigner jobs={selectedJobs} />
            </Box>
          </Flex>
        </Box>
      )}
      {table && (
        <Table
          id="jobs-table"
          columns={columns({
            nodes: table?.nodes,
            scheme,
            clientLocation,
            vehicleType,
            refetch,
          })}
          onChange={({ current, pageSize }, filters) => {
            const jobFiltersParams = parseJobFilters({
              vehicleType: filters['details.vehicleType'] as VehicleType[],
            })
            setQueryParams({
              current,
              pageSize,
              ...jobFiltersParams,
            })
          }}
          rowSelection={rowSelectionProp}
          data={{ table }}
        />
      )}
    </>
  )
}

export default JobsTable
