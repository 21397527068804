import { Tag } from 'antd'
import { format, parseISO } from 'date-fns'
import type { ColumnProps } from 'antd/lib/table'

import * as routes from 'constants/routes'
import Table from 'components/Table'
import AssetLink from 'components/Scheme/AssetLink'
import { CampaignSummaryItem, defaultData, UseListCampaigns } from '../data'
import Copy from 'components/Copy'

type Props = UseListCampaigns

const columns: ColumnProps<CampaignSummaryItem>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (name: string, record: CampaignSummaryItem) => (
      <AssetLink basePath={routes.CAMPAIGN} id={record.id}>
        {name}
      </AssetLink>
    ),
  },
  {
    title: 'ID',
    dataIndex: 'id',
    render: value => {
      return <Copy id="copy" text={value} />
    },
  },
  {
    title: 'Type',
    render: (record: CampaignSummaryItem) => {
      return (
        <>
          <Tag>{record.type}</Tag>
          <Tag>{record.campaign_type}</Tag>
        </>
      )
    },
  },
  {
    title: 'Active',
    dataIndex: 'active',
    render: (active: boolean) => {
      return active ? 'Active' : 'Inactive'
    },
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    render: (date: CampaignSummaryItem['created_at']) => {
      return format(parseISO(date), 'dd/MM/y hh:MM:ss')
    },
  },
  {
    title: 'Action',
    key: 'x',
    render: (record: CampaignSummaryItem) => (
      <AssetLink basePath={routes.CAMPAIGN} id={record.id}>
        View
      </AssetLink>
    ),
  },
]

const CampaignsTable = ({ data, loading }: Props) => {
  // Provide a skeleton response structure
  // so the table will render with the loading state before data is loaded
  const tableData = data ?? defaultData

  return (
    <Table<CampaignSummaryItem>
      id="promotions"
      columns={columns}
      rowKey={'id'}
      data={tableData}
      size="small"
      loading={loading}
    />
  )
}

export default CampaignsTable
