import { QueryHookOptions } from '@apollo/client'
import {
  JobListQuery,
  JobListQueryVariables,
  useJobListQuery,
} from 'gql/generated/graphql'
import useWrappedQuery from './useWrappedQuery'

const useJobList = (
  baseOptions?: QueryHookOptions<JobListQuery, JobListQueryVariables>,
) => useWrappedQuery(useJobListQuery(baseOptions), data => data?.table)

export default useJobList
