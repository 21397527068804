import { useSchemeJobAssetsLazyQuery } from 'gql/generated/graphql'
import { sortBy, uniqBy } from 'lodash'
import { useEffect, useMemo } from 'react'
import { debouncedMessage } from 'utils/debouncedMessage'

const useSchemeJobAssets = (scheme_id?: string | null) => {
  const [getSchemeJobAssets, { data, error }] = useSchemeJobAssetsLazyQuery({
    pollInterval: 0,
    fetchPolicy: 'cache-first',
  })

  const result = useMemo(() => {
    if (!data) return null
    return uniqBy(sortBy(data.scheme_job_assets, 'assetName'), 'assetTag')
  }, [data])

  useEffect(() => {
    if (scheme_id) {
      getSchemeJobAssets({ variables: { scheme_id } })
    }
  }, [scheme_id, getSchemeJobAssets])

  if (error) {
    debouncedMessage.error(error.message)
  }

  return result
}

export default useSchemeJobAssets
