import React, { Component, ChangeEvent } from 'react'
import { get } from 'lodash'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input, Button, message } from 'antd'
import { UpdateModule } from './actions'

const FormItem = Form.Item

type ModuleEditState = {
  edit: boolean
  valid: boolean
  unlock_id: string | null
  module_id: string | null
}

class ModuleEdit extends Component<any, ModuleEditState> {
  static getDerivedStateFromProps(props, state) {
    if (state.edit) {
      return state
    } else {
      return {
        module_id: get(props, 'module_id', ''),
        unlock_id: get(props, 'unlock_id', ''),
      }
    }
  }

  state = {
    edit: false,
    valid: true,
    unlock_id: '',
    module_id: '',
  }

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target
    const value =
      target.type === 'checkbox' ? target.checked : target.value.trim()
    const name = target.name

    this.setState({ [name]: value } as Pick<
      ModuleEditState,
      keyof ModuleEditState
    >)
    this.setState({ valid: target.value.length > 0 })
  }

  render() {
    const { unlock_id } = this.state

    return (
      <Form className="login-form">
        <FormItem>
          <Input
            disabled={!this.state.edit}
            onChange={this.onChange}
            name="unlock_id"
            placeholder="Unlock Id"
            value={unlock_id}
          />
        </FormItem>
        <FormItem>
          {this.state.edit && (
            <UpdateModule
              onCompleted={() => {
                this.setState({ edit: false })
                message.info('success')
              }}
              {...this.state}
            />
          )}
          {!this.state.edit && (
            <Button
              type="default"
              onClick={() =>
                this.setState(_state => ({
                  edit: true,
                }))
              }
            >
              Edit
            </Button>
          )}
        </FormItem>
      </Form>
    )
  }
}

export default ModuleEdit
