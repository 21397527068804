import { Card, Flex, Heading } from '@weareberyl/design-system'

import HeadTitle from 'components/HeadTitle'
import { useListValidationRules } from './data'
import ValidationRulesTable from './ValidationRulesTable'

const ValidationRuleList = () => {
  const { data, loading } = useListValidationRules()
  return (
    <>
      <HeadTitle pageTitle="Validation rules" />

      <Card p={5}>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Heading variant="callout">Validation rules</Heading>
        </Flex>

        <ValidationRulesTable data={data} loading={loading} />
      </Card>
    </>
  )
}

export default ValidationRuleList
