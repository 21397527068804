import { Select, Spin } from 'antd'

import { useCurrentScheme } from 'hooks'
import { useZoneOptionsQuery, ModuleHardware } from 'gql/generated/graphql'

export const HardwareTypeSelect = (
  props: React.ComponentProps<typeof Select>,
) => {
  const options = Object.keys(ModuleHardware).map(hw => ({
    value: hw,
    label: hw,
  }))
  return <Select {...props} options={options} />
}

export const ZoneSelect = (props: React.ComponentProps<typeof Select>) => {
  const { currentSchemeId } = useCurrentScheme()
  const { data, loading } = useZoneOptionsQuery(
    currentSchemeId
      ? {
          variables: { scheme_id: currentSchemeId },
          pollInterval: 0,
          fetchPolicy: 'cache-first',
        }
      : { skip: true },
  )
  const options = data?.all_zones.nodes.map(zone => ({
    value: zone.id,
    label: `${zone.id} - ${zone.name}`,
  }))
  return (
    <Select
      {...props}
      placeholder="Please select a bay"
      notFoundContent={<Spin size="small" />}
      loading={loading}
      options={options}
    />
  )
}
