import useFeatureFlags from 'hooks/useFeatureFlags'
import { Button, Flex } from '@weareberyl/design-system'
import { Heading, Text, Box } from '@weareberyl/design-system'
import { PermissionOnly } from 'components/Session'

export default function FeatureFlags() {
  const [featureFlags, setFeatureFlags] = useFeatureFlags()

  return (
    <Flex flexDirection="col" alignItems="flex-start" gridGap={'20px'}>
      <Heading variant="h1">Feature Flags 🧪</Heading>
      <Box>
        <Text>
          Enabling vehicle state events will add a historical view of vehicle
          state changes to the vehicle page.
        </Text>
      </Box>
      <Button
        title={
          featureFlags.vehicle_state_events
            ? 'Disable vehicle state events'
            : 'Enable vehicle state events'
        }
        variant={featureFlags.vehicle_state_events ? 'outline' : 'primary'}
        onClick={() => {
          setFeatureFlags({
            ...featureFlags,
            vehicle_state_events: !featureFlags.vehicle_state_events,
          })
        }}
      />
      <PermissionOnly.Forbidden permission="promotion_builder.edit">
        <Box>
          <Text>You can try the Voucherify UI feature as it develops</Text>
        </Box>
        <Button
          title={
            featureFlags.promotions_nav
              ? 'Disable Promotion UI'
              : 'Enable Promotion UI'
          }
          variant={featureFlags.promotions_nav ? 'outline' : 'primary'}
          onClick={() => {
            setFeatureFlags({
              ...featureFlags,
              promotions_nav: !featureFlags.promotions_nav,
            })
          }}
        />
      </PermissionOnly.Forbidden>
    </Flex>
  )
}
