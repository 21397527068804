import React from 'react'
import { CardBrandType, CardType } from '__generated__/globalTypes'
import { USER_PAYMENT_CARD_user_payer_default_card as CARD } from './__generated__/USER_PAYMENT_CARD'

const cardTypeLabel: { [key in CardType]: string } = {
  [CardType.apple_pay]: 'Apple Pay',
  [CardType.google_pay]: 'Google Pay',
  [CardType.card]: 'Credit card',
}

const cardBrandLabel: { [key in CardBrandType]: string } = {
  [CardBrandType.amex]: 'American Express',
  [CardBrandType.diners]: 'Diners Club',
  [CardBrandType.diners_club]: 'Diners Club',
  [CardBrandType.discover]: 'Discover',
  [CardBrandType.jcb]: 'JCB',
  [CardBrandType.mastercard]: 'Mastercard',
  [CardBrandType.visa]: 'Visa',
  [CardBrandType.unionpay]: 'Credit card',
  [CardBrandType.unknown]: 'Credit card',
}

export const CardBrand = ({
  brand,
  type,
}: {
  brand: CardBrandType
  type: CardType
}) => {
  return (
    <div>
      <span>
        {type !== CardType.card && `${cardTypeLabel[type]} `}
        {cardBrandLabel[brand]}
      </span>
    </div>
  )
}

export const CardTextDetails = ({ last4, exp_month, exp_year }: CARD) => {
  return (
    <div>
      ending: <span style={{ marginRight: 24 }}>**** {last4}</span>
      expires:{' '}
      <span>
        {exp_month < 10 ? '0' : ''}
        {exp_month} / {exp_year}
      </span>
    </div>
  )
}
