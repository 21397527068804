import { useState, useEffect, useCallback } from 'react'

// Keys as symbols means consumers can avoid collisions without needing
// awareness of each other
type LoadingMap = Map<symbol, boolean>
export type SetAnyLoading = (key: symbol, value: boolean) => void

function useIsAnyLoading() {
  const [loadingMap, setLoadingMap] = useState<LoadingMap>(new Map())
  const [isAnyLoading, setIsAnyLoading] = useState<boolean>(false)

  const setAnyLoading: SetAnyLoading = useCallback((key, value) => {
    setLoadingMap(prevMap => {
      const currentValue = prevMap.get(key)

      // skip re-render if the value isn't changing
      if (currentValue === value) {
        return prevMap // skipcq: TCV-001
      }

      const newMap = new Map(prevMap)
      newMap.set(key, value)
      return newMap
    })
  }, [])

  // isAnyLoading is true if any _one_ of the loadingMap values are true
  useEffect(() => {
    const anyLoading = Array.from(loadingMap.values()).some(value => value)
    setIsAnyLoading(anyLoading)
  }, [loadingMap])

  return [isAnyLoading, setAnyLoading] as [boolean, SetAnyLoading]
}

export default useIsAnyLoading
