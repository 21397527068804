import { Card, Tag, TagProps } from 'antd'
import { Box, Heading } from '@weareberyl/design-system'

import type { SchemeProductListItem } from './data'
import { ProductImageVariant } from 'gql/generated/graphql'

import commute_1 from 'images/products_commute_1_banner.png'
import explore_1 from 'images/products_explore_1_banner.png'
import explore_2 from 'images/products_explore_2_banner.png'

const periodPassImages: Record<ProductImageVariant, string> = {
  [ProductImageVariant.commute_1]: commute_1,
  [ProductImageVariant.explore_1]: explore_1,
  [ProductImageVariant.explore_2]: explore_2,
}

const tagColours: Record<ProductImageVariant, TagProps['color']> = {
  [ProductImageVariant.commute_1]: 'blue',
  [ProductImageVariant.explore_1]: 'red',
  [ProductImageVariant.explore_2]: 'orange',
}

/**
 * Render a table of configs, transposed into rows so each value can be listed for each hardware_type
 */
const ProductDetails = ({
  schemeProduct: { details },
}: {
  schemeProduct: SchemeProductListItem
}) => {
  if (!details) {
    return null
  }
  return (
    <Card
      size="small"
      title={details.title}
      extra={
        <Tag color={tagColours[details.image_variant]}>{details.cta_text}</Tag>
      }
      cover={
        <img
          alt={details.image_variant}
          src={periodPassImages[details.image_variant]}
        />
      }
    >
      <Card.Meta description={details.benefit_sentence} />
      <Box pt={2}>
        <Heading variant="micro">{details.sub_title}</Heading>

        <ul style={{ paddingTop: '8px' }}>
          {details.bullets?.map(item => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </Box>
    </Card>
  )
}

export default ProductDetails
