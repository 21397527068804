import {
  SchemeAvailabilityRestrictionsDocument,
  useAddSchemeAvailabilityRestrictionMutation,
} from 'gql/generated/graphql'

const useAddCurfew = () => {
  return useAddSchemeAvailabilityRestrictionMutation({
    refetchQueries: [SchemeAvailabilityRestrictionsDocument],
  })
}

export default useAddCurfew
