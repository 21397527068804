import { useMemo } from 'react'
import { orderBy } from 'lodash'

import { useSchemePickerQuery } from 'gql/generated/graphql'
import { useCurrentScheme, useCurrentUser } from 'hooks'
import filterSelectableSchemes from 'utils/filterSelectableSchemes'

import type { SchemePickerQuery_schemes_SchemeConnection_nodes_Scheme } from 'gql/generated/graphql'
export type SchemePickerScheme = Omit<
  SchemePickerQuery_schemes_SchemeConnection_nodes_Scheme,
  '__typename'
>

/**
 * Get all scheme names and IDs, used for scheme selection
 * List all schemes available to the current user
 * Return the name/id of the currently selected scheme
 */
const useSchemes = () => {
  const [user] = useCurrentUser()
  const { currentSchemeId } = useCurrentScheme()
  const { data, loading } = useSchemePickerQuery({
    pollInterval: 0,
    fetchPolicy: 'cache-first',
  })

  const allSchemes = useMemo(
    () => orderBy(data?.schemes?.nodes, s => s.name.toLowerCase()),
    [data?.schemes],
  )

  const selectableSchemes = useMemo(
    () => filterSelectableSchemes(allSchemes, user?.roles ?? []),
    [allSchemes, user],
  )

  const currentScheme = useMemo(
    () => allSchemes.find(item => item?.id === currentSchemeId),
    [allSchemes, currentSchemeId],
  )

  return {
    allSchemes,
    selectableSchemes,
    currentScheme,
    loading,
  }
}

export default useSchemes
