import { get } from 'lodash'
import { formatDatetime, getLogsLink } from 'utils'
import { Typography } from 'antd'
import type { ReactNode } from 'react'

import {
  Box,
  Flex,
  Heading,
  TextLink,
  Text,
  colors,
} from '@weareberyl/design-system'

import type { InvoiceQuery_invoice as Invoice } from 'gql/generated/graphql'

import InvoiceStatusLabel from './InvoiceStatus'
import UserLink from '../UserLink'
import { formatInvoiceType } from './formatters'
import AssetLink from 'components/Scheme/AssetLink'
import * as routes from 'constants/routes'
import { InvoiceStatus } from '__generated__/globalTypes'

const logsLink = ({ id, created_at }: Invoice): string => {
  return getLogsLink(`@invoice_id:${id}`, created_at)
}

const Label = ({
  label,
  value,
  warning,
}: {
  label: string
  value: string | ReactNode
  warning?: boolean
}) => {
  if (!value) {
    return null
  }

  return (
    <Flex justifyContent="space-between" mb={1}>
      <Typography.Text strong style={{ marginRight: '1ex' }}>
        {label}:
      </Typography.Text>
      <span style={warning ? { color: colors.brick } : {}}>{value}</span>
    </Flex>
  )
}

const InvoiceHeader = ({ invoice }: { invoice: Invoice }) => {
  return (
    <>
      <Flex justifyContent="space-between" mb={3}>
        <Flex
          flex={1}
          justifyContent="space-between"
          style={{ borderBottom: '1px solid silver' }}
          pb={2}
        >
          <Box>
            <Box mb={-2}>
              <InvoiceStatusLabel
                status={invoice.status ?? InvoiceStatus.pending}
              />
            </Box>
            <Heading variant="callout">Invoice</Heading>
            <Heading variant="h2" mb={2}>
              <AssetLink basePath={routes.INVOICES} id={invoice.id}>
                {invoice.description}
              </AssetLink>
            </Heading>
          </Box>

          <Box>
            <Flex justifyContent="flex-end" mb={2}>
              <TextLink
                id="invoice-logs-link"
                variant="gray"
                onPress={() => window.open(logsLink(invoice), '_blank')}
              >
                View logs
              </TextLink>
              <Text mx={2}>·</Text>
              <TextLink
                variant="gray"
                onPress={() =>
                  window.open(
                    `https://dashboard.stripe.com/test/search?query=${invoice.id}`,
                    '_blank',
                  )
                }
              >
                View on Stripe
              </TextLink>
            </Flex>

            <Typography.Text copyable strong style={{ color: 'gray' }}>
              {invoice.id}
            </Typography.Text>
          </Box>
        </Flex>
      </Flex>
      <Flex mb={5}>
        <Flex flex={2}>
          <Box mr={6} minWidth="200px">
            <Label
              label="Created at"
              value={invoice && formatDatetime(invoice.created_at)}
            />
            <Label
              label="Type"
              value={invoice && formatInvoiceType(invoice.__typename)}
            />
            <Label
              label="Product"
              value={get(invoice, 'scheme_product.name')}
            />
          </Box>

          <Box mr={6} minWidth="200px">
            <Label
              label="User"
              value={<UserLink user={get(invoice, 'user')} />}
            />
            {invoice?.__typename === 'JourneyInvoice' && (
              <>
                <Label
                  label="Journey"
                  value={
                    <AssetLink
                      elementId="invoice-journey-link"
                      basePath={routes.JOURNEYS}
                      id={get(invoice, 'journey.id')}
                      schemeId={get(invoice, 'journey.scheme.id')}
                    />
                  }
                />
                <Label
                  label="Initiated by"
                  value={get(invoice, 'journey.initiator')}
                />
              </>
            )}
          </Box>
        </Flex>

        <Box flex={1} bg="tofu" p={3} borderRadius={10}>
          <Label
            label="Currency total"
            value={invoice?.currency_debit?.formatted_amount}
          />
          <Label
            label="Minute total"
            value={get(invoice, 'minute_debit.formatted_amount')}
          />
          <Label
            label="Currency refundable"
            value={get(invoice, 'currency_refundable.formatted_amount', 0)}
            warning={!get(invoice, 'currency_refundable.amount', 0)}
          />
          <Label
            label="Minute refundable"
            value={get(invoice, 'minute_refundable.formatted_amount', 0)}
            warning={!get(invoice, 'minute_refundable.amount', 0)}
          />
          <Label
            label="Currency balance"
            value={get(invoice, 'currency_balance.formatted_amount', 0)}
          />
        </Box>
      </Flex>
    </>
  )
}

export default InvoiceHeader
