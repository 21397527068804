import { useEffect, useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { Dropdown, Input, Modal, Select, Tag } from 'antd'
import type { ColumnProps } from 'antd/lib/table'
import Query from 'components/Query'
import { EmptyComponent } from 'components/Query/utils'
import { gql, ApolloQueryResult } from '@apollo/client'
import Roles from '../Roles'
import Table from 'components/Table'
import { useQueryParams } from 'utils/useQueryParams'
import { Actions } from 'components/User/actions'
import {
  Card,
  Heading,
  Flex,
  Button,
  Icon as BerylIcon,
  Box,
  Text,
  colors,
} from '@weareberyl/design-system'
import {
  USERS_LIST,
  USERS_LIST_table_nodes as User,
} from './__generated__/USERS_LIST'
import { CUSTOMERS_LIST } from './__generated__/CUSTOMERS_LIST'
import NewMemberModal from '../NewMemberModal'
import Tabs from 'components/shared/Tabs'
import TabTitle from 'components/shared/TabTitle'
import { withScheme } from 'components/Scheme'
import UserLink from 'components/UserLink'
import { UserWhere } from '__generated__/globalTypes'
import { SELECTED_USER_ROWS } from 'constants/sessionStorage'
import SetMemberRole from '../SetMemberRole'
import { withUser } from 'components/Session'
import { CURRENT_USER } from 'types'
import { hasRoleForScheme } from 'utils/firebase/authentication'
import { STAFF_LIST } from './__generated__/STAFF_LIST'
import HeadTitle from 'components/HeadTitle'

const { Tab } = Tabs

const fragments = {
  userList: gql`
    fragment UserList on UserConnection {
      nodes {
        id
        email
        has_valid_licence
        is_blocked
        roles {
          id
          scheme {
            id
            name
          }
          org {
            id
            name
          }
          type {
            id
            description
          }
        }
      }
      pagination {
        current: page
        pageSize: per_page
        total
      }
    }
  `,
}

const USERS_QUERY = gql`
  query USERS_LIST(
    $scheme_id: ID
    $where: [UserWhere]
    $order_by: [UserOrderby]
    $paginate: Paginate
  ) {
    table: users(
      scheme_id: $scheme_id
      paginate: $paginate
      where: $where
      order_by: $order_by
    ) {
      ...UserList
    }
  }
  ${fragments.userList}
`

export const CUSTOMERS_QUERY = gql`
  query CUSTOMERS_LIST(
    $scheme_id: ID!
    $where: [UserWhere]
    $order_by: [UserOrderby]
    $paginate: Paginate
  ) {
    table: customers(
      scheme_id: $scheme_id
      paginate: $paginate
      where: $where
      order_by: $order_by
    ) {
      ...UserList
    }
    total_customers: customers(scheme_id: $scheme_id, where: $where) {
      pagination {
        total
      }
    }
    total_staff: staff(scheme_id: $scheme_id, where: $where) {
      pagination {
        total
      }
    }
  }
  ${fragments.userList}
`

export const STAFF_QUERY = gql`
  query STAFF_LIST(
    $scheme_id: ID!
    $where: [UserWhere]
    $order_by: [UserOrderby]
    $paginate: Paginate
  ) {
    table: staff(
      scheme_id: $scheme_id
      paginate: $paginate
      where: $where
      order_by: $order_by
    ) {
      ...UserList
    }
    total_customers: customers(scheme_id: $scheme_id, where: $where) {
      pagination {
        total
      }
    }
    total_staff: staff(scheme_id: $scheme_id, where: $where) {
      pagination {
        total
      }
    }
  }
  ${fragments.userList}
`

const columns: ColumnProps<User>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    sorter: true,
    // @ts-ignore
    sortFunction: (direction: string) => ({
      id: direction,
    }),
    render: (id, user) => <UserLink user={user}>{id}</UserLink>,
  },
  {
    title: 'Email',
    sorter: true,
    // @ts-ignore
    sortFunction: (direction: string) => ({
      email: direction,
    }),
    render: user => <UserLink user={user}>{user.email}</UserLink>,
  },
  {
    title: 'Valid licence',
    dataIndex: 'has_valid_licence',
    align: 'center',
    render: valid => (
      <Box
        bg={valid ? 'mint' : 'prawn'}
        width={20}
        height={20}
        borderRadius={10}
        justifyContent="center"
        alignItems="center"
        margin="0 auto"
      >
        <BerylIcon
          type={valid ? 'tick' : 'cross'}
          color={valid ? 'jade' : 'brick'}
          width={10}
          height={10}
        />
      </Box>
    ),
  },
  {
    title: 'Active',
    dataIndex: 'is_blocked',
    align: 'center',
    render: value => (
      <Box
        bg={value ? 'prawn' : 'mint'}
        width={20}
        height={20}
        borderRadius={10}
        justifyContent="center"
        alignItems="center"
        margin="0 auto"
      >
        <BerylIcon
          type={value ? 'cross' : 'tick'}
          color={value ? 'brick' : 'jade'}
          width={10}
          height={10}
        />
      </Box>
    ),
  },
  {
    title: 'Roles',
    dataIndex: 'roles',
    render: roles => (
      <Flex>
        <Roles labelVariant="micro" roles={roles || []} closable={false} />
      </Flex>
    ),
  },
  {
    title: 'Actions',
    className: 'xs-hide',
    align: 'center',
    render: ({ id }) => (
      <Dropdown trigger={['click']} overlay={<Actions user_id={id} />}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="ant-dropdown-link">...</a>
      </Dropdown>
    ),
  },
]
enum FilterType {
  email = 'email',
  phone_number = 'phone_number',
}

type FilterSelectProps = {
  onChange: (filterType: FilterType) => void
  defaultValue: FilterType
}

type UserListProps = {
  scheme: {
    id: string
  }
  user: CURRENT_USER
}

const { Option } = Select

const FilterSelect = ({ onChange, defaultValue }: FilterSelectProps) => (
  <Select
    id="user-filter-type-select"
    onChange={onChange}
    defaultValue={defaultValue}
  >
    <Option value={FilterType.email}>Email</Option>
    <Option value={FilterType.phone_number}>Phone Number</Option>
  </Select>
)

const id = 'users-table'

const UserList = ({ scheme, user }: UserListProps) => {
  // If a scheme is specified then query users for this scheme only.
  // If not, query all users as we're in Admin mode
  const scheme_id = scheme ? scheme.id : null
  const [params, setQueryParams] = useQueryParams(id)
  const { current, pageSize, currentTab, search, filterType } = params
  const [showNewMemberModal, setShowNewMemberModal] = useState(false)
  const [showEditUsersModal, setShowEditUsersModal] = useState(false)

  // Browsing user data in the UserList by navigating through its pages causes the component to
  // unmount and remount, resetting all state. We want to preserve selected rows as we do this,
  // so we save and load the keys of the user's selected rows from session storage.

  // NB: In the case of UserList, row keys are formatted as '<userid>:<email>',
  // or just '<userid>' if there is no email (common in the test environment)

  const storedSelectedRowKeys =
    window.sessionStorage.getItem(SELECTED_USER_ROWS)
  const selectedRowKeysList: string[] = storedSelectedRowKeys
    ? JSON.parse(storedSelectedRowKeys)
    : []

  const [selectedRowKeys, setSelectedRowKeys] = useState(selectedRowKeysList)

  useEffect(() => {
    // If a user navigates to the UserList from another location on the website, i.e. visits the
    // UserList for the "first time", assume we want to clear any previously selected rows.
    if (!('id' in params) && storedSelectedRowKeys) {
      window.sessionStorage.removeItem(SELECTED_USER_ROWS)
      setSelectedRowKeys([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const _onFilterChange = (filterType: FilterType) => {
    setQueryParams({ filterType })
  }

  const closeIcon = () => (
    <CloseOutlined
      onClick={() => {
        setQueryParams({ current: 1, search: '' })
      }}
    />
  )

  const sanitisedFilterType =
    filterType in FilterType ? filterType : FilterType.email

  // Pass empty list of filters if no search value
  // Create a where clause with which filterType is selected
  const where: UserWhere[] = search
    ? [{ [sanitisedFilterType]: { like: `%${search}%` } }]
    : []

  let queryType = USERS_QUERY
  if (scheme_id) {
    queryType = currentTab === 'staff' ? STAFF_QUERY : CUSTOMERS_QUERY
  }

  //#region Logic for selecting rows
  const onSelectedRowsChange = (newSelectedRowKeys: string[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
    window.sessionStorage.setItem(
      SELECTED_USER_ROWS,
      JSON.stringify(newSelectedRowKeys),
    )
  }

  const shouldUserBeAbleToSelectRows = () => {
    const userRoles = user.roles
    const isAdminForScheme =
      scheme_id && hasRoleForScheme(userRoles, 'admin', parseInt(scheme_id, 10))
    const isOpsLeadForScheme =
      scheme_id &&
      hasRoleForScheme(userRoles, 'operations_lead', parseInt(scheme_id, 10))
    return user.is_super_admin || isAdminForScheme || isOpsLeadForScheme
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectedRowsChange,
  }
  //#endregion

  //#region Component and logic for displaying a list of selected users
  const unSelectUser = (userRowKey: string) => {
    const newSelectedRowKeys = selectedRowKeys.filter(key => key !== userRowKey)
    setSelectedRowKeys(newSelectedRowKeys)
    window.sessionStorage.setItem(
      SELECTED_USER_ROWS,
      JSON.stringify(newSelectedRowKeys),
    )
  }

  const SelectedUsers = () => (
    <Flex
      style={{ gap: '5px', flexShrink: 1 }}
      alignSelf="flex-start"
      flexWrap="wrap"
    >
      <Text>Users Selected:</Text>
      {selectedRowKeys.map(rowKey => {
        const userEmail = rowKey.split(':')[1]
        return (
          <Tag key={rowKey} closable onClose={() => unSelectUser(rowKey)}>
            {userEmail ? userEmail : rowKey}
          </Tag>
        )
      })}
    </Flex>
  )
  //#endregion

  //#region Components and logic for batch editing selected users
  const deselectAllUsers = () => {
    setSelectedRowKeys([])
    window.sessionStorage.removeItem(SELECTED_USER_ROWS)
  }

  const onClose = () => {
    setShowEditUsersModal(false)
  }

  const getSelectedUsers = () => {
    // Return an array of objects like [{id: "123", email: "india@india.com", isNewMember: false}]
    const users = selectedRowKeys.map(user => {
      const id = user.split(':')[0]
      const email = user.split(':')[1] ?? id
      return { id, email, isNewMember: false }
    })
    return users
  }

  const EditSelectedUsersModal = () => {
    return (
      <Modal
        open={showEditUsersModal}
        zIndex={1050}
        width={550}
        bodyStyle={{ padding: 0 }}
        onCancel={onClose}
        footer={null}
        destroyOnClose={true}
      >
        <SetMemberRole
          members={getSelectedUsers()}
          closeModal={onClose}
          refetchQueries={[
            { query: STAFF_QUERY, variables: { scheme_id: scheme.id } },
            {
              query: CUSTOMERS_QUERY,
              variables: { scheme_id: scheme.id },
            },
          ]}
        />
      </Modal>
    )
  }

  const EditSelectedUsers = () => {
    return (
      <Flex flexWrap="no-wrap" justifyContent="space-between">
        <SelectedUsers />
        <Flex>
          <Button
            mr={2}
            title="✎ Edit"
            onPress={() => setShowEditUsersModal(true)}
          />
          <Button
            mr={2}
            title="❌"
            variant="outline"
            style={{ borderColor: colors.alert }}
            onPress={deselectAllUsers}
          />
          <EditSelectedUsersModal />
        </Flex>
      </Flex>
    )
  }
  //#endregion

  return (
    <>
      <HeadTitle pageTitle="Users" />

      <Card p={5}>
        <Flex justifyContent="space-between" alignItems="flex-end" mb={5}>
          <Heading variant="callout">Users</Heading>
          <Button
            title="Create User"
            onClick={() => setShowNewMemberModal(true)}
          />
        </Flex>
        <NewMemberModal
          show={showNewMemberModal}
          close={() => setShowNewMemberModal(false)}
        />
        <Input
          id="users-search-input"
          addonBefore={
            <FilterSelect
              onChange={_onFilterChange}
              defaultValue={sanitisedFilterType}
            />
          }
          placeholder={
            sanitisedFilterType === FilterType.email
              ? 'Search users by email'
              : 'Search users by phone number'
          }
          onPressEnter={e => {
            setQueryParams({ search: e.currentTarget.value.trim(), current: 1 })
          }}
          defaultValue={search}
          suffix={search && closeIcon()}
        />
        {search?.length > 0 && (
          <Heading variant="h1" my={2}>
            Search results for: {search}
          </Heading>
        )}
        <Query
          query={queryType}
          variables={{
            scheme_id,
            where,
            paginate: {
              page: current,
              per_page: pageSize,
            },
          }}
          pollInterval={0}
          waitFor="data.table"
        >
          {(
            props: ApolloQueryResult<USERS_LIST | CUSTOMERS_LIST | STAFF_LIST>,
          ) => {
            const titleProp =
              selectedRowKeys.length > 0
                ? { title: () => <EditSelectedUsers /> }
                : {}
            const rowSelectionProp = shouldUserBeAbleToSelectRows()
              ? { rowSelection: { ...rowSelection } }
              : {}
            return (
              <Card variant="gray" p={3} mt={4}>
                <Tabs>
                  {'total_customers' in props.data && (
                    <Tab
                      onPress={() =>
                        setQueryParams({ currentTab: 'customers', current: 1 })
                      }
                      isSelected={currentTab === 'customers'}
                    >
                      <TabTitle tabText="Customers" icon="bikeJourney" />
                      <Heading variant="callout">
                        {props.data?.total_customers?.pagination?.total || 0}
                      </Heading>
                    </Tab>
                  )}
                  {'total_staff' in props.data && (
                    <Tab
                      onPress={() =>
                        setQueryParams({ currentTab: 'staff', current: 1 })
                      }
                      isSelected={currentTab === 'staff'}
                    >
                      <TabTitle tabText="Staff" icon="user" />
                      <Heading variant="callout">
                        {props.data?.total_staff?.pagination?.total || 0}
                      </Heading>
                    </Tab>
                  )}
                </Tabs>
                {(props.data.table?.nodes ?? []).length === 0 ? (
                  <EmptyComponent />
                ) : (
                  <Card variant="borderless">
                    <Table
                      id="users-table"
                      columns={columns}
                      {...props}
                      onChange={({ current, pageSize }) =>
                        setQueryParams({ current, pageSize })
                      }
                      {...rowSelectionProp}
                      {...titleProp}
                      customRowKey="email"
                    />
                  </Card>
                )}
              </Card>
            )
          }}
        </Query>
      </Card>
    </>
  )
}

export default withScheme(withUser(UserList))
