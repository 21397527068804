import { Row, Col, Card } from 'antd'

import History from './History'
import Map from './Map'
import Telemetry from './Telemetry'
import JourneyList from 'components/Journey/List/Table'
import Summary from './Summary'
import Bike from 'components/Bike'
import Unlock from 'components/Module/unlock'
import ConnectivityHistory from './ConnectivityHistory'
import VehicleStateEvents from 'components/Vehicle/VehicleStateEvents'
import { PermissionOnly } from 'components/Session'
import DockHistory from './DockHistory'
import useFeatureFlags from 'hooks/useFeatureFlags'
import useModuleVehicle from 'hooks/useModuleVehicle'
import HeadTitle from 'components/HeadTitle'
import AssetJobs from 'components/Job/List/AssetJobs'

const SCROLL_STYLES = {
  marginTop: 2,
  maxHeight: 720,
  overflowY: 'scroll' as const,
}

type ModuleProps = {
  id: string
}

const cols = 3
const headStyle = { fontSize: '20px' }

const Module = ({ id }: ModuleProps) => {
  const [featureFlags] = useFeatureFlags()

  const vehicle = useModuleVehicle(id)
  const scheme = vehicle?.detail?.scheme
  const assetTag = vehicle?.frame_id
  return (
    <>
      <HeadTitle pageTitle={`Module ${id}`} />
      <Row gutter={[16, 16]} className="fill-height">
        <Col xs={24} lg={12} className="flex-col">
          <Summary id={id} />
          <Telemetry id={id} />
        </Col>
        <Col xs={24} lg={12}>
          <Card id="module-map-card" bodyStyle={{ padding: 0 }} title="Map">
            <div className="minimap-wrapper">
              <Map id={id} />
            </div>
          </Card>
        </Col>
        <Col sm={24} lg={12}>
          <Card
            id="module-frame-and-scheme-card"
            title="Vehicle"
            headStyle={headStyle}
          >
            <Bike module_id={id} />
          </Card>
        </Col>
        <Col sm={24} lg={12}>
          <Card
            id="module-unlock-card"
            title="Module Unlock ID"
            headStyle={headStyle}
          >
            <Unlock module_id={id} />
          </Card>
        </Col>
      </Row>
      <Row gutter={16} className="fill-height">
        <Col sm={24} lg={12} xl={24 / cols}>
          <Card
            id="module-history-card"
            title="Lock State History"
            bodyStyle={{ ...SCROLL_STYLES }}
            headStyle={headStyle}
          >
            <History id={id} />
          </Card>
        </Col>
        <Col sm={24} lg={12} xl={24 / cols}>
          <Card
            id="module-dock-history-card"
            title="Dock History"
            bodyStyle={{ ...SCROLL_STYLES }}
            headStyle={headStyle}
          >
            <DockHistory id={id} />
          </Card>
        </Col>

        <Col sm={24} lg={12} xl={24 / cols}>
          <Card
            id="module-connectivity-history-card"
            title="Connectivity History"
            bodyStyle={{ ...SCROLL_STYLES }}
            headStyle={headStyle}
          >
            <ConnectivityHistory id={id} />
          </Card>
        </Col>
        {featureFlags.vehicle_state_events && (
          <Col sm={24} lg={12} xl={24 / cols}>
            <Row>
              <Card title="Vehicle State Events" headStyle={headStyle}>
                <VehicleStateEvents module_id={id} />
              </Card>
            </Row>
          </Col>
        )}
        <PermissionOnly permission="journey.view">
          <Col xs={24}>
            <Card title="Journeys" headStyle={headStyle}>
              <JourneyList module_id={id} />
            </Card>
          </Col>
        </PermissionOnly>
      </Row>
      {assetTag && scheme && <AssetJobs assetTag={assetTag} scheme={scheme} />}
    </>
  )
}

export default Module
