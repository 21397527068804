import { Col, Row, Switch, Tag, TagProps } from 'antd'
import type { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import { Card, Flex, Heading } from '@weareberyl/design-system'

import type { SCHEME } from 'types'

import Table from 'components/Table'
import { withScheme } from 'components/Scheme'
import { useQueryParams } from 'utils/useQueryParams'

import { ConfigTable } from './ConfigTable'
import { REWARD_TYPES } from 'constants/domain'
import HeadTitle from 'components/HeadTitle'
import { ProductType } from 'gql/generated/graphql'

import {
  SchemeProductListItem,
  UseSchemeProducts,
  defaultData,
  useSchemeProducts,
} from './data'
import ProductDetails from './ProductDetails'

const id = 'products-table'

type SchemeProductComponentProps = {
  setQueryParams: (params: {
    current?: number
    pageSize?: number
    expandedProducts?: string
  }) => void
  expandedProducts?: string
} & UseSchemeProducts

const tagColours: Record<ProductType, TagProps['color']> = {
  [ProductType.payg]: 'green',
  [ProductType.bundle]: 'blue',
  [ProductType.period_pass]: 'purple',
  [ProductType.day_pass]: 'orange',
}

const SchemeProductComponent = ({
  setQueryParams,
  expandedProducts,
  data,
  loading,
}: SchemeProductComponentProps) => {
  const columns: ColumnProps<SchemeProductListItem>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      width: 200,
      render: ({
        name,
        is_active,
        is_protected,
        is_discoverable,
      }: SchemeProductListItem) => {
        if (!is_active) {
          return (
            <strong>
              <s>{name}</s>
            </strong>
          )
        }
        if (is_protected) {
          return <strong>🔒 {name}</strong>
        }
        if (!is_discoverable) {
          return <strong>🎟️ {name}</strong>
        }
        return <strong>{name}</strong>
      },
    },
    {
      title: 'Product type',
      dataIndex: 'product_type',
      render: product_type => {
        return <Tag color={tagColours[product_type]}>{product_type}</Tag>
      },
    },
    {
      title: 'Price',
      dataIndex: ['price', 'formatted_amount'],
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (quantity: string) => quantity.toLocaleString(),
    },
    {
      title: 'Valid for',
      dataIndex: 'validity_interval',
      render: validity_interval =>
        validity_interval ? moment.duration(validity_interval).humanize() : '',
    },
    {
      title: 'Availability',
      dataIndex: 'availability',
    },
    {
      title: 'Journey cap',
      render: ({ billable_journey_cap, billable_journey_cap_sub_period }) => {
        if (billable_journey_cap && billable_journey_cap_sub_period) {
          const duration = moment
            .duration(billable_journey_cap_sub_period)
            .humanize()
            .replace('a ', '') // e.g. "a day" -> "day"
          return `${billable_journey_cap} per ${duration}`
        }
        return null
      },
    },
    {
      title: 'Active',
      align: 'center',
      dataIndex: 'is_active',
      render: is_active => {
        return <Switch disabled checked={is_active} size="small" />
      },
    },
    {
      title: 'Is Protected',
      align: 'center',
      dataIndex: 'is_protected',
      render: is_protected => {
        return <Switch disabled checked={is_protected} size="small" />
      },
    },
    {
      title: 'Is discoverable',
      align: 'center',
      dataIndex: 'is_discoverable',
      render: is_discoverable => {
        return <Switch disabled checked={is_discoverable} size="small" />
      },
    },
    {
      title: 'Fulfills rewards',
      dataIndex: 'fulfill_rewards',
      width: 150,
      render: (rewards: string[]) => {
        return rewards.map(reward => (
          <Tag
            style={{ display: 'inline-block', marginBottom: '1ex' }}
            key={reward}
          >
            {REWARD_TYPES[reward]}
          </Tag>
        ))
      },
    },
    {
      title: 'Role whitelist',
      dataIndex: 'role_whitelist',
      width: 150,
      render: (role_whitelist: string[]) => {
        return role_whitelist.map(role => (
          <Tag
            style={{ display: 'inline-block', marginBottom: '1ex' }}
            key={role}
          >
            {role}
          </Tag>
        ))
      },
    },
  ]

  const tableData = data ?? defaultData

  return (
    <Table<SchemeProductListItem>
      id={id}
      onChange={({ current, pageSize }) =>
        setQueryParams({ current, pageSize })
      }
      columns={columns}
      expandable={{
        expandedRowRender: schemeProduct => (
          <Row gutter={[10, 10]}>
            <Col xs={24} md={6} lg={6} offset={1}>
              <ProductDetails schemeProduct={schemeProduct} />
            </Col>
            <Col xs={24} md={15} offset={1}>
              <ConfigTable schemeProduct={schemeProduct} />
            </Col>
          </Row>
        ),
        expandRowByClick: true,
        expandedRowKeys: expandedProducts?.split(','),
        onExpand: (isExpanded, { id }) => {
          let expandedIds = expandedProducts?.split(',') ?? []
          if (isExpanded) {
            expandedIds.push(id)
          } else {
            expandedIds = expandedIds.filter(i => i !== id)
          }
          setQueryParams({ expandedProducts: expandedIds.join(',') })
        },
      }}
      rowClassName={({ is_active, is_discoverable }: SchemeProductListItem) => {
        if (!is_active) {
          return 'inactive-row'
        }
        if (!is_discoverable) {
          return 'subdued-row'
        }
        return ''
      }}
      rowKey={'id'}
      data={tableData}
      loading={loading}
    />
  )
}

const ProductList = ({ scheme }: { scheme: SCHEME }) => {
  const [{ current, pageSize, expandedProducts }, setQueryParams] =
    useQueryParams(id, {
      expandedProducts: '',
    })
  const { data, loading } = useSchemeProducts({ current, pageSize, scheme })

  return (
    <>
      <HeadTitle pageTitle="Products" />
      <Card p={5}>
        <Flex justifyContent="space-between" alignItems="flex-end" mb={5}>
          <Heading variant="callout">Products</Heading>
        </Flex>
        <SchemeProductComponent
          setQueryParams={setQueryParams}
          expandedProducts={expandedProducts}
          data={data}
          loading={loading}
        />
      </Card>
    </>
  )
}

export default withScheme(ProductList)
