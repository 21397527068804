import { Modal } from 'antd'
import { Button } from '@weareberyl/design-system'

import { useVoidTransactionMutation } from './hooks'

type ClearFailedPaymentsProps = {
  idsToVoid: string[]
  idsToUnvoid: string[]
  onComplete: () => void
}

const ClearFailedPayments = ({
  idsToVoid,
  idsToUnvoid,
  onComplete,
}: ClearFailedPaymentsProps) => {
  const confirm = Modal.confirm
  const { voidTransaction, unvoidTransaction, loadingVoid, loadingUnvoid } =
    useVoidTransactionMutation()

  const voidTransactions = () => {
    idsToVoid.forEach((transaction_id: string) => {
      voidTransaction({ variables: { transaction_id } })
    })
    onComplete()
  }

  const unvoidTransactions = () => {
    idsToUnvoid.forEach((transaction_id: string) => {
      unvoidTransaction({ variables: { transaction_id } })
    })
    onComplete()
  }

  return (
    <>
      <Button
        mt={2}
        mr={3}
        title="Void"
        variant="primary"
        onPress={() => {
          confirm({
            zIndex: 1050,
            title: 'Are you sure?',
            content: `Please confirm that you would like to clear these ${idsToVoid.length} transactions on this invoice`,
            onOk: voidTransactions,
            onCancel: undefined,
          })
        }}
        disabled={idsToVoid.length === 0}
        loading={loadingVoid}
      />
      <Button
        mt={2}
        mr={3}
        title="Un-void"
        variant="primary"
        onPress={() => {
          confirm({
            zIndex: 1050,
            title: 'Are you sure?',
            content: `Please confirm that you would like to un-void these ${idsToUnvoid.length} transactions on this invoice`,
            onOk: () => unvoidTransactions(),
            onCancel: undefined,
          })
        }}
        disabled={idsToUnvoid.length === 0}
        loading={loadingUnvoid}
      />
    </>
  )
}

export default ClearFailedPayments
