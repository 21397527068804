import Query from 'components/Query'
import { ApolloQueryResult } from '@apollo/client'
import Edit from './Edit'

import {
  ModuleVehicleCommissionedQueryVariables,
  ModuleVehicleCommissionedDocument,
  ModuleVehicleCommissionedQuery,
} from 'gql/generated/graphql'

const Bike = ({ module_id }: ModuleVehicleCommissionedQueryVariables) => (
  <Query
    query={ModuleVehicleCommissionedDocument}
    variables={{ module_id }}
    waitFor="data.module"
    pollInterval={0}
  >
    {({
      data: { module },
    }: ApolloQueryResult<ModuleVehicleCommissionedQuery>) =>
      module && <Edit module={module} />
    }
  </Query>
)

export default Bike
