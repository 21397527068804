import { useState, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { message } from 'antd'

const VOID_TRANSACTIONS = gql`
  mutation VOID_TRANSACTIONS($transaction_id: GUID!) {
    void_transaction(transaction_id: $transaction_id) {
      id
      void
    }
  }
`

const UNVOID_TRANSACTIONS = gql`
  mutation UNVOID_TRANSACTIONS($transaction_id: GUID!) {
    unvoid_transaction(transaction_id: $transaction_id) {
      id
      void
    }
  }
`

export const useVoidTransactionMutation = () => {
  const [voidTransaction, { loading: loadingVoid }] = useMutation(
    VOID_TRANSACTIONS,
    {
      onCompleted: () => message.success('Transaction successfully voided', 5),
      onError: () => message.error('Something went wrong, please try again', 5),
      refetchQueries: ['INVOICE'],
    },
  )
  const [unvoidTransaction, { loading: loadingUnvoid }] = useMutation(
    UNVOID_TRANSACTIONS,
    {
      onCompleted: () =>
        message.success('Transaction successfully un-voided', 5),
      onError: () => message.error('Something went wrong, please try again', 5),
      refetchQueries: ['INVOICE'],
    },
  )

  return {
    voidTransaction,
    unvoidTransaction,
    loadingVoid,
    loadingUnvoid,
  }
}

export const useValidators = (
  validators: (() => string | false)[],
  changableThings: unknown[],
) => {
  const [changed, setChangedCount] = useState(0)

  const validationMessages = validators
    .map(validator => validator())
    .filter(Boolean)

  const isValid = validationMessages.length === 0

  useEffect(() => {
    setChangedCount(changed + 1)
    // deps can't be passed statically
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, changableThings)

  const hasErrorMessage = !isValid && changed > 2

  return {
    isValid,
    errorMessage: hasErrorMessage && validationMessages[0],
  }
}
