import { gql, useMutation } from '@apollo/client'
import * as types from './__generated__/requestVehicleLowBatteryOverrideStart'

const query = gql`
  mutation requestVehicleLowBatteryOverrideStart($vehicleId: GUID!) {
    vehicle_low_battery_override_start(vehicle_id: $vehicleId)
  }
`

export default function useLowBatteryOverrideStart(
  variables?: types.requestVehicleLowBatteryOverrideStartVariables,
) {
  return useMutation<
    types.requestVehicleLowBatteryOverrideStart,
    types.requestVehicleLowBatteryOverrideStartVariables
  >(query, {
    variables,
    context: {
      useApolloNetworkStatus: false,
    },
  })
}
