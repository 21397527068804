import {
  GetAllVehiclesQueryResult,
  useGetAllVehiclesQuery,
} from 'gql/generated/graphql'

export default function useAllVehicles(): {
  assetTags: string[]
} & GetAllVehiclesQueryResult {
  const queryResult = useGetAllVehiclesQuery({
    pollInterval: 0,
    fetchPolicy: 'cache-first',
  })
  const { data } = queryResult
  const assetTags = data?.vehicles?.nodes?.map(({ frame_id }) => frame_id) ?? []

  return { ...queryResult, assetTags }
}
