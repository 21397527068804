import React from 'react'
import { Icon } from '@weareberyl/design-system'

export type Status = 'complete' | 'blocked' | string

export default ({ status }: { status: Status }) => {
  if (status === 'complete') {
    return <Icon height={15} type="tick" width={15} mt={1} />
  } else if (status === 'blocked') {
    return <Icon height={15} type="warning" width={15} mt={1} />
  }

  return <Icon height={15} type="cross" width={15} mt={1} />
}
