import { Tag } from 'antd'
import { Box, colors } from '@weareberyl/design-system'

import AssetLink from 'components/Scheme/AssetLink'
import { JOBS as JOBS_ROUTE } from 'constants/routes'
import { JobAutomationsQuery_automations_Automation_jobs_AutomationJob } from 'gql/generated/graphql'

interface Props {
  jobs: JobAutomationsQuery_automations_Automation_jobs_AutomationJob[]
}

const AutomationJobFrames = ({ jobs }: Props) => {
  return (
    <>
      {jobs.map(({ uuid, frame_id, scheme_id }) => (
        <Box key={uuid} pb={1}>
          <Tag color={colors.lake}>
            <AssetLink
              basePath={JOBS_ROUTE}
              id={uuid}
              schemeId={String(scheme_id)}
            >
              {frame_id}
            </AssetLink>
          </Tag>
        </Box>
      ))}
    </>
  )
}

export default AutomationJobFrames
