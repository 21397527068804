import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { message } from 'antd'

const REMOVE_WHITELIST = gql`
  mutation REMOVE_WHITELIST($whitelist_id: ID!) {
    remove_whitelist(whitelist_id: $whitelist_id)
  }
`

export default ({ whitelist_id }) => {
  return (
    <Mutation
      onCompleted={() => message.info('Whitelist removed!')}
      onError={() => message.error('Something went wrong')}
      refetchQueries={['WHITELIST_LIST']}
      mutation={REMOVE_WHITELIST}
    >
      {(rmWhiteList, { loading }) => (
        <LegacyIcon
          type={loading ? 'loading' : 'delete'}
          onClick={() =>
            rmWhiteList({
              variables: {
                whitelist_id,
              },
            })
          }
        />
      )}
    </Mutation>
  )
}
