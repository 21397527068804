import { Box } from '@weareberyl/design-system'
import { message } from 'antd'
import { Center } from 'components/Layout'
import logo from 'images/logo.png'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { authentication } from 'utils/firebase'
import SignInForm from './Form'

const SignInPage = ({ history }) => (
  <Center containerStyle={{ maxWidth: 400 }}>
    <Box pt={3} pb={5} alignItems="center">
      <img alt="logo" src={logo} width={200} />
    </Box>
    <SignInForm auth={authentication} history={history} message={message} />
  </Center>
)

export default withRouter(SignInPage)
