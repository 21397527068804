import { useState } from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Alert, Input, InputNumber, Modal, Select, message } from 'antd'
import Sentry from 'utils/sentry'
import {
  Button,
  Box,
  Heading,
  Flex,
  Text,
  Card,
} from '@weareberyl/design-system'

import { useScheduleRefresh } from 'hooks/useScheduleRefresh'
import { generateResultMessage, journeyDescriptionChoices } from './Refund'
import { useFormatters } from './Refund/AdjustTransaction'
import { useValidators } from './hooks'
import {
  InvoiceDocument,
  InvoiceQuery_invoice_JourneyInvoice,
  useReverseCancelledJourneyMutation,
} from 'gql/generated/graphql'

type ReverseUnlockedBikeFineProps = {
  invoice: InvoiceQuery_invoice_JourneyInvoice
  onComplete: () => void
}

const ReverseUnlockedBikeFine = ({
  invoice,
  onComplete,
}: ReverseUnlockedBikeFineProps) => {
  const scheduleRefresh = useScheduleRefresh([InvoiceDocument])
  const [visible, setVisible] = useState(false)
  const [showingConfirmation, setShowingConfirmation] = useState(false)

  // Form state
  const [description, setDescription] = useState<string | 'Please select'>(
    'Please select',
  )
  const [customDescription, setCustomDescription] = useState<string>('')
  const [amendedDuration, setAmendedDuration] = useState(
    invoice.journey.duration,
  )

  const descriptionValue =
    description.toLowerCase() === 'other' ? customDescription : description

  const { isValid, errorMessage } = useValidators(
    [
      () =>
        descriptionValue === 'Please select' && 'Please select a description',
      () => descriptionValue.length === 0 && 'Please enter a description',
    ],
    [descriptionValue, amendedDuration],
  )

  const { formatMinutes, parser } = useFormatters()

  const [submitReversal, { loading }] = useReverseCancelledJourneyMutation({
    refetchQueries: [InvoiceDocument],
    onCompleted: result => {
      message.info('Request successful', 10)
      if (!result.reverse_cancelled_journey) {
        return
      }
      if (result.reverse_cancelled_journey.currency?.status) {
        generateResultMessage(
          result.reverse_cancelled_journey.currency.status,
          'Refund currency',
          result.reverse_cancelled_journey.currency.message ?? null,
        )
        if (result.reverse_cancelled_journey.currency.status === 'succeeded') {
          // Currency refund settling transaction takes a bit of
          // time to come through as it's added by the webhook from Stripe.
          // So schedule an update in a couple of seconds
          scheduleRefresh()
        }
      }

      onComplete()
    },
    onError: err => {
      Sentry.captureException(err)
      onComplete()
      message.error('Something went wrong', 10)
    },
  })

  const buttonAction = () => {
    if (showingConfirmation) {
      submitReversal({
        variables: {
          id: invoice.id,
          description: descriptionValue,
          amendedDuration: amendedDuration,
        },
      })
    } else {
      setShowingConfirmation(true)
    }
  }

  const hideModal = () => {
    setVisible(false)
    setShowingConfirmation(false)
    onComplete()
  }

  return (
    <>
      <Button
        mt={2}
        mr={3}
        title="Reverse cancelled journey fine"
        variant="primary"
        onPress={() => setVisible(true)}
      />

      <Modal
        open={visible}
        zIndex={1050}
        footer={null}
        onCancel={hideModal}
        bodyStyle={{ padding: 0 }}
        width={800}
      >
        <Form onSubmit={buttonAction} title="Refund form">
          <Box pt={4} px={5} zIndex={2}>
            <Heading variant="callout" pt={2} pb={4}>
              Reverse cancelled journey fine
            </Heading>
            <Text>
              To reverse the unlocked journey fine, enter the new duration to
              recalculate the charges.
              <br /> Any excess amount charged will be refunded to the customer.
            </Text>
          </Box>

          <Box py={2} px={5} zIndex={2}>
            <Box bg="tofu" p={3} pl={5} my={2} borderRadius={10}>
              <Box mb={2}>
                <Heading>Amend journey duration</Heading>
              </Box>
              <Flex alignItems="center" style={{ gap: '10px' }}>
                <Box>
                  <Text>Corrected duration</Text>
                </Box>
                <Box style={{ gap: '10px' }}>
                  <Flex alignItems="center" style={{ gap: '10px' }}>
                    <InputNumber
                      size="large"
                      min={0}
                      step={1}
                      max={invoice.journey.duration ?? undefined}
                      value={amendedDuration ?? 0}
                      formatter={formatMinutes}
                      parser={parser}
                      onChange={parsedValue => {
                        if (parsedValue !== '' && parsedValue !== null) {
                          const value =
                            typeof parsedValue === 'number'
                              ? parsedValue
                              : parseFloat(parsedValue)

                          setAmendedDuration(value)
                        }
                      }}
                      title="Amended duration"
                    />
                  </Flex>
                </Box>
              </Flex>
            </Box>
            <Box p={3} pl={5} my={2} style={{ gap: '10px' }}>
              <Box mb={1}>
                <label htmlFor="refund-description" style={{ width: '150px' }}>
                  <Heading>Description</Heading>
                </label>
              </Box>
              <Select
                id="refund-description"
                style={{ flex: 1 }}
                value={description}
                size="large"
                onChange={(selected: string) => {
                  setDescription(selected)
                }}
                showSearch
                placeholder="Please select"
              >
                {journeyDescriptionChoices.map(item => (
                  <Select.Option value={item.key} key={item.key}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
              {description?.toLowerCase() === 'other' && (
                <Input
                  size="large"
                  value={customDescription ?? ''}
                  onChange={event => setCustomDescription(event.target.value)}
                  placeholder="Please enter a custom description"
                  title="Custom description"
                />
              )}
            </Box>
            {errorMessage && (
              <Alert
                message="Opps"
                description={errorMessage}
                type="error"
                showIcon
              />
            )}
          </Box>
        </Form>
        <Card
          p={5}
          borderTopLeftRadius={0}
          borderTopRightRadius={0}
          variant="gray"
          testID="refund-invoice-confirmation"
        >
          {showingConfirmation ? (
            <Flex mr={2}>
              <Flex
                width="50%"
                justifyContent="flex-start"
                alignContent="center"
              >
                <Text>Are you sure?</Text>
              </Flex>
              <Box width="50%">
                <Button
                  title="Confirm"
                  onPress={buttonAction}
                  disabled={!isValid || loading}
                  loading={loading}
                  type="submit"
                />
              </Box>
            </Flex>
          ) : (
            <Button
              testID="refund-invoice-confirmation-btn"
              title="Refund"
              onPress={buttonAction}
              disabled={!isValid}
              loading={loading}
              type="submit"
            />
          )}
        </Card>
      </Modal>
    </>
  )
}

export default ReverseUnlockedBikeFine
