import { Component } from 'react'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Input, message, Select } from 'antd'
import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { Box, Flex } from '@weareberyl/design-system'

const ADD_WHITELIST = gql`
  mutation ADD_WHITELIST($whitelist: WhitelistInput!) {
    add_whitelist(whitelist: $whitelist) {
      id
      pattern
      role_type_id
    }
  }
`

type WhitelistFormProps = {
  scheme: {
    id: string
  }
}

type WhitelistFormState = {
  pattern: string
}

class Form extends Component<WhitelistFormProps, WhitelistFormState> {
  state = {
    pattern: '',
    roleTypeId: 14,
  }

  _empty = () => {
    this.setState({ pattern: '' })
  }

  _onChangePattern = e =>
    this.setState(state => ({ ...state, pattern: e.target.value.trim() }))

  _onChangeRoleTypeId = value => {
    this.setState(state => ({ ...state, roleTypeId: value }))
  }

  render() {
    const { pattern, roleTypeId } = this.state
    const { scheme } = this.props
    const allowedRoleTypes = [
      {
        key: 'Member',
        value: 1,
      },
      {
        key: 'Workshop',
        value: 5,
      },
      {
        key: 'External Stakeholder',
        value: 14,
      },
    ]

    return (
      <Mutation
        onCompleted={() => {
          this._empty()
          message.info('Whitelist added!')
        }}
        onError={() => message.error('Something went wrong')}
        refetchQueries={['WHITELIST_LIST']}
        mutation={ADD_WHITELIST}
      >
        {(addWhiteList, { loading }) => (
          <Flex flexDirection="row">
            <Box flex={1} minWidth={200} mr={2}>
              <Select
                size="large"
                value={roleTypeId}
                onChange={this._onChangeRoleTypeId}
              >
                {allowedRoleTypes.map(({ key, value }) => (
                  <Select.Option key={key} value={value}>
                    {key}
                  </Select.Option>
                ))}
              </Select>
            </Box>
            <Box flex={9}>
              <Input
                size="large"
                id="user-whitelist-search-input"
                suffix={loading ? <LegacyIcon type={'loading'} /> : null}
                placeholder="whitelist pattern + press enter"
                value={pattern}
                onChange={this._onChangePattern}
                onPressEnter={() =>
                  addWhiteList({
                    variables: {
                      whitelist: {
                        scheme_id: scheme.id,
                        pattern,
                        role_type_id: roleTypeId,
                      },
                    },
                  })
                }
                style={{ height: 44 }}
              />
            </Box>
          </Flex>
        )}
      </Mutation>
    )
  }
}

export default Form
