import { Col, Row } from 'antd'
import { Card, Heading } from '@weareberyl/design-system'

import Token from './Token'
import FeatureFlags from 'components/FeatureFlags'
import ServiceNowAuth from 'components/ServiceNowAuth'
import HeadTitle from 'components/HeadTitle'

const AccountPage = () => (
  <>
    <HeadTitle pageTitle="Account settings" />

    <Card mb={4} p={5} pb={3} flex={1}>
      <Heading mb={5} variant="callout">
        ⚙️ Settings
      </Heading>
    </Card>

    <Row gutter={[16, 16]}>
      <Col xs={12} xl={8}>
        <Card p={5}>
          <FeatureFlags />
        </Card>
        <Card p={5} mt={4}>
          <ServiceNowAuth />
        </Card>
      </Col>

      <Col xs={12} xl={16}>
        <Card p={5}>
          <Token />
        </Card>
      </Col>
    </Row>
  </>
)

export default AccountPage
