import bbox from '@turf/bbox'

import { BasisMap } from 'components/Map'

export const calculateSchemeCoordinates = (serviceAreas, viewport) => {
  let schemeCenter
  let zoom: number | null = BasisMap.defaultProps.zoom

  const boundBox = bbox(serviceAreas)

  const bbounds = [
    [boundBox[0], boundBox[1]],
    [boundBox[2], boundBox[3]],
  ]

  try {
    viewport = viewport.fitBounds(bbounds, { padding: -50 })
    const { latitude, longitude, zoom: newZoom } = viewport
    schemeCenter = { latitude, longitude }
    zoom = newZoom
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(err)
    // tslint:disable-next-line:no-console
    console.log("Couldn't calculate map bounds")
  }

  return { coords: schemeCenter, zoom }
}
