import { useState } from 'react'

import {
  useZoneEnableMutation,
  useZoneSuspendMutation,
} from 'gql/generated/graphql'

type useBulkZoneStatusMutationProps = {
  suspend: boolean
  zoneIds: string[]
  onCompleted: (failedIds: string[]) => void
  onError: (err: unknown) => void
}

export function useBulkZoneStatusMutation({
  suspend,
  zoneIds,
  onCompleted,
  onError,
}: useBulkZoneStatusMutationProps): [() => void, boolean] {
  const [loading, setLoading] = useState(false)

  const [enableZone] = useZoneEnableMutation({ onError })
  const [suspendZone] = useZoneSuspendMutation({ onError })

  const updateFunction = async () => {
    if (zoneIds.length !== 0) {
      let failedZoneIds: string[] = []
      setLoading(true)

      const updateZoneStatus = suspend ? enableZone : suspendZone

      zoneIds.forEach(async (id, index) => {
        const isLastZone = index + 1 === zoneIds.length

        const refetchQueries = isLastZone ? ['ZonesList'] : undefined

        let result:
          | Awaited<ReturnType<typeof enableZone>>
          | Awaited<ReturnType<typeof suspendZone>>
        try {
          result = await updateZoneStatus({ variables: { id }, refetchQueries })
        } finally {
          // Whether it worked or not, set loading false when it gets to the last zone
          if (isLastZone) {
            setLoading(false)
            onCompleted(failedZoneIds)
          }
        }
        if (!result || result?.errors) {
          failedZoneIds = [...failedZoneIds, id]
        }
      })
    }
  }

  return [updateFunction, loading]
}
