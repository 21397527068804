import React from 'react'
import BooleanIcon from 'components/shared/BooleanIcon'
import ServiceAreaLink from 'components/ServiceArea/Link'
import { SERVICE_AREAS_GEOJSON_PROPERTIES } from 'components/Scheme/withSchemeServiceAreas'
import LegendCard from './Card'
import { Box, Flex, Heading, Text } from '@weareberyl/design-system'
import { VehicleIcon } from 'utils'

export interface ServiceAreaLegendProps {
  service_area: SERVICE_AREAS_GEOJSON_PROPERTIES
  onClose: () => void
  where?: object | null
}

const ServiceAreaLegend = ({
  service_area,
  onClose,
}: ServiceAreaLegendProps) => {
  const {
    id,
    service_area_type,
    speed_limit,
    effective_from,
    effective_to,
    applies_to,
    name,
    scheme_id,
    is_parking_allowed,
  } = service_area

  return (
    <LegendCard onClose={onClose} footer={null}>
      <Box flex="1">
        <Heading variant="h3" mb={3}>
          <ServiceAreaLink id={id} scheme_id={scheme_id.toString()}>
            {name}
          </ServiceAreaLink>
        </Heading>
        <Flex flexDirection="column" width="100%" mb={3}>
          <Flex justifyContent="flex-start" mb={2}>
            <Text variant="small">Parking: </Text>
            <BooleanIcon value={is_parking_allowed} />
          </Flex>
          <Flex mb={2}>
            <Text variant="small">Type: </Text>
            <Text variant="small" fontFamily="Hellix-SemiBold">
              {service_area_type}
            </Text>
          </Flex>
          <Flex mb={2}>
            <Text variant="small">Speed restrictions km/h: </Text>
            <Text variant="small" fontFamily="Hellix-SemiBold">
              {speed_limit}
            </Text>
          </Flex>
          {effective_from && effective_to && (
            <Flex mb={2}>
              <Text variant="small">Operational time: </Text>
              <Text variant="small" fontFamily="Hellix-SemiBold">
                {`${effective_from} - ${effective_to}`}
              </Text>
            </Flex>
          )}
          <Flex mb={2}>
            <Text variant="small">Applies to: </Text>
            <Flex>
              {applies_to.map(vehicle => (
                <VehicleIcon key={vehicle} vehicle={vehicle} size={21} />
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </LegendCard>
  )
}

export default ServiceAreaLegend
