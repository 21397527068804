import React from 'react'
import { Select, Spin } from 'antd'

import { SCHEME_EMOJIS } from 'constants/domain'
import useSchemes from './useSchemes'

const Option = Select.Option

type SchemeSelectProps = {
  scheme_id?: string
  inputRef?: any
} & React.ComponentProps<typeof Select>

export const SchemeSelect = ({
  scheme_id,
  inputRef,
  ...props
}: SchemeSelectProps) => {
  const { selectableSchemes } = useSchemes()
  return (
    <Select
      ref={inputRef}
      value={scheme_id}
      placeholder="Please select a scheme"
      notFoundContent={<Spin size="small" />}
      {...props}
    >
      {selectableSchemes.map(({ id, name }) => {
        const emoji = SCHEME_EMOJIS[id]
        return (
          <Option key={id} value={id}>
            {emoji} {name}
          </Option>
        )
      })}
    </Select>
  )
}

// SchemeSelect is wrapped by antd Form.Item which needs to forward refs to its children components
// So we need to use forwardRef here otherwise will throw an error
// see https://stackoverflow.com/questions/57976702/how-to-fix-warning-about-react-forwardref-after-update-ant-design
export default React.forwardRef((props: SchemeSelectProps, ref: any) => {
  return <SchemeSelect {...props} inputRef={ref} />
})
