import { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { Heading } from '@weareberyl/design-system'
import Table from 'components/Table'
import {
  LoadingComponent,
  ErrorComponent,
  EmptyComponent,
} from 'components/Query/utils'
import { compareAndShortenGeoms } from 'utils'

import { SERVICE_AREA_DIFF } from './__generated__/SERVICE_AREA_DIFF'

const SERVICE_AREA_DIFF_QUERY = gql`
  query SERVICE_AREA_DIFF($id: ID!) {
    service_area(service_area_id: $id) {
      id
      name
      geom
    }
  }
`

export default ({ serviceArea, columns }) => {
  const [[oldGeom, newGeom], setGeom] = useState(['-', '-'])

  const { loading, error, data } = useQuery<SERVICE_AREA_DIFF>(
    SERVICE_AREA_DIFF_QUERY,
    {
      variables: { id: serviceArea.id },
      pollInterval: 0,
      onCompleted: () => {
        setGeom(
          compareAndShortenGeoms(data?.service_area?.geom, serviceArea?.geom),
        )
      },
    },
  )

  const cols = [
    {
      title: '',
      dataIndex: 'diffType',
      render: diffType => <Heading variant="h4">{diffType}</Heading>,
    },
    ...columns,
    {
      title: 'Geometry',
      dataIndex: 'geom',
    },
  ]

  if (error) {
    return <ErrorComponent error={error} />
  }

  if (loading) {
    return <LoadingComponent />
  }

  if (!data) {
    return <EmptyComponent />
  }

  return (
    <div>
      <Table
        id="service-areas-sync-table-diff"
        columns={cols}
        hideProgress
        data={{
          table: {
            pagination: false,
            nodes: [
              { ...serviceArea, geom: newGeom, diffType: 'New' },
              {
                ...data.service_area,
                id: 'old',
                geom: oldGeom,
                diffType: 'Old',
              },
            ],
          },
        }}
      />
    </div>
  )
}
