import { Box, Card, Flex, Heading, colors } from '@weareberyl/design-system'
import { message } from 'antd'
import React from 'react'

import Loading from 'components/Loading'
import CardSection from 'components/shared/CardSection'
import Dropdown from 'components/shared/Dropdown'
import TabTitle from 'components/shared/TabTitle'
import {
  ZoneSummaryQuery_zone_Zone,
  ZoneType,
  useZoneSummaryQuery,
} from 'gql/generated/graphql'
import {
  VehicleIcon,
  capitalize,
  getPositionLinkFromCoordinates,
  isDockedScheme,
  isPromotionalZoneScheme,
} from 'utils'
import { getDerivedZone } from 'utils/derived'

import { SetPromotional } from './Actions/SetPromotional'
import { SuspendZone } from './Actions/SuspendZone'
import { ViewHistory } from './Actions/ViewHistory'
import ZoneNotes from './ZoneNotes'
import ZoneTypeStatus from './ZoneTypeStatus'
import useIsAnyLoading, { SetAnyLoading } from './useIsAnyLoading'

const getHiringOrParkingString = (status: string | undefined) =>
  status ? capitalize(status.split('_')[0]) : undefined

export const ZoneHeading = ({
  name,
  zoneType,
}: {
  name: string
  zoneType: ZoneType
}) => {
  return (
    <Flex flexDirection="row" flexWrap="nowrap">
      <ZoneTypeStatus zoneType={zoneType} />
      <Heading variant="h1" pl={2}>
        {name ?? 'Unknown'}
      </Heading>
    </Flex>
  )
}

const SectionHeader = ({
  isPromotional,
  isSuspended,
  schemeId,
  setAnyLoading,
  showPromotional,
  zone,
  ...props
}: {
  isPromotional: boolean
  isSuspended: boolean
  schemeId: string
  setAnyLoading: SetAnyLoading
  showPromotional: boolean
  zone: ZoneSummaryQuery_zone_Zone
}) => {
  const id = zone.id
  const zoneType = zone?.zone_type ?? ZoneType.normal

  return (
    <Flex
      justifyContent="space-between"
      alignItems="baseline"
      width="100%"
      gridGap={1}
      {...props}
    >
      <Box flex="1">
        <ZoneHeading zoneType={zoneType} name={zone.name} />
      </Box>
      <Box bg={colors.white}>
        <Dropdown
          menu={{
            items: [
              {
                label: (
                  <SuspendZone
                    id={id}
                    isSuspended={isSuspended}
                    setAnyLoading={setAnyLoading}
                  />
                ),
                key: 'suspend-zone',
              },
              {
                label: showPromotional ? (
                  <SetPromotional
                    id={id}
                    isPromotional={isPromotional}
                    setAnyLoading={setAnyLoading}
                  />
                ) : null,
                key: 'set-promotional',
              },
              {
                label: schemeId ? (
                  <ViewHistory id={id} schemeId={schemeId} />
                ) : null,
                key: 'view-history',
              },
            ].filter(item => item.label !== null),
          }}
        />
      </Box>
    </Flex>
  )
}

const SectionStatus = ({
  isAnyLoading,
  isPromotional,
  isSuspended,
}: {
  isAnyLoading: boolean
  isPromotional: boolean
  isSuspended: boolean
}) => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex gridGap={16}>
        <TabTitle
          tabText={isSuspended ? 'Suspended' : 'Enabled'}
          icon={isSuspended ? 'cross' : 'tick'}
        />

        {isPromotional && <TabTitle tabText={'Promotional'} icon={'tick'} />}
      </Flex>

      {isAnyLoading && (
        <Box>
          <Loading />
        </Box>
      )}
    </Flex>
  )
}

const SectionVehiclesAndCapacity = ({
  schemeId,
  zone,
}: {
  schemeId: string
  zone: ZoneSummaryQuery_zone_Zone
}) => {
  const showDockCount = isDockedScheme(schemeId)
  return (
    <React.Fragment>
      <Flex>
        <CardSection
          title="Total vehicles"
          titleValue={zone?.all_commissioned_modules_count.toString()}
          items={[]}
        />
      </Flex>
      <Flex>
        <CardSection
          title="Capacity"
          titleValue={zone?.capacity.toString()}
          titleStyle={{ mt: 1 }}
          items={[]}
        />
      </Flex>
      {showDockCount && (
        <Flex>
          <CardSection
            title="Docked vehicles"
            titleValue={zone?.docked_vehicles_count.toString()}
            titleStyle={{ mt: 1 }}
            items={[]}
          />
        </Flex>
      )}
      <Flex>
        <CardSection
          title="Permitted vehicles"
          titleValue={
            <Flex>
              {zone?.permitted_vehicles.map(vehicle => (
                <VehicleIcon key={vehicle} vehicle={vehicle} size={21} />
              ))}
            </Flex>
          }
          titleStyle={{ mt: 1 }}
          items={[]}
        />
      </Flex>
    </React.Fragment>
  )
}

const SectionHireable = ({ zone }: { zone: ZoneSummaryQuery_zone_Zone }) => {
  return (
    <Flex>
      <CardSection
        title="Hireable"
        titleValue={zone?.hireable_modules_count.toString()}
        items={[
          { key: 'Bikes', value: zone?.hireable_bikes },
          { key: 'E-Bikes', value: zone?.hireable_ebikes },
          {
            key: 'E-Scooters',
            value: zone?.hireable_scooters,
          },
        ]}
      />
    </Flex>
  )
}

const SectionCentreAndStatus = ({
  isPromotional,
  showPromotional,
  zone,
}: {
  isPromotional: boolean
  showPromotional: boolean
  zone: ZoneSummaryQuery_zone_Zone
}) => {
  const centreAndStatusList = [
    {
      key: 'Centre',
      value: getPositionLinkFromCoordinates(zone?.centre?.coordinates),
    },
    {
      key: 'Hiring status',
      value: getHiringOrParkingString(zone?.hiring_capacity_status),
    },
    {
      key: 'Parking status',
      value: getHiringOrParkingString(zone?.parking_capacity_status),
    },
  ]

  if (showPromotional) {
    centreAndStatusList.push({
      key: 'Promotional status',
      value: isPromotional ? 'Promotional' : 'Not Promotional',
    })
  }

  return (
    <Flex>
      <CardSection items={centreAndStatusList} />
    </Flex>
  )
}

const SectionNotHireable = ({ zone }: { zone: ZoneSummaryQuery_zone_Zone }) => {
  return (
    <Flex mt={1}>
      <CardSection
        title="Not hireable"
        titleValue={(
          (zone?.all_commissioned_modules_count ?? 0) -
          (zone?.hireable_modules_count ?? 0)
        ).toString()}
        items={[
          {
            key: 'Bikes',
            value: (zone?.bikes ?? 0) - (zone?.hireable_bikes ?? 0),
          },
          {
            key: 'E-Bikes',
            value: (zone?.ebikes ?? 0) - (zone?.hireable_ebikes ?? 0),
          },
          {
            key: 'E-Scooters',
            value: (zone?.scooters ?? 0) - (zone?.hireable_scooters ?? 0),
          },
        ]}
      />
    </Flex>
  )
}

const Summary = ({ id }) => {
  const { data, error, loading } = useZoneSummaryQuery({ variables: { id } })
  const [isAnyLoading, setAnyLoading] = useIsAnyLoading()

  if (loading) {
    return <Loading />
  }

  if (error) {
    message.error(error.message)
    return null
  }

  const zone = getDerivedZone(data?.zone, data?.zone?.scheme?.id)
  const schemeId = zone?.scheme?.id ?? null
  const isSuspended = zone?.is_suspended ?? false
  const showPromotional = Boolean(schemeId && isPromotionalZoneScheme(schemeId))
  // Never mark a bay as promotional in schemes that have showPromotional = false
  const isPromotional = Boolean(zone?.is_promotional && showPromotional)

  if (!zone || !schemeId) return null

  return (
    <Card id="zone-summary-card" mb={3} p={5}>
      <Flex flexDirection="column" gridGap={2}>
        <SectionHeader
          isPromotional={isPromotional}
          isSuspended={isSuspended}
          schemeId={schemeId}
          setAnyLoading={setAnyLoading}
          showPromotional={showPromotional}
          zone={zone}
        />
        <SectionStatus
          isAnyLoading={isAnyLoading}
          isPromotional={isPromotional}
          isSuspended={isSuspended}
        />

        <Box mt={1}>
          <ZoneNotes zone={zone} />
        </Box>

        <Flex justifyContent="space-between" width="100%">
          <Box flex="1" mr={5}>
            <SectionVehiclesAndCapacity schemeId={schemeId} zone={zone} />
          </Box>
          <Box flex="1">
            <SectionHireable zone={zone} />
          </Box>
        </Flex>

        <Flex justifyContent="space-between" alignItems="flex-end" width="100%">
          <Box flex="1" mr={5}>
            <SectionCentreAndStatus
              isPromotional={isPromotional}
              showPromotional={showPromotional}
              zone={zone}
            />
          </Box>
          <Box flex="1">
            <SectionNotHireable zone={zone} />
          </Box>
        </Flex>
      </Flex>
    </Card>
  )
}

export default Summary
