import { ApolloQueryResult } from '@apollo/client'
import { FeatureCollection } from 'geojson'

import Query from 'components/Query'
import withScheme from './withScheme'

import {
  VehicleType,
  ServiceAreaType,
  SchemeServiceAreaDocument,
  SchemeServiceAreaQuery,
} from 'gql/generated/graphql'

export type SERVICE_AREAS_GEOJSON_PROPERTIES = {
  id: string
  name: string
  external_id: string
  scheme_id: number // this is different from normal Scheme['id']
  service_area_type: ServiceAreaType
  is_parking_allowed: boolean
  effective_to: string
  effective_from: string
  speed_limit: number
  applies_to: VehicleType[]
}

export type SERVICE_AREAS_GEOJSON = FeatureCollection<
  null,
  SERVICE_AREAS_GEOJSON_PROPERTIES
>

export default Component =>
  withScheme(props => {
    return (
      <Query
        waitFor="data.scheme.service_areas"
        fetchPolicy="cache-first"
        pollInterval={0}
        query={SchemeServiceAreaDocument}
        variables={{ schemeId: props.scheme.id }}
      >
        {({ data }: ApolloQueryResult<SchemeServiceAreaQuery>) => (
          <Component
            serviceAreas={data?.scheme?.service_areas.geojson}
            {...props}
          />
        )}
      </Query>
    )
  })
