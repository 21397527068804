import { gql, useMutation } from '@apollo/client'
import * as types from './__generated__/requestVehicleMaintenancePickUp'

const query = gql`
  mutation requestVehicleMaintenancePickUp($vehicleId: GUID!) {
    vehicle_maintenance_pick_up(vehicle_id: $vehicleId)
  }
`

export default function useMaintenancePickUp(
  variables?: types.requestVehicleMaintenancePickUpVariables,
) {
  return useMutation<
    types.requestVehicleMaintenancePickUp,
    types.requestVehicleMaintenancePickUpVariables
  >(query, {
    variables,
    context: {
      useApolloNetworkStatus: false,
    },
  })
}
