import {
  setDoc,
  doc,
  getDoc,
  arrayUnion,
  updateDoc,
  onSnapshot,
} from 'firebase/firestore'
import { db } from './firebase'
import { v4 as uuidv4 } from 'uuid'

import { authentication } from '.'
import * as storageKeys from 'constants/localStorage' // skipcq JS-C1003

export type Device = {
  deviceID: string
  userAgent: string
}

type DeviceData = {
  devices: Device[]
}

export const getDeviceId = () => {
  let deviceID = window.localStorage.getItem(storageKeys.DEVICE_ID)

  if (!deviceID) {
    deviceID = uuidv4()
    window.localStorage.setItem(storageKeys.DEVICE_ID, deviceID)
  }

  return deviceID
}

export const trackLogin = async () => {
  const authUser = await authentication.loadCurrentUser()
  if (!authUser.id) {
    return
  }
  const device = {
    deviceID: getDeviceId(),
    userAgent: navigator.userAgent,
    timestamp: new Date().toISOString(),
  }
  try {
    await setDoc(
      doc(db, 'logins', authUser.id),
      {
        devices: arrayUnion(device),
      },
      { merge: true },
    )
  } catch (e) {
    console.error('Error adding document: ', e)
  }
}

export const trackLogout = async () => {
  const authUser = await authentication.loadCurrentUser()
  if (!authUser.id) {
    return
  }
  const docRef = doc(db, 'logins', authUser.id)
  const deviceID = getDeviceId()
  try {
    try {
      const docSnapshot = await getDoc(docRef)
      if (docSnapshot.exists()) {
        const data = docSnapshot.data()
        const currentDevices = data?.devices || []
        const updatedDevices = currentDevices.filter(
          device => device.deviceID !== deviceID,
        )
        await updateDoc(docRef, {
          devices: updatedDevices,
        })
      } else {
        console.error('Document not found.')
      }
    } catch (error) {
      console.error('Error removing device: ', error)
    }
  } catch (e) {
    console.error('Error adding document: ', e)
  }
}

export const getCurrentDevices = async () => {
  const authUser = await authentication.loadCurrentUser()
  if (!authUser.id) {
    return []
  }

  const docRef = doc(db, 'logins', authUser.id)
  try {
    const docSnapshot = await getDoc(docRef)
    if (docSnapshot.exists()) {
      const data = docSnapshot.data() as DeviceData
      return data?.devices || []
    } else {
      console.error('Document not found.')
      return []
    }
  } catch (error) {
    console.error('Error getting current devices: ', error)
    return []
  }
}

export const subscribeToDeviceChanges = async (
  callback: (data: Device[]) => void,
) => {
  const authUser = await authentication.loadCurrentUser()
  if (!authUser.id) {
    return null
  }

  const docRef = doc(db, 'logins', authUser.id)

  return onSnapshot(docRef, { includeMetadataChanges: true }, doc => {
    const data = doc.data() as DeviceData
    if (data?.devices) {
      callback(data.devices)
    }
  })
}
