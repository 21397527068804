import { useSchemeAvailabilityRestrictionsQuery } from 'gql/generated/graphql'

const useCurfews = (schemeId: string | null) => {
  const {
    loading,
    data: rawData,
    error,
  } = useSchemeAvailabilityRestrictionsQuery({
    variables: { schemeId: schemeId ?? '' },
    pollInterval: 0,
    skip: !schemeId,
  })

  const data = rawData?.scheme?.availability_restriction.nodes
  return { loading, data, error }
}

export default useCurfews
