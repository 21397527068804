import { Redirect, Route, Switch } from 'react-router'
import { matchPath, withRouter } from 'react-router-dom'

import Account from 'components/Account'
import Automations from 'components/Automations'
import Deployment from 'components/Deployment'
import DeploymentList from 'components/Deployment/List'
import JobList from 'components/Job/List'
import JourneyList from 'components/Journey/List'
import ModuleList from 'components/Module/List'
import SoakTestList from 'components/Module/SoakTest'
import { SchemeList } from 'components/Scheme'
import { AuthOnly, PermissionOnly, SuperAdminOnly } from 'components/Session'
import UserList from 'components/User/List'
import SchemeContext from './SchemeContext'
import SignedOut from './SignedOut'

import { getDefaultRoute } from 'components/Navigation'
import * as routes from 'constants/routes'

import Main from 'components/App/Main'
import { useCurrentUser } from 'hooks'
import {
  doSignOut,
  hasAnyRelevantPermission,
} from 'utils/firebase/authentication'
import { ErrorComponent } from '../Query/utils'
import { message } from 'antd'
import { useEffect } from 'react'

const App = ({ history, location }) => {
  const [user] = useCurrentUser()
  const userId = user?.id
  const userRoles = user?.roles
  const hasPermissions = hasAnyRelevantPermission(userRoles)

  useEffect(() => {
    if (userId && userRoles && !hasPermissions) {
      doSignOut()
      message.error('User is unauthorised')
    }
  }, [userId, userRoles, hasPermissions])

  if (matchPath(location.pathname, { path: '/', exact: true })) {
    const route = getDefaultRoute(user, '')
    history.push(route)
  }

  if (!userId || !hasPermissions) return <SignedOut />

  return (
    <AuthOnly.Forbidden>
      <Main>
        <Switch>
          <Redirect from={routes.SIGN_IN} to={routes.MAP} />
          <Route
            exact
            path={routes.ALL_MODULES}
            component={() => (
              <PermissionOnly.Forbidden permission="module.all">
                <ModuleList scheme={{ id: null }} />
              </PermissionOnly.Forbidden>
            )}
          />
          <Route
            exact
            path={routes.ALL_JOURNEYS}
            component={() => (
              <SuperAdminOnly.Forbidden>
                <JourneyList scheme={{ id: null }} />
              </SuperAdminOnly.Forbidden>
            )}
          />
          <Route
            exact
            path={routes.ALL_USERS}
            component={() => (
              <PermissionOnly.Forbidden permission="user.view">
                <UserList scheme={{ id: null }} />
              </PermissionOnly.Forbidden>
            )}
          />
          <Route
            exact
            path={routes.ALL_JOBS}
            component={() => (
              <PermissionOnly.Forbidden permission="job.all">
                <JobList scheme={{ id: undefined }} />
              </PermissionOnly.Forbidden>
            )}
          />
          <Route
            exact
            path={routes.SOAK_TEST}
            component={() => (
              <PermissionOnly.Forbidden permission="provision.view">
                <SoakTestList />
              </PermissionOnly.Forbidden>
            )}
          />
          <Route
            exact
            path={routes.DEPLOYMENT}
            component={({ match }) => (
              <PermissionOnly.Forbidden permission="deployment.view">
                <Deployment id={match.params.id} />
              </PermissionOnly.Forbidden>
            )}
          />
          <Route
            exact
            path={routes.DEPLOYMENTS}
            component={() => (
              <PermissionOnly.Forbidden permission="deployment.view">
                <DeploymentList />
              </PermissionOnly.Forbidden>
            )}
          />
          <Route
            exact
            path={routes.AUTOMATIONS}
            component={() => (
              <SuperAdminOnly.Forbidden>
                <Automations />
              </SuperAdminOnly.Forbidden>
            )}
          />
          <Route exact path={routes.ACCOUNT} component={Account} />
          <Route exact path={routes.SCHEMES} component={SchemeList} />
          <SchemeContext />
          <Route
            component={() => (
              <ErrorComponent
                error={{
                  message:
                    "Page error: We couldn't find what you are looking for",
                }}
              />
            )}
          />
        </Switch>
      </Main>
    </AuthOnly.Forbidden>
  )
}

export default withRouter(App)
