import { gql, useQuery } from '@apollo/client'
import { CURRENT_SCHEME_KEY } from 'constants/localStorage'
import { createContext, useState, ReactNode } from 'react'
import { SCHEME } from 'types'
import type {
  SCHEME_CONFIG__DASHBOARD,
  SCHEME_CONFIG__DASHBOARDVariables,
  SCHEME_CONFIG__DASHBOARD_scheme_config,
} from './__generated__/SCHEME_CONFIG__DASHBOARD'

export const SCHEME_CONFIG_QUERY = gql`
  query SCHEME_CONFIG__DASHBOARD($schemeId: ID!) {
    scheme(scheme_id: $schemeId) {
      id
      config {
        is_vehicle_rewards_enabled
      }
    }
  }
`

type CurrentSchemeCtx = {
  currentSchemeId: SCHEME['id'] | null
  setCurrentSchemeId: (scheme: SCHEME['id']) => void
  config?: SCHEME_CONFIG__DASHBOARD_scheme_config
}

export const CurrentSchemeContext = createContext<CurrentSchemeCtx>({
  currentSchemeId: null,
  setCurrentSchemeId: () => {},
  config: undefined,
})

type Props = {
  schemeId?: SCHEME['id']
  children: ReactNode
}

export default ({ schemeId, children }: Props) => {
  const [currentSchemeId, setCurrentSchemeId] = useState(
    schemeId || window.localStorage.getItem(CURRENT_SCHEME_KEY),
  )

  const { data: schemeConfigData } = useQuery<
    SCHEME_CONFIG__DASHBOARD,
    SCHEME_CONFIG__DASHBOARDVariables
  >(SCHEME_CONFIG_QUERY, {
    variables: { schemeId: currentSchemeId ?? '' },
    skip: !currentSchemeId,
    fetchPolicy: 'cache-first',
    pollInterval: 0,
  })

  function persistSchemeId(id: SCHEME['id']) {
    setCurrentSchemeId(id)
    window.localStorage.setItem(CURRENT_SCHEME_KEY, id)
  }

  return (
    <CurrentSchemeContext.Provider
      value={{
        currentSchemeId,
        setCurrentSchemeId: persistSchemeId,
        config: schemeConfigData?.scheme?.config,
      }}
    >
      {children}
    </CurrentSchemeContext.Provider>
  )
}
