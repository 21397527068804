import { Fragment } from 'react'
import { capitalize as _capitalize, camelCase } from 'lodash'
import { format, isDate, parseISO } from 'date-fns'
import Copy from 'components/Copy'

import config from 'constants/config'
import { ZonesSyncMutation_sync_zones_ZoneSyncResult } from 'gql/generated/graphql'
import { SERVICE_AREAS_SYNC_sync_service_areas } from 'components/ServiceArea/Sync/__generated__/SERVICE_AREAS_SYNC'
import {
  LockConfiguration,
  ModuleHardware,
  VehicleType,
} from '__generated__/globalTypes'
import {
  SchemeId,
  DockedSchemes,
  LockConfigurationSchemes,
  PromotionalZoneSchemes,
} from '../constants/domain'
import { Icon, colors } from '@weareberyl/design-system'
import type { Feature, FeatureCollection } from '@turf/helpers'
import { LineString } from 'geojson'

export type IconMapEntry = {
  icon: string
  colour: string
}

export type IconsMap = {
  [key: string]: IconMapEntry
}

export const ICONS_MAP: IconsMap = {
  locked: { icon: 'lock', colour: '#52525c' },
  unavailable_locked: { icon: 'lock', colour: '#52525c' },
  waiting_for_unlock: { icon: 'hourglass', colour: '#52525c' },
  waiting_for_lock: { icon: 'hourglass', colour: '#52525c' },
  unavailable_waiting_for_unlock: { icon: 'hourglass', colour: '#52525c' },
  docked: { icon: 'login', colour: '#52525c' },
  not_docked: { icon: 'logout', colour: '#52525c' },
  unknown_dock_state: { icon: 'question-circle', colour: '#52525c' },
  lock_fault: { icon: 'warning', colour: '#52525c' },
  unlocked: { icon: 'unlock', colour: '#52525c' },
  unlock_timeout: { icon: 'clock-circle', colour: '#52525c' },
  spontaneous_unlock: { icon: 'warning', colour: '#52525c' },
  lock_stuck: { icon: 'close-circle', colour: '#52525c' },
  unavailable_unlocked: { icon: 'unlock', colour: '#52525c' },
  unknown: { icon: 'question-circle-o', colour: '#52525c' },
  unlock_failed: { icon: 'warning', colour: '#52525c' },
  front_lock_only: { icon: 'double-left', colour: '#52525c' },
  rear_lock_only: { icon: 'double-right', colour: '#52525c' },
  partially_locked: { icon: 'unlock', colour: colors.tumeric },
  partially_unlocked: { icon: 'unlock', colour: colors.tumeric },
  // journey states
  started: { icon: 'check-circle-o', colour: '#52525c' },
  unlock_request: { icon: 'hourglass', colour: '#52525c' },
  bike_unlocked: { icon: 'unlock', colour: '#52525c' },
  bike_locked: { icon: 'lock', colour: '#52525c' },
  false_unlock: { icon: 'warning', colour: '#52525c' },
  on_hold: { icon: 'hourglass', colour: '#52525c' },
  paused: { icon: 'pause', colour: '#52525c' },
  resume_request: { icon: 'caret-right', colour: '#52525c' },
  ended: { icon: 'check-circle', colour: '#52525c' },
  ended_failed: { icon: 'close-circle', colour: '#52525c' },
  ended_success: { icon: 'check-circle', colour: '#52525c' },
  history_ready: { icon: 'line-chart', colour: '#52525c' },
  charge_ready: { icon: 'pound', colour: '#52525c' },
  cancelled: { icon: 'stop', colour: '#52525c' },
  voided: { icon: 'stop', colour: '#52525c' },
}

export const getIcon = (value: string) => {
  if (!!value && Object.keys(ICONS_MAP).includes(value)) {
    return ICONS_MAP[value]
  } else {
    return { icon: '', colour: '' }
  }
}

export const AVAILABILITY_ICONS_MAP = {
  available: 'check-circle-o',
  unavailable: 'close-circle-o',
}

export const colorByFixInvalid = (fixInvalid: boolean) => {
  return fixInvalid ? 'red' : 'green'
}

export const iconByFixInvalid = (fixInvalid: boolean) => {
  return fixInvalid ? 'close-circle' : 'check-circle'
}

export const colorByAvailability = (status: string) => {
  switch (status) {
    case 'available':
      return 'green'
    case 'unavailable':
      return 'red'
    default:
      return 'gray'
  }
}

export const iconByIsAutomated = (isAutomated: boolean) => {
  return isAutomated
    ? ['robot', colors.grape, 'Created Automatically']
    : ['user', colors.jade, 'User Created']
}

export const colorByOnlineStatus = (isOnline: boolean) => {
  return isOnline ? 'green' : 'red'
}

export const colorByModuleState = (state: string) => {
  switch (state) {
    case 'unavailable_locked':
    case 'locked':
    case 'docked':
      return 'green'
    case 'unavailable_unlocked':
    case 'unlocked':
    case 'not_docked':
      return 'red'
    case 'unavailable_waiting_for_unlock':
    case 'waiting_for_unlock':
    case 'lock_fault':
    case 'unknown_dock_state':
      return 'orange'
    default:
      return 'gray'
  }
}

export const colorByRoleDescription = (description: string) => {
  // The role description might have the scheme appended, so only check the start of the string
  if (description.startsWith('Super admin')) {
    return 'crimson'
  } else if (description.startsWith('Scheme admin')) {
    return 'red'
  } else if (description.startsWith('Member')) {
    return 'green'
  } else if (description.startsWith('Validated')) {
    return 'blue'
  } else if (description.startsWith('Provisioner')) {
    return 'darkorchid'
  } else if (description.startsWith('Workshop')) {
    return 'fuchsia'
  } else if (description.startsWith('Super workshop')) {
    return 'tomato'
  } else {
    return 'gray'
  }
}

export const availabilityFromState = (state: string) => {
  if (state) {
    if (state.includes('unavailable')) {
      return 'Unavailable'
    } else {
      return 'Available'
    }
  } else {
    return 'Unknown'
  }
}

type Coords = {
  latitude: number
  longitude: number
}

export const currentLocation = (): Promise<Coords> => {
  const fallbackCoords = { latitude: 51.5007, longitude: -0.1246 } // Big ben
  return new Promise((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { coords } = position
          resolve(coords)
        },
        () => resolve(fallbackCoords),
        {
          timeout: 5 * 1000, // 5 second timeout
        },
      )
    } else {
      reject(fallbackCoords)
    }
  })
}

const formatTypeMap = {
  date: 'dd.MM.yy',
  datetime: 'dd.MM.yy HH:mm:ss',
  time: 'HH:mm:ss',
}

export type FormatType = 'time' | 'date' | 'datetime'

export const formatDatetime = (
  UTCDateString?: string,
  formatType: FormatType = 'datetime',
): string => {
  if (!UTCDateString) {
    return ''
  }

  const formatTypeValue = formatTypeMap[formatType]

  if (!formatTypeValue) {
    throw new TypeError(
      `Invalid formatType got: ${formatType} expected one of: ${Object.keys(
        formatTypeMap,
      ).join()}`,
    )
  }

  return format(parseISO(UTCDateString), formatTypeValue)
}

export const capitalize = (name: string | null | undefined) => {
  if (name === null || name === undefined) {
    return name
  }
  if (name) {
    return _capitalize(name.replace(/_/g, ' '))
  }
}

export const getSiteEnv = (): string | null => {
  const str = window.location.hostname
  const env =
    str.substring(str.lastIndexOf('-') + 1, str.lastIndexOf('.')) || 'local'

  return env === 'pdn' ? null : env
}

export const formatLocation = (loc: number) => loc.toFixed(5)

export const getPositionFromLocation = ({
  latitude,
  longitude,
}: {
  latitude: number | null
  longitude: number | null
}) => {
  if (!latitude || !longitude) {
    return 'No data'
  }

  return `${formatLocation(latitude)}, ${formatLocation(longitude)}`
}

export const getPositionFromCoordinates = (coords: [number, number]) => {
  if (!coords || coords.length !== 2) {
    return 'No data'
  }

  const [longitude, latitude] = coords
  return `${formatLocation(latitude)},${formatLocation(longitude)}`
}

export const getPositionLinkFromCoordinates = (coords: [number, number]) => {
  const readPosition = getPositionFromCoordinates(coords)

  if (readPosition === 'No data') {
    return readPosition
  }

  return (
    <Fragment>
      <a
        id="zone-coords-link"
        href={`https://www.google.com/maps/search/?api=1&query=${readPosition}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        {readPosition}
      </a>{' '}
      <Copy id="location" text={readPosition} showText={false} />
    </Fragment>
  )
}

export const hasFeatureFlag = (flagName: string): boolean => {
  const flag = localStorage.getItem(`__feature_flag__.${flagName}`)
  return flag ? true : false
}

export const isEmptySyncData = (
  data:
    | ZonesSyncMutation_sync_zones_ZoneSyncResult
    | SERVICE_AREAS_SYNC_sync_service_areas
    | null,
): boolean => {
  if (!data) {
    return false
  }

  const { added, updated, deleted } = data
  return [...(added ?? []), ...(updated ?? []), ...(deleted ?? [])].length === 0
}

export const compareAndShortenGeoms = (
  geom1: object,
  geom2: object,
): [string, string] => {
  const randomString1 = Math.random().toString(16).substr(2, 8)
  const randomString2 = Math.random().toString(16).substr(2, 8)

  if (JSON.stringify(geom1) !== JSON.stringify(geom2)) {
    return [randomString1, randomString2]
  }

  return [randomString1, randomString1]
}

export const formatRoleName = (name: string) => {
  return name
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

type DDParams = {
  query: string
  live: string
  from_ts?: string
  to_ts?: string
}
export function getLogsLink(
  query: string,
  from?: string | Date,
  to?: string | Date,
): string {
  const env = config.REACT_APP_ENV
  const params: DDParams = {
    query: `env:${env} ${query}`,
    live: 'true',
  }
  if (from) {
    // Add timestap params to filter the the hour after the object was created
    const from_ts: number = isDate(from) ? +from : +parseISO(from as string)
    // Add an hour
    let to_ts: number = +from_ts + 3_600_000
    // unless to is specified
    if (to) {
      to_ts = isDate(to) ? +to : +parseISO(to as string)
    }

    params.from_ts = from_ts.toString()
    params.to_ts = to_ts.toString()
    params.live = 'false'
  }
  const search = new URLSearchParams(params)
  const url = new URL('https://app.datadoghq.eu/logs')
  url.search = search.toString()
  return url.toString()
}

export function showLockConfig(schemeId: string | undefined): boolean {
  // Schemes that support front tether locks should show lock config
  if (!schemeId) {
    return false
  }
  return (LockConfigurationSchemes as string[]).includes(schemeId)
}

export function isDockedScheme(schemeId: string | undefined): boolean {
  // 'Docked' schemes are those where we can determine a bike is docked
  if (!schemeId) {
    return false
  }
  return (DockedSchemes as string[]).includes(schemeId)
}

export function isPromotionalZoneScheme(schemeId: string | undefined): boolean {
  // 'Promotional Zone' schemes are those where a Zone can be promotional
  if (!schemeId) {
    return false
  }
  return (PromotionalZoneSchemes as string[]).includes(schemeId)
}

export function addTimesToJourneyRoute(route: FeatureCollection) {
  const lineLayer = route.features.find(
    l => l?.geometry?.type === 'LineString',
  ) as Feature<LineString> | undefined

  const times = lineLayer?.properties?.times

  const coordinates = lineLayer?.geometry.coordinates
  const finalCoordinate = coordinates?.[coordinates.length - 1]

  const newFeatures: Feature[] = []

  // remove unwanted properties from route.features
  const routeFeatures: Feature[] = []
  route.features.forEach(feature => {
    if (feature.properties && 'duration' in feature.properties) {
      const { duration: _duration, times: _times, ...rest } = feature.properties
      routeFeatures.push({ ...feature, properties: rest })
    } else if (feature.geometry) {
      // for any remaining features with geometry (ie start/end points), push them to the array
      routeFeatures.push(feature)
    } else if (feature.properties?.name === 'end' && finalCoordinate) {
      // if there's no valid endpoint we get `geometry: null` in the `end` Feature, so let's insert
      // the last point of the LineString as 'final', if it exists
      routeFeatures.push({
        geometry: {
          coordinates: finalCoordinate,
          type: 'Point',
        },
        properties: {
          name: 'final',
        },
        type: 'Feature',
      })
    }
  })

  if (coordinates) {
    for (const [i, coords] of coordinates.entries()) {
      try {
        newFeatures.push({
          geometry: {
            coordinates: coords,
            type: 'Point',
          },
          properties: {
            name: 'step',
            time: format(new Date(times[i]), formatTypeMap.datetime),
          },
          type: 'Feature',
        })
      } catch (err) {
        // It's possible that times[0] could be null but either way we
        // want to still render as many points as possible even if one
        // has bad data.
        console.log('error creating point step')
      }
    }
  }

  return {
    ...route,
    features: [...routeFeatures, ...newFeatures],
  }
}

export function getDefaultLockConfig(
  schemeId: string | undefined,
  vehicleType: ModuleHardware,
): LockConfiguration {
  // Smartbikes don't have a lock.
  if (schemeId === SchemeId.tfl_smartbike) {
    return LockConfiguration.no_lock
  }

  if (vehicleType === ModuleHardware.scooter) {
    return LockConfiguration.electronic_lock
  }

  // All other schemes default to rear_lock
  return LockConfiguration.rear_lock
}

export const ucfirst = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1)
}

type VehicleIconProps = {
  vehicle: VehicleType
  size: number
}

export const VehicleIcon = ({ vehicle, size }: VehicleIconProps) => {
  const iconType = camelCase(vehicle)
  return (
    <Icon key={vehicle} type={iconType} width={size} height={size} ml={1} />
  )
}

const isValidLatitude = (latitude: number): boolean => {
  return latitude >= -90 && latitude <= 90
}

const isValidLongitude = (longitude: number): boolean => {
  return longitude >= -180 && longitude <= 180
}

export const convertStringToLatLng = (
  input: string,
): { latitude: number; longitude: number } | null => {
  if (!input) {
    return null
  }

  const parts = input.split(',')
  if (parts.length !== 2) {
    return null
  }

  const latitude = parseFloat(parts[0])
  const longitude = parseFloat(parts[1])

  // Check if the latitude and longitude are within valid ranges
  if (isValidLatitude(latitude) && isValidLongitude(longitude)) {
    return { latitude, longitude }
  }

  return null
}

export const limitChars = (input: string, max = 25) => {
  if (input.length > max) {
    return `${input.substring(0, max - 5)}...`
  }
  return input
}
