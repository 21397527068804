export const ADMIN_ROLE_TYPES = [
  'super_admin',
  'super_workshop',
  'super_marketing',
  'admin',
  'scheme_lead',
  'operations_lead',
] as const

export const OFFICE_ROLE_TYPES = [
  ...ADMIN_ROLE_TYPES,
  'first_line_support',
  'second_line_support',
] as const

export const STAFF_ROLE_TYPES = [
  ...OFFICE_ROLE_TYPES,
  'workshop',
  'provisioner',
  'read_only',
  'mobile_operative',
] as const

export type RoleLiteral = (typeof STAFF_ROLE_TYPES)[number]

export function isStaffRole(role: string): role is RoleLiteral {
  return STAFF_ROLE_TYPES.includes(role as RoleLiteral)
}
