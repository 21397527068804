import React from 'react'
import { Flex, Box, Card, Touchable } from '@weareberyl/design-system'
import { isArray } from 'lodash'

type TabsProps = {
  children: (React.ReactNode | false)[] | React.ReactNode
}

const Tabs = ({ children }: TabsProps) => {
  // Children may contain falses when `condition && Element` checks are used
  const truthyChildren = isArray(children) ? children.filter(Boolean) : children
  return (
    <Flex>
      {React.Children.map(truthyChildren, (tab, index) => (
        <Box
          flex={1}
          mr={React.Children.count(truthyChildren) === index + 1 ? 0 : 3}
        >
          {tab}
        </Box>
      ))}
    </Flex>
  )
}

type TabProps = {
  onPress?: () => void
  isSelected?: boolean
  children: React.ReactNode
}

const Tab = ({ onPress, isSelected, children }: TabProps) => {
  const deselectedStyles = {
    opacity: 0.5,
  }

  return (
    <Touchable onPress={onPress}>
      <Card
        variant="borderless"
        p={3}
        borderBottomLeftRadius={0}
        borderBottomRightRadius={0}
        {...(!isSelected && deselectedStyles)}
      >
        {children}
      </Card>
    </Touchable>
  )
}

Tabs.Tab = Tab

export default Tabs
