import { Button, Input, List, Select } from 'antd'
import { Heading, Box, Flex } from '@weareberyl/design-system'

import { AssetCategory, JobFiltersInput } from 'gql/generated/graphql'
import AssigneeSelect from '../AssigneeSelect'
import { FC, useState } from 'react'
import { AssetCategoryNames } from '../utils'
import { QueryParams } from 'utils/useQueryParams'

export const parseJobFilters = ({
  role,
  scheme_id,
  assetNameContains,
  assetTag,
  bikeNumber,
  assignee,
  status,
  taskId,
  vehicleType,
  assetCategory,
}: JobFiltersInput) => ({
  ...(assignee?.[0] && { assignee }),
  ...(scheme_id?.[0] && { scheme_id }),
  ...(bikeNumber?.[0] && { bikeNumber }),
  ...(role?.[0] && { role }),
  ...(taskId?.[0] && { taskId }),
  ...(status?.[0] && { status }),
  ...(vehicleType?.[0] && { vehicleType }),
  ...(assetTag?.[0] && { assetTag }),
  ...(assetNameContains && { assetNameContains }),
  ...(assetCategory?.[0] && { assetCategory }),
})

const roleFiltersValues = [
  { label: 'Workshop Mechanic', value: 'Workshop Mechanic' },
  { label: 'On Street Mechanic', value: 'On Street Mechanic' },
  { label: 'Redistribution Driver', value: 'Redistribution Driver' },
  { label: 'Security Team', value: 'Security Team' },
]

const statusFiltersValues = [
  { label: 'New', value: 'new' },
  { label: 'In Progress', value: 'inProgress' },
  { label: 'Complete', value: 'complete' },
  { label: 'Blocked', value: 'blocked' },
]

const fieldNameOptions = [
  { label: 'Assignee', value: 'assignee' },
  { label: 'Role', value: 'role' },
  { label: 'Job Type', value: 'taskId' },
  { label: 'Status', value: 'status' },
  { label: 'Asset Name', value: 'assetNameContains' },
  { label: 'Asset Category', value: 'assetCategory' },
]

const assetCategoryOptions = Object.keys(AssetCategory)
  .filter(
    key =>
      ![AssetCategory.dock, AssetCategory.unknown].includes(AssetCategory[key]),
  )
  .map(key => ({
    label: AssetCategoryNames[key],
    value: key,
  }))

interface IJobFilters extends JobFiltersInput {
  jobOptionsByRole:
    | ((role?: string | null) => { label: string; value: string }[])
    | null
  setQueryParams: (params: QueryParams) => void
  hidden: boolean
  loading: boolean
}

export const JobFilters = ({
  jobOptionsByRole,
  setQueryParams,
  assetNameContains,
  assignee,
  role,
  status,
  taskId,
  assetCategory,
  hidden,
  loading,
}: IJobFilters) => {
  const addFilter = (field: string, value: string | string[]) =>
    setQueryParams({
      [field]: value,
      current: 1,
      ...(field === 'role' && { taskId: [] }),
    })

  const getFilters = () => {
    const baseFilters = Object.entries(
      parseJobFilters({
        role,
        assetNameContains,
        assignee,
        status,
        taskId,
        assetCategory,
      }),
    ).map(([filterField, filterValue]) => ({
      filterField,
      filterValue,
    }))

    return [...baseFilters, { filterField: null, filterValue: null }]
  }

  const filters = getFilters()

  const hasFilter = (fieldName: string) =>
    filters.filter(({ filterField }) => fieldName && filterField === fieldName)
      .length > 0

  const getFieldOptions = (fieldName: string) =>
    Array.from(
      new Set([
        ...fieldNameOptions.filter(
          ({ value }) =>
            !filters.map(({ filterField }) => filterField).includes(value),
        ),
        ...fieldNameOptions.filter(({ value }) => value === fieldName),
      ]),
    )

  interface IFilter {
    value: string
  }

  const AssigneeFilter = ({ value }: IFilter) => (
    <AssigneeSelect
      value={value}
      size="large"
      onChange={userId => addFilter('assignee', [userId])}
    />
  )

  const RoleFilter = ({ value }: IFilter) => (
    <Select
      allowClear
      mode="multiple"
      value={value ?? []}
      options={roleFiltersValues}
      size="large"
      placeholder="Select Roles"
      onChange={roleTypes => {
        addFilter('role', roleTypes)
      }}
    />
  )

  const jobTypesFiltersValues = jobOptionsByRole?.(role?.[0]) ?? []
  const JobTypeFilter = ({ value }: IFilter) => (
    <Select
      allowClear
      mode="multiple"
      value={value ?? []}
      options={jobTypesFiltersValues}
      size="large"
      placeholder="Select Job Types"
      onChange={jobTypes => addFilter('taskId', jobTypes)}
      optionFilterProp="label"
    />
  )

  const StatusFilter = ({ value }: IFilter) => (
    <Select
      allowClear
      value={value}
      options={statusFiltersValues}
      size="large"
      placeholder="Select a Status"
      onChange={statusType => addFilter('status', [statusType])}
    />
  )

  const AssetNameFilter = ({ value }: IFilter) => (
    <Input
      allowClear
      id="modules-search-input"
      placeholder="Search by Asset Name"
      size="large"
      onPressEnter={e =>
        addFilter('assetNameContains', e.currentTarget.value.trim())
      }
      defaultValue={value}
      onChange={e => {
        const val = e.currentTarget.value.trim()
        if (e.type === 'click' && val.length < 1) {
          addFilter('assetNameContains', '')
        }
      }}
    />
  )

  const AssetCategoryFilter = ({ value }: IFilter) => (
    <Select
      allowClear
      value={value}
      options={assetCategoryOptions}
      size="large"
      placeholder="Select an Asset Category"
      onChange={category => addFilter('assetCategory', [category])}
    />
  )

  const FilterComponent = ({ filterName, value }) => {
    const [fieldName, setFieldName] = useState(filterName)

    let Filter: FC<IFilter> = () => null
    switch (fieldName) {
      case 'assignee':
        Filter = AssigneeFilter
        break
      case 'role':
        Filter = RoleFilter
        break
      case 'taskId':
        Filter = JobTypeFilter
        break
      case 'status':
        Filter = StatusFilter
        break
      case 'assetNameContains':
        Filter = AssetNameFilter
        break
      case 'assetCategory':
        Filter = AssetCategoryFilter
        break
      default:
        break
    }

    return (
      <List.Item>
        <Flex flexDirection="row" alignItems="center">
          <Box pr={2} flex={1} style={{ minWidth: 170 }}>
            <Select
              value={fieldName}
              size="large"
              placeholder="Select Field"
              options={getFieldOptions(fieldName)}
              onChange={setFieldName}
              disabled={hasFilter(fieldName)}
            />
          </Box>
          <Box flex={3}>
            <Filter value={value} />
          </Box>
        </Flex>
      </List.Item>
    )
  }

  if (hidden) return null

  return (
    <>
      <List
        loading={loading}
        header={<Heading>Filters</Heading>}
        itemLayout="vertical"
        bordered
        dataSource={filters}
        renderItem={({ filterField, filterValue }) => (
          <FilterComponent filterName={filterField} value={filterValue} />
        )}
        rowKey={({ filterField }) => `filter-component-${filterField}`}
        footer={
          <Button
            danger
            disabled={filters.length === 1}
            onClick={() =>
              setQueryParams({
                assetNameContains: undefined,
                assignee: undefined,
                role: undefined,
                status: undefined,
                taskId: undefined,
                vehicleType: undefined,
                assetCategory: undefined,
              })
            }
          >
            Clear All
          </Button>
        }
      />
      {Boolean(assetNameContains) && (
        <Heading variant="h1" my={2}>
          Search results for: &quot;{assetNameContains}&quot;
        </Heading>
      )}
    </>
  )
}

export default JobFilters
