import React, { useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import {
  Flex,
  Card,
  Heading,
  Text,
  TextLink,
  Box,
  Button,
} from '@weareberyl/design-system'
import { Modal, message } from 'antd'
import { withScheme } from 'components/Scheme'
import { EditableCellInput } from 'components/Table/Editables'
import { CURRENT_USER, SCHEME } from 'types'
import { SchemeAvailabilityRestrictionInput } from '__generated__/globalTypes'
import { formatDatetime } from 'utils'
import {
  useAddCurfew,
  useCurfews,
  useRemoveCurfew,
  useUpdateCurfew,
} from 'hooks/Curfew'
import { hasPermission, withUser } from 'components/Session'

import {
  SchemeAvailabilityRestrictionsDocument,
  SchemePickerQuery_schemes_SchemeConnection_nodes_Scheme,
  SchemeAvailabilityRestrictionsQuery_scheme_Scheme_availability_restriction_SchemeAvailabilityRestrictionConnection_nodes_SchemeAvailabilityRestriction,
} from 'gql/generated/graphql'
import { mapCurfews } from './utils'
import HeadTitle from 'components/HeadTitle'

const confirm = Modal.confirm

type TimeInputProps = {
  label: string
  value: string
  editing: boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>
}

const TimeInput = ({ label, value, editing, onChange }: TimeInputProps) => (
  <Flex alignItems="center" mb={2}>
    <Text style={{ width: 140 }} mr={2}>
      {label}
    </Text>
    <Box width={editing ? 120 : undefined}>
      <EditableCellInput
        isEditing={editing}
        value={value || '-'}
        style={{ textAlign: 'center' }}
        type="time"
        onChange={onChange}
      />
    </Box>
  </Flex>
)

interface IVehicleCurfew {
  scheme: SCHEME
  curfew: Partial<SchemeAvailabilityRestrictionsQuery_scheme_Scheme_availability_restriction_SchemeAvailabilityRestrictionConnection_nodes_SchemeAvailabilityRestriction>
  name: string
}

const VehicleCurfew: (props: IVehicleCurfew) => JSX.Element = withUser(
  ({ scheme, curfew, name, user }: IVehicleCurfew & { user: CURRENT_USER }) => {
    const {
      id: restrictionId,
      vehicle_type,
      frame_type,
      propulsion_type,
      start_time,
      end_time,
      last_status_change_time: lastStatusChangeTime,
    } = curfew

    const hasPermissionsToEditCufew = (
      user?: CURRENT_USER,
      scheme?: SchemePickerQuery_schemes_SchemeConnection_nodes_Scheme,
    ) => {
      if (!user || !scheme) return false
      return hasPermission(
        user.roles,
        'vehicle_curfew.edit',
        parseInt(scheme.id),
      )
    }

    const canEdit = hasPermissionsToEditCufew(user, scheme)

    const [loading, setLoading] = useState(false)
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [editing, setEditing] = useState(false)

    const [addCurfew] = useAddCurfew()
    const [updateCurfew] = useUpdateCurfew()
    const [removeCurfew] = useRemoveCurfew()

    const client = useApolloClient()

    const setTimes = () => {
      setStartTime(start_time ?? '')
      setEndTime(end_time ?? '')
    }

    useEffect(() => {
      setTimes()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [start_time, end_time])

    function toggleEditing() {
      setEditing(value => !value)
    }

    async function onSaveChanges() {
      if (!vehicle_type || !frame_type || !propulsion_type) return
      setLoading(true)

      const restriction: SchemeAvailabilityRestrictionInput = {
        scheme_id: scheme.id,
        vehicle_type,
        frame_type,
        propulsion_type,
        start_time: startTime,
        end_time: endTime,
      }

      try {
        if (!restrictionId) {
          // If no restrictionId, means it doens't exist, so lets add it
          await addCurfew({
            variables: {
              scheme_availability_restriction: restriction,
            },
          })
        } else {
          // A restriction already exists, lets update it
          await updateCurfew({
            variables: {
              id: restrictionId,
              scheme_availability_restriction: restriction,
            },
          })
        }
      } catch (e) {
        message.error(`Error modifying curfew: ${e.message}`, 1000)
        await client.refetchQueries({
          include: [SchemeAvailabilityRestrictionsDocument],
        })
        setTimes()
      } finally {
        setEditing(false)
        setLoading(false)
      }
    }

    async function onRemoveRestriction() {
      setLoading(true)

      if (restrictionId) {
        try {
          await removeCurfew({
            variables: {
              id: restrictionId,
            },
          })
        } catch (e) {
          message.error(`Error removing curfew: ${e.message}`)
        }
      }

      setStartTime('')
      setEndTime('')
      setEditing(false)
      setLoading(false)
    }

    return (
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        flexDirection="column"
        p={2}
      >
        <Flex alignItems="center" mt={3} mb={2}>
          <Heading variant="h2" mb={1}>
            {name}
          </Heading>
          {canEdit && (
            <TextLink onPress={toggleEditing} ml={3}>
              Edit
            </TextLink>
          )}
        </Flex>
        <Flex justifyContent="space-between" alignItems="flex-end" mb={3}>
          <Text>
            Last run:{' '}
            {lastStatusChangeTime ? formatDatetime(lastStatusChangeTime) : '-'}
          </Text>
        </Flex>
        <Flex>
          <Card variant="gray" width={null}>
            <TimeInput
              label="Available from"
              value={endTime}
              editing={editing}
              onChange={e => {
                setEndTime(e.target.value)
              }}
            />
            <TimeInput
              label="Unavailable from"
              value={startTime}
              editing={editing}
              onChange={e => {
                setStartTime(e.target.value)
              }}
            />
            {editing && (
              <Flex mt={2}>
                <Box flex={1} mr={2}>
                  <Button
                    onPress={onSaveChanges}
                    loading={loading}
                    title="Save"
                    disabled={!startTime || !endTime}
                  />
                </Box>
                <Box flex={1} ml={2}>
                  <Button
                    onPress={() => {
                      confirm({
                        zIndex: 1050,
                        title: 'Are you sure?',
                        content:
                          'Please confirm that you would like to remove this curfew.',
                        onOk: onRemoveRestriction,
                        onCancel: undefined,
                      })
                    }}
                    title="Remove"
                    variant="danger"
                    disabled={!restrictionId}
                  />
                </Box>
              </Flex>
            )}
          </Card>
        </Flex>
      </Flex>
    )
  },
)

interface IVehicleCurfews {
  scheme: SCHEME
}

const VehicleCurfews = ({ scheme }: IVehicleCurfews) => {
  const { data, error } = useCurfews(scheme.id)

  if (error) {
    message.error(`Error fetching curfews: ${error.message}`)
    return null
  }

  const curfews = mapCurfews(data)

  return (
    <>
      <HeadTitle pageTitle="Curfews" />

      <Card p={5}>
        <Box>
          <Flex justifyContent="space-between" alignItems="flex-end" mb={4}>
            <Heading variant="callout">Vehicle Curfews</Heading>
          </Flex>
          <Flex alignItems="flex-start">
            {curfews.map(curfew => (
              <VehicleCurfew
                key={`curfewInput:${curfew.name}`}
                curfew={curfew}
                scheme={scheme}
                name={curfew.name}
              />
            ))}
          </Flex>
        </Box>
      </Card>
    </>
  )
}

export default withScheme(VehicleCurfews)
