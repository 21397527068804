import React from 'react'
import ServiceAreaLegend, { ServiceAreaLegendProps } from './ServiceArea'
import ZoneLegend, { ZoneLegendProps } from './Zone'
import ModuleLegend, { ModuleLegendProps } from './Module'
import JobLegend, { JobLegendProps } from './Job'

export { ServiceAreaLegend, ZoneLegend, ModuleLegend, JobLegend }
export type LegendType = 'ServiceArea' | 'Zone' | 'Module' | 'Job'

// have to use Omit<onClose> because of: https://github.com/microsoft/TypeScript/issues/31501
export type LegendProps =
  | ({ type: 'Zone' } & Omit<ZoneLegendProps, 'onClose'>)
  | ({ type: 'Module' } & Omit<ModuleLegendProps, 'onClose'>)
  | ({
      type: 'ServiceArea'
    } & Omit<ServiceAreaLegendProps, 'onClose'>)
  | ({
      type: 'Job'
    } & Omit<JobLegendProps, 'onClose'>)
  | { type: null }

const Legend = (props: LegendProps & { onClose: () => void }) => {
  switch (props.type) {
    case 'ServiceArea': {
      return <ServiceAreaLegend {...props} />
    }
    case 'Zone': {
      return <ZoneLegend {...props} />
    }
    case 'Module': {
      return <ModuleLegend {...props} />
    }
    case 'Job': {
      return <JobLegend {...props} />
    }
    default: {
      return null
    }
  }
}

export default Legend
