import React from 'react'
import { Link } from 'react-router-dom'

import * as routes from 'constants/routes'

type Props = {
  id: string
  basePath: string
  schemeId?: string
  elementId?: string
}

const AssetLink: React.FC<Props> = ({
  basePath,
  id,
  schemeId,
  elementId,
  children,
}) => {
  // In case the url pattern is passed, e.g. /promotions/:id
  // remove the param placeholder to build the patht with just the root e.g. /promotions/{add id now}
  const root = basePath.split('/:')[0]
  const assetPath = [root, id].join('/')
  const schemeAssetPath = schemeId
    ? routes.prependScheme(assetPath, schemeId)
    : assetPath

  return (
    <Link to={schemeAssetPath} id={elementId}>
      {children || id}
    </Link>
  )
}

export default AssetLink
