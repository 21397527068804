import { ApolloError } from '@apollo/client'
import { Button, message } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import {
  CommissionVehicleMutationVariables,
  ModuleVehicleCommissionedDocument,
  useCommissionVehicleMutation,
  useDecommissionVehicleMutation,
  useMoveSchemeMutation,
} from 'gql/generated/graphql'

const onError = (err: ApolloError) => message.error(err.message)

type CommissionVehicleProps = Partial<CommissionVehicleMutationVariables> & {
  onCompleted?: () => void
}

export const CommissionVehicle = ({
  module_id,
  frame_id,
  scheme_id,
  lock_configuration,
  frame_type,
  vehicle_unlock_id,
  onCompleted,
}: CommissionVehicleProps) => {
  const [executeCommission, { loading }] = useCommissionVehicleMutation({
    onError,
    onCompleted,
    refetchQueries: [
      { query: ModuleVehicleCommissionedDocument, variables: { module_id } },
    ],
  })

  const valid =
    module_id && frame_id && scheme_id && lock_configuration && frame_type
  const onCommission = () => {
    if (!valid) {
      return
    }
    executeCommission({
      variables: {
        module_id,
        frame_id,
        scheme_id,
        lock_configuration,
        frame_type,
        vehicle_unlock_id,
      },
    })
  }

  return (
    <Button
      disabled={!valid}
      type="primary"
      loading={loading}
      onClick={onCommission}
    >
      Commission
    </Button>
  )
}

type DecommissionVehicleProps = {
  module_id: string
  beforeCompleted?: () => void
  onCompleted?: () => void
}

export const DecommissionVehicle = ({
  module_id,
  beforeCompleted,
  onCompleted,
}: DecommissionVehicleProps) => {
  const [executeDecommission, { loading }] = useDecommissionVehicleMutation({
    variables: {
      module_id,
    },
    onError,
    ...(onCompleted && { onCompleted }),
    refetchQueries: [
      { query: ModuleVehicleCommissionedDocument, variables: { module_id } },
    ],
  })

  const onDecommission = () => {
    beforeCompleted?.()
    executeDecommission()
  }

  return (
    <Button ghost type="primary" loading={loading} onClick={onDecommission}>
      Decommission
    </Button>
  )
}

type MoveSchemeProps = {
  module_id: string
  scheme_id?: string | null
  onCompleted?: () => void
  disabled?: boolean
} & ButtonProps

export const MoveScheme = ({
  module_id,
  scheme_id,
  onCompleted,
  disabled,
  ...props
}: MoveSchemeProps) => {
  const [executeMoveScheme, { loading }] = useMoveSchemeMutation({
    onError,
    onCompleted,
    refetchQueries: [
      { query: ModuleVehicleCommissionedDocument, variables: { module_id } },
    ],
  })
  const onMoveScheme = () => {
    if (!scheme_id) {
      return
    }
    executeMoveScheme({
      variables: {
        module_id,
        scheme_id,
      },
    })
  }
  return (
    <Button
      ghost
      type="primary"
      loading={loading}
      disabled={disabled}
      onClick={onMoveScheme}
      {...props}
    >
      Move Scheme
    </Button>
  )
}
