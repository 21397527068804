import Query from 'components/Query'
import { gql } from '@apollo/client'
import { withScheme } from 'components/Scheme'
import Table from 'components/Table'
import { useQueryParams } from 'utils/useQueryParams'
import RemoveWhitelist from './RemoveWhitelist'
import { startCase } from 'lodash'

import Form from './Form'
import { Center } from 'components/Layout'

import { SCHEME } from 'types'
import Tag from 'components/shared/Tag'
import { Flex } from '@weareberyl/design-system'
import HeadTitle from 'components/HeadTitle'

const columns = [
  {
    title: 'ID',
    sorter: true,
    sortFunction: direction => ({
      id: direction,
    }),
    dataIndex: 'id',
  },
  {
    title: 'Pattern',
    dataIndex: 'pattern',
    render: pattern => <code>{pattern}</code>,
  },
  {
    title: 'Role Type',
    dataIndex: 'role_type',
    render: roleType => (
      <Flex>
        <Tag
          closable={false}
          label={startCase(roleType)}
          labelVariant="micro"
        />
      </Flex>
    ),
  },
  {
    title: 'Actions',
    render: ({ id }) => <RemoveWhitelist whitelist_id={id} />,
  },
]

const WHITELIST_QUERY = gql`
  query WHITELIST_LIST(
    $scheme_id: ID!
    $paginate: Paginate
    $order_by: [WhitelistOrderby]
  ) {
    table: whitelists(
      scheme_id: $scheme_id
      paginate: $paginate
      order_by: $order_by
    ) {
      nodes {
        id
        pattern
        role_type
      }
      pagination {
        current: page
        pageSize: per_page
        total
      }
    }
  }
`

const id = 'user-whitelist-table'
const Whitelist = ({ scheme }: { scheme: SCHEME }) => {
  const [{ current, pageSize }, setQueryParams] = useQueryParams(id)
  return (
    <>
      <HeadTitle pageTitle="Whitelists" />
      <Center>
        <Form scheme={scheme} />
        <Query
          query={WHITELIST_QUERY}
          variables={{
            scheme_id: scheme.id,
            order_by: [{ id: 'desc' }],
            paginate: {
              page: current,
              per_page: pageSize,
            },
          }}
          pollInterval={0}
          waitFor={'data.table.nodes'}
        >
          {props => (
            <Table
              id={id}
              columns={columns}
              onChange={({ current, pageSize }) =>
                setQueryParams({ current, pageSize })
              }
              {...props}
            />
          )}
        </Query>
      </Center>
    </>
  )
}

export default withScheme(Whitelist)
