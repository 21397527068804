import { useState } from 'react'
import { gql, ApolloQueryResult, useMutation } from '@apollo/client'
import { Button, Menu, message, Modal, Radio } from 'antd'

import { Box, Flex, Text } from '@weareberyl/design-system'

import Query from 'components/Query'
import { withUser } from 'components/Session'
import { withScheme } from 'components/Scheme'
import { REWARD_TYPES } from 'constants/domain'
import { USER_PRODUCT_LIST_QUERY } from '../Products'

import type {
  SCHEME_PRODUCTS_BUNDLES__DASHBOARD,
  SCHEME_PRODUCTS_BUNDLES__DASHBOARD_all_scheme_products_nodes as Product,
} from './__generated__/SCHEME_PRODUCTS_BUNDLES__DASHBOARD'
import type {
  ADD_FREE_USER_PRODUCT__DASHBOARD,
  ADD_FREE_USER_PRODUCT__DASHBOARDVariables,
} from './__generated__/ADD_FREE_USER_PRODUCT__DASHBOARD'
import { ProductType, VehicleRewardType } from '__generated__/globalTypes'

export const ADD_FREE_USER_PRODUCT = gql`
  mutation ADD_FREE_USER_PRODUCT__DASHBOARD(
    $userId: ID!
    $schemeProductId: ID!
    $reward: VehicleRewardType
  ) {
    add_user_product(
      user_id: $userId
      scheme_product_id: $schemeProductId
      voucher_purchase: true
      reward: $reward
    ) {
      id
      created_at
      reward {
        title
      }
      scheme_product {
        id
        scheme_id
        product_type
        name
        amount
        quantity
      }
    }
  }
`

type AddProductProps = {
  userId: string
  schemeId: string
  product: Product
}
export const AddProduct = ({ userId, schemeId, product }: AddProductProps) => {
  const [reward, setReward] = useState<VehicleRewardType | null>(
    () => product.fulfill_rewards[0] ?? null,
  )
  const [modalOpen, setModalOpen] = useState(false)
  const [addProduct, { loading }] = useMutation<
    ADD_FREE_USER_PRODUCT__DASHBOARD,
    ADD_FREE_USER_PRODUCT__DASHBOARDVariables
  >(ADD_FREE_USER_PRODUCT, {
    refetchQueries: [
      {
        query: USER_PRODUCT_LIST_QUERY,
        variables: { user_id: userId, scheme_id: schemeId },
      },
    ],
    onCompleted: () => message.info('Free product added!'),
    onError: err => {
      message.error(`Something went wrong: ${err.message}`)
    },
  })

  const onOk = async () => {
    await addProduct({
      variables: {
        userId,
        reward,
        schemeProductId: product.id,
      },
    })
    setModalOpen(false)
  }

  const rewardText = reward
    ? `reward for: ${REWARD_TYPES[reward]}`
    : 'free gift'

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        borderBottomWidth={1}
        borderBottomColor="silver"
        my={1}
        pb={1}
      >
        <Box mr={1}>
          <Text>{product.name}</Text>
          <Text variant="micro">{product.product_type}</Text>
        </Box>
        <Box>
          <Button onClick={() => setModalOpen(true)}>Add</Button>
        </Box>
      </Flex>
      <Modal
        title={`Add ${product.name} as a ${rewardText}`}
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        zIndex={1500}
        onOk={onOk}
        confirmLoading={loading}
        destroyOnClose
      >
        {product.fulfill_rewards.length > 0 && (
          <Box mb={2}>
            <p>
              If the product is being awarded as a bonus, please select a reward
              below:
            </p>
            <Radio.Group
              value={reward}
              onChange={event => setReward(event.target.value)}
            >
              {product.fulfill_rewards.map(rewardType => (
                <Box mb={1} key={rewardType}>
                  <Radio value={rewardType}>{REWARD_TYPES[rewardType]}</Radio>
                </Box>
              ))}
              <Radio value={null}>No reward</Radio>
            </Radio.Group>
          </Box>
        )}

        <p>
          Please confirm that you want to gift <strong>{product.name}</strong>{' '}
          as a <strong>{rewardText}</strong>
        </p>
      </Modal>
    </>
  )
}

// TODO: it would be nicer to only query scheme products available for the current user and also to
// filter for only bundles, but this is the best we have for now...
export const SCHEME_PRODUCTS_BUNDLES_QUERY = gql`
  query SCHEME_PRODUCTS_BUNDLES__DASHBOARD($scheme_id: ID!) {
    all_scheme_products(scheme_id: $scheme_id) {
      nodes {
        id
        name
        product_type
        is_active
        quantity
        is_protected
        role_whitelist
        fulfill_rewards
      }
    }
  }
`

export const FreeProductActions = withScheme(
  withUser(({ userId, scheme }) => {
    const giftable_product_types = [ProductType.bundle, ProductType.period_pass]
    return (
      <Query
        waitFor="data.all_scheme_products.nodes"
        query={SCHEME_PRODUCTS_BUNDLES_QUERY}
        variables={{ scheme_id: scheme.id }}
        pollInterval={0}
      >
        {({ data }: ApolloQueryResult<SCHEME_PRODUCTS_BUNDLES__DASHBOARD>) => {
          // Filter protected product still
          // but querying `all_scheme_products` so that is_discoverable=false can be included for gifting
          const products = data.all_scheme_products?.nodes
            .filter(p => giftable_product_types.includes(p.product_type))
            .filter(p => !p.is_protected)
          if (!products) {
            return null
          }
          return (
            <Menu>
              {products.map(product => (
                <Menu.Item key={product.id}>
                  <AddProduct
                    userId={userId}
                    schemeId={scheme.id}
                    product={product}
                  />
                </Menu.Item>
              ))}
            </Menu>
          )
        }}
      </Query>
    )
  }),
)
