import { gql, useMutation } from '@apollo/client'
import * as types from './__generated__/requestVehicleMissing'

const query = gql`
  mutation requestVehicleMissing($vehicleId: GUID!) {
    vehicle_missing(vehicle_id: $vehicleId)
  }
`

export default function useMissing(
  variables?: types.requestVehicleMissingVariables,
) {
  return useMutation<
    types.requestVehicleMissing,
    types.requestVehicleMissingVariables
  >(query, {
    variables,
    context: {
      useApolloNetworkStatus: false,
    },
  })
}
