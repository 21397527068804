import React, { useEffect } from 'react'
import { Route, Redirect, RouteProps, useRouteMatch } from 'react-router-dom'

import * as routes from 'constants/routes'
import useCurrentScheme from 'hooks/useCurrentScheme'

interface SchemeRouteProps {
  path: RouteProps['path']
}

/**
 * Checks the scheme_id from the route and saves it if it's changed
 */
export const SchemePersistRoute: React.FC<SchemeRouteProps> = ({ path }) => {
  const match = useRouteMatch<{ scheme_id: string | undefined }>({ path })
  const scheme_id = match?.params?.scheme_id

  const { setCurrentSchemeId, currentSchemeId } = useCurrentScheme()

  useEffect(() => {
    if (!scheme_id || scheme_id === currentSchemeId) return
    setCurrentSchemeId(scheme_id)
  }, [scheme_id, setCurrentSchemeId, currentSchemeId])

  return null
}

/**
 * Redirects to scheme picker route if there is no locally saved scheme
 */
export const SchemeRedirect: React.FC<SchemeRouteProps> = ({ path }) => {
  const { currentSchemeId } = useCurrentScheme()
  return (
    <Route
      exact
      path={path}
      render={() => {
        if (currentSchemeId) {
          return (
            <Redirect to={routes.prependScheme(routes.MAP, currentSchemeId)} />
          )
        } else {
          return <Redirect to={routes.SCHEMES} />
        }
      }}
    />
  )
}
