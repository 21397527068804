import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { message } from 'antd'
import { UserSummaryDocument } from 'gql/generated/graphql'

const REMOVE_USER_PHONE_NUMBER = gql`
  mutation REMOVE_USER_PHONE_NUMBER($user_id: ID!) {
    remove_user_phone_number(user_id: $user_id)
  }
`

type Props = {
  user_id: string
}

const RemoveUserPhoneNumber = ({ user_id }: Props) => {
  return (
    <Mutation
      mutation={REMOVE_USER_PHONE_NUMBER}
      onCompleted={() => message.info("User's phone number removed!")}
      refetchQueries={[UserSummaryDocument]}
      onError={err => {
        message.error(`Something went wrong: ${err.message}`)
      }}
    >
      {removePhoneNumber => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a onClick={() => removePhoneNumber({ variables: { user_id } })}>
          Delete phone number
        </a>
      )}
    </Mutation>
  )
}

export default RemoveUserPhoneNumber
