import { RefetchFunction } from '@apollo/client/react/hooks/useSuspenseQuery'
import { Text, colors } from '@weareberyl/design-system'
import { Button, Popover, Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { renderCreatedByFromJustifications } from 'components/Job/List/columns'
import {
  ArchiveJob,
  ArchiveJobListQuery,
  ArchiveJobListQueryVariables,
  useUnarchiveJobMutation,
} from 'gql/generated/graphql'
import { useHistory } from 'react-router-dom'
import { formatDatetime } from 'utils'

interface IRestoreButton {
  archiveJob: ArchiveJob
  refetch: RefetchFunction<ArchiveJobListQuery, ArchiveJobListQueryVariables>
}

const RestoreButton = ({ archiveJob, refetch }: IRestoreButton) => {
  const [unarchiveJob, { loading }] = useUnarchiveJobMutation()
  const history = useHistory()

  const onUnarchive = async (sys_id: string, archiveJob: ArchiveJob) => {
    await unarchiveJob({ variables: { sys_id } })
    history.push(
      `/scheme/${archiveJob.details.scheme?.id}/job/${archiveJob.uuid}`,
    )
    refetch?.()
  }
  return (
    <Button
      disabled={loading}
      loading={loading}
      onClick={() => onUnarchive(archiveJob.sys_id, archiveJob)}
      danger
    >
      Restore
    </Button>
  )
}

const Notes = ({ text }: { text: string }) => {
  if (!text) return null

  let shortened = text.substring(0, 20)
  if (text !== shortened) {
    shortened += '...'
  }
  return (
    <Popover
      content={text}
      trigger="click"
      placement="bottom"
      color={colors.wheat}
    >
      <Tag color="gold">
        <Text>{shortened}</Text>
      </Tag>
    </Popover>
  )
}

interface IColumns {
  refetch: RefetchFunction<ArchiveJobListQuery, ArchiveJobListQueryVariables>
  jobCodeLookup: {
    [k: string]: string
  } | null
}

export const columns = ({
  refetch,
  jobCodeLookup,
}: IColumns): ColumnsType<ArchiveJob> => {
  return [
    {
      dataIndex: ['details', 'isJustified'],
      key: 'isJustified',
      align: 'center',
      render: renderCreatedByFromJustifications,
    },
    {
      title: 'Id',
      dataIndex: 'uuid',
      key: 'id',
      render: (uuid: string) => <Text>{uuid.substring(32)}</Text>,
    },
    {
      title: 'Description',
      dataIndex: ['details', 'taskId'],
      key: 'description',
      render: (text: string) => <Text>{jobCodeLookup?.[text]}</Text>,
    },
    {
      title: 'Scheme',
      dataIndex: ['details', 'scheme', 'name'],
      key: 'scheme_name',
    },
    {
      title: 'Assignee',
      dataIndex: ['details', 'assignee', 'email'],
      key: 'assignee',
    },
    {
      title: 'Status',
      dataIndex: ['details', 'status'],
      key: 'status',
    },
    {
      title: 'Notes',
      dataIndex: ['details', 'notes'],
      key: 'details.notes',
      render: text => <Notes text={text} />,
    },
    {
      title: 'Date Raised',
      key: 'timestamps.created_at',
      dataIndex: ['timestamps', 'created_at'],
      render: t => formatDatetime(t),
    },
    {
      title: 'Date Updated',
      key: 'timestamps.updated_at',
      dataIndex: ['timestamps', 'updated_at'],
      render: t => formatDatetime(t),
    },
    {
      key: 'sys_id',
      dataIndex: ['sys_id'],
      fixed: 'right',
      render: (_, archiveJob) => (
        <RestoreButton archiveJob={archiveJob} refetch={refetch} />
      ),
    },
  ]
}
