import {
  CurrencyPaymentProviderType,
  TransactionType,
} from '../../__generated__/globalTypes'

enum InvoiceTypes {
  JourneyInvoice = 'Journey',
  ProductInvoice = 'Product',
}

export const formatInvoiceType = (type: string): string => {
  return InvoiceTypes[type]
}

export const formatTransactionType = (type: string): string => {
  return TransactionType[type]
}

export const readableProviderType = (
  type: CurrencyPaymentProviderType,
): string => {
  return {
    [CurrencyPaymentProviderType.swift_customer]: 'Swift',
    [CurrencyPaymentProviderType.stripe_customer]: 'Stripe',
    [CurrencyPaymentProviderType.trafi_customer]: 'Trafi',
  }[type]
}
