import React, { useEffect, useState } from 'react'
import { Card, Flex, Heading } from '@weareberyl/design-system'
import { message, Modal } from 'antd'
import AutomationModal from './Modal/AutomationModal'
import { JobAutomationSideEffect } from '__generated__/globalTypes'
import Automation from './Automation/Automation'
import { isNotNullOrUndefined } from 'utils/types'
import HeadTitle from 'components/HeadTitle'
import {
  TriggerJobAutomationMutationVariables,
  useJobAutomationsQuery,
  useTriggerJobAutomationMutation,
} from 'gql/generated/graphql'
import { ExclamationCircleFilled } from '@ant-design/icons'

export const DASHBOARD_JUSTIFICATION_PREFIX = 'ui'
const POLL_TIMEOUT = 5 * 60 * 1000 // 5 minutes

const Automations = () => {
  const variables = {
    filters: {
      prefix: DASHBOARD_JUSTIFICATION_PREFIX,
    },
  }
  const [shouldPoll, setShouldPoll] = useState(false)
  const [expectedJobsMap, setExpectedJobsMap] = useState<Map<string, string[]>>(
    new Map(),
  )
  const [triggerJobAutomationFlow] = useTriggerJobAutomationMutation({})
  const removeExpectedJob = (justification_id: string) => {
    setExpectedJobsMap(current => {
      current.delete(justification_id)
      return current
    })
  }
  const setExpectedJobs = (
    justification_id: string,
    expectedJobs: string[],
  ) => {
    setExpectedJobsMap(current => {
      current.set(justification_id, expectedJobs)
      return current
    })
    setShouldPoll(true)
    setTimeout(() => setShouldPoll(false), POLL_TIMEOUT)
  }

  const runFlow = (
    variables: TriggerJobAutomationMutationVariables,
    text: string,
  ) => {
    const onOk = async variables => {
      try {
        await triggerJobAutomationFlow({ variables })
        setExpectedJobs(variables.justification_id, variables.assets)
      } catch (e) {
        message.error(e)
      }
    }

    Modal.confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleFilled />,
      content: text,
      onOk: () => onOk(variables),
      okText: 'Confirm',
      style: { top: 200 },
      onCancel: undefined,
    })
  }

  const pollInterval = shouldPoll ? 10000 : 0

  const { loading, error, data } = useJobAutomationsQuery({
    variables,
    pollInterval,
  })

  useEffect(() => {
    if (!shouldPoll) return

    const expectedEmptyKeys = Array.from(expectedJobsMap.keys()).filter(
      key => expectedJobsMap.get(key)?.length === 0,
    )
    const actualJobKeys = data?.automations
      ?.filter(isNotNullOrUndefined)
      .map(({ justification_id }) => justification_id)

    for (const key of expectedEmptyKeys) {
      if (!actualJobKeys?.includes(key)) {
        removeExpectedJob(key)
        setShouldPoll(false)
      }
    }
  }, [data, expectedJobsMap, shouldPoll])

  if (loading) {
    return null
  }

  if (error) {
    message.error(error)
    return null
  }

  const AutomationHeader = () => (
    <Flex justifyContent="space-between" alignItems="flex-end" mb={5}>
      <Heading variant="callout">Automations</Heading>
      <AutomationModal
        setExpectedJobs={setExpectedJobs}
        runFlow={runFlow}
        disabled={shouldPoll}
        loading={shouldPoll}
      />
    </Flex>
  )

  return (
    <>
      <HeadTitle pageTitle="Automations" />

      <Card p={5}>
        <AutomationHeader />
        {data?.automations
          ?.filter(isNotNullOrUndefined)
          .map(({ side_effects, jobs, ...props }) => (
            <Automation
              key={props.justification_id}
              expectedJobs={expectedJobsMap.get(props.justification_id) ?? []}
              setExpectedJobs={setExpectedJobs}
              side_effects={side_effects as JobAutomationSideEffect[]}
              runFlow={runFlow}
              shouldPoll={shouldPoll}
              setShouldPoll={setShouldPoll}
              jobs={jobs.filter(isNotNullOrUndefined)}
              {...props}
            />
          ))}
      </Card>
    </>
  )
}

export default Automations
