import type { ColumnProps } from 'antd/lib/table'
import { isNaN } from 'lodash'
import { format, parseISO } from 'date-fns'
import { Box, Flex, useThemeColors } from '@weareberyl/design-system'

import Table from 'components/Table'
import {
  ValidationRulesItem,
  defaultData,
  UseListValidationRules,
  ValidationCriterion,
} from './data'
import Copy from 'components/Copy'
import ExternalLink from 'components/Navigation/ExternalLink'

type Props = UseListValidationRules

const voucherifyUrl = (id: string) =>
  `https://app.voucherify.io/#/app/core/validation-rules/${id}/details`

const editOnVoucherify = (id: string) => {
  window.open(voucherifyUrl(id), '_blank')
}

const columns: ColumnProps<ValidationRulesItem>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: name => {
      return <strong>{name}</strong>
    },
  },
  {
    title: 'ID',
    dataIndex: 'id',
    render: value => {
      return <Copy id="copy" text={value} />
    },
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    render: value => {
      return format(parseISO(value), 'dd/MM/y HH:mm:ss')
    },
  },
  {
    title: 'Action',
    dataIndex: '',
    key: 'x',
    render: (record: ValidationRulesItem) => (
      <ExternalLink onPress={() => editOnVoucherify(record.id)}>
        Edit
      </ExternalLink>
    ),
  },
]

const Item = ({ property, operator, value }) => {
  const { colors } = useThemeColors()
  return (
    <Box
      style={{
        display: 'inline-flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        gap: '1ex',
      }}
      borderRadius={10}
      color={colors.lakePressed}
      borderColor={colors.lake}
      borderWidth={2}
      py={2}
      px={3}
    >
      <>
        <strong>{property}</strong> <i>{operator}</i> <strong>{value}</strong>
      </>
    </Box>
  )
}

const isDate = (value: string) => {
  // See if a string can be parsed into a date or datetime
  return parseISO(value).getFullYear()
}

const RuleListItem = ({ item }: { item: ValidationCriterion }) => {
  switch (item.conditions.__typename) {
    case 'ValidationConditionIn':
      return (
        <Item
          property={item.property}
          operator="IN"
          value={`[${item.conditions.in.join(', ')}]`}
        />
      )
    case 'ValidationConditionIs':
      return (
        <Item
          property={item.property}
          operator={isDate(item.conditions.is[0]) ? 'is on' : 'is'}
          value={item.conditions.is}
        />
      )
    case 'ValidationConditionLessThan':
      return (
        <Item
          property={item.property}
          operator={
            isDate(item.conditions.less_than[0]) ? 'is before' : 'is less than'
          }
          value={item.conditions.less_than}
        />
      )
    case 'ValidationConditionMoreThan':
      return (
        <Item
          property={item.property}
          operator={
            isDate(item.conditions.more_than[0]) ? 'is after' : 'is more than'
          }
          value={item.conditions.more_than}
        />
      )
    case 'ValidationConditionContains':
      return (
        <Item
          property={item.property}
          operator="contains"
          value={item.conditions.contains}
        />
      )
    case 'ValidationConditionStartsWith':
      return (
        <Item
          property={item.property}
          operator="starts with"
          value={item.conditions.starts_with}
        />
      )
    case 'ValidationConditionEndsWith':
      return (
        <Item
          property={item.property}
          operator="ends with"
          value={item.conditions.ends_with}
        />
      )
    case 'ValidationConditionTimeframe':
      return (
        <Item
          property={item.property}
          operator="within"
          value={item.conditions.timeframe}
        />
      )
    case 'ValidationConditionTimeframeAbsolute':
      return (
        <Item
          property={item.property}
          operator="within (absolute)"
          value={item.conditions.timeframe_absolute}
        />
      )
    default:
      return null
  }
}

const RenderLogic = ({ record }: { record: ValidationRulesItem }) => {
  const tokens = record.logic.split(/(\d+)/)
  return (
    <Flex style={{ gap: '1ex' }} alignItems="center">
      {tokens.map((token, index) => {
        if (!isNaN(parseInt(token))) {
          const item = record.rules_list[parseInt(token) - 1]
          if (!item) {
            return null
          }
          // skipcq: JS-0437 No stable ID index is next best thing for non sortable static data
          return <RuleListItem key={`${item.name}-${index}`} item={item} />
        }

        // skipcq: JS-0437 No stable ID index is next best thing for non sortable static data
        return <strong key={`${token}-${index}`}>{token}</strong>
      })}
    </Flex>
  )
}

const ValidationRulesTable = ({ data, loading }: Props) => {
  // Provide a skeleton response structure
  // so the table will render with the loading state before data is loaded
  const tableData = data ?? defaultData

  return (
    <Table<ValidationRulesItem>
      id="promotions"
      columns={columns}
      rowKey={'id'}
      data={tableData}
      size="small"
      loading={loading}
      expandable={{
        expandedRowRender: record => (
          <Box pt={2} px={2}>
            <RenderLogic record={record} />
          </Box>
        ),
        expandRowByClick: true,
      }}
    />
  )
}

export default ValidationRulesTable
