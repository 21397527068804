import { useState } from 'react'
import { gql, useApolloClient, useMutation } from '@apollo/client'
import {
  Flex,
  Box,
  Button,
  Heading,
  Text,
  Icon,
  OptionSelect,
} from '@weareberyl/design-system'
import { Modal, message, Checkbox, Popover } from 'antd'

import { AssistConfig, ModuleHardware } from '__generated__/globalTypes'

export const END_JOURNEY = gql`
  mutation END_JOURNEY(
    $id: ID!
    $with_safe_lock: Boolean
    $use_movement_check: Boolean
    $includeAssist: Boolean!
    $moduleId: ID!
    $assist_config: AssistConfig!
  ) {
    end_journey(
      journey_id: $id
      with_safe_lock: $with_safe_lock
      use_movement_check: $use_movement_check
    ) {
      id
      is_complete
      state {
        id
        message
        time
      }
    }
    set_assist_config(module_id: $moduleId, assist_config: $assist_config)
      @include(if: $includeAssist)
  }
`

export const CANCEL_JOURNEY = gql`
  mutation CANCEL_JOURNEY(
    $id: ID!
    $with_safe_lock: Boolean
    $includeAssist: Boolean!
    $use_movement_check: Boolean
    $moduleId: ID!
    $assist_config: AssistConfig!
  ) {
    cancel_journey(
      journey_id: $id
      with_safe_lock: $with_safe_lock
      use_movement_check: $use_movement_check
    ) {
      id
      is_complete
      state {
        id
        message
        time
      }
    }
    set_assist_config(module_id: $moduleId, assist_config: $assist_config)
      @include(if: $includeAssist)
  }
`

export const VOID_JOURNEY = gql`
  mutation VOID_JOURNEY(
    $id: ID!
    $with_safe_lock: Boolean
    $use_movement_check: Boolean
    $includeAssist: Boolean!
    $moduleId: ID!
    $assist_config: AssistConfig!
  ) {
    void_journey(
      journey_id: $id
      with_safe_lock: $with_safe_lock
      use_movement_check: $use_movement_check
    ) {
      id
      is_complete
      state {
        id
        message
        time
      }
      is_error
    }
    set_assist_config(module_id: $moduleId, assist_config: $assist_config)
      @include(if: $includeAssist)
  }
`

enum JourneyEndType {
  end = 'end',
  cancel = 'cancel',
  void = 'void',
}

const actionInfoText: { [key in JourneyEndType]: string } = {
  end: 'This will calculate the journey cost and attempt to charge as normal.',
  cancel:
    "This will only charge the user a flat fee of £10. (£30 in Manchester or WM if it's a scooter).",
  void: 'This will void the journey and no charges will be generated.',
}

interface IHoverText {
  text: string
  children: React.ReactNode
  disabled?: boolean
}

const HoverText = ({ text, disabled, children }: IHoverText) => {
  // skipcq JS-0424
  if (disabled) return <>{children}</>
  return (
    <Popover content={text} placement="bottom">
      <Box>{children}</Box>
    </Popover>
  )
}

type EndProps = {
  id: string
  moduleId: string
  currentAssistConfig: AssistConfig | null
  hardwareType: ModuleHardware
  onHold?: boolean
}

export const End = ({
  id,
  moduleId,
  currentAssistConfig,
  hardwareType,
  onHold = false,
}: EndProps) => {
  const apolloClient = useApolloClient()
  const assistSetToNormal =
    currentAssistConfig &&
    currentAssistConfig === AssistConfig.ASSIST_CONFIG_NORMAL &&
    hardwareType === ModuleHardware.bbe

  const [visible, setVisible] = useState(false)
  const [selectedAction, setSelectedAction] = useState(JourneyEndType.end)
  const [checked, setChecked] = useState<{ [key in JourneyEndType]: boolean }>({
    end: true,
    cancel: true,
    void: true,
  })
  const [showingConfirmation, setShowingConfirmation] = useState(false)
  const [assistText, setAssistText] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const hideModal = () => {
    setVisible(false)
    setSelectedAction(JourneyEndType.end)
    setShowingConfirmation(false)
  }

  const onError = () => message.error('Something went wrong')

  const [endJourney] = useMutation(END_JOURNEY, {
    onCompleted: () => {
      message.info(`Journey ended ${assistText}`)
      hideModal()
    },
    onError,
  })

  const [cancelJourney] = useMutation(CANCEL_JOURNEY, {
    onCompleted: () => {
      message.info(`Journey cancelled ${assistText}`)
      hideModal()
    },
    onError,
  })

  const [voidJourney] = useMutation(VOID_JOURNEY, {
    onCompleted: () => {
      message.info(`Journey voided ${assistText}`)
      hideModal()
    },
    onError,
  })

  const actionFunction: { [key in JourneyEndType]: (arg) => void } = {
    end: endJourney,
    cancel: cancelJourney,
    void: voidJourney,
  }

  const selectOption = (action: JourneyEndType) => {
    setShowingConfirmation(false)
    setSelectedAction(action)
  }

  // Executes end journey mutations
  const buttonAction = (disableSelected: boolean) => {
    if (showingConfirmation) {
      setButtonDisabled(true)
      if (disableSelected) {
        setAssistText('and assist set to disabled')
      }

      const variables = {
        id,
        with_safe_lock: checked[selectedAction],
        use_movement_check: false,
        includeAssist: disableSelected,
        moduleId,
        assist_config: AssistConfig.ASSIST_CONFIG_DISABLE,
      }

      actionFunction[selectedAction]({
        variables,
      })
    } else {
      setShowingConfirmation(true)
    }
    return
  }

  const onChange = () => {
    setChecked({ ...checked, [selectedAction]: !checked[selectedAction] })
  }

  const JourneyEndMethodSelector = () => (
    <Flex justifyContent="space-between" mt={3}>
      <OptionSelect
        title="As normal"
        selected={selectedAction === JourneyEndType.end}
        onPress={() => selectOption(JourneyEndType.end)}
      />
      <HoverText
        text="⚠️ Journey cannot be cancelled when on hold"
        disabled={!onHold}
      >
        <OptionSelect
          title="With penalty charge"
          selected={selectedAction === JourneyEndType.cancel}
          onPress={() => selectOption(JourneyEndType.cancel)}
          disabled={onHold}
        />
      </HoverText>
      <HoverText
        text="⚠️ Journey cannot be voided when on hold"
        disabled={!onHold}
      >
        <OptionSelect
          title="Void journey"
          selected={selectedAction === JourneyEndType.void}
          onPress={() => selectOption(JourneyEndType.void)}
          disabled={onHold}
        />
      </HoverText>
    </Flex>
  )

  const JourneyEndMethodInformaton = () => (
    <Flex mb={4}>
      <Icon
        color="grape"
        height={14}
        width={14}
        resizeMode="contain"
        type="information"
        mt={1}
        mr={2}
      />
      <Box flex={1}>
        <Text variant="small" color="grape">
          {actionInfoText[selectedAction]}
        </Text>
      </Box>
    </Flex>
  )

  const ConfirmEndJourneyText = () => (
    <Text
      variant="small"
      pr={3}
      className={`EndJourneyConfirmation-text'-visible'`}
      style={{ opacity: 1 }}
    >
      Are you sure?
    </Text>
  )

  const ConfirmEndJourneyButton = () => (
    <Button
      testID="end-journey-confirmation-btn"
      title={showingConfirmation ? 'Yes' : 'End'}
      onPress={() => buttonAction(false)}
      width={96}
      className="EndJourneyConfirmation-button"
      disabled={buttonDisabled}
    />
  )

  const ConfirmDisableAssist = () => (
    <Button
      testID="end-journey-assist-btn"
      title="Yes and disable assist"
      onPress={() => buttonAction(true)}
      className="EndJourneyConfirmation-button"
      mr={2}
      disabled={buttonDisabled}
    />
  )

  const SafeLock = () => (
    <Flex>
      <Checkbox checked={checked[selectedAction]} onChange={onChange} />
      <Text ml={1} variant="small" color="grape">
        Automatically try to lock vehicle
      </Text>
    </Flex>
  )

  const onPress = async () => {
    // refresh journey state in case it's moved into on hold
    // and we need to block cancels and voids
    await apolloClient.refetchQueries({
      include: ['JOURNEY_SUMMARY', 'JOURNEY_HISTORY'],
    })
    setVisible(true)
  }

  return (
    <>
      <Button
        testID="end-journey-btn"
        title="End&nbsp;journey"
        variant="outline"
        onPress={onPress}
      />
      <Modal
        open={visible}
        zIndex={1020}
        footer={null}
        onCancel={hideModal}
        width={550}
      >
        <Box py={2} px={3}>
          <Heading variant="h1" pb={1}>
            End journey:
          </Heading>
          <JourneyEndMethodSelector />
          <Flex mt={3} mb={3}>
            <Box flex={1}>
              <JourneyEndMethodInformaton />
              {/* Some schemes are run by third parties eg Serco in the West Midlands */}
              {/* & don't use the same work flows as Control Room - so lock or raise job option is unnecessary for them */}
              <SafeLock />
            </Box>
          </Flex>
          <Flex alignItems="center" justifyContent="flex-end">
            {showingConfirmation && <ConfirmEndJourneyText />}
            {showingConfirmation && assistSetToNormal && (
              <ConfirmDisableAssist />
            )}
            <ConfirmEndJourneyButton />
          </Flex>
        </Box>
      </Modal>
    </>
  )
}
