import { useModuleVehicleCommissionedQuery } from 'gql/generated/graphql'
import useWrappedQuery from './useWrappedQuery'

const useModuleVehicle = (module_id: string) => {
  const { data } = useWrappedQuery(
    useModuleVehicleCommissionedQuery({ variables: { module_id } }),
    data => data?.module?.vehicle,
  )
  return data
}

export default useModuleVehicle
