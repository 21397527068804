import { Scheme } from 'types'
import { JobList } from '.'
import { Col, Row } from 'antd'
import ArchiveJobListContainer from 'components/ArchiveJob/List'

export interface IAssetJobs {
  assetTag: string
  scheme: Scheme
}

const AssetJobs = ({ assetTag, scheme }: IAssetJobs) => {
  return (
    <Row gutter={16}>
      <Col xs={24}>
        <JobList
          filters={{
            assetTag: [assetTag],
          }}
          scheme={scheme}
        >
          <ArchiveJobListContainer assetTag={assetTag} mt={3} />
        </JobList>
      </Col>
    </Row>
  )
}

export default AssetJobs
