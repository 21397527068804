import { useState, useEffect } from 'react'
import { Row, Col, Tag, message, Popover } from 'antd'
import AddTag from './Add'
import {
  MODULE_SUMMARY__DASHBOARD_module_tags,
  MODULE_SUMMARY__DASHBOARD_module_vehicle,
} from '../__generated__/MODULE_SUMMARY__DASHBOARD'
import { ModuleTagName } from '../../../__generated__/globalTypes'
import {
  useMissing,
  useLocated,
  useProviderDropOff,
  useMaintenancePickUp,
  useMaintenanceStart,
  useMaintenanceEnd,
  useLowBatteryOverrideStart,
  useLowBatteryOverrideEnd,
} from 'hooks'
import { formatDatetime } from 'utils'
import { useApolloClient } from '@apollo/client'
import useEventStart from 'hooks/useEventStart'
import useEventEnd from 'hooks/useEventEnd'

type Props = {
  vehicleId: MODULE_SUMMARY__DASHBOARD_module_vehicle['id']
  tags: MODULE_SUMMARY__DASHBOARD_module_tags[]
}

export default ({ vehicleId, tags }: Props) => {
  const [savedTags, updateSavedTags] = useState<
    Pick<MODULE_SUMMARY__DASHBOARD_module_tags, 'name' | 'created_at'>[]
  >([])
  const [missing] = useMissing({ vehicleId })
  const [located] = useLocated({ vehicleId })
  const [maintenancePickUp] = useMaintenancePickUp({ vehicleId })
  const [providerDropOff] = useProviderDropOff({ vehicleId })
  const [maintenanceStart] = useMaintenanceStart({ vehicleId })
  const [maintenanceEnd] = useMaintenanceEnd({ vehicleId })
  const [lowBatteryOverrideStart] = useLowBatteryOverrideStart({ vehicleId })
  const [lowBatteryOverrideEnd] = useLowBatteryOverrideEnd({ vehicleId })
  const [eventStart] = useEventStart({ vehicleId })
  const [eventEnd] = useEventEnd({ vehicleId })
  const client = useApolloClient()

  useEffect(() => {
    updateSavedTags(tags.map(({ name, created_at }) => ({ name, created_at })))
  }, [tags])

  const refetch = async () =>
    await client.refetchQueries({
      include: ['MODULE_SUMMARY__DASHBOARD'],
    })

  const handleTagAdd = async (name: ModuleTagName) => {
    try {
      switch (name) {
        case ModuleTagName.missing:
          await missing()
          break
        case ModuleTagName.workshop:
          await maintenancePickUp()
          break
        case ModuleTagName.maintenance:
          await maintenanceStart()
          break
        case ModuleTagName.low_battery_override:
          await lowBatteryOverrideStart()
          break
        case ModuleTagName.event:
          await eventStart()
          break
        default:
          throw Error(`Invalid tag name: ${name}`)
      }
      await refetch()
      message.success('Tag added')
    } catch (err) {
      message.error(
        `Something went wrong when trying to add a tag: ${err.message}`,
      )
    }
  }

  const handleTagRemove = (name: ModuleTagName) => async () => {
    try {
      switch (name) {
        case ModuleTagName.missing:
          await located()
          break
        case ModuleTagName.workshop:
          await providerDropOff()
          break
        case ModuleTagName.maintenance:
          await maintenanceEnd()
          break
        case ModuleTagName.low_battery_override:
          await lowBatteryOverrideEnd()
          break
        case ModuleTagName.event:
          await eventEnd()
          break
        default:
          throw Error(`Invalid tag name: ${name}`)
      }
      await refetch()
      message.success('Tag removed.')
    } catch (err) {
      message.error(
        `Something went wrong when trying to remove tag: ${err.message}`,
      )
    }
  }

  return (
    <Row justify="start">
      <Col>
        {savedTags.length !== 0 &&
          savedTags.map(({ name: tagName, created_at }) => (
            <Popover
              key={tagName}
              content={formatDatetime(created_at)}
              placement="bottom"
            >
              <Tag
                closable
                onClose={handleTagRemove(tagName)}
                color="blue"
                style={{
                  border: 'none',
                  borderRadius: 24,
                  padding: '4px 14px',
                  fontFamily: 'Hellix-SemiBold',
                }}
              >
                {tagName}
              </Tag>
            </Popover>
          ))}
      </Col>
      <Col>
        <AddTag
          tags={savedTags.map(({ name }) => name)}
          onComplete={handleTagAdd}
        />
      </Col>
    </Row>
  )
}
