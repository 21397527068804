import config from 'constants/config'

import * as Sentry from '@sentry/react'

import { auth } from './firebase/firebase'

const dsn = config.REACT_APP_SENTRY_URL
const release = config.REACT_APP_RELEASE
const environment = config.REACT_APP_ENV

// tslint:disable-next-line:no-console
console.log(`RELEASE: ${release}, ENVIRONMENT: ${environment}`)

const useSentry = process.env.NODE_ENV === 'production'

if (dsn && useSentry) {
  Sentry.init({
    dsn,
    release,
    environment,
  })

  auth.onAuthStateChanged(authUser => {
    if (authUser) {
      const { email, uid } = authUser
      Sentry.setUser({ email: email ?? undefined, uid })
    } else {
      Sentry.setUser({})
    }
  })
}

export default Sentry
