import { gql, useMutation } from '@apollo/client'
import * as types from './__generated__/requestVehicleMaintenanceStart'

const query = gql`
  mutation requestVehicleMaintenanceStart($vehicleId: GUID!) {
    vehicle_maintenance_start(vehicle_id: $vehicleId)
  }
`

export default function useMaintenanceStart(
  variables?: types.requestVehicleMaintenanceStartVariables,
) {
  return useMutation<
    types.requestVehicleMaintenanceStart,
    types.requestVehicleMaintenanceStartVariables
  >(query, {
    variables,
    context: {
      useApolloNetworkStatus: false,
    },
  })
}
