import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Center } from 'components/Layout'

export default () => {
  return (
    <Center
      style={{
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div>
        <LoadingOutlined spin />
      </div>
    </Center>
  )
}
