import { gql, useMutation } from '@apollo/client'
import { message } from 'antd'
import { Flex, Button, colors, borderRadii } from '@weareberyl/design-system'

import type { USER_PAYMENT_CARD_user_payer_default_card as CARD } from './__generated__/USER_PAYMENT_CARD'
import type {
  REMOVE_PAYMENT_CARD,
  REMOVE_PAYMENT_CARDVariables,
} from './__generated__/REMOVE_PAYMENT_CARD'

import config from 'constants/config'
import { CardBrand, CardTextDetails } from './Card'
import { USER_PAYMENT_CARD_QUERY } from '.'

const isProduction = config.REACT_APP_ENV === 'production'

const REMOVE_PAYMENT_CARD_MUATATION = gql`
  mutation REMOVE_PAYMENT_CARD($user_id: ID!, $card_id: ID!) {
    remove_payer_card(user_id: $user_id, card_id: $card_id)
  }
`

export default ({ user_id, card }: { user_id: string; card: CARD }) => {
  const { id, brand_enum, type } = card
  const refetchQueries = [
    { query: USER_PAYMENT_CARD_QUERY, variables: { user_id } },
  ]

  const [deleteCard, { loading: deleteLoading }] = useMutation<
    REMOVE_PAYMENT_CARD,
    REMOVE_PAYMENT_CARDVariables
  >(REMOVE_PAYMENT_CARD_MUATATION, {
    variables: { user_id, card_id: id },
    refetchQueries,
    onCompleted: () => message.info('Payment card removed successfully!'),
    onError: err => message.error(`Something went wrong: ${err.message}`),
  })

  return (
    <>
      <Flex
        bg={colors.oyster}
        p={3}
        borderRadius={borderRadii.default}
        justifyContent="space-between"
      >
        <CardBrand type={type} brand={brand_enum} />
        <CardTextDetails {...card} />
      </Flex>
      <Flex style={{ gap: '10px' }}>
        {!isProduction && (
          <Button
            title="Delete"
            variant="danger"
            mt={3}
            onPress={deleteCard}
            loading={deleteLoading}
          />
        )}
      </Flex>
    </>
  )
}
