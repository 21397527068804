import isEqual from 'lodash/isEqual'

export type WithIsChangedBool<T> = {
  [K in keyof T]: K extends 'uuid' | 'id' ? T[K] : [T[K], boolean]
}

/**
 * Identify the values that have changed in each row of an ordered collection
 * of records.
 *
 * The row collection is represented as an array. Each row is an object, each
 * record is a value in the object. Assume the collection is ordered, with
 * newest items at index 0.
 *
 * Replaces (almost) every `value` with a `[value, boolean]` tuple. The boolean
 * is true if the record's value has changed since the previous row. Does not
 * transform id or uuid fields, as these are needed by the Table component.
 */
const markChangedValues = <T extends Record<string, unknown>>(
  input: T[],
): WithIsChangedBool<T>[] => {
  if (input.length === 0) return []

  const output = input.map((item: T, index): WithIsChangedBool<T> => {
    const isLastRow = index === input.length - 1
    const entries = Object.entries(item)

    const mapped = entries.map(([key, value]) => {
      // Do not wrap `id` or `uuid`, because the Table component uses these
      if (key === 'id' || key === 'uuid') {
        return [key, value]
      }

      // If this is the last row, compare each value against itself. This
      // prevents all items on the last row from being marked as changed.
      const missingValue = isLastRow ? item[key] : undefined

      // Get the "earlier" value for comparison. Collection is ordered newest to
      // oldest, earlier values have higher index
      const comparison = input[index + 1]?.[key] ?? missingValue

      // Replace `value` with a [value, boolean] tuple.
      return [key, [value, !isEqual(value, comparison)]]
    })

    return Object.fromEntries(mapped) as WithIsChangedBool<T>
  })

  return output
}

export default markChangedValues
