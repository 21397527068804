import { message } from 'antd'

import {
  UserSummaryDocument,
  useBlockUserCardFingerprintMutation,
  useBlockUserMutation,
  useUnBlockUserCardFingerptintMutation,
  useUnBlockUserMutation,
} from 'gql/generated/graphql'
import { useScheduleRefresh } from 'hooks/useScheduleRefresh'

type Props = {
  user_id: string
  is_blocked: boolean
}

const BlockUser = ({ user_id, is_blocked }: Props) => {
  const scheduleRefresh = useScheduleRefresh([UserSummaryDocument])

  const onError = (err: Error) => {
    message.error(`Something went wrong: ${err.message}`, 10)
  }

  const [blockUserPersona] = useBlockUserMutation({
    onError,
    variables: { user_id },
    onCompleted: () => {
      message.info('User successfully blocked on Persona', 10)
      scheduleRefresh()
    },
  })

  const [unBlockUserPersona] = useUnBlockUserMutation({
    onError,
    variables: { user_id },
    onCompleted: () => {
      message.info('User successfully un-blocked on Persona', 10)
      scheduleRefresh()
    },
  })

  const [blockCard] = useBlockUserCardFingerprintMutation({
    onError,
    variables: { user_id },
    onCompleted: () => message.info('Payment card blocked successfully!', 10),
  })

  const [unBlockCard] = useUnBlockUserCardFingerptintMutation({
    onError,
    variables: { user_id },
    onCompleted: () =>
      message.info('Payment card un-blocked successfully!', 10),
  })

  const toggleBlocked = () => {
    if (is_blocked) {
      unBlockUserPersona()
      unBlockCard()
    } else {
      blockUserPersona()
      blockCard()
    }
  }

  const actionLabel = is_blocked
    ? 'Un-block user (and card)'
    : 'Block user and card'

  return (
    <span onClick={toggleBlocked} role="button">
      {actionLabel}
    </span>
  )
}

export default BlockUser
