// tslint:disable:no-console
import { ApolloError, gql } from '@apollo/client'
import queryString from 'query-string'
import {
  Messaging,
  getToken,
  deleteToken,
  onMessage,
  isSupported,
  getMessaging,
} from 'firebase/messaging'
import { app, auth } from './firebase'
import client from 'client'
import config from 'constants/config'
import { registerValidSW } from 'registerServiceWorker'

const ADD_REGISTRATION_ID_MUTATION = gql`
  mutation ADD_REGISTRATION_ID($user_id: ID!, $registration_id: ID!) {
    add_registration_id(user_id: $user_id, registration_id: $registration_id)
  }
`

// based on https://github.com/firebase/firebase-js-sdk/issues/2364#issuecomment-570820017
const getNotificationToken = async (
  messaging: Messaging,
  registration: ServiceWorkerRegistration,
  retry = 0,
): Promise<string | undefined> => {
  try {
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification')
      return
    }

    let permission = Notification.permission
    if (permission !== 'granted') {
      permission = await Notification.requestPermission()
    }
    if (permission === 'denied') {
      console.log('Notification permission denied.')
      return
    }

    console.log('Notification permission granted.')
    const token = await getToken(messaging, {
      vapidKey: config.REACT_APP_FCM_KEY,
      serviceWorkerRegistration: registration,
    })
    return token
  } catch (err) {
    if (err.code === 'messaging/token-unsubscribe-failed') {
      if (retry > 2) {
        console.log(
          'Firebase messaging token unsubscribe failed many times. Aborting.',
        )
        return
      }
      console.log('Firebase messaging token unsubscribe failed. Retrying.')
      return await getNotificationToken(messaging, registration, retry + 1)
    }
  }
}

// initialize ask for permissions
// then send the token to the backend
export default async () => {
  let messaging: Messaging
  if (await isSupported()) {
    messaging = getMessaging(app)
  } else {
    return null
  }

  let registration: null | ServiceWorkerRegistration = null
  // register service worker
  try {
    if (navigator.serviceWorker) {
      console.log('registering FCM service worker')
      registration = await registerValidSW(
        `/firebase-messaging-sw.js?${queryString.stringify({
          apiKey: config.FIREBASE_API_KEY,
          appId: config.FIREBASE_APP_ID,
          projectId: config.GOOGLE_PROJECT_ID,
          authDomain: config.FIREBASE_AUTH_DOMAIN,
          messagingSenderId: config.REACT_APP_FCM_SENDER_ID,
        })}`,
      )
    }
  } catch (err) {
    console.log('registering fcm service worker failed', err)
    return
  }

  try {
    if (registration && auth.currentUser) {
      const token = await getNotificationToken(messaging, registration)

      if (!token) {
        throw new Error('Empty notification token')
      }
      await client.mutate({
        mutation: ADD_REGISTRATION_ID_MUTATION,
        variables: {
          user_id: auth.currentUser.uid,
          registration_id: token,
        },
      })
      console.log('Sent registration id to api')
    }
  } catch (err) {
    console.log('Notification registration id failed', err)
    if (err?.graphQLErrors) {
      const error = err as ApolloError
      const invalidDeviceToken = error.graphQLErrors.some(
        // @ts-ignore extensions is just [string]: any
        e => e.extensions?.response?.status === 400,
      )

      if (invalidDeviceToken) {
        console.log('FCM device token is invalid deleting.')
        // remove token so we get a fresh one next time we boot the app.
        // This is an experiment to see if it reduces cases of: [ch11948]
        try {
          await deleteToken(messaging)
        } catch (err) {
          console.log('Failed to delete FCM device token', err)
        }
      }
    }
  }

  // register message handler
  onMessage(messaging, async payload => {
    console.log('Message received. ', payload)

    // user claims were updated, let's force refresh the token
    if (payload?.data?.eventType === 'user.claims') {
      await auth.currentUser?.getIdToken(true)
    }
  })
}
