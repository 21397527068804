import { useState, Fragment } from 'react'
import { gql } from '@apollo/client'
import { message, Modal, Button } from 'antd'
import { Mutation } from '@apollo/client/react/components'
import { withRouter, RouteComponentProps } from 'react-router'
import pluralize from 'pluralize'
import { Button as BerylButton } from '@weareberyl/design-system'

import SelectVersion from '../SelectVersion'
import * as routes from 'constants/routes'

const START_DEPLOYMENT = gql`
  mutation START_DEPLOYMENT($module_ids: [ID]!, $version: String!) {
    start_deployment(module_ids: $module_ids, version: $version) {
      id
    }
  }
`

type Props = RouteComponentProps & {
  module_ids: string[]
}

export default withRouter(({ module_ids, history }: Props) => {
  const [version, setVersion] = useState('')
  const [visible, setModalVisibility] = useState(false)

  return (
    <Fragment>
      <Mutation
        onCompleted={data => {
          message.info('Deployment Started!')
          history.push(`${routes.DEPLOYMENTS}/${data.start_deployment.id}`)
        }}
        onError={err => {
          message.error(`Something went wrong: ${err.message}`)
        }}
        mutation={START_DEPLOYMENT}
      >
        {(start, { loading }) => (
          <Modal
            open={visible}
            title={`Update ${module_ids.length} ${pluralize(
              'module',
              module_ids.length,
            )}`}
            onCancel={() => {
              setModalVisibility(false)
            }}
            onOk={() => {
              start({
                variables: { module_ids, version },
              })
            }}
            footer={[
              <Button
                type="primary"
                key="cancel"
                onClick={() => setModalVisibility(false)}
              >
                Cancel
              </Button>,
              <Button
                type="primary"
                key="start"
                loading={loading}
                onClick={() => {
                  start({
                    variables: { module_ids, version },
                  })
                }}
              >
                Submit
              </Button>,
            ]}
          >
            Select Version: <SelectVersion onChange={setVersion} />
          </Modal>
        )}
      </Mutation>
      <BerylButton
        type="primary"
        disabled={module_ids.length < 1}
        onClick={() => {
          setModalVisibility(true)
        }}
        title="Deploy"
      />
    </Fragment>
  )
})
