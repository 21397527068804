import MobileDetect from 'mobile-detect'

const md = new MobileDetect(window.navigator.userAgent)

// If phone OR tablet
export const isMobile = (): boolean => !!md.mobile()

export const isPhone = (): boolean => !!md.phone()

export const isTablet = (): boolean => !!md.tablet()

export const isIPhone = (): boolean => !!md.is('iPhone')

export default {
  isMobile,
  isPhone,
  isTablet,
  isIPhone,
}
