import { useReducer, useEffect } from 'react'
import { InputNumber, InputNumberProps, Tooltip } from 'antd'
import { Flex, Box, Heading, Text, Icon } from '@weareberyl/design-system'
import { isNaN } from 'lodash'

import {
  InvoiceQuery_invoice_JourneyInvoice_scheme_product_SchemeProduct_scheme_accounting_SchemeAccounting as SchemeAccounting,
  TransactionMetaChargeType,
  TransactionType,
  InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes as JourneyInvoiceTransaction,
} from 'gql/generated/graphql'

const CURRENCY_MAP = {
  gbp: '£',
  usd: '$',
}

// Return some formatters for InputNumber component
// Formatter adds the unit and parser strips it out again!
export const useFormatters = (currency?: string) => {
  const symbol = CURRENCY_MAP[currency ?? 'gbp']
  const formatCurrency: InputNumberProps['formatter'] = value => {
    if (value === undefined) {
      return ''
    }
    let numericValue = value
    if (typeof value === 'string') {
      numericValue = parseFloat(value)
    }
    const formattedNumber = numericValue.toLocaleString('en-GB', {
      minimumFractionDigits: 2,
    })

    return `${symbol}${formattedNumber}`
  }

  const formatMinutes: InputNumberProps['formatter'] = value => {
    return `${value} mins`
  }

  const parser = (value: string) => {
    const res = parseFloat(value?.replace(/\D/, '') ?? '')
    // Don't return NaN back to the input, or it will display that until blur
    // and that interrupts text input
    return isNaN(res) ? '' : res
  }

  return {
    formatMinutes,
    formatCurrency,
    parser,
  }
}

// UI to calculate currency values from minute amounts
// changes are made by dispatching events on the reducer which calculates the field values
const MinuteCurrencyCalculator = ({
  description,
  dispatch,
  state,
  max,
}: {
  description: string
  dispatch: React.Dispatch<Action>
  state: State
  max: number
}) => {
  const { formatMinutes, parser } = useFormatters()
  return (
    <Box bg="white" p={3} borderRadius={10}>
      <Flex alignItems="center" mb={2} style={{ gap: '1ex' }}>
        <Tooltip title="Calculate currency value to refund by entering a minute value that should be refunded. Or enter a new duration for the journey so that the rest is refunded.">
          <Icon color="grape" height={14} width={14} type="information" />
        </Tooltip>
        <Heading>Calculate</Heading>
        <Text variant="mini">({description})</Text>
      </Flex>
      <Flex alignItems="center" style={{ gap: '10px' }}>
        <Text variant="mini">Refund</Text>
        <InputNumber
          size="large"
          max={max}
          min={1}
          step={1}
          value={state.minutes}
          formatter={formatMinutes}
          parser={parser}
          title="Minutes to refund"
          onChange={parsedValue => {
            if (parsedValue !== '' && parsedValue !== null) {
              const value =
                typeof parsedValue === 'number'
                  ? parsedValue
                  : parseFloat(parsedValue)
              dispatch({ type: 'set_minutes', value })
            }
          }}
        />
        <Text variant="mini">or Keep</Text>
        <InputNumber
          size="large"
          max={max - 1}
          min={0}
          step={1}
          value={state.minutesLeft}
          formatter={formatMinutes}
          parser={parser}
          title="Minutes to keep"
          onChange={parsedValue => {
            if (parsedValue !== '' && parsedValue !== null) {
              const value =
                typeof parsedValue === 'number'
                  ? parsedValue
                  : parseFloat(parsedValue)
              dispatch({ type: 'set_minutes_left', value })
            }
          }}
        />
      </Flex>
    </Box>
  )
}

export type State = {
  currency: number
  minutes: number | undefined
  minutesLeft: number | undefined
}

type Action = {
  type: 'set_minutes' | 'set_minutes_left' | 'set_currency'
  value: number
}

type GetAmountsReducer = React.Reducer<State, Action>

// Using a reducer here to calculate related fields, when any of these three fields changes,
// the other 2 should be re-calculated.
// This is just for displaying form state, and the correct state value for amount, can be used
// to send in the refund request.
export const getAmountsReducer = (
  timeIncrementCost: number,
  minutesCharged: number,
): GetAmountsReducer => {
  return (state, action) => {
    switch (action.type) {
      case 'set_minutes': {
        const newMinutes = action.value
        const newMinutesLeftFromMinutes = Math.max(
          0,
          minutesCharged - newMinutes,
        )
        const newCurrencyFromMinutes = Math.max(
          0,
          (newMinutes * timeIncrementCost) / 100,
        )
        return {
          minutes: newMinutes,
          minutesLeft: newMinutesLeftFromMinutes,
          currency: newCurrencyFromMinutes,
        }
      }
      case 'set_minutes_left': {
        const newMinutesLeft = action.value
        const newMinutesFromMinutesLeft = Math.max(
          0,
          minutesCharged - newMinutesLeft,
        )
        const newCurrencyFromMinutesLeft = Math.max(
          0,
          (newMinutesFromMinutesLeft * timeIncrementCost) / 100,
        )
        return {
          minutes: newMinutesFromMinutesLeft,
          minutesLeft: newMinutesLeft,
          currency: newCurrencyFromMinutesLeft,
        }
      }
      case 'set_currency': {
        const newCurrency = action.value
        const newMinutesFromCurrency = Math.max(
          0,
          Math.ceil(newCurrency * 100) / timeIncrementCost,
        )
        const newMinutesLeftFromCurrency = Math.max(
          0,
          minutesCharged - newMinutesFromCurrency,
        )
        return {
          currency: newCurrency,
          minutes: newMinutesFromCurrency,
          minutesLeft: newMinutesLeftFromCurrency,
        }
      }
      default:
        return state
    }
  }
}

const AdjustTransaction = ({
  transaction: { description, meta, debit_amount, type, id, amount_refundable },
  currency,
  onChange: setAmountToRefund,
}: {
  transaction: JourneyInvoiceTransaction
  currency?: SchemeAccounting['currency']
  onChange: (id: string, amount: number, type: TransactionType) => void
}) => {
  // debit_amount is a number of minutes or an amount of currency
  const isMinuteTransaction = type === TransactionType.minute_transaction

  // If this is a currency time charge, how much were the minutes charged at?
  // If not, time_increment_cost won't be present in meta and we won't need to calculate between <-> currency
  const timeIncrementCost = meta?.time_increment_cost ?? -1

  // How much is refundable for this transaction.
  // Either the whole amount or a total adjusted by an existing partial refund or discount.
  // This could be minutes or currency
  const amount = amount_refundable?.amount ?? debit_amount.amount

  // Minutes for a minute transaction or calculated from a currency transaction
  const maxMinutes = isMinuteTransaction
    ? amount
    : Math.max(amount / timeIncrementCost, 0)
  // Currency for a currency transaction or calculated from a minute transaction
  // NOTE: Don't need this for a minute transaction
  const maxCurrency = isMinuteTransaction ? -1 : amount

  // Scaled max value to pounds for currency to display in UI elements only
  const uiMaxValue = isMinuteTransaction ? maxMinutes : maxCurrency / 100

  // Map minute values to currency values based on minute rate charged
  // for this transaction. Applies to cash settled time_based_charge
  const amountsReducer = getAmountsReducer(timeIncrementCost, maxMinutes)

  const [state, dispatch] = useReducer(amountsReducer, {
    currency: maxCurrency / 100,
    minutes: maxMinutes,
    minutesLeft: 0,
  })

  const { parser, formatMinutes, formatCurrency } = useFormatters(currency)

  // Display different value from state and dispatch different action
  // depending on whether the amount field is in minutes or currency
  const value = isMinuteTransaction ? state.minutes : state.currency
  const formatter = isMinuteTransaction ? formatMinutes : formatCurrency

  // When the (reducer) state for this field changes, update form value
  // by calling the onChange callback thats passed by the parent
  useEffect(() => {
    const value = isMinuteTransaction ? state.minutes : state.currency * 100
    if (value !== undefined && type !== null) {
      // Value is changed, update parent
      setAmountToRefund(
        id,
        Math.round(value),
        type ?? TransactionType.currency_transaction,
      )
    }
  }, [state, type, isMinuteTransaction, id, setAmountToRefund])

  const onChange = (value: number) => {
    if (isNaN(value)) {
      return
    }
    if (isMinuteTransaction) {
      dispatch({ type: 'set_minutes', value })
    } else {
      dispatch({ type: 'set_currency', value })
    }
  }

  // Show extra fields for time based currency charges, to calculate a currency
  // value from minute values
  const showMinutesCalculator = Boolean(
    meta &&
      meta.charge_type === TransactionMetaChargeType.time_based_charge &&
      meta.time_increment_cost !== null &&
      meta.minutes_charged !== null,
  )

  let total = <Text>of {debit_amount.formatted_amount}</Text>

  // The net amount charged might be a bit less, if there is a discount so we can only refund
  // the amount that was acutally charged after discounts so adjust it here if it's different
  if (isMinuteTransaction && maxMinutes !== debit_amount.amount) {
    total = (
      <Text>
        of <s title="Discounted">{debit_amount.formatted_amount}</s>{' '}
        {amount_refundable?.formatted_amount}
      </Text>
    )
  } else if (!isMinuteTransaction && maxCurrency !== debit_amount.amount) {
    total = (
      <Text>
        of <s title="Discounted">{debit_amount.formatted_amount}</s>{' '}
        {amount_refundable?.formatted_amount}
      </Text>
    )
  }

  return (
    <Box bg="tofu" p={3} pl={5} my={2} borderRadius={10}>
      <Box mb={2}>
        <Heading>
          {showMinutesCalculator
            ? 'By the minute currency charge'
            : description}
        </Heading>
      </Box>
      <Flex alignItems="center" style={{ gap: '10px' }}>
        <Box>
          <Text>Refund</Text>
        </Box>
        <Box style={{ gap: '10px' }}>
          <Flex alignItems="center" style={{ gap: '10px' }}>
            <InputNumber
              size="large"
              min={0}
              max={uiMaxValue}
              step={isMinuteTransaction ? 1 : 0.01}
              value={value}
              formatter={formatter}
              parser={parser}
              onChange={onChange}
              disabled={showMinutesCalculator}
              title="Amount to refund"
            />
          </Flex>
        </Box>
        <Box flex={1}>{total}</Box>
        {showMinutesCalculator && meta?.minutes_charged && (
          <MinuteCurrencyCalculator
            max={maxMinutes}
            state={state}
            dispatch={dispatch}
            description={description}
          />
        )}
      </Flex>
    </Box>
  )
}

export default AdjustTransaction
