import React, { useState, memo, useRef } from 'react'
import { BoxProps, Button, ButtonVariant } from '@weareberyl/design-system'
import { Modal, Input, Checkbox, Cascader, Select, Form } from 'antd'
import { v4 as uuidv4 } from 'uuid'

import { formItemLayout } from 'components/Job/Modal'
import { useAllVehicles, useJobTypes } from 'hooks'
import { DASHBOARD_JUSTIFICATION_PREFIX } from '..'
import { isEqual } from 'lodash'
import { isNotNullOrUndefined } from 'utils/types'
import {
  JobAutomationsQuery_automations_Automation,
  TriggerJobAutomationMutationVariables,
} from 'gql/generated/graphql'

const ALLOWED_JOB_TYPES = ['RD01', 'ST01']

interface Props {
  modalOpen: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  automation: JobAutomationsQuery_automations_Automation
  runFlow: (
    variables: TriggerJobAutomationMutationVariables,
    text: string,
  ) => void
}

const AutomationModal = ({
  modalOpen,
  setModalOpen,
  automation,
  runFlow,
}: Props) => {
  const [form] = Form.useForm()

  const ref = useRef(null)
  const disabled = Boolean(automation)
  const { roleByJobLookup, jobOptions } = useJobTypes()
  const { assetTags } = useAllVehicles()

  if (!modalOpen) return null

  const jobs = automation?.jobs.filter(isNotNullOrUndefined) ?? []

  const withDisabledJobOptions = jobOptions?.map(role => {
    let disabled = true
    const children = role.children?.map(child => {
      if (ALLOWED_JOB_TYPES.includes(child?.value)) {
        disabled = false
        return child
      }
      return { ...child, disabled: true }
    })
    return {
      ...role,
      disabled,
      children,
    }
  })

  const onFinish = fields => {
    const [, taskId] = fields.taskId
    fields.taskId = taskId
    fields.justification_id =
      automation?.justification_id ??
      `${DASHBOARD_JUSTIFICATION_PREFIX}-${uuidv4()}`
    fields.side_effects = fields.set_unavailable ? ['set_unavailable'] : []
    delete fields.set_unavailable

    const variables = {
      ...fields,
      dry_run: false,
    } as TriggerJobAutomationMutationVariables

    if (
      !isEqual(
        variables.assets,
        jobs.map(({ frame_id }) => frame_id),
      )
    ) {
      const text = automation
        ? 'Please confirm that you want to modify this automation'
        : 'Please confirm that you want to create this automation'
      runFlow(variables, text)
    }
    setModalOpen(false)
  }

  return (
    <Modal
      title={automation ? 'Edit Automation' : 'Add Automation'}
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      onOk={() => form.submit()}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          label="Description"
          name="description"
          initialValue={automation?.description}
          rules={[
            {
              required: true,
              message: 'Description required!',
            },
          ]}
          {...formItemLayout}
        >
          <Input disabled={disabled} />
        </Form.Item>
        <Form.Item
          label="Job"
          name="taskId"
          initialValue={
            automation?.taskId
              ? [roleByJobLookup?.[automation?.taskId], automation?.taskId]
              : ''
          }
          rules={[
            {
              required: true,
              message: 'Valid job type required!',
            },
          ]}
          {...formItemLayout}
        >
          <Cascader
            options={withDisabledJobOptions}
            disabled={disabled}
            ref={ref}
            expandTrigger="hover"
          />
        </Form.Item>
        <Form.Item
          label="Set Unavailable"
          name="set_unavailable"
          initialValue={Boolean(
            automation?.side_effects?.includes('set_unavailable'),
          )}
          valuePropName="checked"
          {...formItemLayout}
        >
          <Checkbox disabled={disabled} />
        </Form.Item>
        <Form.Item
          label="Assets"
          name="assets"
          initialValue={jobs.map(({ frame_id }) => frame_id)}
          rules={[
            {
              required: true,
              message: 'You must select a valid group of vehicles!',
            },
          ]}
          {...formItemLayout}
        >
          <Select
            mode="multiple"
            options={assetTags.map(assetTag => ({
              value: assetTag,
              name: assetTag,
            }))}
            tokenSeparators={[',', ' ']}
            allowClear
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const CreateAutomationButton = ({
  automation,
  runFlow,
  ...props
}: {
  automation?: JobAutomationsQuery_automations_Automation
  runFlow: (
    variables: TriggerJobAutomationMutationVariables,
    text: string,
  ) => void
  variant?: ButtonVariant
} & BoxProps) => {
  const [modalOpen, setModalOpen] = useState(false)
  const title = automation ? 'Edit' : 'Create Automation'
  return (
    <>
      <Button title={title} onPress={() => setModalOpen(true)} {...props} />
      <AutomationModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        automation={automation}
        runFlow={runFlow}
      />
    </>
  )
}

export default memo(CreateAutomationButton)
