import { useState } from 'react'
import { Card, Row, Col } from 'antd'
import { Flex } from '@weareberyl/design-system'
import Summary from './Summary'
import JourneyList from 'components/Journey/List/Table'
import CardDetailsForm from './../CardDetailsForm'
import PaymentMethods from './PaymentMethods'
import Payer from './Payer'
import UserProducts from './Products'
import InvoiceList from 'components/Invoice/List/table'
import { FreeProductActions } from './free_products'
import Dropdown from 'components/shared/Dropdown'
import config from 'constants/config'
import HeadTitle from 'components/HeadTitle'

const headStyle = { fontSize: '20px' }
const isProduction = config.REACT_APP_ENV === 'production'

export default ({ id }: { id: string }) => {
  const [userEmail, setUserEmail] = useState<string | null>(null)
  return (
    <>
      <HeadTitle pageTitle={`User ${userEmail ?? id}`} />
      <Row gutter={16} className="fill-height">
        <Col xs={24} lg={12}>
          <Card id="user-summary-card">
            <Summary id={id} setUserEmail={setUserEmail} />
          </Card>
        </Col>

        <Col xs={24} lg={12}>
          <Card
            id="user-account-balance-card"
            title="Account Balance"
            bodyStyle={{ display: 'flex' }}
            headStyle={headStyle}
          >
            <Payer user_id={id} />
          </Card>
        </Col>

        <Col xs={24} sm={12}>
          {!isProduction && (
            <Card
              id="user-add-a-card-card"
              title="Add a card"
              headStyle={headStyle}
            >
              <CardDetailsForm id={id} />
            </Card>
          )}
        </Col>

        <Col xs={24} sm={12}>
          <Card
            id="user-payment-methods-card"
            title="Payment methods"
            headStyle={headStyle}
          >
            <PaymentMethods id={id} />
          </Card>
        </Col>

        <Col xs={24}>
          <Card
            id="user-products-card"
            headStyle={headStyle}
            title={
              <Flex
                justifyContent="space-between"
                alignItems="flex-start"
                mr={1}
              >
                <span>User products</span>
                <Dropdown
                  overlay={<FreeProductActions userId={id} />}
                  title="Add product"
                />
              </Flex>
            }
          >
            <UserProducts user_id={id} />
          </Card>
        </Col>
        <Col xs={24}>
          <Card title="Journeys" headStyle={headStyle}>
            <JourneyList user_id={id} />
          </Card>
        </Col>
        <Col xs={24}>
          <Card title="Invoices" headStyle={headStyle}>
            <InvoiceList user_id={id} />
          </Card>
        </Col>
      </Row>
    </>
  )
}
