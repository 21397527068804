import React from 'react'
import * as routes from 'constants/routes'
import AssetLink from 'components/Scheme/AssetLink'
import { SCHEME } from 'types'

type LinkProps = {
  id: string
  scheme_id?: SCHEME['id']
  children?: React.ReactNode
}

const ServiceAreaLink = ({ id, scheme_id, children }: LinkProps) => (
  <AssetLink basePath={routes.SERVICE_AREAS} id={id} schemeId={scheme_id}>
    {children || id}
  </AssetLink>
)

export default ServiceAreaLink
