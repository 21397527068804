import { Card, Flex, Heading } from '@weareberyl/design-system'

import InvoiceTable, { InvoiceListProps } from './table'
import HeadTitle from 'components/HeadTitle'

const InvoiceList = (props: InvoiceListProps) => {
  return (
    <>
      <HeadTitle pageTitle="Invoices" />
      <Card p={5}>
        <Flex justifyContent="space-between" alignItems="flex-end" mb={5}>
          <Heading variant="callout">Invoices</Heading>
        </Flex>
        <InvoiceTable {...props} />
      </Card>
    </>
  )
}

export default InvoiceList
