import { Component } from 'react'
import { QueryResult } from '@apollo/client'
import { unionWith } from 'lodash'
import { Switch, Collapse } from 'antd'
import { Box, Card } from '@weareberyl/design-system'
import { WebMercatorViewport } from 'deck.gl'
import { currentLocation } from 'utils'
import Query from 'components/Query'
import ModulesMap, { BasisMap, ViewState } from 'components/Map'
import Legend, { LegendProps } from 'components/Map/Legend'
import withSchemeServiceAreas, {
  SERVICE_AREAS_GEOJSON,
} from 'components/Scheme/withSchemeServiceAreas'
import Loading from 'components/Loading'
import {
  ServiceAreaGeoJsonLayer,
  ModuleIconLayer,
  ZoneIconLayers,
  ZonePolygonLayer,
  WorkshopPolygonLayer,
  WorkshopIconLayers,
} from 'components/Map/layers'

import { SCHEME } from 'types'
import { REDUCED_POLL_INTERVAL } from 'client'
import { getDerivedModule } from 'utils/derived'
import { calculateSchemeCoordinates } from 'utils/calculateSchemeCoordinates'

import {
  ModuleMapQuery_modules_ModuleConnection_nodes_Module as Module,
  ModulesMapQuery_all_zones_ZoneConnection_nodes_Zone as Zone,
  LocationAreaInput,
  ModuleWhere,
  ZoneType,
  ModulesMapDocument,
  ModulesMapQuery,
} from 'gql/generated/graphql'
import HeadTitle from 'components/HeadTitle'

const { Panel } = Collapse

const viewport = new WebMercatorViewport(BasisMap.defaultProps)

type MapViewProps = {
  scheme: SCHEME
  serviceAreas: SERVICE_AREAS_GEOJSON
}

type MapViewState = {
  legendProps: LegendProps
  coords: {
    latitude: number | undefined
    longitude: number | undefined
  }
  bounds: LocationAreaInput | null
  pendingLocation: boolean
  showLiveZones: boolean
  showLockedModules: boolean
  showModules: boolean
  showOfflineModules: boolean
  showOnlineModules: boolean
  showSuspendedZones: boolean
  showUnlockedModules: boolean
  showZones: boolean
  showWorkshops: boolean
}

const initialState: MapViewState = {
  legendProps: { type: null },
  coords: {
    latitude: undefined,
    longitude: undefined,
  },
  bounds: {
    latitude_min: 0,
    latitude_max: 0,
    longitude_min: 0,
    longitude_max: 0,
  },
  pendingLocation: false,
  showLiveZones: true,
  showLockedModules: false,
  showModules: true,
  showOfflineModules: false,
  showOnlineModules: false,
  showSuspendedZones: false,
  showUnlockedModules: false,
  showZones: true,
  showWorkshops: true,
}

export class ModulesMapContainer extends Component<MapViewProps, MapViewState> {
  state: MapViewState = {
    ...initialState,
    pendingLocation: true,
  }

  displayName = 'ModulesMapContainer'
  _isMounted = false
  _fetchMore: QueryResult<ModulesMapQuery>['fetchMore'] | null = null

  _setState = newState => {
    if (this._isMounted) {
      this.setState(newState)
    }
  }

  componentDidMount() {
    this._isMounted = true
    this._fetchLocation()
  }

  componentDidUpdate(prevProps: MapViewProps) {
    // If the scheme has changed then re-center the map
    if (prevProps.scheme.id !== this.props.scheme.id && this._isMounted) {
      this.setState(initialState)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  _fetchLocation = async () => {
    const coords = await currentLocation()

    this._setState({ coords, pendingLocation: false })
  }

  _generateModuleFilters = () => {
    // FIXME: Lock state filtering doesn't appear to work so commenting out
    // const lockStates: string[] = []
    const filters: ModuleWhere[] = []

    // if (this.state.showLockedModules) {
    //   lockStates.push('locked')
    // }
    // if (this.state.showUnlockedModules) {
    //   lockStates.push('unlocked')
    // }

    // if (lockStates.length) {
    //   filters.push({
    //     state: {
    //       message: {
    //         in: lockStates,
    //       },
    //     },
    //   })
    // }

    // only one connectivity state is selected, lets add a filter
    if (this.state.showOnlineModules !== this.state.showOfflineModules) {
      let isOnline = true

      if (this.state.showOfflineModules) {
        isOnline = false
      }

      filters.push({
        connectivity: {
          is_online: {
            eq: isOnline,
          },
        },
      })
    }

    return filters
  }

  _generateZoneFilters = () => {
    let suspendedStates
    if (this.state.showLiveZones && !this.state.showSuspendedZones) {
      suspendedStates = false
    }
    if (!this.state.showLiveZones && this.state.showSuspendedZones) {
      suspendedStates = true
    }
    return suspendedStates
  }

  _toggleModuleLayer = () =>
    this._setState(({ showModules }) => ({
      clickedModuleId: null,
      showModules: !showModules,
    }))

  _toggleZoneLayer = () =>
    this._setState(({ showZones }) => ({
      clickedZoneId: null,
      showZones: !showZones,
    }))

  _toggleWorkshopLayer = () =>
    this._setState(({ showWorkshops }) => ({
      clickedZoneId: null,
      showWorkshops: !showWorkshops,
    }))

  _toggleFilterState = filterName => {
    this._setState({ [filterName]: !this.state[filterName] })
  }

  _setLegend = (legendProps: MapViewState['legendProps']) => {
    this.setState({
      legendProps,
    })
  }

  _clearLegend = () => {
    this.setState({
      legendProps: { type: null },
    })
  }

  _generateServiceAreasLayer = () =>
    ServiceAreaGeoJsonLayer({
      serviceAreas: this.props.serviceAreas,
      layerId: 'service-area-layer',
      layerProps: {
        onClick: ({ object: { properties } }) => {
          this._setLegend({
            type: 'ServiceArea',
            service_area: properties,
          })
        },
      },
    })

  _generateNormalZonePolygonLayer = (zones: Zone[]) => {
    const { legendProps } = this.state
    const visibleZoneId =
      legendProps?.type === 'Zone' ? legendProps.zone_id : null
    return ZonePolygonLayer({
      zones,
      layerId: 'zone-polygon-layer',
      visible: this.state.showZones,
      visibleZoneId,
    })
  }

  _generateWorkshopZonePolygonLayer = (zones: Zone[]) => {
    return WorkshopPolygonLayer({
      zones,
      layerId: 'workshop-polygon-layer',
      visible: this.state.showWorkshops,
      onClick: obj => {
        this._setLegend({
          type: 'Zone',
          zone_id: obj?.object.properties.id,
        })
      },
    })
  }

  _generateModuleIconLayer = (modules: Module[]) =>
    ModuleIconLayer({
      modules,
      layerId: 'modules-icon-layer',
      layerProps: {
        visible: this.state.showModules,
        onClick: ({ object }) => {
          this._setLegend({ type: 'Module', module_id: object.id })
        },
      },
    })

  _generateParkingZoneLayers = (zones: Zone[]) =>
    ZoneIconLayers({
      zones,
      layerId: 'zone-layer',
      iconLayerProps: {
        visible: this.state.showZones,
        onClick: ({ object }) => {
          this._setLegend({ type: 'Zone', zone_id: object.id })
        },
      },
      textLayerProps: {
        visible: this.state.showZones,
      },
      scheme_id: this.props.scheme.id,
    })

  _generateWorkshopParkingZoneLayers = (zones: Zone[]) =>
    WorkshopIconLayers({
      zones,
      layerId: 'workshop-layer',
      iconLayerProps: {
        visible: this.state.showWorkshops,
        onClick: ({ object }) => {
          this._setLegend({ type: 'Zone', zone_id: object.zone.id })
        },
      },
    })

  _renderLayerToggle() {
    const { showModules, showZones, showWorkshops } = this.state

    return (
      <Card
        className="map-filter-card"
        style={{
          position: 'absolute',
          padding: 0,
          top: 20,
          right: 20,
          width: 180,
        }}
      >
        <Box p={4} pb={2}>
          <div
            style={{
              color: '#52525c',
              fontSize: '16px',
              fontWeight: 600,
              paddingBottom: '16px',
            }}
          >
            Layers
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>Vehicles</p>
            <Switch checked={showModules} onChange={this._toggleModuleLayer} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>Bays</p>
            <Switch checked={showZones} onChange={this._toggleZoneLayer} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p style={{ marginBottom: 0 }}>Workshops</p>
            <Switch
              checked={showWorkshops}
              onChange={this._toggleWorkshopLayer}
            />
          </div>
        </Box>

        <Collapse style={{ border: 0 }}>
          <Panel
            header="Filters"
            key="1"
            style={{
              backgroundColor: 'white',
              border: 0,
              paddingBottom: '10px',
              paddingTop: '10px',
            }}
          >
            <Box pl={2} pr={2}>
              <div
                style={{
                  color: '#52525c',
                  fontSize: '16px',
                  fontWeight: 600,
                  paddingBottom: '16px',
                }}
              >
                Vehicles
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>Online</p>
                <Switch
                  checked={this.state.showOnlineModules}
                  onChange={() => {
                    this._toggleFilterState('showOnlineModules')
                  }}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>Offline</p>
                <Switch
                  checked={this.state.showOfflineModules}
                  onChange={() => {
                    this._toggleFilterState('showOfflineModules')
                  }}
                />
              </div>
              {/* FIXME: These don't appear to work so commenting out
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>Locked</p>
                <Switch
                  checked={this.state.showLockedModules}
                  onChange={() => this._toggleFilterState('showLockedModules')}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>Unlocked</p>
                <Switch
                  checked={this.state.showUnlockedModules}
                  onChange={() =>
                    this._toggleFilterState('showUnlockedModules')
                  }
                />
              </div> */}
              <div
                style={{
                  color: '#52525c',
                  fontSize: '16px',
                  fontWeight: 600,
                  paddingBottom: '16px',
                }}
              >
                Bays
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>Live</p>
                <Switch
                  checked={this.state.showLiveZones}
                  onChange={() => this._toggleFilterState('showLiveZones')}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>Suspended</p>
                <Switch
                  checked={this.state.showSuspendedZones}
                  onChange={() => this._toggleFilterState('showSuspendedZones')}
                />
              </div>
            </Box>
          </Panel>
        </Collapse>
      </Card>
    )
  }

  _fetchData = (viewState: ViewState) => {
    if (this._fetchMore === null) {
      return null
    }

    this._fetchMore({
      variables: {
        location: {
          longitude_min: viewState.bounds._ne.lng,
          longitude_max: viewState.bounds._sw.lng,
          latitude_min: viewState.bounds._ne.lat,
          latitude_max: viewState.bounds._sw.lat,
        },
        scheme_id: this.props.scheme.id,
        in_zone: false,
        where: this._generateModuleFilters(),
        suspended: this._generateZoneFilters(),
      },
      query: ModulesMapDocument,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev
        }

        if (!prev) {
          return fetchMoreResult
        }

        // Merge the data from the previous query, if any duplicates occur then take the new value
        const moduleNodes = unionWith(
          fetchMoreResult.modules.nodes,
          prev.modules.nodes,
          (p, n) => p.id === n.id,
        )

        // Merge the data from the previous query, if any duplicates occur then take the new value
        const zoneNodes = unionWith(
          fetchMoreResult.all_zones.nodes,
          prev.all_zones.nodes,
          (p, n) => p.id === n.id,
        )

        return {
          ...prev,
          modules: {
            __typename: prev.modules.__typename,
            nodes: moduleNodes,
          },
          zones: {
            __typename: prev.all_zones.__typename,
            nodes: zoneNodes,
          },
        }
      },
    })
  }

  render() {
    const { coords, legendProps, bounds, pendingLocation } = this.state

    const {
      scheme: { id: scheme_id },
    } = this.props

    if (pendingLocation) {
      return <Loading />
    }

    return (
      <>
        <HeadTitle pageTitle="Overview" />

        <Query
          showEmpty={false}
          waitFor="data"
          variables={{
            scheme_id,
            location: bounds,
            in_zone: false,
            where: this._generateModuleFilters(),
            suspended: this._generateZoneFilters(),
          }}
          query={ModulesMapDocument}
          pollInterval={0}
        >
          {(props: QueryResult<ModulesMapQuery>) => {
            if (props.loading && props.previousData) {
              props.data = props.previousData
            }

            const allZones = props?.data?.all_zones?.nodes ?? []

            const normalZones = allZones.filter(
              z => z.zone_type === ZoneType.normal,
            )
            const workshopZones = allZones.filter(
              z => z.zone_type === ZoneType.workshop,
            )

            const modules = (props?.data?.modules?.nodes ?? []).map(
              getDerivedModule,
            )

            this._fetchMore = props.fetchMore

            const schemeCoords = calculateSchemeCoordinates(
              this.props.serviceAreas,
              viewport,
            )
            const latitude = schemeCoords?.coords?.latitude ?? coords.latitude
            const longitude =
              schemeCoords?.coords?.longitude ?? coords.longitude

            return (
              <ModulesMap
                layers={[
                  this._generateServiceAreasLayer(),
                  this._generateNormalZonePolygonLayer(normalZones),
                  this._generateWorkshopZonePolygonLayer(workshopZones),
                  this._generateModuleIconLayer(modules),
                  ...this._generateParkingZoneLayers(normalZones),
                  ...this._generateWorkshopParkingZoneLayers(workshopZones),
                ]}
                latitude={latitude}
                longitude={longitude}
                zoom={schemeCoords.zoom !== Infinity ? schemeCoords.zoom : 15}
                pollInterval={REDUCED_POLL_INTERVAL}
                onViewStateChange={this._fetchData}
                {...props}
              >
                {legendProps.type && (
                  <Legend
                    {...legendProps}
                    onClose={this._clearLegend}
                    where={this._generateModuleFilters()}
                  />
                )}
                {this._renderLayerToggle()}
              </ModulesMap>
            )
          }}
        </Query>
      </>
    )
  }
}

export default withSchemeServiceAreas(ModulesMapContainer)
