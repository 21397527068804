import { gql } from '@apollo/client'
import { IconLayer, TextLayer, GeoJsonLayer } from 'deck.gl'
import { feature, featureCollection } from '@turf/helpers'

import { colors } from '@weareberyl/design-system'

import { ModulesNodes_nodes as MODULE } from 'components/Map/__generated__/ModulesNodes'
import { MODULES_MAP_all_zones_nodes as ZONE } from 'components/MapView/__generated__/MODULES_MAP'
import { SERVICE_AREAS_GEOJSON } from 'components/Scheme/withSchemeServiceAreas'
import { ModuleHardware } from '__generated__/globalTypes'

import {
  formatDatetime,
  getPositionFromLocation,
  getPositionFromCoordinates,
} from 'utils'

import {
  MODULE_ICON_MAPPING,
  BIKE_ICON,
  EBIKE_ICON,
  ZONE_ICON_MAPPING,
  LAKE_PIN_COLOUR,
  JAFFA_PIN_COLOUR,
  BRICK_PIN_COLOUR,
  BERYL_GREEN_PIN_COLOUR,
} from 'components/Map/constants'

import pinIconMap from 'images/pins.png'
import locationPin from 'images/location-pin.png'
import zoneIconMap from 'images/bays.png'
import spanner from 'images/spanner.svg'
import { getDerivedZone } from 'utils/derived'
import { JobListQuery_table_JobConnection_nodes_Job as Job } from 'gql/generated/graphql'
import { useJobTypes } from 'hooks'
import { ModuleMapQuery_module_Module } from 'gql/generated/graphql'

export type ServiceAreaGeoJsonLayerProps = {
  serviceAreas: SERVICE_AREAS_GEOJSON
  layerId: string
  layerProps?: object
}

const hexToRgb = (input: string) => {
  const m = input.match(/^#([0-9a-f]{6})$/i)
  if (m) {
    return [
      parseInt(m[1].substr(0, 2), 16),
      parseInt(m[1].substr(2, 2), 16),
      parseInt(m[1].substr(4, 2), 16),
    ]
  }

  throw new Error(`Invalid hex: {input}`)
}

const serviceAreaTypeIndex = {
  normal: 1,
  go_slow: 2,
  no_go: 3,
}

export const ServiceAreaGeoJsonLayer = ({
  serviceAreas,
  layerId,
  layerProps,
}: ServiceAreaGeoJsonLayerProps) => {
  // Create shallow copies of serviceAreas and serviceAreas.features
  // as both are read-only
  serviceAreas = { ...serviceAreas }
  serviceAreas.features = [...(serviceAreas.features || [])]
  // ensure that normal service areas are at the bottom of the layer
  serviceAreas.features = serviceAreas.features.sort((a, b) => {
    const aIndex = serviceAreaTypeIndex[a.properties.service_area_type] ?? 1
    const bIndex = serviceAreaTypeIndex[b.properties.service_area_type] ?? 1

    return aIndex - bIndex
  })

  return new GeoJsonLayer({
    id: layerId,
    data: serviceAreas,
    filled: true,
    pickable: true,
    opacity: 0.2,
    getFillColor: data => {
      if (data?.properties?.service_area_type === 'no_go') {
        // black
        return hexToRgb(colors.deepPurple)
      }

      if (data?.properties?.service_area_type === 'go_slow') {
        // yellow
        return hexToRgb(colors.jaffaPressed)
      }

      // none
      return [128, 0, 0, 0]
    },
    getLineColor: data => {
      if (data?.properties?.service_area_type === 'no_go') {
        // black
        return hexToRgb(colors.deepPurple)
      }

      if (data?.properties?.service_area_type === 'go_slow') {
        // yellow
        return hexToRgb(colors.jaffaPressed)
      }
      // red
      return hexToRgb(colors.alert)
    },
    lineWidthMinPixels: 2,
    getLineWidth: _ => 3,
    ...layerProps,
  })
}

type ModuleIconLayerProps = {
  modules: Array<MODULE | ModuleMapQuery_module_Module>
  layerId: string
  onHover?: (obj: object) => void
  layerProps?: object
  includeNonPresentModules?: boolean
}

export const ModuleIconLayer = ({
  modules,
  layerId,
  onHover,
  layerProps,
  includeNonPresentModules = false,
}: ModuleIconLayerProps) => {
  if (modules.length <= 0) {
    return null
  }

  const getIcon = (m: MODULE) => {
    let hardwareType: ModuleHardware
    switch (m.hardware_type) {
      case ModuleHardware.beryl_bike:
      case ModuleHardware.bbe:
      case ModuleHardware.scooter:
        hardwareType = m.hardware_type
        break
      default:
        hardwareType = ModuleHardware.beryl_bike
    }

    const hasReward = !!m?.vehicle?.rewards?.length
    let icon = `${hardwareType}-jade`

    const message =
      m?.lock_state?.vehicle_lock_state ?? m?.lock_state?.overall_state
    if (message?.includes('unlocked')) {
      icon = `${hardwareType}-jaffa`
    }
    if (hasReward) {
      icon = `${icon}-promotional`
    }
    return icon
  }

  const getSize = (m: MODULE) => {
    const scale = 0.8
    switch (m.hardware_type) {
      case ModuleHardware.bbe:
        return EBIKE_ICON.height * scale
      default:
        return BIKE_ICON.height * scale
    }
  }

  const transformIconProperties = (m: MODULE | null) => {
    if (m?.location) {
      const location = m.location
      const position = getPositionFromLocation(location)

      return {
        properties: {
          id: m?.id,
          position,
          max_error: location?.max_error,
          time: formatDatetime(location?.time),
        },
      }
    }
  }

  return new IconLayer({
    id: layerId,
    iconAtlas: pinIconMap,
    pickable: true,
    data: modules.filter(
      m => m?.location?.longitude && (includeNonPresentModules || m.is_present),
    ),
    getSize,
    iconMapping: MODULE_ICON_MAPPING,
    getIcon,
    getPosition: ({ location: l }) => [l.longitude, l.latitude],
    autoHighlight: true,
    highlightColor: [0, 0, 0, 32],
    onHover: !onHover
      ? null
      : args => {
          return onHover({
            ...args,
            object: transformIconProperties(args.object),
          })
        },
    ...layerProps,
  })
}

ModuleIconLayer.fragments = {
  modules: gql`
    fragment ModulesNodes on ModuleConnection {
      nodes {
        id
        hardware_type
        is_present
        location {
          id
          latitude
          longitude
          max_error
          time
        }
        lock_state {
          id
          time
          overall_state
          vehicle_lock_state
        }
        vehicle {
          id
          frame_type
          rewards {
            type
          }
        }
      }
    }
  `,
  module: gql`
    fragment ModuleNode on Module {
      id
      hardware_type
      is_present
      location {
        id
        latitude
        longitude
        max_error
        time
      }
      lock_state {
        id
        time
        overall_state
        vehicle_lock_state
      }
      vehicle {
        id
        frame_type
        rewards {
          type
        }
      }
    }
  `,
}

const formatZoneFeatures = (zoneData: ZONE[], scheme_id?: string | null) => {
  const features = zoneData
    .map(z => getDerivedZone(z, scheme_id))
    .map(
      ({
        id,
        name,
        geom,
        centre,
        bikes,
        ebikes,
        scooters,
        capacity,
        is_suspended,
        zone_type,
      }) => {
        const properties = {
          id,
          name,
          centre,
          bikes,
          ebikes,
          scooters,
          capacity,
          is_suspended,
          zone_type,
        }

        return feature(geom, properties)
      },
    )

  return featureCollection(features)
}

const transformZoneProperties = (zone: ZONE) => {
  if (!zone) return null
  const coords = zone?.centre?.coordinates
  const position = getPositionFromCoordinates(coords)

  return {
    properties: {
      id: zone?.id,
      name: zone?.name,
      position,
      bikes: zone?.bikes,
      ebikes: zone?.ebikes,
      scooters: zone?.scooters,
      capacity: zone?.capacity,
      is_suspended: zone?.is_suspended,
    },
  }
}

type ZoneIconLayersProps = {
  zones: ZONE[]
  layerId: string
  onHover?: (obj: object) => void
  onClick?: (obj: object) => void
  iconLayerProps?: object
  textLayerProps?: object
  scheme_id?: string | null
}

export const WorkshopIconLayers = ({
  zones: zoneData,
  layerId,
  onHover,
  iconLayerProps,
}: ZoneIconLayersProps) => {
  if (zoneData.length <= 0) {
    return []
  }
  const zoneFeatures = formatZoneFeatures(zoneData)
  const zones = zoneFeatures ? zoneFeatures.features.map(f => f.properties) : []
  const workshopIconLayer = new IconLayer({
    id: `workshop-pin-layer-${layerId}`,
    pickable: true,
    data: zones.map((zone, index) => ({
      zone,
      id: `workshop-pin-${index}`,
      location: {
        latitude: zone.centre.coordinates[1],
        longitude: zone.centre.coordinates[0],
      },
    })),
    getSize: 50,
    getIcon: () => ({
      url: spanner,
      height: 150,
      width: 150,
      id: 'spanner',
      mask: true,
    }),
    getColor: () => [238, 149, 0],
    autoHighlight: true,
    highlightColor: [0, 0, 0, 32],
    getPosition: ({ location: l }) => [l.longitude, l.latitude],
    onHover: !onHover
      ? null
      : args => {
          return onHover({
            ...args,
            object: transformZoneProperties(args.object),
          })
        },
    ...iconLayerProps,
  })

  return [workshopIconLayer]
}

export const ZoneIconLayers = ({
  zones: zoneData,
  layerId,
  onHover,
  iconLayerProps,
  textLayerProps,
  scheme_id,
}: ZoneIconLayersProps) => {
  if (zoneData.length <= 0) {
    return []
  }
  const zoneFeatures = formatZoneFeatures(zoneData, scheme_id)
  const zones = zoneFeatures ? zoneFeatures.features.map(f => f.properties) : []

  const getIcon = z => {
    if (z.bikes === 0 && z.ebikes === 0 && z.scooters === 0) {
      return z.is_suspended === true ? 'emptysuspended' : 'empty'
    }
    return `${z.bikes > 0 ? ModuleHardware.beryl_bike : ''}${
      z.ebikes > 0 ? ModuleHardware.bbe : ''
    }${z.scooters > 0 ? ModuleHardware.scooter : ''}${
      z.is_suspended === true ? 'suspended' : ''
    }`
  }

  const zoneIconLayer = new IconLayer({
    id: 'icon-' + layerId,
    iconAtlas: zoneIconMap,
    pickable: true,
    data: zones,
    getSize: 50,
    // Only show available state/icon for now
    iconMapping: ZONE_ICON_MAPPING,
    getIcon,
    getPosition: z => z.centre.coordinates,
    autoHighlight: true,
    highlightColor: [0, 0, 0, 32],
    onHover: !onHover
      ? null
      : args => {
          return onHover({
            ...args,
            object: transformZoneProperties(args.object),
          })
        },
    ...iconLayerProps,
  })

  const zoneTextLayerProps = {
    data: zones,
    getPosition: z => z.centre.coordinates,
    getColor: _ => [60, 60, 67],
    getSize: 22,
    fontFamily: 'Hellix-Bold, Helvetica, Arial, sans-serif',
  }

  const getZoneTextAmount = (z: ZONE) =>
    Number(z.bikes > 0) + Number(z.ebikes > 0) + Number(z.scooters > 0)

  const zoneTextFirstLayer = new TextLayer({
    ...zoneTextLayerProps,
    id: 'first-text-' + layerId,
    getText: z => String(z.bikes || z.ebikes || z.scooters),
    getPixelOffset: z => {
      const amount = getZoneTextAmount(z)
      const offset = {
        '0': [-15, -3],
        '1': [-15, -3],
        '2': [-10, -3],
        '3': [-38, -3],
      }
      return offset[amount]
    },
    ...textLayerProps,
  })

  const zoneTextSecondLayer = new TextLayer({
    ...zoneTextLayerProps,
    id: 'second-text-' + layerId,
    // second layer is only used if there are at least 2 vehicle types, otherwise we return ''
    getText: z =>
      getZoneTextAmount(z) >= 2 ? String(z.ebikes || z.scooters) : '',
    getPixelOffset: z => {
      const amount = getZoneTextAmount(z)
      const offset = {
        '0': [0, 0],
        '1': [0, 0],
        '2': [32, -3],
        '3': [4, -3],
      }
      return offset[amount]
    },
    ...textLayerProps,
  })

  const zoneTextThirdLayer = new TextLayer({
    ...zoneTextLayerProps,
    id: 'third-text-' + layerId,
    // third layer is only used if there are 3 vehicle types, otherwise we return ''
    getText: z => (getZoneTextAmount(z) === 3 ? String(z.scooters) : ''),
    getPixelOffset: [54, -3],
    ...textLayerProps,
  })

  return [
    zoneIconLayer,
    zoneTextFirstLayer,
    zoneTextSecondLayer,
    zoneTextThirdLayer,
  ]
}

ZoneIconLayers.fragments = {
  zone: gql`
    fragment ZoneNode on Zone {
      id
      name
      centre
      capacity
      geom
      is_suspended
      zone_type
      bikes: all_commissioned_modules_count(hardware_type: beryl_bike)
      ebikes: all_commissioned_modules_count(hardware_type: bbe)
      scooters: all_commissioned_modules_count(hardware_type: scooter)
    }
  `,
}

type ZonePolygonLayerProps = {
  zones: ZONE[]
  layerId: string
  visible: boolean
  visibleZoneId?: string | null
}

export const ZonePolygonLayer = ({
  zones: zoneData,
  layerId,
  visible,
  visibleZoneId,
}: ZonePolygonLayerProps) => {
  const zones = formatZoneFeatures(zoneData)

  return new GeoJsonLayer({
    id: layerId,
    visible,
    // Only show 1 zone if visibleZoneId is provided, if null then show none, if undefined show all
    data:
      visibleZoneId || visibleZoneId === null
        ? zones.features.filter(
            ({ properties }) => properties.id === visibleZoneId,
          )
        : zones,
    opacity: 0.4,
    stroked: true,
    filled: true,
    lineWidthMinPixels: 2,
    getRadius: 5,
    getLineWidth: 1,
    lineJointRounded: true,
    getLineColor: [255, 164, 14],
    getFillColor: [255, 164, 14],
  })
}

export const WorkshopPolygonLayer = ({
  zones: zoneData,
  layerId,
  visible,
  onClick,
}: ZonePolygonLayerProps & { onClick: (obj) => void }) => {
  const zones = formatZoneFeatures(zoneData)

  return new GeoJsonLayer({
    id: layerId,
    visible,
    pickable: true,
    data: zones,
    opacity: 0.4,
    stroked: true,
    filled: true,
    lineWidthMinPixels: 2,
    getRadius: 5,
    getLineWidth: 1,
    lineJointRounded: true,
    getLineColor: [238, 149, 0],
    getFillColor: [82, 82, 92],
    autoHighlight: true,
    highlightColor: [0, 0, 0, 32],
    onClick,
  })
}

export const GeocoderPin = (location: {
  latitude: number
  longitude: number
}) => {
  return new IconLayer({
    id: 'geocoder-pin-layer',
    iconAtlas: locationPin,
    pickable: false,
    data: [
      {
        id: 'geocoder-pin',
        location,
      },
    ],
    getSize: 100,
    iconMapping: {
      marker: { x: 0, y: 0, width: 42, height: 60, anchorY: 60, mask: true },
    },
    getIcon: () => 'marker',
    getColor: () => [26, 152, 255], // lake
    getPosition: ({ location: l }) => [l.longitude, l.latitude],
    onHover: null,
  })
}

type JobIconLayerProps = {
  jobs: Array<Job>
  layerId: string
  onClick?: (obj: Record<string, unknown>) => void
  layerProps?: Record<string, unknown>
}

const getColor = (job: Job, jobCode: string) => {
  // Match colouring to Eng App.
  let pinColor
  const priority = job?.details?.priority === 'true'

  // override pin colour for battery swap jobs
  switch (jobCode) {
    case 'e-Scooter Battery Swap':
      pinColor = LAKE_PIN_COLOUR
      break
    case 'e-Bike Battery Swap':
      pinColor = JAFFA_PIN_COLOUR
      break
    default:
      pinColor = priority ? BRICK_PIN_COLOUR : BERYL_GREEN_PIN_COLOUR
  }

  return pinColor
}

type Coordinates = [number, number]
const getCoordinates = (job: Job): Coordinates | null => {
  if (!job) return null
  if (
    job.vehicle &&
    job.vehicle?.detail?.module?.location?.longitude &&
    job.vehicle?.detail?.module?.location?.latitude
  ) {
    return [
      job.vehicle.detail.module.location.longitude,
      job.vehicle.detail.module.location.latitude,
    ]
  }

  if (
    typeof job.details?.location === 'string' &&
    job.details.location.length > 0
  ) {
    return [
      Number(job.details.location.split(',')[1]),
      Number(job.details.location.split(',')[0]),
    ]
  }

  return null
}

export const JobIconLayer = ({
  jobs,
  layerId,
  onClick,
  layerProps,
}: JobIconLayerProps) => {
  const { jobCodeLookup } = useJobTypes()

  if (jobs.length <= 0) return null

  return new IconLayer({
    id: layerId,
    iconAtlas: locationPin,
    iconMapping: {
      marker: { x: 0, y: 0, width: 42, height: 60, anchorY: 60, mask: true },
    },
    getSize: 50,
    pickable: true,
    data: jobs.filter(job => getCoordinates(job) !== null),
    getIcon: () => 'marker',
    getColor: job => getColor(job, jobCodeLookup?.[job.details.taskId]),
    getPosition: job => getCoordinates(job),
    autoHighlight: true,
    highlightColor: [0, 0, 0, 32],
    onClick,
    ...layerProps,
  })
}
