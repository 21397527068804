import { gql, useMutation } from '@apollo/client'
import * as types from './__generated__/requestVehicleMaintenanceEnd'

const query = gql`
  mutation requestVehicleMaintenanceEnd($vehicleId: GUID!) {
    vehicle_maintenance_end(vehicle_id: $vehicleId)
  }
`

export default function useMaintenanceEnd(
  variables?: types.requestVehicleMaintenanceEndVariables,
) {
  return useMutation<
    types.requestVehicleMaintenanceEnd,
    types.requestVehicleMaintenanceEndVariables
  >(query, {
    variables,
    context: {
      useApolloNetworkStatus: false,
    },
  })
}
