import { useHistory } from 'react-router-dom'

import { Button, Card, Flex, Heading } from '@weareberyl/design-system'

import * as routes from 'constants/routes'
import HeadTitle from 'components/HeadTitle'
import CampaignsTable from './CampaignsTable'
import { useListCampaigns } from '../data'
import { useCurrentScheme } from 'hooks'

const CampaignList = () => {
  const history = useHistory()
  const { currentSchemeId } = useCurrentScheme()
  const { data, loading } = useListCampaigns(currentSchemeId)

  return (
    <>
      <HeadTitle pageTitle="Campaigns" />

      <Card p={5}>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Heading variant="callout">Campaigns</Heading>
          {currentSchemeId && (
            <Button
              title="Create new Auto-promo"
              onPress={() =>
                history.push(
                  routes.prependScheme(routes.NEW_CAMPAIGN, currentSchemeId),
                )
              }
            />
          )}
        </Flex>
        <CampaignsTable data={data} loading={loading} />
      </Card>
    </>
  )
}
export default CampaignList
