import { useState, useEffect } from 'react'
import InvalidSignIn from 'components/InvalidSignIn'
import Loading from 'components/Loading'
import { onAuthStateChanged } from 'firebase/auth'
import sentry from 'utils/sentry'

import { auth, authentication, messaging } from 'utils/firebase'
import useCurrentUser from 'hooks/useCurrentUser'
import { useDevices } from 'hooks'
import { getMagicLinkEmail } from 'utils/firebase/authentication'

export default props => {
  const [, setCurrentUser] = useCurrentUser()
  const [loading, setLoading] = useState(true)
  const [invalidSignIn, setInvalidSignin] = useState(false)
  const { fetchDevices } = useDevices()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async authUser => {
      // we check for a magic link first so we ensure the user is logged in,
      // when booting the app otherwise we direct the user to the sign in page
      // when they are actually logging in via magic link.
      if (authUser) {
        const currentUser = await authentication.loadCurrentUser()
        setCurrentUser(currentUser)
        await messaging()
      } else {
        try {
          setCurrentUser(authentication.DEFAULT_USER)
          // if it's a magic link we'll login the user.
          const emailLogin = await authentication.signInWithMagicLink(
            window.location.href,
          )

          if (emailLogin) {
            fetchDevices()
          }
        } catch (err) {
          // if it fails we render the invalidSignIn component
          if (!getMagicLinkEmail()) {
            setInvalidSignin(true)
          }
          sentry.captureException(err)
        }
      }
      setLoading(false)
    })

    // Cleanup subscription on unmount
    return () => unsubscribe()
  }, [setCurrentUser, fetchDevices])

  if (invalidSignIn) {
    return <InvalidSignIn onClick={() => setInvalidSignin(false)} />
  }

  if (loading) {
    return <Loading />
  }

  return props.children
}
