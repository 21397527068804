import Query from 'components/Query'
import { gql, ApolloQueryResult } from '@apollo/client'

import type {
  PAYER,
  PAYERVariables,
  PAYER_schemes_nodes as Scheme,
  PAYER_user_payer_providers as Provider,
} from './__generated__/PAYER'

import { Text, Box, Flex, Heading } from '@weareberyl/design-system'
import { ucfirst } from 'utils'

const Row = ({
  label,
  value,
}: {
  label: string
  value: string
}): JSX.Element => (
  <Flex
    alignItems="baseline"
    justifyContent="space-between"
    borderBottomWidth={1}
    borderColor="oyster"
    pb={2}
    mt={1}
  >
    <Heading variant="h5" pr={3}>
      {label}:
    </Heading>
    <Text variant="small">{value}</Text>
  </Flex>
)

const ProviderBalance = ({
  provider,
  schemes,
}: {
  provider: Provider
  schemes: Record<Scheme['id'], Scheme['name']>
}): JSX.Element => {
  const formatted_currency_amount = provider.balance?.formatted_amount
  const niceProviderType = provider.type.replace('_customer', '')

  return (
    <Box mr="auto">
      <Box mb={3}>
        <Heading variant="h3">{ucfirst(niceProviderType)} balance</Heading>
      </Box>

      {formatted_currency_amount && !provider.usable_minute_balance && (
        <Text variant="regular">{formatted_currency_amount}</Text>
      )}
      {provider.usable_minute_balance?.map(item => {
        const name = schemes[item.scheme_id]
        // Don't bother rendering if they're all the same
        if (provider.legacy_minute_balance?.amount === item.balance.amount) {
          return null
        }
        return (
          <Row
            key={item.scheme_id}
            label={name ?? item.scheme_id}
            value={item.balance.formatted_amount}
          />
        )
      })}
      {provider.legacy_minute_balance && (
        <Row
          label="Legacy balance"
          value={provider.legacy_minute_balance.formatted_amount}
        />
      )}
    </Box>
  )
}

export const QUERY = gql`
  query PAYER($user_id: ID!) {
    user(user_id: $user_id) {
      id
      payer {
        id
        providers {
          id
          type
          balance {
            formatted_amount
            amount
          }
          legacy_minute_balance {
            formatted_amount
            amount
          }
          usable_minute_balance {
            scheme_id
            balance {
              formatted_amount
              amount
            }
          }
        }
      }
    }
    schemes(paginate: { per_page: 100 }) {
      nodes {
        id
        name
      }
    }
  }
`

export default ({ user_id }: PAYERVariables) => (
  <Query
    waitFor="data.user.payer.providers"
    query={QUERY}
    pollInterval={0}
    variables={{ user_id }}
  >
    {({ data }: ApolloQueryResult<PAYER>) => {
      const providers = data?.user?.payer?.providers || []
      if (!data.schemes) {
        return null
      }
      // Make a lookup for scheme name by ID
      const schemes: Record<Scheme['id'], Scheme['name']> = Object.fromEntries(
        data?.schemes.nodes.map(scheme => [scheme.id, scheme.name]),
      )

      return (
        <Flex flex={1}>
          {providers.map(provider => {
            return (
              <ProviderBalance
                key={provider.id}
                provider={provider}
                schemes={schemes}
              />
            )
          })}
        </Flex>
      )
    }}
  </Query>
)
