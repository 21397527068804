import { Component } from 'react'
import { injectStripe, Elements, CardElement } from 'react-stripe-elements'
import { Mutation } from '@apollo/client/react/components'
import { gql, MutationFunction, OperationVariables } from '@apollo/client'
import { message } from 'antd'
import { Box, Button, colors, borderRadii } from '@weareberyl/design-system'
import { USER_PAYMENT_CARD_QUERY } from 'components/User/PaymentMethods'

const CREATE_SETUP_INTENT = gql`
  mutation CREATE_SETUP_INTENT($user_id: ID!) {
    create_setup_intent(user_id: $user_id) {
      client_secret
    }
  }
`

type FormComponentProps = {
  user_id: string
  stripe?: any
}

type FormComponentState = {
  loading: boolean
  complete: boolean
  focused: boolean
  hasError: boolean
}

type StripeCardElement = {
  clear: () => void
}

type StripeOnChangeEvent = {
  code: number | string
  message: string
  type: string
}

class FormComponent extends Component<FormComponentProps, FormComponentState> {
  _cardElement: StripeCardElement | null

  state = {
    loading: false,
    complete: false,
    focused: false,
    hasError: false,
  }

  onChange = ({
    complete,
    error,
  }: {
    error: StripeOnChangeEvent | undefined
    complete: boolean
  }) => {
    this.setState({ complete, hasError: !!error })
  }

  handleSubmit = async (
    createSetupIntent: MutationFunction<any, OperationVariables>,
  ) => {
    const { user_id, stripe } = this.props
    const { complete, hasError } = this.state
    if (!complete || hasError) {
      return null
    }

    this.setState({ loading: true })

    try {
      // Get the setup intent
      const {
        data: { create_setup_intent },
      }: any = await createSetupIntent({ variables: { user_id } })

      const result = await stripe.handleCardSetup(
        create_setup_intent.client_secret,
        this._cardElement,
      )

      // everything went well
      this._cardElement?.clear()

      // Handle result.error or result.setupIntent
      if (result.error) {
        // tslint:disable-next-line
        message.error(
          `Something went wrong when trying to add a card: ${result.error.message}`,
        )
        this.setState({ loading: false })
      } else {
        message.info('Payment card added successfully!')
      }
    } catch (err) {
      // tslint:disable-next-line
      message.error(
        `Something went wrong when trying to add a card: ${err.message}`,
      )
      this.setState({ loading: false })
    }
  }

  render() {
    const { hasError } = this.state
    const { user_id } = this.props

    return (
      <Mutation
        onError={err => message.error(`Something went wrong: ${err.message}`)}
        mutation={CREATE_SETUP_INTENT}
        refetchQueries={[
          {
            query: USER_PAYMENT_CARD_QUERY,
            variables: { user_id },
          },
        ]}
      >
        {(createSetupIntent, { loading }) => {
          return (
            <>
              <Box
                bg="oyster"
                px={3}
                borderRadius={borderRadii.default}
                border={
                  hasError ? `solid ${colors.brick}` : `solid ${colors.oyster}`
                }
              >
                <div className="CardDetailsForm-fieldWrapper">
                  <CardElement
                    style={{ base: { fontSize: '14px' } }}
                    disabled={this.state.loading || loading}
                    onReady={element => (this._cardElement = element)}
                    onFocus={() => this.setState({ focused: true })}
                    onBlur={() => this.setState({ focused: false })}
                    onChange={this.onChange}
                  />
                </div>
              </Box>
              <Box width="150px">
                <Button
                  mt={3}
                  title="Add card"
                  onPress={() => this.handleSubmit(createSetupIntent)}
                />
              </Box>
            </>
          )
        }}
      </Mutation>
    )
  }
}

const Form = injectStripe(FormComponent)

const CardDetailsForm = ({ id }: { id: string }) => {
  return (
    <Elements>
      <Form user_id={id} />
    </Elements>
  )
}

export default CardDetailsForm
