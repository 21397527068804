import {
  UserCredential,
  sendSignInLinkToEmail as fbSendSignInLinkToEmail,
  signInWithEmailAndPassword as fbSignInWithEmailAndPassword,
  signInWithPopup as fbSignInWithPopup,
  isSignInWithEmailLink as fbIsSignInWithEmailLink,
  signInWithEmailLink as fbSignInWithEmailLink,
} from 'firebase/auth'
import { auth, googleAuthProvider } from './firebase'
import history from 'client/history'
import * as routes from 'constants/routes' // skipcq JS-C1003
import * as storageKeys from 'constants/localStorage' // skipcq JS-C1003
import { isStaffRole, STAFF_ROLE_TYPES } from 'constants/roles'
import { CURRENT_USER, CURRENT_USER_ROLES } from 'types'
import { isNotNullOrUndefined } from '../types'
import { trackLogin, trackLogout } from './firestore'

// This is the role type of the jwt not the API
export type IRole = CURRENT_USER_ROLES

export const sendSignInLinkToEmail = (email: string): Promise<void> => {
  window.localStorage.setItem(storageKeys.MAGIC_LINK_EMAIL_KEY, email)
  let redirectUrl = window.location.href
  if (window.location.pathname.search('/logout') > -1) {
    // Don't redirect users to /logout, goto default route
    redirectUrl = `${window.location.origin}/`
  }
  return fbSendSignInLinkToEmail(auth, email, {
    url: redirectUrl,
    handleCodeInApp: true,
  })
}

export const signInWithEmailAndPassword = (
  email: string,
  password: string,
): Promise<UserCredential> => {
  return fbSignInWithEmailAndPassword(auth, email, password)
}

export const signInWithGoogleUsingPopup = (): Promise<UserCredential> => {
  return fbSignInWithPopup(auth, googleAuthProvider)
}

export const getMagicLinkEmail = () =>
  window.localStorage.getItem(storageKeys.MAGIC_LINK_EMAIL_KEY)

export const signInWithMagicLink = (url: string): Promise<boolean> => {
  if (fbIsSignInWithEmailLink(auth, url)) {
    const email = window.localStorage.getItem(storageKeys.MAGIC_LINK_EMAIL_KEY)
    if (!email) {
      throw new Error('MagicLinkLocalStorageError')
    }
    // The client SDK will parse the code from the link for you.
    return fbSignInWithEmailLink(auth, email, url).then(_result => {
      window.localStorage.removeItem(storageKeys.MAGIC_LINK_EMAIL_KEY)
      trackLogin()
      return true
    })
  }
  return Promise.resolve(false)
}

const getIsSuperRole = (role: string) => {
  return (roles: IRole[]) => {
    return roles.some(r => r.role === role)
  }
}

export const isSuperMarketing = getIsSuperRole('super_marketing')
export const isSuperAdmin = getIsSuperRole('super_admin')
export const isSuperWorkshop = getIsSuperRole('super_workshop')

export const getSchemesForRole = (
  roles: IRole[],
  roleType: string,
): number[] => {
  // Return an array of schemes for which the user is of the specified role
  if (roles?.filter) {
    return roles
      .filter(role => role.role === roleType)
      .map(role => role.scheme_id)
      .filter(isNotNullOrUndefined)
  } else {
    return []
  }
}

export const expandOrgRoles = (claims: CustomClaims) => {
  if (!claims.org_schemes || !claims.roles) {
    return claims
  }

  const expandedRoles = claims.roles.flatMap((role: IRole) => {
    const schemes = (role.org_id && claims.org_schemes?.[role.org_id]) || []

    const roles: IRole[] = schemes.map((schemeId: number) => ({
      role: role.role,
      scheme_id: schemeId,
      org_id: null,
    }))

    return roles
  })

  claims.roles = [...claims.roles, ...expandedRoles]
  return claims
}

export type CustomClaims = {
  user_id?: string | null
  email?: string | null
  roles?: IRole[]
  org_schemes?: { [key: string]: number[] }
}

export const customClaims = async (): Promise<CustomClaims> => {
  const emptyClaims = {}
  if (!auth.currentUser) {
    return emptyClaims
  }
  try {
    const token = await auth.currentUser.getIdToken()
    const payload = JSON.parse(atob(token.split('.')[1]))
    const claimsWithSchemeRoles = expandOrgRoles(payload)
    return claimsWithSchemeRoles as CustomClaims
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.error('Error parsing custom claims', err)
    return emptyClaims
  }
}

export const DEFAULT_USER: CURRENT_USER = {
  email: null,
  is_super_admin: false,
  scheme_admin: [],
  id: null,
  roles: [],
}

export const loadCurrentUser = async (): Promise<CURRENT_USER> => {
  const { user_id, email, roles } = await customClaims()
  if (!user_id) {
    return DEFAULT_USER
  }

  // Add typename into roles array - needed by caching
  let typed_roles: IRole[] = []

  if (roles?.map) {
    typed_roles = roles.map(role => {
      return {
        scheme_id: role.scheme_id || null,
        role: role.role,
        org_id: role.org_id || null,
      }
    })
  }

  return {
    id: user_id,
    email: email ?? null,
    is_super_admin: isSuperAdmin(roles ?? []),
    // The following are arrays of scheme_ids for which the current user has that role
    scheme_admin: getSchemesForRole(roles ?? [], 'admin'),
    roles: typed_roles,
  }
}

// Sign out
export const doSignOut = async () => {
  await trackLogout()
  auth.signOut()
  Object.keys(storageKeys)
    .map(k => storageKeys[k]) // skipcq JS-E1007
    .forEach(val => {
      localStorage.removeItem(val)
    })
  history.push(routes.SIGN_IN)
}

export const hasRoleForScheme = (
  roles: IRole[],
  roleType: string,
  scheme_id: number | '*' | null,
): boolean => {
  // Return true if the user has the specified role for the scheme
  if (roles?.filter) {
    return (
      roles
        .filter(role => role.role === roleType)
        .filter(role => {
          // Handle the case where the scheme_id on the role hasn't been defined, and we're matching
          // against a null scheme_id
          if (!role.scheme_id && !scheme_id) {
            return true
          } else if (scheme_id === '*') {
            return true
          } else {
            return role.scheme_id === scheme_id
          }
        }).length > 0
    )
  } else {
    return false
  }
}

export const hasAnyRelevantPermission = (roles?: IRole[]) =>
  typeof roles !== 'undefined' &&
  STAFF_ROLE_TYPES.some(role => hasRoleForScheme(roles, role, '*'))

const getRoleTypesByScheme = (roles: IRole[], scheme_id: number): string[] => {
  // Return true if the user has the specified role for the scheme
  if (roles?.filter) {
    return roles
      .filter(role => role.scheme_id === scheme_id)
      .map(role => role.role)
  } else {
    return []
  }
}

export const isSchemeStaff = (roles: IRole[], scheme_id: number) => {
  const roleTypes = getRoleTypesByScheme(roles, scheme_id)

  return (
    isSuperAdmin(roles) || isSuperWorkshop(roles) || roleTypes.some(isStaffRole)
  )
}
