import { useEffect, useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import { RouteComponentProps, withRouter } from 'react-router'
import {
  Flex,
  Box,
  Card,
  Heading,
  TextLink,
  Button,
} from '@weareberyl/design-system'
import { Modal } from 'antd'
import { LoadingComponent, ErrorComponent } from 'components/Query/utils'
import SyncTabs, { SyncTab } from 'components/shared/SyncTabs'
import Diff from './Diff'
import * as routes from 'constants/routes'
import { isEmptySyncData } from 'utils'

import { SERVICE_AREAS_SYNC } from './__generated__/SERVICE_AREAS_SYNC'

const SERVICE_AREAS_SYNC_QUERY = gql`
  mutation SERVICE_AREAS_SYNC($scheme_id: ID!, $dry_run: Boolean) {
    sync_service_areas(scheme_id: $scheme_id, dry_run: $dry_run) {
      added {
        ... on DraftServiceArea {
          name
          geom
        }
      }
      updated {
        id
        name
        geom
      }
      deleted {
        id
        geom
        name
      }
    }
  }
`

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
]

type Props = RouteComponentProps & { scheme_id: string }

const ServiceAreaSync = ({ scheme_id, history }: Props) => {
  const [syncServiceAreas, { data, loading, error }] =
    useMutation<SERVICE_AREAS_SYNC>(SERVICE_AREAS_SYNC_QUERY)

  const [modelVisibility, setModalVisibility] = useState(false)
  const [selectedServiceArea, setSelectedServiceArea] = useState({})

  useEffect(() => {
    syncServiceAreas({
      variables: { scheme_id, dry_run: true },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    return <ErrorComponent error={error} />
  }

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <div>
      <Modal
        open={modelVisibility}
        footer={null}
        onCancel={() => setModalVisibility(false)}
        width="800px"
      >
        <Heading variant="h1">Differences</Heading>
        <Box mt={4}>
          <Diff columns={columns} serviceArea={selectedServiceArea} />
        </Box>
      </Modal>

      <Card p={5}>
        <Flex justifyContent="space-between" alignItems="flex-end" mb={5}>
          <Heading variant="callout">Sync Service Areas</Heading>
          {!isEmptySyncData(data?.sync_service_areas ?? null) && (
            <Flex>
              <Button
                title="Reject all"
                onPress={async () => {
                  history.push(`/scheme/${scheme_id}${routes.SERVICE_AREAS}`)
                }}
                variant="outline"
                mr={3}
                width="140px"
              />
              <Button
                title="Accept all"
                onPress={async () => {
                  await syncServiceAreas({
                    variables: { scheme_id, dry_run: false },
                  })

                  history.goBack()
                }}
                variant="primary"
                width="140px"
              />
            </Flex>
          )}
        </Flex>
        <SyncTabs
          id="service-areas-sync"
          data={data?.sync_service_areas ?? null}
          columns={tab =>
            tab === SyncTab.updated
              ? [
                  ...columns,
                  {
                    render: serviceArea => {
                      return (
                        <TextLink
                          variant="gray"
                          onPress={() => {
                            setSelectedServiceArea(serviceArea)
                            setModalVisibility(true)
                          }}
                        >
                          See differences
                        </TextLink>
                      )
                    },
                  },
                ]
              : columns
          }
        />
      </Card>
    </div>
  )
}

export default withRouter(ServiceAreaSync)
