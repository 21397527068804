import { WarningFilled } from '@ant-design/icons'

import { Center } from 'components/Layout'

export default () => {
  return (
    <Center className="text-center">
      <h3>You do not have permissions required to view this resource</h3>
      <WarningFilled style={{ fontSize: '50px' }} />
    </Center>
  )
}
