import {
  ListValidationRulesQueryResult,
  useListValidationRulesQuery,
  ListValidationRulesQuery_table_ValidationRuleConnection_nodes_ValidationRule,
  ListValidationRulesQuery_Query,
  ListValidationRulesQuery_table_ValidationRuleConnection_nodes_ValidationRule_rules_list_ValidationCriterion,
} from 'gql/generated/graphql'

export type ValidationRulesItem =
  ListValidationRulesQuery_table_ValidationRuleConnection_nodes_ValidationRule
export type ValidationCriterion =
  ListValidationRulesQuery_table_ValidationRuleConnection_nodes_ValidationRule_rules_list_ValidationCriterion

// Define a skeleton of data so the table can be shown
// with the loading state if there isn't any data yet
export const defaultData: ListValidationRulesQuery_Query = {
  __typename: 'Query',
  table: {
    __typename: 'ValidationRuleConnection',
    nodes: [],
    pagination: {
      __typename: 'Pagination',
      total: 0,
      current: 0,
      pageSize: 0,
    },
  },
}

export type UseListValidationRules = Pick<
  ListValidationRulesQueryResult,
  'data' | 'loading'
>

export const useListValidationRules = () => {
  const { data, loading } = useListValidationRulesQuery({
    pollInterval: 0,
    fetchPolicy: 'cache-first',
  })
  return { data, loading }
}
