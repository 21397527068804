// Custom query component
import React, { Component, useEffect, useRef, useState } from 'react'
import { Query } from '@apollo/client/react/components'
import {
  useQuery as useApolloQuery,
  FetchPolicy,
  ErrorPolicy,
  OperationVariables,
} from '@apollo/client'
import Visibility from 'visibilityjs'

import { LoadingComponent, ErrorBoundary, EmptyComponent } from './utils'
import { get, isEmpty, noop, isEqual, isNumber } from 'lodash'
import { DocumentNode, OperationDefinitionNode } from 'graphql'

// wrap every query component in an Error boundary
// also run render method through the lifecycle HoF

type BasisQueryProps = {
  query: DocumentNode
  variables?: OperationVariables
  waitFor: string
  pollInterval?: number
  fetchPolicy?: FetchPolicy
  errorPolicy?: ErrorPolicy
  opts?: { showRefetchLoader: boolean }
  children?: React.ReactNode
  onCompleted?: (data: any) => void
  defaultData?: Record<string, any>
  showEmpty?: boolean
}

type BasisQueryState = {
  visible: boolean
  polling: boolean
}

const Child = ({ children, ...props }) => {
  return children(props)
}

export default class BasisQuery extends Component<
  BasisQueryProps,
  BasisQueryState
> {
  static defaultProps = {
    opts: {
      showRefetchLoader: false,
    },
    errorPolicy: 'all',
    showEmpty: true,
  }

  state = {
    visible: true,
    polling: this.shouldPoll(),
  }

  listener: number | boolean
  startPolling: () => void = noop
  stopPolling: () => void = noop

  componentDidMount() {
    this.listener = Visibility.change((e, state) => {
      if (state === 'hidden' && this.shouldPoll()) {
        this.setState({ visible: false })
      }

      if (state === 'visible' && this.shouldPoll()) {
        this.setState({ visible: true })
      }
    })
  }

  componentWillUnmount() {
    if (isNumber(this.listener)) {
      Visibility.unbind(this.listener)
    }
  }

  componentDidUpdate(prevProps: BasisQueryProps) {
    const { visible } = this.state

    if (!isEqual(prevProps.variables, this.props.variables) || !visible) {
      this.stopPolling()
    } else if (this.shouldPoll() && visible) {
      this.startPolling()
    }
  }

  shouldPoll() {
    return this.props.pollInterval !== 0
  }

  setStartPolling(queryProps) {
    this.startPolling = () => {
      if (!this.state.polling) {
        queryProps.startPolling(
          this.props.pollInterval ||
            queryProps.client.defaultOptions.watchQuery.pollInterval,
        )

        // tslint:disable-next-line
        console.log(
          'started polling',
          (this.props.query?.definitions?.[0] as OperationDefinitionNode).name
            ?.value,
        )

        this.setState({ polling: true })
      }
    }
  }

  setStopPolling(queryProps) {
    this.stopPolling = () => {
      if (this.state.polling) {
        queryProps.stopPolling()
        // tslint:disable-next-line
        console.log(
          'stopped polling',
          (this.props.query?.definitions?.[0] as OperationDefinitionNode).name
            ?.value,
        )

        this.setState({ polling: false })
      }
    }
  }

  render() {
    const { waitFor, opts, children, showEmpty, defaultData, ...props } =
      this.props

    return (
      <ErrorBoundary>
        <Query notifyOnNetworkStatusChange {...props}>
          {queryProps => {
            this.setStartPolling(queryProps)
            this.setStopPolling(queryProps)

            const data = get(queryProps, waitFor)
            // Only show the spinner if its the first time fetching the data since remount
            // AND there is nothing in the cache or the component explicitly asks to show the loader
            if (!defaultData) {
              if (
                queryProps.networkStatus === 1 &&
                (isEmpty(data) || opts?.showRefetchLoader)
              ) {
                return <LoadingComponent />
              }

              // if the required prop is not defined and no errors return empty list
              if (isEmpty(data) && !queryProps.error && showEmpty) {
                return <EmptyComponent />
              }
            } else if (isEmpty(data)) {
              queryProps.data = defaultData
            }

            // throw network/gql error
            if (queryProps.error && props.errorPolicy !== 'all') {
              throw queryProps.error
            }

            // Child is a hack so that this ErrorBoundary catches
            // errors from children(queryProps).
            // Could not find any docs on it but assume render props
            // dont count as children?
            return (
              <ErrorBoundary rootError={queryProps.error}>
                <Child {...queryProps}>{children}</Child>
              </ErrorBoundary>
            )
          }}
        </Query>
      </ErrorBoundary>
    )
  }
}

export const useQuery = ({
  query,
  variables,
  waitFor,
  pollInterval,
  fetchPolicy,
  errorPolicy = 'all',
  opts = { showRefetchLoader: false },
  onCompleted,
}: Omit<BasisQueryProps, 'children'>) => {
  const listener = useRef<number>()

  const shouldPoll = pollInterval !== 0

  const [visible, setVisible] = useState(true)
  const [polling, setPolling] = useState(shouldPoll)

  useEffect(() => {
    listener.current = Visibility.change((e, state) => {
      if (state === 'hidden' && shouldPoll) {
        setVisible(false)
      }
      if (state === 'visible' && shouldPoll) {
        setVisible(true)
      }
    }) as number

    return () => {
      if (listener.current) {
        Visibility.unbind(listener.current)
      }
    }
  }, [shouldPoll])

  const queryProps = useApolloQuery(query, {
    variables,
    pollInterval: pollInterval ?? 0,
    fetchPolicy,
    errorPolicy,
    onCompleted,
    notifyOnNetworkStatusChange: true,
  })

  const startPolling = () => {
    const interval =
      pollInterval ?? queryProps.client.defaultOptions.watchQuery?.pollInterval

    if (!polling && interval) {
      queryProps.startPolling(interval)

      // tslint:disable-next-line
      console.log(
        `Started polling ${interval}`,
        (query?.definitions?.[0] as OperationDefinitionNode).name?.value,
      )

      setPolling(true)
    }
  }

  const stopPolling = () => {
    if (polling) {
      queryProps.stopPolling()
      // tslint:disable-next-line
      console.log(
        'stopped polling',
        (query?.definitions?.[0] as OperationDefinitionNode).name?.value,
      )

      setPolling(false)
    }
  }

  useEffect(() => {
    if (!visible) {
      stopPolling()
    } else if (shouldPoll && visible) {
      startPolling()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldPoll, visible])

  const data = get(queryProps, waitFor)

  // Only show the spinner if its the first time fetching the data since remount
  // AND there is nothing in the cache or the component explicitly asks to show the loader
  if (
    queryProps.networkStatus === 1 &&
    (isEmpty(data) || opts.showRefetchLoader)
  ) {
    return { queryProps, Component: LoadingComponent }
  }

  // if the required prop is not defined and no errors return empty list
  if (isEmpty(data) && !queryProps.error) {
    return { queryProps, Component: EmptyComponent }
  }

  // throw network/gql error
  if (queryProps.error && errorPolicy !== 'all') {
    throw queryProps.error
  }

  // Child is a hack so that this ErrorBoundary catches
  // errors from children(queryProps).
  // Could not find any docs on it but assume render props
  // dont count as children?
  const Component = ({ children }) => (
    <ErrorBoundary rootError={queryProps.error}>
      <Child {...queryProps}>{children}</Child>
    </ErrorBoundary>
  )

  return { queryProps, Component }
}
