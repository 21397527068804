import { useMemo } from 'react'

import Table from 'components/Table'
import {
  ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module as ModuleInZone,
  useZoneModulesListQuery,
} from 'gql/generated/graphql'
import { isPhone } from 'utils/mobileDetect'
import { columns } from 'components/Module/List'

type ModulesInZoneProps = {
  id: string
}

const ModulesInZone = ({ id }: ModulesInZoneProps) => {
  const { data } = useZoneModulesListQuery({
    variables: {
      zone_id: id,
      is_mobile: isPhone(),
      lean_query: false,
    },
  })

  const filteredNodes = useMemo(
    () => data?.zone?.table?.nodes?.filter(node => node.is_present),
    [data],
  )

  if (!data) {
    return null
  }
  const filteredData = { table: { nodes: filteredNodes ?? [] } }

  return <Table<ModuleInZone> columns={columns} data={filteredData} />
}

export default ModulesInZone
