import { Component } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { CopyOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { Box, Flex, Text } from '@weareberyl/design-system'

type CopyProps = {
  id: string
  text: string
  handler?: (text: string) => void
  showText: boolean
}

type CopyState = {
  copied: boolean
}

export default class Copy extends Component<CopyProps, CopyState> {
  static defaultProps = {
    showText: true,
  }

  state = {
    copied: false,
  }

  render() {
    const { text, handler, id, showText } = this.props

    if (text) {
      return (
        <Flex>
          <CopyToClipboard
            text={text}
            onCopy={() => {
              this.setState({ copied: true })
              if (handler) {
                handler(text)
              }
              setTimeout(() => this.setState({ copied: false }), 1000)
            }}
          >
            <Tooltip open={this.state.copied} title="Copied!">
              <Box mr={1} mt={1}>
                <CopyOutlined style={{ fontSize: 16 }} />
              </Box>
            </Tooltip>
          </CopyToClipboard>
          {showText && (
            <Box flex={1}>
              <Text id={id}>
                <code>{text}</code>
              </Text>
            </Box>
          )}
        </Flex>
      )
    }
    return <div />
  }
}
