import {
  ListCampaignsQueryResult,
  ListCampaignsQuery_Query,
  ListCampaignsQuery_table_CampaignConnection_nodes_CampaignSummary,
  useListCampaignsQuery,
  GetCampaignQuery_campaign,
  GetCampaignQuery_campaign_CampaignSummary,
  GetCampaignQuery_campaign_EoJPromotion,
  useGetCampaignQuery,
} from 'gql/generated/graphql'

export type CampaignSummaryItem =
  ListCampaignsQuery_table_CampaignConnection_nodes_CampaignSummary

export type EoJPromotion = GetCampaignQuery_campaign_EoJPromotion
export type CampaignSummary = GetCampaignQuery_campaign_CampaignSummary
export type CampaignDetail = GetCampaignQuery_campaign

export const useCampaign = (
  campaignId: string,
  currentSchemeId: string | null,
) => {
  const { data, loading } = useGetCampaignQuery(
    currentSchemeId
      ? {
          variables: { schemeId: currentSchemeId, campaignId },
          pollInterval: 0,
        }
      : { skip: true },
  )

  return { data, loading }
}

// Define a skeleton of data so the table can be shown
// with the loading state if there isn't any data yet
export const defaultData: ListCampaignsQuery_Query = {
  __typename: 'Query',
  table: {
    __typename: 'CampaignConnection',
    nodes: [],
    pagination: {
      __typename: 'Pagination',
      total: 0,
      current: 0,
      pageSize: 0,
    },
  },
}

export type UseListCampaigns = Pick<
  ListCampaignsQueryResult,
  'data' | 'loading'
>

export const useListCampaigns = (
  currentSchemeId: string | null,
): UseListCampaigns => {
  const { data, loading } = useListCampaignsQuery(
    currentSchemeId
      ? {
          variables: { schemeId: currentSchemeId },
          pollInterval: 0,
        }
      : { skip: true },
  )

  return { data, loading }
}
