import {
  useImperativeHandle,
  createContext,
  createRef,
  useEffect,
  useState,
  ReactNode,
} from 'react'

export type FeatureFlags = {
  vehicle_state_events: boolean
  promotions_nav: boolean
}

export const ContextRef = createRef<FeatureFlags>()

const defaultFeatureFlags: FeatureFlags = {
  vehicle_state_events: false,
  promotions_nav: false,
}

type FeatureFlagsCtx = [FeatureFlags, (featureFlags: FeatureFlags) => void]
export const FeatureFlagsContext = createContext<FeatureFlagsCtx>([
  { ...defaultFeatureFlags },
  () => {},
])

type Props = {
  children: ReactNode
}

function storeFeatureFlags(featureFlags: FeatureFlags) {
  for (const key in featureFlags) {
    const value = featureFlags[key] ? '1' : '0'
    localStorage.setItem(`__feature_flag__.${key}`, value)
  }
}

export default ({ children }: Props) => {
  const [featureFlags, setFeatureFlags] = useState(defaultFeatureFlags)

  // https://stackoverflow.com/questions/59232118/how-to-acess-react-context-from-apollo-set-context-http-link
  // Allows us to access this context value in our apollo links
  // outside the react tree.
  useImperativeHandle(ContextRef, () => featureFlags)
  useEffect(() => {
    // This runs on component mount
    const loadedFlags = defaultFeatureFlags
    for (const key in defaultFeatureFlags) {
      const value = localStorage.getItem(`__feature_flag__.${key}`)
      loadedFlags[key] = value === '1'
    }
    setFeatureFlags(loadedFlags)
  }, [])

  useEffect(() => {
    // This runs on any featureFlag change
    storeFeatureFlags(featureFlags)
  }, [featureFlags])

  return (
    <FeatureFlagsContext.Provider
      value={[
        featureFlags,
        // always create a new object so that useEffect
        // comparisons are easy.
        flags => setFeatureFlags({ ...flags }),
      ]}
    >
      {children}
    </FeatureFlagsContext.Provider>
  )
}
