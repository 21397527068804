import { Card, Heading, Flex } from '@weareberyl/design-system'

import Table from './Table'
import { ComponentProps } from 'react'
import HeadTitle from 'components/HeadTitle'

export default (props: ComponentProps<typeof Table>) => {
  return (
    <>
      <HeadTitle pageTitle="Journeys" />
      <Card p={5}>
        <Flex justifyContent="space-between" alignItems="flex-end" mb={5}>
          <Heading variant="callout">Journeys</Heading>
        </Flex>

        <Table {...props} />
      </Card>
    </>
  )
}
