const MAX_STR_LENGTH = 25

export const truncateUserStr = (
  str: string,
  maxLength: number = MAX_STR_LENGTH,
): string => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + '...'
  }
  return str
}
