import { useState } from 'react'
import { Menu, message, Modal } from 'antd'
import { TextLink, Button } from '@weareberyl/design-system'
import { Props as ButtonProps } from '@weareberyl/design-system/build/components/Button'
import { gql, useMutation } from '@apollo/client'
import JobModal, { NewJob } from '../Modal'
import { DELETE_JOB, DELETE_JOBVariables } from './__generated__/DELETE_JOB'
import { UPDATE_JOB, UPDATE_JOBVariables } from './__generated__/UPDATE_JOB'
import { CREATE_JOB, CREATE_JOBVariables } from './__generated__/CREATE_JOB'
import {
  JobListQuery_table_JobConnection_nodes_Job as Job,
  JobStatusEnum,
} from 'gql/generated/graphql'

const confirm = Modal.confirm
type OnSuccess = (() => Promise<void>) | (() => void)

const DELETE_JOB_MUTATION = gql`
  mutation DELETE_JOB($job_id: ID!) {
    delete_job(job_id: $job_id)
  }
`

const DeleteJob = ({ job, onSuccess }: { job: Job; onSuccess: OnSuccess }) => {
  const [deleteJob] = useMutation<DELETE_JOB, DELETE_JOBVariables>(
    DELETE_JOB_MUTATION,
  )

  return (
    <TextLink
      variant="grape"
      size="small"
      onPress={() => {
        confirm({
          zIndex: 1050,
          title: `Are you sure?`,
          content: `Please confirm that you would like to delete ${job.uuid}`,
          onOk: async () => {
            try {
              await deleteJob({ variables: { job_id: job.uuid } })
              message.success('Job deleted!')
              await onSuccess()
            } catch (err) {
              message.error('Something went wrong')
            }
          },
          onCancel: undefined,
        })
      }}
    >
      Delete
    </TextLink>
  )
}

export const UPDATE_JOB_MUTATION = gql`
  mutation UPDATE_JOB($job_id: ID!, $job: JobUpdateInput!) {
    update_job(job_id: $job_id, job: $job) {
      uuid
      details {
        taskId
        status
        assignee {
          id
          email
        }
        description
        assetTag
        reportedLocation
        locationDescription
        role
        priority
        notes
      }
    }
  }
`

const UpdateJob = ({ job, onSuccess }: { job: Job; onSuccess: OnSuccess }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [updateJob] = useMutation<UPDATE_JOB, UPDATE_JOBVariables>(
    UPDATE_JOB_MUTATION,
  )

  return (
    <>
      <TextLink
        variant="grape"
        size="small"
        onPress={() => {
          setIsModalVisible(true)
        }}
      >
        Update
      </TextLink>
      <JobModal
        title="Update Job"
        job={job}
        isVisible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false)
        }}
        onOk={async (job: Job) => {
          const { uuid, details } = job
          try {
            await updateJob({
              variables: {
                job_id: uuid,
                job: {
                  details: {
                    ...details,
                    assignee: details?.assignee?.id,
                  },
                },
              },
            })
            message.success(`Job updated!`)
            await onSuccess()
          } catch (err) {
            message.error(`Could not update job: ${err.message}`)
          } finally {
            setIsModalVisible(false)
          }
        }}
      />
    </>
  )
}

const CREATE_JOB_MUTATION = gql`
  mutation CREATE_JOB($details: JobInput!) {
    create_job(job: $details) {
      uuid
    }
  }
`

export const CreateJob = ({
  assetTag,
  scheme,
  onSuccess,
  ...rest
}: {
  onSuccess?: OnSuccess
} & ButtonProps &
  NewJob['details']) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [createJob, { loading }] = useMutation<CREATE_JOB, CREATE_JOBVariables>(
    CREATE_JOB_MUTATION,
  )

  return (
    <>
      <Button
        title="Create Job"
        onPress={() => {
          setIsModalVisible(true)
        }}
        {...rest}
      />
      <JobModal
        title="Create Job"
        loading={loading}
        job={{
          details: {
            assetTag,
            scheme,
            locationDescription: '',
            status: JobStatusEnum.new,
            priority: 'low',
          },
        }}
        isVisible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false)
        }}
        onOk={async (job: Job) => {
          const { details } = job
          try {
            await createJob({
              variables: {
                details: {
                  ...details,
                  assignee: details?.assignee?.id,
                },
              },
            })
            setIsModalVisible(false)
            message.success(`Job created!`)
            if (onSuccess) {
              await onSuccess()
            }
          } catch (err) {
            setIsModalVisible(false)
            message.error(`Could not update job: ${err.message}`)
          }
        }}
      />
    </>
  )
}

export const Actions = ({
  job,
  onSuccess,
}: {
  job: Job
  onSuccess: () => void
}) => {
  return (
    <Menu id="job-list-actions-menu">
      <Menu.Item>
        <UpdateJob job={job} onSuccess={onSuccess} />
      </Menu.Item>
      <Menu.Item>
        <DeleteJob job={job} onSuccess={onSuccess} />
      </Menu.Item>
    </Menu>
  )
}
