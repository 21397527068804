import { QueryHookOptions } from '@apollo/client'
import {
  ArchiveJobListQuery,
  ArchiveJobListQueryVariables,
  useArchiveJobListQuery,
} from 'gql/generated/graphql'
import useWrappedQuery from './useWrappedQuery'

const useArchiveJobList = (
  baseOptions: QueryHookOptions<
    ArchiveJobListQuery,
    ArchiveJobListQueryVariables
  > &
    (
      | { variables: ArchiveJobListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) => useWrappedQuery(useArchiveJobListQuery(baseOptions), data => data?.table)

export default useArchiveJobList
