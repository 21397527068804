import { useState, Dispatch, SetStateAction, ChangeEvent } from 'react'
import { useMutation, gql } from '@apollo/client'
import {
  Heading,
  Box,
  Text,
  TextInput,
  Alert,
  Button,
  Card,
} from '@weareberyl/design-system'
import validateEmail from 'utils/validateEmail'

const CREATE_USER = gql`
  mutation CREATE_USER($email: EmailAddress!) {
    create_user(email: $email)
  }
`

type createMemberProps = {
  setNewMember: Dispatch<
    SetStateAction<{
      isCreated: boolean
      id: string
      email: string
    }>
  >
}

const CreateMember = ({ setNewMember }: createMemberProps) => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)

  const [createUser, { error: submitEmailError, loading }] = useMutation(
    CREATE_USER,
    {
      variables: { email },
      onCompleted: ({ create_user: id }) => {
        setNewMember({
          isCreated: true,
          id,
          email,
        })
      },
    },
  )

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value.trim()

    setError(false)
    setEmail(emailValue)
  }

  const onSubmit = () => {
    const isEmailValid = validateEmail(email)

    if (isEmailValid && !loading) {
      createUser()
    } else if (!isEmailValid) {
      setError(true)
    }
  }

  return (
    <>
      <Box py={4} px={6}>
        <Heading variant="callout" pt={2} pb={4}>
          Create User
        </Heading>
        <Text>Email</Text>
        <TextInput
          placeholder="Email Address"
          onChange={handleChange}
          value={email}
        />
        {error && (
          <Alert
            type="error"
            message="Please provide a valid email address"
            size="small"
          />
        )}
        {submitEmailError && (
          <Alert type="error" message="Something went wrong" size="small" />
        )}
      </Box>
      <Card
        p={null}
        px={6}
        py={4}
        borderTopLeftRadius={0}
        borderTopRightRadius={0}
        variant="gray"
      >
        <Button
          title="Create"
          onPress={onSubmit}
          loading={loading}
          opacity={error ? 0.5 : 1}
        />
      </Card>
    </>
  )
}

export default CreateMember
