import { gql, useQuery } from '@apollo/client'

import { EmptyComponent, LoadingComponent } from 'components/Query/utils'
import PaymentCardDisplay from './PaymentCardDisplay'

import type {
  USER_PAYMENT_CARD,
  USER_PAYMENT_CARDVariables,
} from './__generated__/USER_PAYMENT_CARD'

export const USER_PAYMENT_CARD_QUERY = gql`
  query USER_PAYMENT_CARD($user_id: ID!) {
    user(user_id: $user_id) {
      id
      payer {
        id
        default_card {
          id
          brand
          brand_enum
          country
          exp_month
          exp_year
          funding
          last4
          type
        }
      }
    }
  }
`

export default ({ id }: { id: string }) => {
  const { data, loading } = useQuery<
    USER_PAYMENT_CARD,
    USER_PAYMENT_CARDVariables
  >(USER_PAYMENT_CARD_QUERY, {
    variables: { user_id: id },
    pollInterval: 0,
  })

  if (loading) {
    return <LoadingComponent />
  }

  if (!data?.user?.payer?.default_card) {
    return <EmptyComponent text="No payment method added yet" />
  }

  return <PaymentCardDisplay user_id={id} card={data.user.payer.default_card} />
}
