import { createContext, useState, ReactNode } from 'react'
import { CURRENT_USER } from 'types'

type CurrentUserCtx = [CURRENT_USER | undefined, (user: CURRENT_USER) => void]
export const CurrentUserContext = createContext<CurrentUserCtx>([
  undefined,
  () => {},
])

type Props = {
  user?: CURRENT_USER
  children: ReactNode
}

export default ({ user, children }: Props) => {
  const [currentUser, setCurrentUser] = useState(user)

  return (
    <CurrentUserContext.Provider value={[currentUser, setCurrentUser]}>
      {children}
    </CurrentUserContext.Provider>
  )
}
