import { Component, useState } from 'react'
import { isEqual, min } from 'lodash'
import Query from 'components/Query'
import withSchemeServiceAreas, {
  SERVICE_AREAS_GEOJSON,
} from 'components/Scheme/withSchemeServiceAreas'
import Map, { BasisMap, ViewState, BasisMapProps } from 'components/Map'
import Tooltip from 'components/Map/Tooltip'
import { gql, QueryResult } from '@apollo/client'
import { WebMercatorViewport, GeoJsonLayer } from 'deck.gl'
import bbox from '@turf/bbox'
import { BBox } from '@turf/helpers'
import {
  ServiceAreaGeoJsonLayer,
  ModuleIconLayer,
  ZoneIconLayers,
} from 'components/Map/layers'

import { JOURNEY_MAP } from './__generated__/JOURNEY_MAP'
import { ModulesNodes } from 'components/Map/__generated__/ModulesNodes'
import Legend, { LegendProps } from 'components/Map/Legend'
import { addTimesToJourneyRoute } from 'utils'
import { Position } from 'geojson'
import { useCurrentScheme } from 'hooks'

const DEFAULT_ZOOM = 15

type MapLocation = {
  latitude: number
  longitude: number
  zoom: number
}

let viewport = new WebMercatorViewport(BasisMap.defaultProps)

const QUERY = gql`
  query JOURNEY_MAP(
    $id: ID!
    $hasLocation: Boolean!
    $location: LocationAreaInput
  ) {
    journey(journey_id: $id) {
      id
      route: route_V2
      module {
        ...ModuleNode
      }
    }
    modules(location: $location) @include(if: $hasLocation) {
      ...ModulesNodes
    }
    zones(location: $location) @include(if: $hasLocation) {
      nodes {
        ...ZoneNode
      }
    }
  }
  ${ModuleIconLayer.fragments.module}
  ${ModuleIconLayer.fragments.modules}
  ${ZoneIconLayers.fragments.zone}
`

type MapQuery = JOURNEY_MAP & {
  modules?: ModulesNodes
}

type MapProps = {
  data: MapQuery
  location: {
    latitude: number
    longitude: number
    zoom: number
  }
  serviceAreas: SERVICE_AREAS_GEOJSON
  onViewStateChange: (viewState: ViewState) => void
}

type MapState = {
  x: number | null
  y: number | null
  hoveredObject: { properties: unknown } | null
  bounds: Position[] | null
  legendProps: LegendProps
  scheme_id: string | null
}

class JourneyMap extends Component<MapProps, MapState> {
  state: MapState = {
    x: null,
    y: null,
    hoveredObject: null,
    bounds: null,
    legendProps: { type: null },
    scheme_id: null,
  }

  _setLegend = (legendProps: MapState['legendProps']) => {
    this.setState({
      legendProps: legendProps,
    })
  }

  _setToolTip = ({ object, x, y }) =>
    this.setState({ x, y, hoveredObject: object })

  _getLineWidth = ({ properties }) => {
    switch (properties.name) {
      case 'Raw':
      case 'Fast':
        return 5
      case 'start':
      case 'end':
      case 'final':
        return 10
      default:
        return 0
    }
  }

  _getFillColor = ({ properties }) => {
    switch (properties.name) {
      case 'start':
        return [56, 231, 100] // green for go
      case 'end':
        return [231, 56, 100] // red for stop
      case 'final':
        return [243, 163, 26] // amber for final
      case 'step':
        return [82, 82, 92] // points in between start & end
      case 'Fast':
        return [255, 164, 14] // in progress line
      default:
        return [67, 225, 183]
    }
  }

  _generateRouteLayer = route => {
    const routeWithTimes = addTimesToJourneyRoute(route)

    return new GeoJsonLayer({
      id: 'geoJSON-layer',
      data: routeWithTimes,
      opacity: 0.4,
      stroked: true,
      filled: true,
      lineWidthMinPixels: 2,
      getRadius: 5,
      getLineWidth: this._getLineWidth,
      lineJointRounded: true,
      getLineColor: this._getFillColor,
      getFillColor: this._getFillColor,
      pickable: true,
      autoHighlight: true,
      onHover: args => this._setToolTip(args),
    })
  }

  render() {
    const { serviceAreas, data, location } = this.props
    const { x, y, hoveredObject, legendProps } = this.state

    const mapProps: Partial<BasisMapProps> = {
      layers: [],
      latitude: location.latitude,
      longitude: location.longitude,
      zoom: location.zoom,
    }
    const hasRouteData = data.journey && !!data.journey?.route?.features?.length
    const journeyModule = data.journey?.module

    mapProps.layers = [
      ServiceAreaGeoJsonLayer({
        serviceAreas,
        layerId: 'service-area-layer',
        layerProps: {
          onClick: ({ object: { properties } }) =>
            this._setLegend({
              type: 'ServiceArea',
              service_area: properties,
            }),
        },
      }),
      ModuleIconLayer({
        // Always filter out the journey module because we want to make it bigger than the
        // other modules.
        modules:
          data?.modules?.nodes.filter(m => m.id === journeyModule?.id) ?? [],
        layerId: 'modules-icon-layer',
        onHover: this._setToolTip,
        layerProps: {
          getSize: 50,
          onClick: ({ object }) =>
            this._setLegend({
              type: 'Module',
              module_id: object.id,
            }),
        },
      }),
      ...ZoneIconLayers({
        zones: data?.zones?.nodes ?? [],
        layerId: 'zone-layer',
        onHover: this._setToolTip,
        iconLayerProps: {
          onClick: ({ object }) =>
            this._setLegend({
              type: 'Zone',
              zone_id: object.id,
            }),
        },
        scheme_id: this.state.scheme_id,
      }),
    ]

    if (hasRouteData) {
      const routeLayer = this._generateRouteLayer(data.journey?.route)
      mapProps.layers.push(routeLayer)
    }

    if (journeyModule?.id) {
      mapProps.layers.push(
        ModuleIconLayer({
          modules: data.journey ? [data.journey.module] : [],
          layerId: 'module-icon-layer',
          layerProps: {
            onClick: ({ object }) =>
              this._setLegend({
                type: 'Module',
                module_id: object.id,
              }),
          },
        }),
      )
    }

    return (
      <Map
        {...mapProps}
        message="No route data is available for this journey"
        onViewStateChange={this.props.onViewStateChange}
      >
        {hoveredObject && (
          // @ts-ignore
          <Tooltip x={x} y={y} properties={hoveredObject.properties} />
        )}
        <Legend
          onClose={() => this._setLegend({ type: null })}
          {...legendProps}
        />
      </Map>
    )
  }
}

const JourneyMapWithServiceAreas = withSchemeServiceAreas(JourneyMap)

function getLocation([neLng, neLat, swLng, swLat]: BBox) {
  const bounds = [
    [neLng, neLat],
    [swLng, swLat],
  ]
  try {
    viewport = viewport.fitBounds(bounds, { padding: 50 })

    return {
      latitude: viewport.latitude,
      longitude: viewport.longitude,
      zoom: min([viewport.zoom, DEFAULT_ZOOM]),
    }
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(err)
    // tslint:disable-next-line:no-console
    console.log(`Couldn't calculate map bounds`)
  }
}

function round(n: number) {
  return n.toFixed(5)
}

function MapContainer({ id }) {
  const [bounds, setBounds] = useState<BBox>()
  const { currentSchemeId } = useCurrentScheme()

  const hasBounds = bounds && bounds?.length > 0
  function setBoundsIfNew(newBounds: BBox) {
    if (!hasBounds) {
      setBounds(newBounds)
      return
    }

    if (!isEqual(newBounds.map(round), bounds.map(round))) {
      setBounds(newBounds)
    }
  }

  function onViewStateChange({ bounds }: ViewState) {
    const { _ne, _sw } = bounds
    setBoundsIfNew([_ne.lng, _ne.lat, _sw.lng, _sw.lat])
  }

  return (
    <Query
      showEmpty={false}
      waitFor="data.journey"
      query={QUERY}
      pollInterval={0}
      variables={{
        id,
        hasLocation: !!hasBounds,
        location: hasBounds
          ? {
              longitude_min: bounds[0],
              latitude_min: bounds[1],
              longitude_max: bounds[2],
              latitude_max: bounds[3],
            }
          : null,
      }}
    >
      {(props: QueryResult<MapQuery>) => {
        const { loading, previousData } = props
        let { data } = props
        if (loading && previousData) {
          // hack: without this we get a flash of a blank
          // map when ever a query is inflight.
          // I tried notifyOnNetworkStatusChange = false
          // first but it didn't seem to work.
          data = previousData
        }

        const route = data?.journey?.route

        let location: MapLocation | undefined
        if (bounds) {
          // if the map already has bounds we set to them.
          location = getLocation(bounds)
        } else {
          // if
          location = getLocation(bbox(route))
        }

        // No map location, so get it from module location
        if (!location && data?.journey?.module.location) {
          location = {
            latitude: data.journey.module.location.latitude,
            longitude: data.journey.module.location.longitude,
            zoom: DEFAULT_ZOOM,
          }
        }

        return (
          <JourneyMapWithServiceAreas
            data={data}
            location={location}
            onViewStateChange={onViewStateChange}
            scheme_id={currentSchemeId}
          />
        )
      }}
    </Query>
  )
}

export default MapContainer
