import { Empty, Button as AntButton } from 'antd'
import { gql, useMutation, useQuery } from '@apollo/client'
import { parseISO } from 'date-fns'
import {
  Box,
  Card,
  Heading,
  LoadingDots,
  Button,
  Flex,
  Icon,
} from '@weareberyl/design-system'

import ActionModal, { ActionModalProps } from './ActionModal'
import {
  USER_EXEMPTION__DASHBOARD,
  USER_EXEMPTION__DASHBOARDVariables,
  USER_EXEMPTION__DASHBOARD_user_charge_exemptions,
} from './__generated__/USER_EXEMPTION__DASHBOARD'
import {
  SET_USER_EXEMPTION__DASHBOARD,
  SET_USER_EXEMPTION__DASHBOARDVariables,
} from './__generated__/SET_USER_EXEMPTION__DASHBOARD'
import {
  DELETE_USER_EXEMPTION__DASHBOARD,
  DELETE_USER_EXEMPTION__DASHBOARDVariables,
} from './__generated__/DELETE_USER_EXEMPTION__DASHBOARD'

type ChargeExemption = USER_EXEMPTION__DASHBOARD_user_charge_exemptions

const USER_EXEMPTION_QUERY = gql`
  query USER_EXEMPTION__DASHBOARD($userId: ID!, $schemeId: ID!) {
    user(user_id: $userId) {
      id
      charge_exemptions(scheme_id: $schemeId) {
        charge_type
        created_at
        user_id
        scheme_id
      }
    }
  }
`

const SET_USER_EXEMPTION_MUTATION = gql`
  mutation SET_USER_EXEMPTION__DASHBOARD($userId: ID!, $schemeId: ID!) {
    create_charge_exemption(user_id: $userId, scheme_id: $schemeId) {
      message
    }
  }
`

const DELETE_USER_EXEMPTION_MUTATION = gql`
  mutation DELETE_USER_EXEMPTION__DASHBOARD($userId: ID!, $schemeId: ID!) {
    delete_charge_exemption(user_id: $userId, scheme_id: $schemeId) {
      message
    }
  }
`

const useChargeExemptions = (userId: string, schemeId: string) => {
  const variables = { userId, schemeId }
  const refetchQueries = [USER_EXEMPTION_QUERY]

  const { data, loading } = useQuery<
    USER_EXEMPTION__DASHBOARD,
    USER_EXEMPTION__DASHBOARDVariables
  >(USER_EXEMPTION_QUERY, { variables, pollInterval: 0 })

  const [createChargeExemption, { loading: savingCreate }] = useMutation<
    SET_USER_EXEMPTION__DASHBOARD,
    SET_USER_EXEMPTION__DASHBOARDVariables
  >(SET_USER_EXEMPTION_MUTATION, { variables, refetchQueries })

  const [deleteChargeExemption, { loading: savingDelete }] = useMutation<
    DELETE_USER_EXEMPTION__DASHBOARD,
    DELETE_USER_EXEMPTION__DASHBOARDVariables
  >(DELETE_USER_EXEMPTION_MUTATION, { refetchQueries })

  const saving = savingCreate || savingDelete

  return {
    chargeExemptions: data?.user?.charge_exemptions,
    createChargeExemption,
    deleteChargeExemption,
    loading,
    saving,
  }
}

type ItemProps = {
  chargeExemption: ChargeExemption
  deleteItem: (userId: string, schemeId: string) => void
  saving: boolean
}
const Item = ({ chargeExemption, deleteItem, saving }: ItemProps) => {
  const deleteExemption = () =>
    deleteItem(chargeExemption.user_id, chargeExemption.scheme_id)
  const date = parseISO(chargeExemption.created_at)
  return (
    <Flex
      style={{ gap: '10px', justifyContent: 'space-between' }}
      bg="oyster"
      borderRadius={5}
      px={3}
      pt={3}
    >
      <Box>
        <Heading variant="h3" mb={3}>
          😇 Rider will not be fined for:
        </Heading>
        <pre style={{ fontSize: 12 }}>{chargeExemption.charge_type}</pre>
        <p>Created: {date.toLocaleString()}</p>
      </Box>
      <AntButton
        type="primary"
        title="Delete"
        onClick={deleteExemption}
        loading={saving}
      >
        Delete
      </AntButton>
    </Flex>
  )
}

type Props = ActionModalProps & {
  userId: string
  schemeId: string
}
const ChargeExemptionModal = ({ visible, close, userId, schemeId }: Props) => {
  const {
    chargeExemptions,
    createChargeExemption,
    deleteChargeExemption,
    loading,
    saving,
  } = useChargeExemptions(userId, schemeId)

  const deleteItem = (userId: string, schemeId: string) => {
    deleteChargeExemption({ variables: { userId, schemeId } })
  }

  // There's only exemption available at the moment so if they have one, that's all
  const canAdd = !chargeExemptions?.length

  return (
    <ActionModal visible={visible} close={close}>
      <Box py={4} px={6}>
        <Heading variant="callout" pt={2} pb={4}>
          Charge exemptions
        </Heading>
        {chargeExemptions?.map(item => (
          <Item
            chargeExemption={item}
            key={item.charge_type}
            saving={saving}
            deleteItem={deleteItem}
          />
        ))}
        {!chargeExemptions?.length && !loading && (
          <Empty description="No exemptions yet" />
        )}
        {loading && <LoadingDots />}
      </Box>
      <Card
        p={null}
        px={6}
        py={4}
        borderTopLeftRadius={0}
        borderTopRightRadius={0}
        variant="gray"
      >
        <Flex style={{ gap: '10px' }}>
          <Icon color="grape" height={30} width={30} type="tether" />
          <Box flex={1}>
            <p>
              If the customer has repeatedly proven that they do use the front
              lock, create an exemption to prevent them from being fined again.
            </p>
          </Box>
        </Flex>
        <Button
          title="Create partial lock fine exemption"
          onPress={createChargeExemption}
          loading={saving}
          disabled={!canAdd}
        />
      </Card>
    </ActionModal>
  )
}

export default ChargeExemptionModal
