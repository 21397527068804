import { Progress, Table } from 'antd'
import type { ColumnProps, TableProps } from 'antd/lib/table'
import { SorterResult } from 'antd/lib/table/interface'
import type { Pagination } from 'types'

interface ICustomSorterResult<T> extends SorterResult<T> {
  column: ICustomColumnProps<T>
}

export interface ICustomColumnProps<T> extends ColumnProps<T> {
  sortFunction?: (sortDirection: 'asc' | 'desc') => { [key: string]: any }
}

interface Variables {
  order_by?: [{ [key: string]: any }]
}
interface BasisTableProps<T> extends TableProps<T> {
  data: {
    table: {
      nodes: (T & { uuid?: string; id?: string })[]
      pagination?: Pagination | false
    } | null
  } | null
  id?: string
  refetch?: (variables: unknown) => Promise<void>
  hideProgress?: boolean
  customRowKey?: keyof T
  variables?: Variables
}

export default function BasisTable<T>({
  columns,
  refetch,
  onChange,
  onRow,
  rowSelection,
  data,
  rowClassName,
  variables = {},
  customRowKey,
  loading = false,
  hideProgress = false,
  bordered = false,
  ...rest
}: BasisTableProps<T>) {
  const { nodes, pagination } = data?.table || { nodes: [], pagination: false }

  return (
    <div className="basis-table">
      {!hideProgress && (
        <Progress
          showInfo={false}
          status={loading ? 'active' : 'normal'}
          strokeLinecap={'square'}
          percent={100}
          strokeWidth={2}
        />
      )}
      {nodes && nodes.length > 0 && (
        <Table<T & { uuid?: string; id?: string }>
          {...rest}
          scroll={{ x: true }}
          loading={loading}
          bordered={bordered}
          rowClassName={rowClassName}
          rowKey={node => {
            let key = node.id ?? node.uuid ?? JSON.stringify(node)
            if (customRowKey && node[customRowKey]) {
              key = `${key}:${node[customRowKey]}`
            }
            return key
          }}
          rowSelection={rowSelection}
          onRow={onRow}
          dataSource={nodes}
          columns={columns}
          pagination={{
            ...pagination,
            hideOnSinglePage: true,
          }}
          onChange={async (
            pagination,
            filters,
            { column, order, ...otherSorterFields }: ICustomSorterResult<any>,
            sorter,
          ) => {
            if (column) {
              // url state does not support yet support filter state
              // so we just refetch.
              const { sortFunction } = column
              const sortOrder = order === 'ascend' ? 'asc' : 'desc'
              if (sortFunction) {
                variables.order_by = [sortFunction(sortOrder)]
                refetch?.(variables)
              }
            } else {
              return onChange?.(
                pagination,
                filters,
                { column, order, ...otherSorterFields },
                sorter,
              )
            }
          }}
        />
      )}
    </div>
  )
}
