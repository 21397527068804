import React, { CSSProperties } from 'react'
import { Row, Col } from 'antd'

type CenterProps = {
  className?: string
  style?: CSSProperties
  containerStyle?: any
  span?: number
  children?: any
}

export const Center = ({
  containerStyle,
  span = 6,
  children,
  ...props
}: CenterProps) => (
  <Row justify="center" style={{ marginTop: '5%' }}>
    <Col style={containerStyle} span={span} xs={18}>
      <div {...props}>{children}</div>
    </Col>
  </Row>
)
