import { ExportOutlined } from '@ant-design/icons'
import { TextLink } from '@weareberyl/design-system'
import type { Props as TextLinkProps } from '@weareberyl/design-system/build/components/Typography/TextLink'

const ExternalLink = ({ children, ...textLinkProps }: TextLinkProps) => {
  return (
    <TextLink variant="gray" {...textLinkProps}>
      {children}{' '}
      <ExportOutlined style={{ fontSize: '0.8em', marginLeft: '0.6ex' }} />
    </TextLink>
  )
}

export default ExternalLink
