import {
  ArchiveJobListQuery_table_ArchiveJobConnection,
  ArchiveJobListQueryVariables,
  ArchiveJobListQuery,
  ArchiveJobListQuery_table_ArchiveJobConnection_nodes_ArchiveJob,
} from 'gql/generated/graphql'
import Table from 'components/Table'
import { RefetchFunction } from '@apollo/client/react/hooks/useSuspenseQuery'
import { columns } from './columns'
import { useJobTypes } from 'hooks'

interface IArchiveJobsTable {
  table: ArchiveJobListQuery_table_ArchiveJobConnection | undefined
  refetch: RefetchFunction<ArchiveJobListQuery, ArchiveJobListQueryVariables>
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  setPageSize: React.Dispatch<React.SetStateAction<number>>
}

const ArchiveJobsTable = ({
  table,
  refetch,
  setCurrentPage,
  setPageSize,
}: IArchiveJobsTable) => {
  const onChange = ({ current, pageSize }) => {
    setCurrentPage(current)
    setPageSize(pageSize)
  }

  const { jobCodeLookup } = useJobTypes()

  return (
    <Table<ArchiveJobListQuery_table_ArchiveJobConnection_nodes_ArchiveJob>
      id="archive-jobs-table"
      onChange={onChange}
      data={{
        table: table ?? null,
      }}
      columns={columns({ refetch, jobCodeLookup })}
      loading={!table}
    />
  )
}

export default ArchiveJobsTable
