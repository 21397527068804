import { gql, useMutation } from '@apollo/client'
import * as types from './__generated__/requestVehicleLowBatteryOverrideEnd'

const query = gql`
  mutation requestVehicleLowBatteryOverrideEnd($vehicleId: GUID!) {
    vehicle_low_battery_override_end(vehicle_id: $vehicleId)
  }
`

export default function useLowBatteryOverrideEnd(
  variables?: types.requestVehicleLowBatteryOverrideEndVariables,
) {
  return useMutation<
    types.requestVehicleLowBatteryOverrideEnd,
    types.requestVehicleLowBatteryOverrideEndVariables
  >(query, {
    variables,
    context: {
      useApolloNetworkStatus: false,
    },
  })
}
