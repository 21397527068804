import { Component } from 'react'
import { QueryResult } from '@apollo/client'
import Query from 'components/Query'
import withSchemeServiceAreas, {
  SERVICE_AREAS_GEOJSON,
} from 'components/Scheme/withSchemeServiceAreas'
import Map, { ViewState, BasisMapProps } from 'components/Map'
import {
  ServiceAreaGeoJsonLayer,
  ModuleIconLayer,
  ZoneIconLayers,
} from 'components/Map/layers'

import { LocationAreaInput } from '../../__generated__/globalTypes'
import { SCHEME } from 'types'
import Legend, { LegendProps } from 'components/Map/Legend'
import { getDerivedModule } from 'utils/derived'
import { ModuleMapDocument, ModuleMapQuery } from 'gql/generated/graphql'

type MapProps = {
  id: string
  scheme: SCHEME
  serviceAreas: SERVICE_AREAS_GEOJSON
}

type MapState = {
  legendProps: LegendProps | null
  bounds: LocationAreaInput | null
}

class ModuleMap extends Component<MapProps, MapState> {
  state: MapState = {
    bounds: null,
    legendProps: null,
  }

  _setLegend = (legendProps: MapState['legendProps']) => {
    this.setState({
      legendProps: legendProps,
    })
  }

  _onViewStateChange = (viewState: ViewState) => {
    const bounds = {
      longitude_min: viewState.bounds._ne.lng,
      longitude_max: viewState.bounds._sw.lng,
      latitude_min: viewState.bounds._ne.lat,
      latitude_max: viewState.bounds._sw.lat,
    }
    this.setState({ bounds })
  }

  render() {
    const { id, serviceAreas } = this.props
    const { legendProps, bounds } = this.state

    return (
      <Query
        showEmpty={false}
        waitFor="data.module"
        query={ModuleMapDocument}
        variables={{
          id,
          hasLocation: bounds !== null,
          location: bounds,
        }}
      >
        {({ data, loading, previousData }: QueryResult<ModuleMapQuery>) => {
          const mapProps: Partial<BasisMapProps> = { layers: [] }

          if (loading && previousData) {
            // hack: without this we get a flash of a blank
            // map when ever a query is inflight.
            // I tried notifyOnNetworkStatusChange = false
            // first but it didn't seem to work.
            data = previousData
          }

          if (data?.module?.location) {
            mapProps.latitude = data?.module?.location?.latitude
            mapProps.longitude = data?.module?.location?.longitude
            const module = getDerivedModule(data?.module)
            const modules = (
              data?.modules?.nodes.filter(m => m.id !== id) ?? []
            ).map(getDerivedModule)

            mapProps.layers = [
              ServiceAreaGeoJsonLayer({
                serviceAreas,
                layerId: 'service-area-layer',
                layerProps: {
                  onClick: ({ object: { properties } }) =>
                    this._setLegend({
                      type: 'ServiceArea',
                      service_area: properties,
                    }),
                },
              }),
              ...ZoneIconLayers({
                zones: data?.zones?.nodes ?? [],
                layerId: 'zone-layer',
                iconLayerProps: {
                  onClick: ({ object }) =>
                    this._setLegend({
                      type: 'Zone',
                      zone_id: object.id,
                    }),
                },
                scheme_id: this.props.scheme.id,
              }),
              ModuleIconLayer({
                modules,
                layerId: 'modules-icon-layer',
                layerProps: {
                  getSize: 50,
                  onClick: ({ object }) =>
                    this._setLegend({
                      type: 'Module',
                      module_id: object.id,
                    }),
                },
              }),
              ModuleIconLayer({
                modules: [module],
                layerId: 'module-icon-layer',
                layerProps: {
                  onClick: ({ object }) =>
                    this._setLegend({
                      type: 'Module',
                      module_id: object.id,
                    }),
                },
                includeNonPresentModules: true,
              }),
            ]
          }

          return (
            <Map
              {...mapProps}
              message="Could not find module location"
              onViewStateChange={this._onViewStateChange}
            >
              <Legend
                onClose={() => this._setLegend(null)}
                {...(legendProps as LegendProps)}
              />
            </Map>
          )
        }}
      </Query>
    )
  }
}

export default withSchemeServiceAreas(ModuleMap)
