import { Row, Col, Button } from 'antd'
import sentry from 'utils/sentry'

import history from 'client/history'
import * as routes from 'constants/routes'
import { Center } from 'components/Layout'

type Props = {
  onClick: () => void
}

export default (props: Props) => {
  return (
    <Center
      style={{
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div>
        <h1>Oh no!</h1>
        <p>
          It looks like you tried to open the email link in a different browser,
          please try again using the same browser.
        </p>
        <p>If this is not the case then please report an error.</p>
        <div style={{ maxWidth: 320, margin: '20px auto 0' }}>
          <Row justify="space-around">
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  sentry.captureMessage('No email in Signin transaction')
                  history.replace(routes.SIGN_IN)
                  props.onClick()
                }}
              >
                Report error
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  history.replace(routes.SIGN_IN)
                  props.onClick()
                }}
              >
                Retry
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Center>
  )
}
