import { gql, useMutation } from '@apollo/client'
import * as types from './__generated__/requestVehicleLocated'

const query = gql`
  mutation requestVehicleLocated($vehicleId: GUID!) {
    vehicle_located(vehicle_id: $vehicleId)
  }
`

export default function useLocated(
  variables?: types.requestVehicleLocatedVariables,
) {
  return useMutation<
    types.requestVehicleLocated,
    types.requestVehicleLocatedVariables
  >(query, {
    variables,
    context: {
      useApolloNetworkStatus: false,
    },
  })
}
